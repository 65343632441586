/* eslint-disable react-hooks/exhaustive-deps */
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../core/AppStyles";
import useIsMobile from "../../shared/hooks/useIsMobile";
import useIsTablet from "../../shared/hooks/useIsTablet";
import CustomHeader from "../../shared/custom/CustomHeader";
import useIsDesktop from "../../shared/hooks/useIsDesktop";
import { useNavigate } from "react-router";
import {
  useLazyCheckUsersWithUncomletedTTQuery,
  useLazyGetAdminDashboardQuery,
} from "../../data/endpoints/app.endpoints";
import { useSelector } from "react-redux";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SingleCardDashboard from "./testDashboard/DashboardComponents/SingleCard-Dashboard";
import PieChartAdmin from "./testDashboard/AdminDahboardComponents/PieChart";
import ModalDashboardLegend from "./testDashboard/DashboardComponents/ModalDashboardLegend";
import KinetonChatContanier from "./testDashboard/DashboardComponents/KinetonChatBox/KinetonChatContanier";
import LeaveRequestNotifyComponent from "./testDashboard/DashboardComponents/LeaveRequestNotifyComponent";
import { useEffect, useMemo } from "react";
import SsidChartIcon from "@mui/icons-material/SsidChart";
import VerticalSplitIcon from "@mui/icons-material/VerticalSplit";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import useIsLargeScreen from "../../shared/hooks/useIsLargeScreen";
import { useTranslation } from "react-i18next";
import UsersNotCompletedTimeTracker from "./testDashboard/AdminDahboardComponents/UsersNotCompletedTimeTracker";
import moment from "moment";
import { UserRole } from "../../utils/enums/user-role.enum";

const DashboardAdmin = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isDesktop = useIsDesktop();
  const isLargeScreen = useIsLargeScreen();
  const navigate = useNavigate();
  const { user } = useSelector((state: any) => state.auth);

  const [adminDashboard, adminDashboardResult] =
    useLazyGetAdminDashboardQuery();

  const { t } = useTranslation();

  useEffect(() => {
    adminDashboard(undefined);
  }, []);
  const attendance = adminDashboardResult?.data?.allAbsents?.length;

  const { adminDashboardDatas } = useSelector(
    (state: any) => state.adminDashboard
  );
  const projectsPerDepartment = adminDashboardDatas?.projectPerDep;
  const userPerDepartment = adminDashboardDatas?.getUserDep;

  const handleNavigate = () => {
    navigate("/absents");
  };

  const leavesPending = adminDashboardResult?.data?.pendingLeaves;

  const handleNavigateToProjects = () => {
    navigate("/projects");
  };
  const handleNavigateToEmployess = () => {
    navigate("/employees");
  };

  const navigateToTimeTracker = (e: any) => {
    e.preventDefault();
    navigate("/view-time-trackers");
  };

  const navigateToProjectDetails = () => {
    navigate("/ongoing-projects");
  };
  const navigateToEmployees = () => {
    navigate("/projects-employees");
  };

  const [IncompleteTT, resultIncompleteTT] =
    useLazyCheckUsersWithUncomletedTTQuery();

  const firstDayOfMonth = moment().startOf("month").subtract(2, "weeks").startOf('weeks');
  const endDate = moment().subtract(1, "days");
  const numberOfDays = endDate.diff(firstDayOfMonth, "days");

  useEffect(() => {
    IncompleteTT({
      startDate: firstDayOfMonth.format("YYYY-MM-DD"),
      endDate: endDate.format("YYYY-MM-DD"),
    });
  }, []);

  const UnCompletTimeTrackers = resultIncompleteTT?.data;

  const projectsPerDepartmentMemo = useMemo(() => {
    if (projectsPerDepartment) {
      const d: { name: string; value: number }[] = projectsPerDepartment?.map(
        (item: any) => {
          return {
            name: item?.depName,
            value: Number(item?.depCount),
          };
        }
      );

      return (
        <PieChartAdmin
          data={d}
          width={300}
          height={300}
          outerRadius={isLargeScreen ? 65 : isDesktop ? 45 : 40}
        />
      );
    }
  }, [projectsPerDepartment]);

  const userPerDepartmentMemo = useMemo(() => {
    if (userPerDepartment) {
      const users: { name: string; value: number }[] = userPerDepartment?.map(
        (item: any) => {
          return {
            name: item?.department.name,
            value: Number(item?.projectCount),
          };
        }
      );
      return (
        <PieChartAdmin
          data={users}
          width={300}
          height={300}
          outerRadius={isLargeScreen ? 65 : isDesktop ? 45 : 40}
        />
      );
    }
  }, [userPerDepartment]);

  const seeEmployess = () => {
    navigate("/user-project");
  };
  const seeProjects = () => {
    navigate("/ongoing-projects");
  };

  let col = "";
  let backCol = "";
  user?.role === UserRole.ADMIN
    ? (col = "#EE9932") && (backCol = "#EE9632")
    : (col = "black") && (backCol = "#78D6C6");
  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        style={{
          width: `${isMobile ? "100%" : "auto"}`,
        }}
      >
        <CustomHeader
          title={t("DASHBOARD.TITLE")}
          subtitle={t("DASHBOARD.SUBTITLE") + " " + user?.firstName}
        />

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
            height: "auto",
            width: `${isMobile ? "80px" : "8vw"}`,
          }}
        >
          <p
            style={{
              padding: "4px 7px",
              borderRadius: "8px",
              margin: 0,

              color: `${col}`,
              fontSize: isLargeScreen ? "1.1rem" : "auto",
              border: `1px solid ${backCol}`,
            }}
          >
            {user?.role}
          </p>
          {isMobile || isTablet ? "" : <ModalDashboardLegend />}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          width: "100%",
          height: "auto",
          mt: "2%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: `${isMobile || isTablet ? "column" : "row"}`,
            justifyContent: "space-between",
            width: "100%",
            height: `${isMobile || isTablet ? "auto" : "22vh"}`,
          }}
        >
          <Box
            style={{
              width: `${isMobile || isTablet ? "100%" : "31%"}`,
              height: isLargeScreen ? "100%" : "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: isTablet ? "center" : "auto",
              paddingBottom: isMobile ? "2vh" : isTablet ? "2vh" : "0",
              gap: isMobile ? 4 : 10,
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: isTablet ? "75%" : "28%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderRadius: "10px",
                boxShadow: "1px 1px 3px 0.5px rgba(0, 0, 0, 0.08)",
                background: colors.component,
                margin: 0,
                padding: "0 2% 0 2%",
                cursor: "pointer",
              }}
              onClick={navigateToProjectDetails}
            >
              <SsidChartIcon
                color="secondary"
                sx={{
                  fontSize: isLargeScreen ? "30px" : "auto",
                }}
              />
              <p
                style={{
                  width: "80%",
                  fontSize: isLargeScreen ? "1.1rem" : "auto",
                }}
              >
                {t("DASHBOARD.ADMIN.PROJECT_TRACKER")}
              </p>
              <ArrowForwardIosIcon color="inherit" />
            </Box>
            <Box
              sx={{
                width: "100%",
                height: isTablet ? "75%" : "28%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderRadius: "10px",
                boxShadow: "1px 1px 3px 0.5px rgba(0, 0, 0, 0.08)",
                background: colors.component,
                margin: 0,
                padding: "0 2% 0 2%",
                cursor: "pointer",
              }}
              onClick={navigateToEmployees}
            >
              <VerticalSplitIcon
                sx={{
                  color: "orange",
                  fontSize: isLargeScreen ? "30px" : "auto",
                }}
              />
              <p
                style={{
                  width: "80%",
                  fontSize: isLargeScreen ? "1.1rem" : "auto",
                }}
              >
                {t("DASHBOARD.ADMIN.PROJECT_INSIGHTS")}
              </p>
              <ArrowForwardIosIcon color="inherit" />
            </Box>
            <Box
              sx={{
                width: "100%",
                height: isTablet ? "75%" : "28%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderRadius: "10px",
                boxShadow: "1px 1px 3px 0.5px rgba(0, 0, 0, 0.08)",
                background: colors.component,
                margin: 0,
                padding: "0 2% 0 2%",
                cursor: "pointer",
              }}
              onClick={navigateToTimeTracker}
            >
              <AccessTimeIcon
                style={{
                  color: "#0088FE",
                  fontSize: isLargeScreen ? "30px" : "auto",
                }}
              />
              <p
                style={{
                  width: "80%",
                  fontSize: isLargeScreen ? "1.1rem" : "auto",
                }}
              >
                {t("DASHBOARD.ADMIN.SEE_COMPLETED_TIME_TRACKERS")}
              </p>
              <ArrowForwardIosIcon color="inherit" />
            </Box>
          </Box>
          <Box
            sx={{
              width: `${isMobile || isTablet ? "100%" : "31%"}`,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: 1,
              height: "auto",
              // marginTop: `${isMobile ? "2vh" : "0vh"}`,
            }}
          >
            <SingleCardDashboard
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={isLargeScreen ? "35" : isTablet ? "23" : "22"}
                  height={isLargeScreen ? "35" : isTablet ? "23" : "22"}
                  fill="orange"
                  className="bi bi-people-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7Zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-5.784 6A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216ZM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                </svg>
              }
              number={adminDashboardResult?.data?.totalUsers?.length}
              title={t("USERS").toLocaleLowerCase()}
              clickFunction={handleNavigateToEmployess}
            />
            <SingleCardDashboard
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={isLargeScreen ? "35" : isTablet ? "23" : "22"}
                  height={isLargeScreen ? "35" : isTablet ? "23" : "22"}
                  fill={colors.blueAccent[400]}
                  className="bi bi-diagram-3-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M6 3.5A1.5 1.5 0 0 1 7.5 2h1A1.5 1.5 0 0 1 10 3.5v1A1.5 1.5 0 0 1 8.5 6v1H14a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0v-1A.5.5 0 0 1 2 7h5.5V6A1.5 1.5 0 0 1 6 4.5v-1zm-6 8A1.5 1.5 0 0 1 1.5 10h1A1.5 1.5 0 0 1 4 11.5v1A1.5 1.5 0 0 1 2.5 14h-1A1.5 1.5 0 0 1 0 12.5v-1zm6 0A1.5 1.5 0 0 1 7.5 10h1a1.5 1.5 0 0 1 1.5 1.5v1A1.5 1.5 0 0 1 8.5 14h-1A1.5 1.5 0 0 1 6 12.5v-1zm6 0a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5v-1z"
                  />
                </svg>
              }
              number={adminDashboardResult?.data?.totalProject?.length}
              title={t("DASHBOARD.ADMIN.PROJECTS").toLocaleLowerCase()}
              clickFunction={handleNavigateToProjects}
            />
            <SingleCardDashboard
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={isLargeScreen ? "35" : isTablet ? "23" : "22"}
                  height={isLargeScreen ? "35" : isTablet ? "23" : "22"}
                  fill="#00C49F"
                  className="bi bi-person-vcard-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm9 1.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 0-1h-4a.5.5 0 0 0-.5.5ZM9 8a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 0-1h-4A.5.5 0 0 0 9 8Zm1 2.5a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 0-1h-3a.5.5 0 0 0-.5.5Zm-1 2C9 10.567 7.21 9 5 9c-2.086 0-3.8 1.398-3.984 3.181A1 1 0 0 0 2 13h6.96c.026-.163.04-.33.04-.5ZM7 6a2 2 0 1 0-4 0 2 2 0 0 0 4 0Z" />
                </svg>
              }
              number={attendance}
              title={t("DASHBOARD.ADMIN.TODAY_ATTENDANCE").toLocaleLowerCase()}
              clickFunction={handleNavigate}
            />
          </Box>
          <Box
            sx={{
              width: isMobile || isTablet ? "100%" : "30%",
              height: "100%",
              marginTop: isMobile ? "0" : isTablet ? "2vh" : "0",
            }}
          >
            <LeaveRequestNotifyComponent leavesPending={leavesPending} />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: `${isMobile ? "column" : "row"}`,
            justifyContent: "space-between",
            alignItems: "flex-start",
            width: "100%",
            height: `${isMobile ? "auto" : "100%"}`,
            paddingTop: isMobile ? "1vh" : "2vh",
          }}
        >
          <Box
            sx={{
              width: isMobile || isTablet ? "100%" : "66%",
              height: "auto",
              display: "flex",
              justifyContent:
                isMobile || isTablet ? "space-between" : "space-between",
              flexWrap: "wrap",
              flexDirection: isMobile ? "column" : "row",
              paddingBottom: "1vh",
            }}
          >
            <Box
              sx={{
                width: `${isMobile ? "100%" : isTablet ? "48%" : "47%"}`,
                height: `${isMobile ? "200px" : "auto"}`,
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                boxShadow: "1px 1px 3px 0.5px rgba(0, 0, 0, 0.08)",
                background: colors.component,
                padding: `${isMobile ? "2%" : "1%"}`,
                marginTop: `${isMobile ? "1vh" : "0"}`,
                borderRadius: "10px",
                overflow: "hidden",
              }}
            >
              <Box
                style={{
                  padding: 2,
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  textDecoration: "none",
                }}
              >
                <b
                  style={{
                    fontSize: `${
                      isMobile ? "13px" : isLargeScreen ? "1rem" : "auto"
                    }`,
                  }}
                >
                  {t("DASHBOARD.ADMIN.USERS_PER_DEPARTMENT")}
                </b>
                <button
                  type="button"
                  onClick={seeEmployess}
                  style={{
                    border: "none",
                    backgroundColor: "transparent",
                    color: colors.blueAccent[400],
                    cursor: "pointer",
                    fontSize: `${
                      isMobile ? "11px" : isLargeScreen ? "1rem" : "auto"
                    }`,
                  }}
                >
                  {t("SEE_MORE")}
                </button>
              </Box>

              <Box
                sx={{
                  width: "100%",
                  height: "25vh",
                  display: "flex",
                  alignItems: "center",
                  overflow: "hidden",
                }}
              >
                <ul
                  style={{
                    listStyle: "none",
                    fontSize: `${
                      isMobile ? "12px" : isLargeScreen ? "1.1rem" : "auto"
                    }`,
                  }}
                >
                  {userPerDepartment?.map((dep: any, index: number) => (
                    <li key={index}>{dep?.department?.name}</li>
                  ))}
                </ul>
                {userPerDepartmentMemo}
              </Box>
            </Box>
            <Box
              sx={{
                width: `${isMobile ? "100%" : isTablet ? "48%" : "47%"}`,
                height: `${isMobile ? "200px" : "auto"}`,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                overflow: "hidden",
                boxShadow: "1px 1px 3px 0.5px rgba(0, 0, 0, 0.08)",
                background: colors.component,
                padding: `${isMobile ? "2%" : "1%"}`,
                marginTop: `${isMobile ? "2vh" : "0"}`,
                border: "5px",
                borderRadius: "10px",
              }}
            >
              <Box
                style={{
                  padding: 2,
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  textDecoration: "none",
                }}
              >
                <b
                  style={{
                    fontSize: `${
                      isMobile ? "13px" : isLargeScreen ? "1rem" : "auto"
                    }`,
                  }}
                >
                  {t("DASHBOARD.ADMIN.PROJECTS_PER_DEPARTMENT")}
                </b>
                <button
                  type="button"
                  onClick={seeProjects}
                  style={{
                    padding: 2,
                    border: "none",
                    backgroundColor: "transparent",
                    color: colors.blueAccent[400],
                    cursor: "pointer",
                    fontSize: `${
                      isMobile ? "11px" : isLargeScreen ? "1rem" : "auto"
                    }`,
                  }}
                >
                  {t("SEE_MORE")}
                </button>
              </Box>

              <Box
                sx={{
                  width: "100%",
                  height: "25vh",
                  display: "flex",
                  alignItems: "center",
                  overflow: "hidden",
                }}
              >
                <ul
                  style={{
                    listStyle: "none",
                    fontSize: `${
                      isMobile ? "13px" : isLargeScreen ? "1.1rem" : "auto"
                    }`,
                  }}
                >
                  {projectsPerDepartment?.map((dep: any, index: number) => (
                    <li key={index}>{dep?.depName}</li>
                  ))}
                </ul>
                {projectsPerDepartmentMemo}
              </Box>
            </Box>

            <Box
              sx={{
                width: isMobile ? "100%" : "100%",
                height: "auto",
                borderRadius: "8px",
                boxShadow: `${colors.shadow} 1px 1px 5px 1px`,
                marginTop: isMobile ? "2vh" : "2vh",
              }}
            >
              <UsersNotCompletedTimeTracker
                data={UnCompletTimeTrackers}
                nrDays={numberOfDays}
              />
            </Box>
          </Box>
          <Box
            sx={{
              width: "30%",
              height: "48vh",
              display: `${isMobile || isTablet ? "none" : "flex"}`,
              flexDirection: "column",
              justifyContent: "flex-start",
              cursor: "pointer",
            }}
          >
            <KinetonChatContanier />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default DashboardAdmin;
