import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import {
  BLUE_COLOR_ACCENT,
  BLUE_HOVER_COLOR_ACCENT,
} from "../../utils/constants/colors";
import CustomButton from "../../shared/custom/CustomButton";
import { Box, Button, Typography, useTheme } from "@mui/material";
import CustomTextField from "../../shared/custom/CustomTextField";
import { useContext, useEffect, useState } from "react";
// import { useChangeUserWorkingHoursFromDateMutation, useUserCompensationMutation } from "../../data/endpoints/app.endpoints";
import { forwardRef } from "react";
import { RootContext } from "../../context/RootContext";
import { useSelector } from "react-redux";
import { selectEmployeeById } from "../../data/store/employeeSlice";
import { tokens } from "../../core/AppStyles";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";

import { useTranslation } from "react-i18next";
import CustomDatePicker from "../../shared/CustomDatePicker";
import { Formik, FormikHelpers } from "formik";
import { handleRequiredValidation } from "../../utils/regex/formRegex";
import moment from "moment";
import { useCreateContractMutation } from "../../data/endpoints/app.endpoints";
import dayjs from "dayjs";
import { Work } from "@mui/icons-material";
import useIsMobile from "../../shared/hooks/useIsMobile";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface ICompensationUser {
  id: string | undefined;
  name: string;
}
interface Icompesate {
  activityType: string;
  hours: any;
  actionBy: string;
  userId: string | undefined;
}

export default function ChangeWorkingHoursFromDateModal({
  id,
  name,
}: ICompensationUser) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { showSnackbar } = useContext(RootContext);
  // const [changeUserWorkingHours, result] = useChangeUserWorkingHoursFromDateMutation();
  const user = useSelector(selectEmployeeById(id));
  const [createContract] = useCreateContractMutation();
  const isMobile = useIsMobile();

  const initialValues = {
    userId: user.userId,
    percentage: "",
    date: "",
    isLoading: false,
  };

  const validate = (values: typeof initialValues) => {
    const errors: Partial<typeof values> = {};

    handleRequiredValidation(values, errors, "percentage", "Required");

    if (!values.date) {
      errors.date = "Date is required!";
    } else {
      const isDateValid = moment(values.date).isValid();
      if (!isDateValid) {
        errors.date = "";
      }
    }

    if (Number(values.percentage) <= 0) {
      errors.percentage = "Value should be bigger than 0.";
    }

    if (values.percentage === "") {
      errors.percentage = "Percentage is required!";
    }

    return errors;
  };

  const onSubmit = async (
    values: typeof initialValues,
    formikHelpers: FormikHelpers<typeof values>
  ) => {
    formikHelpers.setFieldValue("isLoading", true);
    const response = await createContract({
      startDate: values.date,
      userId: values.userId,
      percentage: Number(values.percentage),
      workingHours: user.workingHours,
    });
    // const response = await changeUserWorkingHours({
    //     date: values.date,
    //     userId: values.userId,
    //     percentage: Number(values.percentage)
    // })

    if ("data" in response) {
      showSnackbar("New contract successfully added!", false, true);
    } else {
      if ((response.error as any).data.error.message) {
        showSnackbar((response.error as any).data.error.message, true, true);
      } else {
        showSnackbar(
          "There was an error while adding a new contract!",
          true,
          true
        );
      }
    }

    formikHelpers.setFieldValue("isLoading", false);
    formikHelpers.resetForm();

    setOpen(false);
  };

  // const [compensation, setCompensation] = useState<Icompesate>({
  //     activityType: "vacation compensation",
  //     hours: "",
  //     actionBy: loggedUser?.firstName + " " + loggedUser?.lastName,
  //     userId: id,
  // });
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChangeCompensation = (event: any) => {
    // setCompensation((prevState) => ({
    //     ...prevState,
    //     hours: event.target.value ? Number(event.target.value) : "",
    // }));
  };

  useEffect(() => {
    // if (compensation.hours === "") {
    //     setCanSubmit(false);
    //     setError(`${t("FORM_VALIDATIONS.REQUIRED")}`);
    // } else if (compensation.hours === 0) {
    //     setCanSubmit(false);
    //     setError(`${t("USERS_ADMIN.VIEW_USER.VALUE_ZERO")}`);
    // } else {
    //     setCanSubmit(true);
    //     setError("");
    // }
  }, []);

  return (
    <>
      <Button
        sx={{
          background: BLUE_COLOR_ACCENT,
          color: "white",
          width: "auto",
          maxWidth: isMobile ? "100%" : "150px",
          height: "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: isMobile ? "flex-start" : "space-between",
          gap: 1,

          ":hover": {
            background: BLUE_HOVER_COLOR_ACCENT,
          },
        }}
        onClick={handleClickOpen}
      >
        <Work />
        Create Contract
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent
          sx={{
            background: colors.component,
            padding: "0.5vh 2.5vw",
          }}
        >
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validate={validate}
          >
            {({
              errors,
              touched,
              values,
              handleChange,
              setFieldValue,
              handleSubmit,
            }) => (
              <Box
                sx={{
                  width: "100%",
                  height: "300px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                }}
              >
                <Box
                  sx={{
                    width: "70px",
                    height: "70px",
                    borderRadius: "50%",
                    background: colors.greenAccent[900],
                    border: `1px solid ${colors.greenAccent[700]}`,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <WorkHistoryIcon
                    sx={{ color: colors.invert }}
                    fontSize="large"
                  />
                </Box>
                <Typography
                  variant="h5"
                  color={colors.grey[400]}
                  sx={{
                    fontWeight: 700,
                    fontFamily: "sans-serif",
                    margin: 0,
                    padding: 0,
                  }}
                  textAlign={"center"}
                >
                  {t(`You are changing ${user.firstName}'s contract`)}
                </Typography>
                <CustomTextField
                  label="Percentage"
                  value={values.percentage}
                  onChange={handleChange}
                  name="percentage"
                  width="100%"
                  type="number"
                  errors={errors.percentage}
                  touched={true}
                />
                <CustomDatePicker
                  label="From"
                  value={values.date}
                  name="date"
                  onChange={(value) => {
                    if (dayjs(value).isValid())
                      setFieldValue(
                        "date",
                        value ? value.format("YYYY-MM-DD") : ""
                      );
                  }}
                  error={errors.date}
                  touched={touched.date}
                  fullWidth
                />
                <CustomButton
                  title={t("Update").toUpperCase()}
                  width="100%"
                  onClick={handleSubmit}
                  color={BLUE_COLOR_ACCENT}
                  colorHover={BLUE_HOVER_COLOR_ACCENT}
                  disabled={!!errors.date || !!errors.percentage}
                  isLoading={values.isLoading}
                />
              </Box>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
}
