import { Box } from "@mui/material";
import useIsMobile from "../../../../shared/hooks/useIsMobile";
interface ILegendComponent {
  backgroundColor?: string;
  icon?: any;
  title?: string;
  description?: string;
}

const LegendComponent = ({
  backgroundColor,
  icon,
  title,
  description,
}: ILegendComponent) => {
  const isMobile = useIsMobile();

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "100px",
        border: "1px solid lightgray",
        borderRadius: "8px",
        padding: "2%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: "1vh",
      }}
    >
      <Box
        sx={{
          width: "70px",
          minHeight: "100px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "60px",
            height: "60px",
            borderRadius: "50%",
            background: backgroundColor,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {icon}
        </Box>
      </Box>
      <Box
        sx={{
          width: "85%",
          minHeight: "100px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          overflowY: "scroll",
        }}
      >
        <b
          style={{
            fontSize: "0.9rem",
            marginTop: "1vh",
          }}
        >
          {title}
        </b>
        <p
          style={{
            padding: "0",
            margin: "0",
            width: "100%",
            textAlign: "justify",
            fontSize: isMobile ? "10px" : "1rem",
          }}
        >
          {description}
        </p>
      </Box>
    </Box>
  );
};

export default LegendComponent;
