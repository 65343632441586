import { Box, useTheme } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { tokens } from "../../../../../core/AppStyles";
import SearchBar from "../../../../../shared/custom/SearchBar";
import ChatOneOneBox from "./ChatOneOneBox";

const ChatSelectToChat = () => {
  const [selectedUser, setSelectedUser] = useState("");
  const [conversationWith, setConversationWith] = useState("");
  const [mode, setmode] = useState<boolean>(true);
  const { data } = useSelector((state: any) => state.employee);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { user } = useSelector((state: any) => state.auth);

  const userid = user.userId;

  const UsersWithoutLogedInUser = data.filter(
    (user: any) => user.userId !== userid
  );

  const open1V1chat = (userId: string, name: string) => {
    setSelectedUser(userId);
    setConversationWith(name);
    setmode(!mode);
  };

  const users = () => {
    let filteredData: any[] = [];
    if (user.role === "Admin") {
      filteredData = UsersWithoutLogedInUser.filter((item: any) =>
        item?.firstName.toLowerCase().includes(selectedUser.toLowerCase())
      );
    } else {
      filteredData = UsersWithoutLogedInUser.filter(
        (item: any) =>
          item?.firstName.toLowerCase().includes(selectedUser.toLowerCase()) &&
          item.role === "Admin"
      );
    }

    const mappedData = filteredData?.map((user: any, index: number) => (
      <li
        key={index}
        onClick={() =>
          open1V1chat(user?.userId, user?.firstName + " " + user?.lastName)
        }
        style={{
          listStyle: "none",
          marginTop: "1vh",
          width: "100%",
          background: colors.white,
          padding: "1vh 0 1vh 1vw",
          borderRadius: "5px",
          color: colors.invert,
        }}
      >
        {user?.firstName + " " + user?.lastName}
      </li>
    ));

    return mappedData;
  };

  return mode ? (
    <Box
      sx={{
        width: "100%",
        height: "90%",
        background: colors.primary[400],
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-around",
      }}
    >
      <SearchBar
        border="1px solid"
        borderColor="gray"
        setSearchEvent={setSelectedUser}
      />

      <Box
        sx={{
          width: "98%",
          height: "70%",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flexStart",
          padding: "1vw, 1vh",
          overflowY: "scroll",
        }}
      >
        {users()}
      </Box>
    </Box>
  ) : (
    <ChatOneOneBox
      reciverId={selectedUser}
      coversation_with={conversationWith}
    />
  );
};

export default ChatSelectToChat;
