import { Dispatch, SetStateAction, useContext } from "react";
import { FilePond, registerPlugin } from "react-filepond";
import { useTranslation } from "react-i18next";
import { Box, useTheme } from "@mui/material";
import useIsMobile from "../shared/hooks/useIsMobile";
import useIsTablet from "../shared/hooks/useIsTablet";
import { tokens } from "../core/AppStyles";
import { User } from "../data/models/user.interface";
import { RootContext } from "../context/RootContext";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import { useDeleteFilePondFilesMutation } from "../data/endpoints/app.endpoints";

registerPlugin(FilePondPluginImageTransform);
interface UploadFileProps {
  files: any[];
  setFieldValue: any;
  user: User;
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  handleSubmit: any;
  name: string;
  pond: any;
  errors: any;
}

const UploadFile = ({
  files,
  setFieldValue,
  name,
  user,
  pond,
  errors,
}: UploadFileProps) => {
  const { showSnackbar } = useContext(RootContext);
  const [deleteFile] = useDeleteFilePondFilesMutation();
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const baseUrl =
    process.env.NODE_ENV === "production"
      ? "api"
      : process.env.REACT_APP_BASE_URL;

  const isMobile = useIsMobile();
  const isTablet = useIsTablet();

  return (
    <Box
      sx={{
        width: isMobile || isTablet ? "100%" : "100%",
        "& .custom-filepond": {
          minHeight: "300px !important",
          background: colors.timeline,
          overflowX: "hidden",
          scrollBehavior: "smooth",
          overflowY: "scroll",
          maxHeight: "66vh",
          borderRadius: "8px",
          "& .filepond--drop-label": {
            background: colors.timeline,
          },
          "& .filepond--panel-root": {
            background: colors.timeline,
          },
          "& .filepond--label-action": {
            color: colors.invert,
          },
          "& .filepond--action-process-item": {
            display: "none",
          },
          "& .filepond--action-retry-item-processing": {
            display: "none",
          },
          "& .filepond--file-status-sub": {
            display: "none",
          },
        },
      }}
    >
      <FilePond
        ref={(ref) => {
          pond.current = ref;
        }}
        credits={false}
        allowProcess={true}
        files={files}
        acceptedFileTypes={[
          "application/pdf",
          "application/msword",
          "application/vnd.ms-excel",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          ".xlsx",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          "image/*",
        ]}
        labelFileTypeNotAllowed={t("FILE_MANAGER.FILE_UPLOAD.ERROR")}
        allowFileSizeValidation={true}
        onaddfile={(error, file) => {
          if (error) {
            file?.abortLoad();
            //@ts-ignore
            showSnackbar(`${error?.main}`, true, true);
            return;
          }
        }}
        imageTransformOutputQuality={30}
        minFileSize={null}
        maxFileSize={"5000KB"}
        maxTotalFileSize={"15000KB"}
        labelMaxFileSize={t("FILE_MANAGER.FILE_UPLOAD.MAX_FILE_SIZE")}
        labelMaxTotalFileSize={t("FILE_MANAGER.FILE_UPLOAD.TOTAL_NAX_FILE")}
        //@ts-ignore
        fileValidateTypeDetectType={(source, type) =>
          new Promise((resolve, reject) => {
            resolve(type);
          })
        }
        //@ts-ignore
        allowImageCrop={true}
        allowImageTransform={true}
        imagePreviewHeight={200}
        imageCropAspectRatio={"1:1"}
        imageResizeTargetWidth={500}
        imageResizeTargetHeight={500}
        imageTransformAfterCreateBlob={(blob: any) =>
          new Promise((resolve) => {
            resolve(blob);
          })
        }
        dropOnElement={true}
        dropOnPage={true}
        allowImageResize={true}
        imageResizeMode={"cover"}
        imageTransformOutputQualityMode="optional"
        onupdatefiles={(files) => {
          setFieldValue("files", files, true);
        }}
        instantUpload={false}
        server={{
          process: {
            url: `${baseUrl}/upload`,
            method: "POST",
            ondata: (formdata) => {
              formdata.append("userId", user?.userId);
              formdata.append("type", "CustomExpenses");
              return formdata;
            },
          },
          revert: (props) => {
            const resData = JSON.parse(props) as {
              fileId: string;
            };
            deleteFile({
              fileId: resData?.fileId,
            }).then((res: any) => {
              if (res?.data) {
                showSnackbar(
                  `${t("FILE_MANAGER.FILE_UPLOAD.DELETED_SUCCESSFULLY")}`,
                  false,
                  true
                );
              } else if (res?.error) {
                showSnackbar(res?.error?.data?.error?.message, true, true);
              }
            });
          },
        }}
        name={name}
        className="custom-filepond"
        labelIdle={`<span class="filepond--label-action">${t(
          "FILE_MANAGER.FILE_UPLOAD.DRAG_DROP_FILES"
        )}</span>`}
        allowReorder={true}
        checkValidity={true}
        allowRevert={true}
      />
      {errors && (
        <Box
          style={{
            display: "flex",
            width: "100%",
            color: colors.error,
            justifyContent: "center",
          }}
        >
          {errors}
        </Box>
      )}
    </Box>
  );
};

export default UploadFile;
