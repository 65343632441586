import { Box, Typography, useTheme } from "@mui/material";
import Message from "./Message";
import { ChangeEvent, FormEvent, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { tokens } from "../../../../../core/AppStyles";
import {
  useLazyGetOneToOneChatsQuery,
  useSentOneToOneChatsMutation,
} from "../../../../../data/endpoints/app.endpoints";
import { selectLoggedUser } from "../../../../../data/store/authSlice";
import { CommentsDisabledOutlined } from "@mui/icons-material";

import { BLUE_COLOR_ACCENT } from "../../../../../utils/constants/colors";
import useIsMobile from "../../../../../shared/hooks/useIsMobile";
import useIsTablet from "../../../../../shared/hooks/useIsTablet";
import { useTranslation } from "react-i18next";

interface IchatOneOne {
  reciverId?: string;
  coversation_with?: string;
}
const ChatOneOneBox = ({ reciverId, coversation_with }: IchatOneOne) => {
  const [message, setMessage] = useState<string>("");
  const [btnDisabled, setBtnDisabled] = useState<boolean>(true);
  const [addChats] = useSentOneToOneChatsMutation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const { t } = useTranslation();

  const loggedUser = useSelector(selectLoggedUser);
  const user = loggedUser.firstName + " " + loggedUser.lastName;
  const senderId = loggedUser?.userId;

  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (message.length < 1) {
      setBtnDisabled(true);
    } else {
      setBtnDisabled(false);
    }
  }, [message.length]);

  const handleMessageChange = (e: ChangeEvent<HTMLInputElement>) => {
    setMessage(e.target.value);
  };

  const handleSendMessage = (e: FormEvent) => {
    e.preventDefault();
    if (message.trim() !== "") {
      addChats({ user, message, senderId, reciverId, coversation_with });
      setMessage("");
      if (inputRef.current) {
        inputRef.current.focus();
        setBtnDisabled(true);
      }
    }
  };

  const [getOneToOneChats, OneToOnechatResult] = useLazyGetOneToOneChatsQuery();

  useEffect(() => {
    getOneToOneChats({ senderId, reciverId });
  }, []);

  const chatData = OneToOnechatResult?.data;

  return (
    <Box
      sx={{
        width: "100%",
        height: "90%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          minHeight: "72%",
          maxHeight: "72%",
          display: "flex",
          flexDirection: "column-reverse",
          overflowY: "scroll",
          alignItems: "flex-end",
          background: colors.primary[400],
          paddingBottom: "1vh",
          padding: "0 3% 3% 0",
        }}
      >
        {chatData?.length === 0 ? (
          <Typography variant="body1" color="gray" textAlign="center">
            <CommentsDisabledOutlined /> <br />
            {t("CHAT.NO_MESSAGES")} <br /> {t("CHAT.MESSAGES_WILL_BE_DELETED")}
          </Typography>
        ) : (
          chatData
            ?.slice()
            .reverse()
            .map((chat: any, index: number) => {
              return (
                <Message
                  key={index}
                  message={chat?.message}
                  user={chat?.user}
                  data={chat?.createdAt.split("T")[0]}
                  margin={
                    loggedUser?.firstName + " " + loggedUser?.lastName ===
                      chat?.user
                      ? "40%"
                      : "5%"
                  }
                  bColor={
                    loggedUser?.firstName + " " + loggedUser?.lastName ===
                      chat?.user
                      ? "#007ffc"
                      : "#ffffff"
                  }
                  color={
                    loggedUser?.firstName + " " + loggedUser?.lastName ===
                      chat?.user
                      ? "#ffffff"
                      : colors.grey[400]
                  }
                />
              );
            })
        )}
      </Box>
      <Box
        sx={{
          width: "90%",
          height: "20%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <form
          onSubmit={handleSendMessage}
          style={{
            width: "100%",
            height: "40px",
            maxHeight: "120px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            overflow: "hidden",
            borderRadius: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100%",
              alignItems: "center",
              backgroundColor: colors.timeline,
              maxHeight: "120px",
            }}
          >
            <input
              type="text"
              placeholder="Enter your message ..."
              value={message}
              onChange={handleMessageChange}
              ref={inputRef}
              inputMode="text"
              style={{
                border: "none",
                width: "84%",
                height: `${isMobile || isTablet ? "40px" : "50px"}`,
                outline: "0",
                paddingLeft: `${isMobile || isTablet ? "3vw" : "1vw"}`,
                background: "none",
                backgroundColor: colors.timeline,
                color: colors.invert,
                fontSize: "16px",
              }}
            />
            <button
              disabled={btnDisabled}
              style={{
                width: "16%",
                height: "100%",
                fontSize: "15px",
                fontWeight: 600,
                border: "none",
                background: "none",
                color: BLUE_COLOR_ACCENT,
                display: `${btnDisabled ? "none" : "block"}`,
                cursor: "pointer",
              }}
              onMouseDown={(e) => e.preventDefault()}
              type="submit"
            >
              Send
            </button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default ChatOneOneBox;
