import { Box, Typography, useTheme } from '@mui/material'
import React, { useEffect, useMemo } from 'react'
import { tokens } from '../../core/AppStyles';
import useIsMobile from '../../shared/hooks/useIsMobile';
import { useTranslation } from 'react-i18next';
import CustomTable from '../../shared/custom/CustomTable';
import { QueryStatus } from '@reduxjs/toolkit/query';
import ContractTableRow from './ContractTableRow';
import { useLazyGetContractsByUserIdQuery } from '../../data/endpoints/app.endpoints';
import { useParams } from 'react-router';
import { selectEmployeeById } from '../../data/store/employeeSlice';
import { useSelector } from 'react-redux';
import { selectDepartmentById } from '../../data/store/departmentSlice';
import moment from 'moment';


const ViewEmployeeDetails = () => {
    const theme = useTheme();
    const color = tokens(theme.palette.mode);
    const isMobile = useIsMobile();
    const { t } = useTranslation();
    const { id } = useParams();

    const {
        firstName,
        lastName,
        role,
        email,
        hiringDate,
        status,
        endOfContract,
        departmentId,
        userId
    } = useSelector(selectEmployeeById(id));

    const department = useSelector(selectDepartmentById(departmentId));

    const userInformation = [
        {
            section: `${t("ROLE")}`,
            value: role,
        },
        {
            section: `${t("EMAIL")}`,
            value: email,
        },
        {
            section: `${t("DEPARTMENT")}`,
            value: department?.name,
        },
        {
            section: `${t("HIRING_DATE")}`,
            value: hiringDate ? moment(hiringDate).format("DD MMM YYYY") : '- -',
        },
        {
            section: `${t("STATUS")}`,
            value: status,
        },
        {
            section: `${t("END_CONTRACT")}`,
            value: endOfContract,
        },
    ];
    const [getContracts, result] = useLazyGetContractsByUserIdQuery();

    useEffect(() => {
        if (id) {
            getContracts({ userId: id })
        }
    }, [id])

    const contractTableTitles = ["No.", t('START_DATE'), t('END_DATE'), t("USERS_ADMIN.VIEW_USER.PERCENTAGE"), t("USERS_ADMIN.VIEW_USER.TO_WORK_DAILY")]

    const contractBodyRenderer = useMemo(() => {
        if (result?.isError || result?.status !== QueryStatus.fulfilled) {
            return [] as JSX.Element[];
        }
        const dataRenderer: JSX.Element[] = [];

        result?.data?.map((row: any, index: any) => {
            return dataRenderer.push(<ContractTableRow key={index} index={index + 1} row={row} />);
        });
        return dataRenderer;
    }, [result]);


    return (
        <>
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                }}
            >
                <Box
                    style={{
                        width: "92%",
                        height: "10%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        marginBottom: "1vh",
                        marginTop: "1vh",
                    }}
                >
                    <p
                        style={{
                            color: color.invert,
                            fontSize: isMobile ? "1.2rem" : "1.5rem",
                            fontWeight: 500,
                        }}
                    >
                        {t("USERS_ADMIN.VIEW_USER.USER_DETAILS")}
                    </p>
                </Box>
                {userInformation?.map((info: any, index: number) => (
                    <li
                        key={index}
                        style={{
                            width: "100%",
                            height: "4vh",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginTop: "1vh",
                        }}
                    >
                        <b>{info?.section}</b> {info?.value || "- - "}
                    </li>
                ))}

            </Box>

            <Box sx={{
                marginTop: '5%',
                width: '95%'
            }}>
                <Typography variant="h4" fontWeight='700' color={color.invert} marginBottom='3%'>
                    Contracts
                </Typography>
                <CustomTable isPaginated={false} style={{
                    // height: "50%",
                }} tableBodyRenderer={contractBodyRenderer} width="100%" tableTitles={contractTableTitles} />
            </Box>

        </>
    )
}

export default ViewEmployeeDetails