import { useState, useMemo, useEffect } from "react";
import { Box, MenuItem, SelectChangeEvent } from "@mui/material";
import CustomHeader from "../../shared/custom/CustomHeader";
import CustomSelector from "../../shared/custom/CustomSelector";
import SearchBar from "../../shared/custom/SearchBar";
import CustomTable from "../../shared/custom/CustomTable";
import LoadingSpinner from "../../shared/LoadingSpinner";
import { Project } from "../../data/models/project.interface";
import { ProjectStatus } from "../../utils/enums/project-status.enum";
import ProjectTableRow from "./ProjectTableRow";
import { useSelector } from "react-redux";
import { useLazyGetPaginatedProjectsQuery } from "../../data/endpoints/app.endpoints";
import BoxContainer from "../../shared/BoxContainer";
import CustomButton from "../../shared/custom/CustomButton";
import {
  BLUE_COLOR_ACCENT,
  BLUE_HOVER_COLOR_ACCENT,
} from "../../utils/constants/colors";
import { useNavigate } from "react-router";
import useIsMobile from "../../shared/hooks/useIsMobile";
import CustomHeaderMobile from "../../shared/custom/CustomHeaderMobile";
import CustomTableProjectsMobile from "./CustomTableProjectsMobile";
import useIsTablet from "../../shared/hooks/useIsTablet";
import NoDataComponent from "../../shared/NoDataComponent";
import { useTranslation } from "react-i18next";
import { QueryStatus } from "@reduxjs/toolkit/query";

const Projects = () => {
  const { departments } = useSelector((state: any) => state.department);
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const [q, setQ] = useState("");
  const [status, setStatus] = useState<any>("ALL");
  const [department, setDepartment] = useState<any>("ALL");
  const [getPaginatedProjects, result] = useLazyGetPaginatedProjectsQuery();
  const [limit, setLimit] = useState<any>(10);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [page, setPage] = useState(1);

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };
  const handleRowPerPageChange = (event: SelectChangeEvent) => {
    setLimit(event.target.value);
    setPage(1);
  };

  useEffect(() => {
    getPaginatedProjects({
      limitTo: limit,
      currentPage: page,
      status: status === "ALL" ? "" : status,
      q,
      department: department === "ALL" ? "" : department,
    });
  }, [q, status, limit, department, page]);

  const handleDepartmentChange = (event: SelectChangeEvent) => {
    setDepartment(event.target.value);
    setPage(1);
  };

  const handleStatusChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value);
    setPage(1);
  };

  const projectTitles = [
    `${t("CLIENT")}`,
    `${t("PROJECT")}`,
    `${t("DEPARTMENT")}`,
    `${t("START_TIME")}`,
    `${t("END_TIME")}`,
    `${t("STATUS")}`,
    `${t("PROGRESS")}`,
    `${t("EDIT")}`,
  ];

  const projectsBodyRenderer = useMemo(() => {
    const projectsRenderer: JSX.Element[] = [];
    result?.data?.data?.map((row: Project, index: number) => {
      return projectsRenderer.push(<ProjectTableRow key={index} row={row} />);
    });
    return projectsRenderer;
  }, [result?.data?.data]);

  return (
    <BoxContainer>
      {isMobile || isTablet ? (
        <CustomHeaderMobile
          title={t("USERS_ADMIN.PROJECTS.TITLE")}
          subtitle={t("USERS_ADMIN.PROJECTS.SUBTITLE")}
          btnTitle="+"
          btnNavLink="add-project"
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "100%",
              marginTop: "1vh",
            }}
          >
            <SearchBar setSearchEvent={setQ} setPage={setPage} />

            <Box
              sx={{
                width: "100%",
                marginTop: "2vh",
                display: "flex",
                flexWrap: "wrap",
                gap: 2,
                justifyContent: "space-between",
              }}
            >
              <CustomSelector
                label={t("STATUS")}
                value={status}
                onSelectChange={handleStatusChange}
                size="small"
                width="100%"
              >
                <MenuItem value={"ALL"}>{t("STATUSES.ALL")}</MenuItem>
                <MenuItem value={ProjectStatus.INPROGRESS}>
                  {t("STATUSES.IN_PROGRESS")}
                </MenuItem>
                <MenuItem value={ProjectStatus.COMPLETED}>
                  {t("STATUSES.COMPLETED")}
                </MenuItem>
                <MenuItem value={ProjectStatus.PENDING}>
                  {t("STATUSES.PENDING")}
                </MenuItem>
              </CustomSelector>
              <CustomSelector
                size="small"
                label={t("DEPARTMENT")}
                value={department}
                onSelectChange={handleDepartmentChange}
                width="100%"
              >
                <MenuItem value={"ALL"}>{t("STATUSES.ALL")}</MenuItem>
                {departments?.map((department: any, index: number) => {
                  return (
                    <MenuItem key={index} value={department?.name}>
                      {department?.name}
                    </MenuItem>
                  );
                })}
              </CustomSelector>
            </Box>
          </Box>
        </CustomHeaderMobile>
      ) : (
        <CustomHeader
          title={t("USERS_ADMIN.PROJECTS.TITLE")}
          subtitle={t("USERS_ADMIN.PROJECTS.SUBTITLE")}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              width: "70%",
              alignItems: "center",
              gap: 1,
            }}
          >
            <SearchBar setSearchEvent={setQ} setPage={setPage} />
            <CustomSelector
              label={t("STATUS")}
              value={status}
              onSelectChange={handleStatusChange}
              size="small"
              width="25%"
            >
              <MenuItem value={"ALL"}>{t("STATUSES.ALL")}</MenuItem>
              <MenuItem value={ProjectStatus.INPROGRESS}>
                {t("STATUSES.IN_PROGRESS")}
              </MenuItem>
              <MenuItem value={ProjectStatus.COMPLETED}>
                {t("STATUSES.COMPLETED")}
              </MenuItem>
              <MenuItem value={ProjectStatus.PENDING}>
                {t("STATUSES.PENDING")}
              </MenuItem>
            </CustomSelector>
            <CustomSelector
              size="small"
              label={t("DEPARTMENT")}
              value={department}
              onSelectChange={handleDepartmentChange}
              width="25%"
            >
              <MenuItem value={"ALL"}>{t("STATUSES.ALL")}</MenuItem>
              {departments?.map((department: any, index: number) => {
                return (
                  <MenuItem key={index} value={department?.name}>
                    {department?.name}
                  </MenuItem>
                );
              })}
            </CustomSelector>
            <CustomButton
              title={t("USERS_ADMIN.PROJECTS.ADD_PROJECT")}
              width="35%"
              color={BLUE_COLOR_ACCENT}
              onClick={() => navigate("add-project")}
              colorHover={BLUE_HOVER_COLOR_ACCENT}
            />
          </Box>
        </CustomHeader>
      )}
      <>
        {result?.status === QueryStatus.fulfilled ? (
          !result?.data?.data?.length ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: isMobile ? "50vh" : "75vh",
              }}
            >
              <NoDataComponent
                title={`${t("USERS_ADMIN.TEXT")} ${
                  status === ProjectStatus.ALL
                    ? ""
                    : t(
                        `STATUSES.${status.toUpperCase().replace(" ", "_")}`
                      ).toLowerCase()
                } ${t("USERS_ADMIN.PROJECTS_FOUND")}!`}
              />
            </Box>
          ) : isMobile || isTablet ? (
            <CustomTableProjectsMobile
              mt={2}
              column1Name={t("DETAILS")}
              column2Name={t("USERS_ADMIN.PROJECTS.NAME")}
              isPaginated={result?.data?.totalRecords > 10 ? true : false}
              page={page}
              data={result?.data?.data}
              handleChangePage={handleChangePage}
              rowsPerPage={limit}
              handleChangeRowsPerPage={handleRowPerPageChange}
              count={result?.data?.totalRecords}
            />
          ) : (
            <Box mt={3}>
              <CustomTable
                isPaginated={result?.data?.totalRecords > 10 ? true : false}
                count={result?.data?.totalRecords}
                handleChangePage={handleChangePage}
                page={page}
                rowsPerPage={limit}
                handleChangeRowsPerPage={handleRowPerPageChange}
                tableTitles={projectTitles}
                tableBodyRenderer={projectsBodyRenderer}
                style={{
                  borderBottomLeftRadius:
                    result?.data?.totalRecords > 9 ? "0px" : "10px",
                  borderBottomRightRadius:
                    result?.data?.totalRecords > 9 ? "0px" : "10px",
                }}
              />
            </Box>
          )
        ) : (
          <LoadingSpinner />
        )}
      </>
    </BoxContainer>
  );
};
export default Projects;
