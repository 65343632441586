import { memo } from "react";
import { ActivityResponse } from "../../data/models/activity-response.interface";
import { TableCell, TableRow } from "@mui/material";

interface ActivityTableRowProps {
  row: ActivityResponse;
}

const ActivityTableRow = ({ row }: ActivityTableRowProps) => {
  return (
    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      {row?.firstName && (
        <TableCell component="th" scope="row">
          {row?.firstName} {row?.lastName}
        </TableCell>
      )}
      <TableCell component="th" scope="row">
        {row?.expectedWorkingHours?.toFixed(2).replace(/(\.0+|0+)$/, "")}
      </TableCell>
      <TableCell component="th" scope="row">
        {row?.totalLeavesHours?.toFixed(2).replace(/(\.0+|0+)$/, "")}
      </TableCell>
      <TableCell component="th" scope="row">
        {row?.totalCompenstionHours?.toFixed(2).replace(/(\.0+|0+)$/, "") || 0}
      </TableCell>
      <TableCell component="th" scope="row">
        {row?.effectiveWorkedTime?.toFixed(2).replace(/(\.0+|0+)$/, "")}
      </TableCell>
      <TableCell component="th" scope="row">
        {row?.paidHours?.toFixed(2).replace(/(\.0+|0+)$/, "")}
      </TableCell>
      <TableCell component="th" scope="row">
        {row?.overtime?.toFixed(2).replace(/(\.0+|0+)$/, "")}
      </TableCell>
    </TableRow>
  );
};

export default memo(ActivityTableRow);
