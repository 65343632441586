import { Box, Tooltip, useTheme } from "@mui/material";
import { tokens } from "../../../../core/AppStyles";
import useIsLargeScreen from "../../../../shared/hooks/useIsLargeScreen";
import useIsMobile from "../../../../shared/hooks/useIsMobile";
import useIsTablet from "../../../../shared/hooks/useIsTablet";

interface IDailyCalendarTT {
  day: string;
  hoursWorked: number;
  status: number;
}

const DailyCalendarTimeTracker = ({
  day,
  hoursWorked,
  status,
}: IDailyCalendarTT) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isLargeScreen = useIsLargeScreen();

  return (
    <Box
      sx={{
        width: "90%",
        height: isLargeScreen ? "9%" : isMobile || isTablet ? "30px" : "11%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderRadius: "1rem",
        background: "whitesmoke",
        overflow: "hidden",
        marginTop: "2%",
      }}
    >
      <Box
        sx={{
          width: "40%",
          height: "100%",
          // background: "rgb(248, 88, 80)",
          background: colors.redAccent[500],
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: colors.black,
          fontWeight: 600,
          fontSize: "0.8rem",
          borderRadius: "1rem",
        }}
      >
        {day}
      </Box>
      <Box
        sx={{
          width: "30%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "black",
          fontWeight: 600,
          fontSize: "0.9rem",
        }}
      >
        {hoursWorked} hr
      </Box>
      <Tooltip title={status > 0 ? "Completed" : "Uncompleted"}>
        <Box
          sx={{
            width: "10px",
            height: "10px",
            background:
              status > 0 ? colors.greenAccent[500] : colors.redAccent[500],
            borderRadius: "50%",
            marginRight: "5%",
            cursor: "pointer",
          }}
        ></Box>
      </Tooltip>
    </Box>
  );
};

export default DailyCalendarTimeTracker;
