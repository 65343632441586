import { useContext } from "react";
import {
  Box,
  CircularProgress,
  DialogActions,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import AlertDialog from "../../shared/modal/AlertDialog";
import { RootContext } from "../../context/RootContext";
import {
  BLUE_COLOR_ACCENT,
  BLUE_HOVER_COLOR_ACCENT,
} from "../../utils/constants/colors";
import CustomButton from "../../shared/custom/CustomButton";
import AlertActionButton from "../../shared/modal/AlertActionButton";
import useIsMobile from "../../shared/hooks/useIsMobile";
import { useTranslation } from "react-i18next";

interface DepartmentFormFieldsProps {
  handleSubmit: any;
  values: any;
  touched: any;
  handleChange: any;
  errors: any;
  setFieldValue: any;
  children?: any;
  id?: string;
  handleMutation: any;
  buttonTitle: string;
  resetForm?: any;
  departmentName?: any;
  updateModal?: any;
  setUpdateModal?: any;
  submitIsLoading?: boolean;
}

const DepartmentForm = ({
  handleSubmit,
  values,
  touched,
  handleChange,
  errors,
  setFieldValue,
  children,
  id,
  handleMutation,
  buttonTitle,
  resetForm,
  departmentName,
  updateModal,
  setUpdateModal,
  submitIsLoading,
}: DepartmentFormFieldsProps) => {
  const isMobile = useIsMobile();
  const {
    showSnackbar,
    setOpenModal,
    state: { openModal },
  } = useContext(RootContext);

  const { t } = useTranslation();

  const handleSubmitDialog = () => {
    const { name } = values;
    if (id && name?.length >= 3)
      handleMutation({
        departmentId: id,
        name,
      }).then((res: any) => {
        if (!res.error) {
          showSnackbar(
            `${t("TEXT_RESPONSE.DEPARTMENT_UPDATED_SUCCESSFULLY")}`,
            false,
            true
          );
        } else if (res.error) {
          showSnackbar(
            `${t("FORM_VALIDATIONS.DEPARTMENT_VALIDATIONS.INVALID_NAME")}`,
            true,
            true
          );
        }
        setUpdateModal(false);
      });
    else {
      handleMutation({
        name,
      }).then((res: any) => {
        if (!res.error) {
          showSnackbar(
            `${t("TEXT_RESPONSE.DEPARTMENT_CREATED_SUCCESSFULLY")}`,
            false,
            true
          );
          resetForm();
        } else if (res.error) {
          return;
        }
        setOpenModal(false);
      });
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      {!id ? (
        <Typography variant="h4" sx={{ fontWeight: "600", mb: 3 }}>
          {t("USERS_ADMIN.DEPARTMENTS.CREATE_DEPARTMENT.CREATE_DEPARTMENTS")}
        </Typography>
      ) : (
        <Typography variant="h3" sx={{ fontWeight: "600", mb: 3 }}>
          {t("USERS_ADMIN.DEPARTMENTS.CREATE_DEPARTMENT.UPDATE_DEPARTMENTS")}
        </Typography>
      )}

      <Grid container spacing={2} sx={{ mb: 1.5 }}>
        <Grid item xs={12} sm={12}>
          <TextField
            autoComplete="off"
            variant="outlined"
            name="name"
            size="small"
            fullWidth
            id="name"
            label={t("USERS_ADMIN.DEPARTMENTS.DEPARTMENT_NAME")}
            inputProps={{ style: { fontSize: "16px" } }}
            value={values.name}
            onChange={handleChange}
            color="info"
          />
          <Typography
            sx={{ color: "#d9534f", fontSize: "12px" }}
            variant="inherit"
          >
            {errors.name as any}
          </Typography>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        {!id ? (
          <AlertDialog
            openModal={openModal}
            setOpenModal={setOpenModal}
            buttonTitle={
              submitIsLoading ? (
                <CircularProgress color="inherit" size={25} />
              ) : (
                buttonTitle
              )
            }
            values={values}
            handleSubmitDialog={handleSubmitDialog}
            color={BLUE_COLOR_ACCENT}
            colorHover={BLUE_HOVER_COLOR_ACCENT}
            mt={isMobile ? "10px" : "20px"}
          >
            {t("USERS_ADMIN.DEPARTMENTS.CONFIRM_CREATE_UPDATE", {
              action: t("CREATE").toLowerCase(),
            })}
          </AlertDialog>
        ) : (
          <DialogActions>
            <AlertActionButton
              title={t("CANCEL")}
              variant="outlined"
              onClick={() => setUpdateModal(false)}
            />
            <CustomButton
              title={t("CONFIRM")}
              onClick={handleSubmitDialog}
              color={BLUE_COLOR_ACCENT}
              width="auto"
              colorHover={BLUE_HOVER_COLOR_ACCENT}
            />
          </DialogActions>
        )}
      </Box>
    </Box>
  );
};

export default DepartmentForm;
