import { useContext } from "react";
import CustomHeader from "../../shared/custom/CustomHeader";
import CustomNavigationButton from "../../shared/custom/CustomNavigationButton";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import {
  BLUE_COLOR_ACCENT,
  BLUE_HOVER_COLOR_ACCENT,
} from "../../utils/constants/colors";
import ProjectFormFields from "./ProjectFormField";
import { useParams } from "react-router-dom";
import { Project } from "../../data/models/project.interface";
import { useSelector } from "react-redux";
import { selectProjectById } from "../../data/store/projectSlice";
import {
  handleRequiredValidation,
  timeIntervalEndTimeSmaller,
} from "../../utils/regex/formRegex";
import { selectDepartments } from "../../data/store/departmentSlice";
import { RootContext } from "../../context/RootContext";
import { Formik } from "formik";
import { useUpdateProjectMutation } from "../../data/endpoints/app.endpoints";
import useIsMobile from "../../shared/hooks/useIsMobile";
import useIsLargeScreen from "../../shared/hooks/useIsLargeScreen";
import useIsDesktop from "../../shared/hooks/useIsDesktop";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

const UpdateProject = () => {
  const isMobile = useIsMobile();
  const isDesktop = useIsDesktop();
  const isLargeScreen = useIsLargeScreen();
  const { id } = useParams();
  const existingDepartments = useSelector(selectDepartments);
  const project: Project = useSelector(selectProjectById(id)) as Project;
  const { t } = useTranslation();
  const [updateProject, result] = useUpdateProjectMutation();
  const { setOpenModal } = useContext(RootContext);

  const onSubmitForm = () => {
    setOpenModal(true);
  };

  const initialValues = {
    company: project?.company,
    title: project?.title,
    description: project?.description === null ? "" : project?.description,
    departments: project?.departments,
    startTime: project?.startTime === null ? "" : project?.startTime,
    endTime: project?.endTime === null ? "" : project?.endTime,
    projectId: id,
    anticipatedDuration: project?.anticipatedDuration,
  };

  const validate = (values: typeof initialValues) => {
    const errors = {} as any;

    handleRequiredValidation(
      values,
      errors,
      "company",
      t("FORM_VALIDATIONS.REQUIRED")
    );
    handleRequiredValidation(
      values,
      errors,
      "title",
      t("FORM_VALIDATIONS.REQUIRED")
    );

    if (values?.startTime && values?.endTime) {

      timeIntervalEndTimeSmaller(
        errors,
        "endTime",
        {
          startTime: values.startTime,
          endTime: values.endTime,
        },
        {
          startTimeNotValid: t(
            "FORM_VALIDATIONS.TIME_TRACKER_VALIDATIONS.NOT_VALID.START_DATE"
          ),
          endTimeNotValid: t(
            "FORM_VALIDATIONS.TIME_TRACKER_VALIDATIONS.NOT_VALID.END_DATE"
          ),
        },
        t("FORM_VALIDATIONS.END_DATE_SMALLER_OR_EQUAL")
      );
    }

    return errors;
  };

  return (
    <>
      <CustomHeader
        title={t("USERS_ADMIN.PROJECTS.CREATE_EDIT_PROJECT.UPDATE_PROJECT")}
        subtitle={t(
          "USERS_ADMIN.PROJECTS.CREATE_EDIT_PROJECT.UPDATE_EXISTING_PROJECT"
        )}
      >
        <CustomNavigationButton
          customStyle={{
            backgroundColor: BLUE_COLOR_ACCENT,
            width: "10%",
            display: "flex",
            gap: 1,
            alignSelf: "center",
            fontSize: "12px",
            ":hover": {
              bgcolor: BLUE_HOVER_COLOR_ACCENT,
              color: "white",
            },
          }}
          navigateTo={-1}
        >
          <KeyboardArrowLeftIcon /> {isMobile ? null : t("GO_BACK")}
        </CustomNavigationButton>
      </CustomHeader>
      <Box
        sx={{
          width: "auto",
          height: "auto",
          marginTop: isDesktop || isLargeScreen ? 4 : 0,
        }}
      >
        <Formik
          initialValues={initialValues}
          validate={validate}
          onSubmit={onSubmitForm}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            setFieldValue,
          }: any) => (
            <ProjectFormFields
              initialValues={initialValues}
              buttonTitle={t("UPDATE")}
              errors={errors}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              setFieldValue={setFieldValue}
              touched={touched}
              values={values}
              existingDepartments={existingDepartments}
              handleMutation={updateProject}
              id={id}
              submitIsLoading={result?.isLoading}
            />
          )}
        </Formik>
      </Box>
    </>
  );
};

export default UpdateProject;
