import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../../../core/AppStyles";
import useIsMobile from "../../../../shared/hooks/useIsMobile";
import useIsTablet from "../../../../shared/hooks/useIsTablet";
import moment from "moment";
import { useTranslation } from "react-i18next";

interface IVacationing {
  data: [];
}

const FollowingVacationComp = ({ data }: IVacationing) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        width: `${isMobile || isTablet ? "100%" : "30%"}`,
        height: `${isMobile || isTablet ? "100%" : "21vh"}`,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        background: colors.component,
        boxShadow: `${colors.shadow} 1px 1px 5px 1px`,
        padding: `${isMobile ? "4%" : "1%"}`,
        marginTop: `${isMobile || isTablet ? "2vh" : "0"}`,
        overflow: "hidden",
        borderRadius: "10px",
      }}
    >
      <p
        style={{
          margin: 1,
        }}
      >
        <b style={{ fontSize: "16px" }}>
          {t("DASHBOARD.USER.UPCOMING_HOLIDAYS")}
        </b>
      </p>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "90%",
          height: "80%",
          padding: "2%",
          overflowY: "auto",
        }}
      >
        {data?.length !== 0 ? (
          data?.map((vacation: any, index: number) => (
            <Typography
              variant="body2"
              component="li"
              key={vacation?.holidayId}
              style={{
                listStyle: "none",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 10,
                marginTop: 8,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "50px",
                  height: "50px",
                  backgroundColor: colors.timeline,
                  borderRadius: "10px",
                }}
              >
                <h3 style={{ fontSize: "18px", margin: 0 }}>
                  {moment(vacation?.date).format("DD")}
                </h3>
                <Box>{moment(vacation?.date).format("MMM")}</Box>
              </Box>
              <h3> {vacation?.name}</h3>
            </Typography>
          ))
        ) : (
          <small style={{ color: "gray" }}>
            {t("DASHBOARD.USER.NO_VACATIONS")}
          </small>
        )}
      </Box>
    </Box>
  );
};

export default FollowingVacationComp;
