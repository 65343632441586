/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { Route } from "react-router";
import { AdminRoutes } from "../admin";
import ProtectedRoute from "../../shared/ProtectedRoute";
import DashboardAdmin from "../../features/dashboard/DashboardAdmin";
import Employees from "../../features/employees/Employees";
import AddEmployee from "../../features/employees/AddEmployee";
import Projects from "../../features/projects/Projects";
import AddProject from "../../features/projects/AddProject";
import UpdateProject from "../../features/projects/UpdateProject";
import ViewProject from "../../features/projects/ViewProject";
import ProjectWorkingHoursDashboardComponent from "../../features/dashboard/testDashboard/AdminDahboardComponents/ProjectWorkingHoursDashboardComponent";
import Activity from "../../features/activity/Activity";
import UserProjects from "../../features/dashboard/testDashboard/AdminDahboardComponents/UserProjects";
import EmployeesOnProjects from "../../features/dashboard/testDashboard/AdminDahboardComponents/EmployeesOnProjects";
import Absents from "../../features/dashboard/testDashboard/AdminDahboardComponents/Absents";
import UpdateEmployee from "../../features/employees/UpdateEmployee";
import ViewEmployee from "../../features/employees/ViewEmployee";
import Departments from "../../features/departments/Departments";
import UpdateDepartment from "../../features/departments/UpdateDepartment";
import AddDepartment from "../../features/departments/AddDepartment";
import Holidays from "../../features/holidays/Holidays";
import AddHolidays from "../../features/holidays/AddHolidays";
import AllLeaveRequests from "../../features/leave-request/AllLeaveRequests";
import UpdateLeaveRequest from "../../features/leave-request/UpdateLeaveRequest";
import FileUploadForm from "../../features/file-manager/UploadFile";
import FileSystem from "../../features/file-manager/FileSystem";
import UpdateEquipment from "../../features/equipments/UpdateEquipment";
import AddEquipment from "../../features/equipments/AddEquipment";
import Equipments from "../../features/equipments/Equipments";
import TimeTracker from "../../features/time-tracker/TimeTracker";
import ManageTimeTrackers from "../../features/time-tracker/ManageTimeTrackers";
import ViewTimeTrackers from "../../features/time-tracker/ViewTimeTrackers";
import UncompletedTimeTrackers from "../../features/dashboard/testDashboard/AdminDahboardComponents/UncompletedTimeTrackers";
import { useSelector } from "react-redux";
import { selectLoggedUser } from "../../data/store/authSlice";
import { store } from "../../data/store/store";
import { appEndpoints } from "../../data/endpoints/app.endpoints";
import { selectProjectSliceState } from "../../data/store/projectSlice";
import { selectEmployeeSliceState } from "../../data/store/employeeSlice";
import { selectDepartmentSliceState } from "../../data/store/departmentSlice";
import { selectEquipmentsState } from "../../data/store/equipmentSlice";
import { UserRole } from "../../utils/enums/user-role.enum";
import PageNotFound from "../Errors/PageNotFound";
import LeaveCalendar from "../../features/overview-calendar/LeaveCalendar";
import { selectHolidaysState } from "../../data/store/holidaysSlice";
import moment from "moment";
import Expenses from "../../features/expenses/Expenses";
import AddExpenses from "../../features/expenses/AddExpenses";
import { selectLeavesState } from "../../data/store/leaveRequestSlice";
import Vehicles from "../../features/vehicles/Vehicles";
import AddVehicle from "../../features/vehicles/AddVehicle";
import UpdateVehicle from "../../features/vehicles/UpdateVehicle";
import { selectVehicleSliceState } from "../../data/store/vehicleSlice";
import UserExpenses from "../../features/expenses/UserExpenses";

interface UseAdminRoutesProps {
  refreshToken: string;
}

const useAdminRoutes = ({ refreshToken }: UseAdminRoutesProps) => {
  const user = useSelector(selectLoggedUser);

  const {
    isLoading: isLoadingProjects,
    limitTo: projectLimitTo,
    currentPage: projectCurrentPage,
  } = useSelector(selectProjectSliceState);

  const { isLoading: isLoadingHolidays } = useSelector(selectHolidaysState);

  const { isLoading: isLoadingEmployees } = useSelector(
    selectEmployeeSliceState
  );

  const { isLoading: isLoadingDepartments } = useSelector(
    selectDepartmentSliceState
  );

  const { isLoading: isLoadingVehicles } = useSelector(selectVehicleSliceState);

  const { isLoading: isLoadingLeaves } = useSelector(selectLeavesState);

  const { isLoading: isLoadingEquipments } = useSelector(selectEquipmentsState);

  useEffect(() => {
    if (user?.userId && user.role === UserRole.ADMIN) {
      store.dispatch(
        appEndpoints.endpoints.getPaginatedUsers.initiate({
          currentPage: 1,
          limitTo: 10,
        })
      );
      store.dispatch(
        appEndpoints.endpoints.getEquipments.initiate({
          currentPage: 1,
          limitTo: 10,
        })
      );

      store.dispatch(appEndpoints.endpoints.getAllUsers.initiate(true));
      store.dispatch(
        appEndpoints.endpoints.getPaginatedProjects.initiate({
          currentPage: projectCurrentPage,
          limitTo: projectLimitTo,
        })
      );
      store.dispatch(
        appEndpoints.endpoints.getAllLeaves.initiate({
          currentPage: 1,
          limitTo: 10,
        })
      );
      store.dispatch(
        appEndpoints.endpoints.getAdminDashboard.initiate(undefined)
      );
      const selectedDate = moment().format();
      const endYear = moment(selectedDate).endOf("year").format();
      const startYear = moment(selectedDate).startOf("year").format();
      store.dispatch(
        appEndpoints.endpoints.getHolidays.initiate({
          startDate: startYear,
          endDate: endYear,
        })
      );
    }
  }, [user?.userId]);

  return (
    <Route element={<AdminRoutes refreshToken={refreshToken} />}>
      <Route
        path="dashboard"
        element={
          <ProtectedRoute
            isUserLoggedIn={!!refreshToken}
            loadingSpinnerCondition={!user?.userId}
            Component={DashboardAdmin}
          />
        }
      />
      <Route
        path="employees"
        element={
          <ProtectedRoute
            isUserLoggedIn={!!refreshToken}
            loadingSpinnerCondition={isLoadingEmployees}
            Component={Employees}
          />
        }
      />
      <Route
        path="add-user"
        element={
          <ProtectedRoute
            isUserLoggedIn={!!refreshToken}
            loadingSpinnerCondition={isLoadingEmployees}
            Component={AddEmployee}
          />
        }
      />
      <Route
        path="projects"
        element={
          <ProtectedRoute
            isUserLoggedIn={!!refreshToken}
            loadingSpinnerCondition={isLoadingProjects}
            Component={Projects}
          />
        }
      />
      <Route
        path="projects/add-project"
        element={
          <ProtectedRoute
            isUserLoggedIn={!!refreshToken}
            loadingSpinnerCondition={isLoadingProjects}
            Component={AddProject}
          />
        }
      />
      <Route
        path="projects/edit-project/:id"
        element={
          <ProtectedRoute
            isUserLoggedIn={!!refreshToken}
            loadingSpinnerCondition={isLoadingProjects}
            Component={UpdateProject}
          />
        }
      />

      <Route
        path="projects/view-project/:id"
        element={
          <ProtectedRoute
            isUserLoggedIn={!!refreshToken}
            loadingSpinnerCondition={isLoadingProjects}
            Component={ViewProject}
          />
        }
      />
      <Route
        path="ongoing-projects"
        element={
          <ProtectedRoute
            isUserLoggedIn={!!refreshToken}
            loadingSpinnerCondition={isLoadingDepartments}
            Component={ProjectWorkingHoursDashboardComponent}
          />
        }
      />
      <Route
        path="vehicle"
        element={
          <ProtectedRoute
            isUserLoggedIn={!!refreshToken}
            loadingSpinnerCondition={isLoadingEmployees}
            Component={Vehicles}
          />
        }
      />
      <Route
        path="add-vehicle"
        element={
          <ProtectedRoute
            isUserLoggedIn={!!refreshToken}
            loadingSpinnerCondition={isLoadingEmployees}
            Component={AddVehicle}
          />
        }
      />
      <Route
        path="edit-vehicle/:id"
        element={
          <ProtectedRoute
            isUserLoggedIn={!!refreshToken}
            loadingSpinnerCondition={isLoadingVehicles && !!user}
            Component={UpdateVehicle}
          />
        }
      />
      <Route
        path="user-expenses"
        element={
          <ProtectedRoute
            isUserLoggedIn={!!refreshToken}
            loadingSpinnerCondition={isLoadingEmployees}
            Component={UserExpenses}
          />
        }
      />
      <Route
        path="activity"
        element={
          <ProtectedRoute
            isUserLoggedIn={!!refreshToken}
            loadingSpinnerCondition={isLoadingEmployees}
            Component={Activity}
          />
        }
      />
      <Route
        path="user-project"
        element={
          <ProtectedRoute
            isUserLoggedIn={!!refreshToken}
            loadingSpinnerCondition={isLoadingEmployees}
            Component={UserProjects}
          />
        }
      />
      <Route
        path="projects-employees"
        element={
          <ProtectedRoute
            isUserLoggedIn={!!refreshToken}
            loadingSpinnerCondition={isLoadingEmployees}
            Component={EmployeesOnProjects}
          />
        }
      />
      <Route
        path="absents"
        element={
          <ProtectedRoute
            isUserLoggedIn={!!refreshToken}
            loadingSpinnerCondition={isLoadingEmployees}
            Component={Absents}
          />
        }
      />
      <Route
        path="expenses"
        element={
          <ProtectedRoute
            isUserLoggedIn={!!refreshToken}
            loadingSpinnerCondition={false}
            Component={Expenses}
          />
        }
      />
      <Route
        path="/add-expenses"
        element={
          <ProtectedRoute
            isUserLoggedIn={!!refreshToken}
            loadingSpinnerCondition={false}
            Component={AddExpenses}
          />
        }
      />
      <Route
        path="edit-user/:id"
        element={
          <ProtectedRoute
            isUserLoggedIn={!!refreshToken}
            loadingSpinnerCondition={isLoadingEmployees}
            Component={UpdateEmployee}
          />
        }
      />
      <Route
        path="view-user/:id/*"
        element={
          <ProtectedRoute
            isUserLoggedIn={!!refreshToken}
            loadingSpinnerCondition={isLoadingEmployees}
            Component={ViewEmployee}
          />
        }
      />

      <Route
        path="departments"
        element={
          <ProtectedRoute
            isUserLoggedIn={!!refreshToken}
            loadingSpinnerCondition={isLoadingDepartments}
            Component={Departments}
          />
        }
      />
      <Route
        path="update-department/:id"
        element={
          <ProtectedRoute
            isUserLoggedIn={!!refreshToken}
            loadingSpinnerCondition={isLoadingDepartments}
            Component={UpdateDepartment}
          />
        }
      />
      <Route
        path="add-department"
        element={
          <ProtectedRoute
            isUserLoggedIn={!!refreshToken}
            loadingSpinnerCondition={isLoadingDepartments}
            Component={AddDepartment}
          />
        }
      />

      <Route
        path="holidays"
        element={
          <ProtectedRoute
            isUserLoggedIn={!!refreshToken}
            loadingSpinnerCondition={isLoadingHolidays}
            Component={Holidays}
          />
        }
      />
      <Route
        path="add-holiday"
        element={
          <ProtectedRoute
            isUserLoggedIn={!!refreshToken}
            loadingSpinnerCondition={isLoadingHolidays}
            Component={AddHolidays}
          />
        }
      />
      <Route
        path="leave-requests"
        element={
          <ProtectedRoute
            isUserLoggedIn={!!refreshToken}
            loadingSpinnerCondition={!user}
            Component={AllLeaveRequests}
          />
        }
      />

      <Route
        path="leave-requests/:status"
        element={
          <ProtectedRoute
            isUserLoggedIn={!!refreshToken}
            loadingSpinnerCondition={!user && isLoadingLeaves}
            Component={AllLeaveRequests}
          />
        }
      />

      <Route
        path="overview-calendar"
        element={
          <ProtectedRoute
            isUserLoggedIn={!!refreshToken}
            loadingSpinnerCondition={isLoadingHolidays}
            Component={LeaveCalendar}
          />
        }
      />
      <Route
        path="leaves/update/:id"
        element={
          <ProtectedRoute
            isUserLoggedIn={!!refreshToken}
            loadingSpinnerCondition={isLoadingLeaves}
            Component={UpdateLeaveRequest}
          />
        }
      />
      <Route
        path="manage-time-trackers"
        element={
          <ProtectedRoute
            isUserLoggedIn={!!refreshToken}
            loadingSpinnerCondition={isLoadingEmployees && isLoadingProjects}
            Component={ManageTimeTrackers}
          />
        }
      />
      <Route
        path="uncomplete-time-trackers"
        element={
          <ProtectedRoute
            isUserLoggedIn={!!refreshToken}
            loadingSpinnerCondition={isLoadingEmployees}
            Component={UncompletedTimeTrackers}
          />
        }
      />
      <Route
        path="view-time-trackers"
        element={
          <ProtectedRoute
            isUserLoggedIn={!!refreshToken}
            loadingSpinnerCondition={isLoadingEmployees && isLoadingProjects}
            Component={ViewTimeTrackers}
          />
        }
      />

      <Route
        path="manage-time-trackers/create-time-tracker/:id/:date"
        element={
          <ProtectedRoute
            isUserLoggedIn={!!refreshToken}
            loadingSpinnerCondition={isLoadingEmployees && isLoadingProjects}
            Component={TimeTracker}
          />
        }
      />
      <Route
        path="equipments"
        element={
          <ProtectedRoute
            isUserLoggedIn={!!refreshToken}
            loadingSpinnerCondition={!user}
            Component={Equipments}
          />
        }
      />
      <Route
        path="add-equipment"
        element={
          <ProtectedRoute
            isUserLoggedIn={!!refreshToken}
            loadingSpinnerCondition={isLoadingEquipments}
            Component={AddEquipment}
          />
        }
      />
      <Route
        path="equipments/edit-equipment/:id"
        element={
          <ProtectedRoute
            isUserLoggedIn={!!refreshToken}
            loadingSpinnerCondition={isLoadingEquipments}
            Component={UpdateEquipment}
          />
        }
      />

      <Route
        path="file-manager/upload"
        element={
          <ProtectedRoute
            isUserLoggedIn={!!refreshToken}
            loadingSpinnerCondition={isLoadingEmployees}
            Component={FileUploadForm}
          />
        }
      />

      <Route
        path="/file-manager/*"
        element={
          <ProtectedRoute
            isUserLoggedIn={!!refreshToken}
            loadingSpinnerCondition={!user}
            Component={FileSystem}
          />
        }
      />
      <Route path="/*" element={<PageNotFound />} />
    </Route>
  );
};

export default useAdminRoutes;
