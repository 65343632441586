import { createSelector, createSlice } from "@reduxjs/toolkit";
import { appEndpoints } from "../endpoints/app.endpoints";
import { RootState } from "./store";

const initialState: {
  chats: [];
  chatsOneToOne: [];
  conversationsWith: [];
  isError: boolean;
  isSuccess: boolean;
  isLoading: boolean;
} = {
  chats: [],
  chatsOneToOne: [],
  conversationsWith: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
};

const selectSelf = (state: RootState) => state.chats;

export const selectChatsState = createSelector(selectSelf, (state) => state);

export const chatSlice = createSlice({
  name: "chats",
  initialState,
  reducers: {
    resetChatState: () => initialState,
    getChats: (state, action) => {
      state.chats = action.payload;
    },
    addMessage: (state, action) => {
      state.conversationsWith = action.payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addMatcher(
        appEndpoints.endpoints.getOneToOneChats.matchFulfilled,
        (state, { payload }) => {
          state.isError = false;
          state.isLoading = false;
          state.chatsOneToOne = payload;
          state.isSuccess = true;
        }
      )
      .addMatcher(
        appEndpoints.endpoints.getConversations.matchFulfilled,
        (state, { payload }) => {
          state.isError = false;
          state.isLoading = false;
          state.conversationsWith = payload;
          state.isSuccess = true;
        }
      ),
});

export const { resetChatState, getChats, addMessage } = chatSlice.actions;
