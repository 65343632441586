import { createSlice, createSelector } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { Project } from "../models/project.interface";
import { appEndpoints } from "../endpoints/app.endpoints";
import { PaginationState } from "../models/states/pagination-state.interface";

const initialState: PaginationState<Project> = {
  data: [],
  isLoading: true,
  error: undefined,
  currentPage: 1,
  limitTo: 10,
  hasMorePages: true,
  lastPage: undefined,
  totalRecords: 10,
};

const selectSelf: any = (state: RootState) => state.project;

export const selectProjectSliceState = createSelector(
  selectSelf,
  (state) => state as PaginationState<Project>
);

export const selectProjects = createSelector(
  selectProjectSliceState,
  (state) => state.data
);

export const selectProjectById = (id: string | undefined) =>
  createSelector(
    selectProjects,
    (state) => state.find((project) => project.projectId === id) as Project
  );

export const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    resetProjectState: () => initialState,
  },
  extraReducers: (builder) =>
    builder
      .addMatcher(
        appEndpoints.endpoints.getPaginatedProjects.matchFulfilled,
        (state, { payload }) => {
          state.error = undefined;
          state.isLoading = false;
          state.data = payload.data;
          state.totalRecords = payload.totalRecords;
          state.currentPage = payload.currentPage;
          state.hasMorePages = payload.hasMorePages;
        }
      )
      .addMatcher(
        appEndpoints.endpoints.getPaginatedProjects.matchRejected,
        (state, { payload }: any) => {
          return { ...state, error: payload.data, isLoading: true };
        }
      ),
});

export const { resetProjectState } = projectSlice.actions;
