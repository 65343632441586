import { Formik } from "formik";
import { useContext } from "react";
import { RootContext } from "../../context/RootContext";
import { useCreateDepartmentMutation } from "../../data/endpoints/app.endpoints";
import {
  DEPARTMENT_NAME_REGEX,
  handleRegexValidation,
} from "../../utils/regex/formRegex";
import DepartmentForm from "./DepartmentForm";
import { useTranslation } from "react-i18next";

const AddDepartment = () => {
  const { setOpenModal } = useContext(RootContext);
  const { t } = useTranslation();
  const onFormSubmit = () => {
    setOpenModal(true);
  };

  const initialValues = {
    name: "",
  };
  const [createDepartment, result] = useCreateDepartmentMutation();

  const validate = (values: typeof initialValues) => {
    const errors = {} as any;

    handleRegexValidation(
      values,
      errors,
      "name",
      DEPARTMENT_NAME_REGEX,
      t("FORM_VALIDATIONS.DEPARTMENT_VALIDATIONS.INVALID_NAME"),
      t("FORM_VALIDATIONS.REQUIRED")
    );

    return errors;
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={onFormSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
      }: any) => (
        <DepartmentForm
          buttonTitle={t("USERS_ADMIN.DEPARTMENTS.CREATE")}
          errors={errors}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          setFieldValue={setFieldValue}
          handleMutation={createDepartment}
          touched={touched}
          values={values}
          resetForm={resetForm}
          submitIsLoading={result?.isLoading}
        />
      )}
    </Formik>
  );
};

export default AddDepartment;
