import { FileManagerTypes } from "../../utils/enums/fileManagerTypes";
import { Box, Tooltip, useTheme } from "@mui/material";
import FolderFill from "../../assets/folder_filled_1.png";
import { useNavigate } from "react-router";
import { useMemo, useState } from "react";
import CustomFileManagerHeader from "./CustomFileManagerHeader";
import {
  FormatListBulleted,
  GridView,
  Search,
  Upload,
} from "@mui/icons-material";
import CustomFMActionButton from "./CustomFMActionButton";
import { tokens } from "../../core/AppStyles";
import useIsMobile from "../../shared/hooks/useIsMobile";

const MainFolders = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useIsMobile();
  const [viewFileMode, setFileViewMode] = useState<string>("");
  const [fileSearch, setFileSearch] = useState<string | undefined>("");

  const navigate = useNavigate();
  const handleFileViewMode = () => {
    const viewMode = localStorage.getItem("view-mode");
    if (viewMode && viewMode === "Grid") {
      setFileViewMode("List");
      localStorage?.setItem("view-mode", "List");
    } else if (viewMode && viewMode === "List") {
      setFileViewMode("Grid");
      localStorage?.setItem("view-mode", "Grid");
    } else {
      localStorage?.setItem("view-mode", "Grid");
    }
  };

  const handleFileChange = (e: any) => {
    setFileSearch(e?.target?.value);
  };

  const filteredFolders = useMemo(() => {
    return FileManagerTypes?.filter((folder: string) =>
      folder?.toLowerCase().includes(fileSearch as string)
    );
  }, [fileSearch]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CustomFileManagerHeader
        children={
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", gap: 2 }}>
              <Box
                sx={{
                  width: isMobile ? "130px" : "200px",
                  height: "30px",
                  border: "0.5px solid lightgray",
                  borderRadius: "6px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  cursor: "pointer",
                  gap: 1,
                  padding: "5px",
                  overflow: "hidden",
                }}
              >
                <input
                  type="text"
                  id="fileSearch"
                  style={{
                    border: "none",
                    outline: 0,
                    background: "none",
                    height: "100%",
                    color: colors.invert,
                  }}
                  onChange={handleFileChange}
                  value={fileSearch}
                  placeholder="search ..."
                  autoComplete="off"
                />
                {isMobile ? (
                  ""
                ) : (
                  <Search fontSize="small" sx={{ color: colors.invert }} />
                )}
              </Box>
              <CustomFMActionButton
                title={viewFileMode ? viewFileMode : "Grid"}
                icon={
                  viewFileMode === "Grid" ? (
                    <GridView fontSize="small" sx={{ color: colors.invert }} />
                  ) : viewFileMode === "List" ? (
                    <FormatListBulleted
                      fontSize="small"
                      sx={{ color: colors.invert }}
                    />
                  ) : (
                    <GridView fontSize="small" sx={{ color: colors.invert }} />
                  )
                }
                onAction={() => handleFileViewMode()}
              />
            </Box>
            <Box
              sx={{
                width: isMobile ? "30px" : "100px",
                height: isMobile ? "30px" : "30px",
                border: `1px solid ${colors.invert}`,
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
                padding: "1%",
                overlay: "hidden",
                cursor: "pointer",
              }}
              onClick={() => navigate("/file-manager/upload")}
            >
              <Upload sx={{ color: colors.invert, fontSize: "17px" }} />

              <h4
                style={{
                  color: colors.invert,
                  fontSize: isMobile ? "13px" : "auto",
                }}
              >
                {isMobile ? "" : "Upload"}
              </h4>
            </Box>
          </Box>
        }
      />
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection:
            viewFileMode && viewFileMode === "Grid"
              ? "row"
              : viewFileMode === "List"
              ? "column"
              : "row",
          gap: 1,
          alignItems: "flex-start",
          justifyContent: "flex-start",
          flexWrap: "wrap",
          marginTop: "2vh",
          overflowY: "auto",
        }}
      >
        {filteredFolders?.map((folder: string, index: number) =>
          viewFileMode && viewFileMode === "List" ? (
            isMobile ? (
              <Box
                sx={{
                  width: "100%",
                  height: "30px",
                  padding: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: 1,
                  cursor: "pointer",
                }}
                key={index}
                onClick={() => navigate(`${folder}`)}
              >
                <img src={FolderFill} alt="folder" width="20px" />
                <span>{folder}</span>
              </Box>
            ) : (
              <Box
                sx={{
                  width: "100%",
                  height: "30px",
                  padding: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: 1,
                  cursor: "pointer",
                }}
                key={index}
                onDoubleClick={() => navigate(`${folder}`)}
              >
                <img src={FolderFill} alt="folder" width="20px" />
                <span>{folder}</span>
              </Box>
            )
          ) : isMobile ? (
            <Box
              sx={{
                width: "90px",
                height: "90px",
                padding: "1%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                overflow: "hidden",
                cursor: "pointer",
                // gap: 1,
              }}
              key={index}
              onClick={() => navigate(`${folder}`)}
            >
              <img src={FolderFill} alt="folder" width="60px" />
              <b>
                {
                  <Tooltip title={folder}>
                    {folder?.length > 8
                      ? ((folder?.slice(0, 8) + "...") as any)
                      : (folder as any)}
                  </Tooltip>
                }
              </b>
            </Box>
          ) : (
            <Box
              sx={{
                width: "90px",
                height: "110px",
                padding: "1%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                overflow: "hidden",
                cursor: "pointer",
              }}
              key={index}
              onDoubleClick={() => navigate(`${folder}`)}
            >
              <img src={FolderFill} alt="folder" width="65px" />
              <b>
                {
                  <Tooltip title={folder}>
                    {folder?.length > 8
                      ? ((folder?.slice(0, 8) + "...") as any)
                      : (folder as any)}
                  </Tooltip>
                }
              </b>
            </Box>
          )
        )}
      </Box>
    </Box>
  );
};

export default MainFolders;
