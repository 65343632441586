import { useTranslation } from "react-i18next";
import BoxContainer from "../../shared/BoxContainer";
import CustomHeader from "../../shared/custom/CustomHeader";
import { Box } from "@mui/material";
import ReportHeader from "./report-components/Report-header";

const ReportPaper = () => {
  const { t } = useTranslation();

  return (
    <BoxContainer>
      <CustomHeader
        title={t("REPORT_PAPERS.TITLE").toUpperCase()}
        subtitle={t("REPORT_PAPERS.SUBTITLE")}
      />
      <Box
        sx={{
          width: "100%",
          height: "75vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          overflowY: "auto",
          padding: "1% 0%",
        }}
      >
        <Box
          sx={{
            width: "70%",
            height: "700px",
            borderRadius: "10px",
            boxShadow: "0px 0px 1px 1px rgba(0,0,0,0.1)",
            background: "#ffffff",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            padding: "15px",
          }}
        >
          <ReportHeader />
          <Box
            sx={{
              width: "100%",
              height: "60px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              overflow: "hidden",
            }}
          >
            <h3
              style={{
                margin: 0,
                padding: 0,
                width: "2%",
              }}
            >
              Regiarapport
            </h3>
            <h4
              style={{
                margin: 0,
                padding: 0,
                width: "10%",
              }}
            >
              Nr. 334
            </h4>
            <Box
              sx={{
                width: "auto",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center",
              }}
            >
              <small>Autraggeber</small>
              <input
                type="text"
                style={{ width: "220px", height: "30px" }}
              ></input>
            </Box>
            <Box
              sx={{
                width: "auto",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center",
              }}
            >
              <small>Autrags-Nr</small>
              <input
                type="text"
                style={{ width: "220px", height: "30px" }}
              ></input>
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "125px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                width: "47%",
                height: "100%",
                display: "grid",
                gridTemplateColumns: "25% 75%",
                border: "1px solid black",
              }}
            >
              <Box
                sx={{
                  height: "100%",
                  background: "lightgray",
                }}
              >
                Objekt
              </Box>
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <input
                  type="text"
                  style={{
                    width: "100%",
                    height: "30px",
                    border: "none",
                    borderBottom: "1px solid gray",
                  }}
                ></input>
                <input
                  type="text"
                  style={{
                    width: "100%",
                    height: "30px",
                    border: "none",
                    borderBottom: "1px solid gray",
                  }}
                ></input>
                <input
                  type="text"
                  style={{
                    width: "100%",
                    height: "30px",
                    border: "none",
                    borderBottom: "1px solid gray",
                  }}
                ></input>
                <input
                  type="text"
                  style={{
                    width: "100%",
                    height: "30px",
                    border: "none",
                    borderBottom: "none",
                  }}
                ></input>
              </Box>
            </Box>
            <Box
              sx={{
                width: "47%",
                height: "100%",
                display: "grid",
                gridTemplateColumns: "25% 75%",
                border: "1px solid black",
              }}
            >
              <Box
                sx={{
                  height: "100%",
                  background: "lightgray",
                }}
              >
                Rechnung
              </Box>
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <input
                  type="text"
                  style={{
                    width: "100%",
                    height: "30px",
                    border: "none",
                    borderBottom: "1px solid gray",
                  }}
                ></input>
                <input
                  type="text"
                  style={{
                    width: "100%",
                    height: "30px",
                    border: "none",
                    borderBottom: "1px solid gray",
                  }}
                ></input>
                <input
                  type="text"
                  style={{
                    width: "100%",
                    height: "30px",
                    border: "none",
                    borderBottom: "1px solid gray",
                  }}
                ></input>
                <input
                  type="text"
                  style={{
                    width: "100%",
                    height: "30px",
                    border: "none",
                    borderBottom: "none",
                  }}
                ></input>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              width: "100%",
              height: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              border: "1px solid gray",
              marginTop: "1vh",
              padding: "1%",
              listStyle: "none",
            }}
          >
            <b
              style={{
                padding: "0",
                margin: "0",
                width: "100%",
                borderBottom: "1px solid gray",
              }}
            >
              Informativ:
            </b>
            <Box
              sx={{
                width: "100%",
                maxHeight: "130px",
                overflowY: "auto",
              }}
            >
              <input
                type="text"
                style={{
                  width: "100%",
                  height: "30px",
                  border: "none",
                  borderBottom: "1px solid gray",
                }}
              ></input>
            </Box>

            <b
              style={{
                padding: "0",
                margin: "0",
                width: "100%",
                borderBottom: "1px solid gray",
              }}
            >
              Waz war zu tun:
            </b>
            <Box
              sx={{
                width: "100%",
                maxHeight: "130px",
                overflowY: "auto",
              }}
            >
              <Box>
                <input
                  type="text"
                  style={{
                    width: "100%",
                    height: "30px",
                    border: "none",
                    borderBottom: "1px solid gray",
                  }}
                ></input>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "120px",
              display: "grid",
              gridTemplateColumns: "27% 27% 33%",
              alignItems: "center",
              alignContent: "center",
              gap: "3%",
              marginTop: "1vh",
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <small>Visum Monteur:</small>{" "}
                <input
                  type="text"
                  style={{
                    width: "130px",
                    background: "none",
                    border: "none",
                    borderBottom: "1px solid gray",
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <small>Visum PL:</small>{" "}
                <input
                  type="text"
                  style={{
                    width: "130px",
                    background: "none",
                    border: "none",
                    borderBottom: "1px solid gray",
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <small>Datum:</small>
                <input
                  type="text"
                  style={{
                    width: "130px",
                    background: "none",
                    border: "none",
                    borderBottom: "1px solid gray",
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <small>Der Auftraggeber:</small>
                <input
                  type="text"
                  style={{
                    width: "130px",
                    background: "none",
                    border: "none",
                    borderBottom: "1px solid gray",
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <small
                style={{
                  width: "75%",
                  fontSize: "9px",
                  textAlign: "justify",
                  float: "right",
                }}
              >
                <i>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Laborum nesciunt natus alias facere ducimus rerum perferendis
                  officiis deserunt corrupti doloremque possimus.
                </i>
              </small>
            </Box>
          </Box>
        </Box>
      </Box>
    </BoxContainer>
  );
};

export default ReportPaper;
