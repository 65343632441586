import { Box, useTheme } from "@mui/material";
import ChangeWorkingHoursFromDateModal from "./ChangeWorkingHoursFromDateModal";
import OvertimeCompensationModal from "./OvertimeCompensationModal";
import VacanciesCompensationModal from "./VacanciesCompensationModal";
import ChangePasword from "./ChangePassword";
import ChangeStatus from "./ChangeStatus";
import { useParams } from "react-router";
import { tokens } from "../../core/AppStyles";
import useIsMobile from "../../shared/hooks/useIsMobile";
import { selectEmployeeById } from "../../data/store/employeeSlice";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const ViewEmployeeActions = () => {
  const { id } = useParams();
  const theme = useTheme();
  const color = tokens(theme.palette.mode);
  const isMobile = useIsMobile();
  const { t } = useTranslation();

  const { firstName } = useSelector(selectEmployeeById(id));
  return (
    <>
      <Box
        style={{
          width: "92%",
          height: "10%",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          marginBottom: "1vh",
          marginTop: "1vh",
        }}
      >
        <p
          style={{
            color: color.invert,
            fontSize: isMobile ? "1.2rem" : "1.5rem",
            fontWeight: 500,
          }}
        >
          {t("USERS_ADMIN.VIEW_USER.USER_ACTIONS")}
        </p>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          flexWrap: "wrap",
          justifyContent: "space-evenly",
          alignSelf: "center",
          gap: 1,
        }}
      >
        <ChangeWorkingHoursFromDateModal id={id} name={firstName} />
        <OvertimeCompensationModal id={id} name={firstName} />
        <VacanciesCompensationModal id={id} name={firstName} />
        <ChangePasword id={id} name={firstName} />
        <ChangeStatus id={id} name={firstName} />
      </Box>
    </>
  );
};

export default ViewEmployeeActions;
