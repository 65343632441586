import { PieChart, Pie, Tooltip, ResponsiveContainer, Cell } from "recharts";
import useIsLargeScreen from "../../../../shared/hooks/useIsLargeScreen";
interface IProjectWorkedByUser {
  data: [];
}

const PieChartComponent = ({ data }: IProjectWorkedByUser) => {
  const isLargeScreen = useIsLargeScreen();
  const graphData = data?.map((proj: any) => {
    return {
      name: proj?.projectTitle,
      value: proj?.workedOnProjetcs?.totalTimeWorked?.hours,
    };
  });

  const COLORS = ["#8884d8", "#00C49F", "#0088FE", "#FF8042", "#FFBB28"];

  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart width={300} height={300} style={{ zIndex: 100 }}>
        <Pie
          dataKey="value"
          isAnimationActive={false}
          data={graphData}
          cx="50%"
          cy="50%"
          outerRadius={isLargeScreen ? 80 : 60}
          fill="#8884d8"
          label
        >
          {graphData?.map((entry: any, index: number) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default PieChartComponent;
