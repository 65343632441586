/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useEffect, useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import Message from "./Message";
import { tokens } from "../../../../../core/AppStyles";
import { useSelector } from "react-redux";
import { useLazyGetChatsQuery } from "../../../../../data/endpoints/app.endpoints";
import { selectLoggedUser } from "../../../../../data/store/authSlice";
import moment from "moment";
import { CommentsDisabledOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { socket } from "../../../../../App";

const ChatMessagesBox = () => {
  const [getGroupChats, getGroupChatsResults] = useLazyGetChatsQuery();
  const lastMessageRef = useRef<any>(null);

  const onlineStatus = window.navigator.onLine;
  const online = () => {};
  const offline = () => {};
  if (onlineStatus) online();
  else offline();

  useEffect(() => {
    getGroupChats(undefined);
  }, []);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  const user = useSelector(selectLoggedUser);
  const chatMessages = getGroupChatsResults?.data;
  const [allMessage, setSocketMessageArray] = useState<any>([]);

  useEffect(() => {
    if (chatMessages) {
      setSocketMessageArray(chatMessages);
    }

    socket.on("message", (messageDatas) => {
      const newMessage = {
        message: messageDatas?.message,
        user: messageDatas?.user,
        id: messageDatas?.userId,
        createdAt: messageDatas?.createdAt,
        updatedAt: "",
      };
      setSocketMessageArray((prevMessages: any) => [
        ...prevMessages,
        newMessage,
      ]);
    });

    return () => {
      socket.off("message");
    };
  }, [chatMessages]);

  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef?.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [allMessage]);

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "60%",
        maxHeight: "61%",
        display: "flex",
        flexDirection: "column-reverse",
        overflowY: "scroll",
        background: colors.primary[400],
        padding: "0 3% 3% 0",
      }}
    >
      {allMessage?.length === 0 ? (
        <Typography
          variant="body1"
          color="gray"
          paddingLeft="2vw"
          textAlign="center"
        >
          <CommentsDisabledOutlined /> <br />
          {t("CHAT.NO_MESSAGES")} <br /> {t("CHAT.MESSAGES_WILL_BE_DELETED")}
        </Typography>
      ) : (
        allMessage
          ?.slice()
          .reverse()
          .map((chat: any, index: number) => {
            return (
              <div key={index} ref={index === 0 ? lastMessageRef : null}>
                <Message
                  message={chat?.message}
                  user={chat?.user}
                  data={moment(chat?.createdAt).format(`HH:mm | DD/MMM/YYYY`)}
                  margin={
                    user?.firstName + " " + user?.lastName === chat?.user
                      ? "40%"
                      : "5%"
                  }
                  bColor={
                    user?.firstName + " " + user?.lastName === chat?.user
                      ? "#007ffc"
                      : "#e3e3e3"
                  }
                  color={
                    user?.firstName + " " + user?.lastName === chat?.user
                      ? "#ffffff"
                      : "#2C3639"
                  }
                />
              </div>
            );
          })
      )}
    </Box>
  );
};

export default ChatMessagesBox;
