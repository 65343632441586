import { createSlice, createSelector } from "@reduxjs/toolkit";

import { appEndpoints } from "../endpoints/app.endpoints";

const initialState: any = {
  expenses: [],
  isLoading: true,
  isSuccess: false,
  error: undefined,
};

const selectSelf: any = (state: any) => state.expenses;

export const selectExpensesSliceState = createSelector(
  selectSelf,
  (state) => state as any
);
export const selectAllExpenses = createSelector(
  selectExpensesSliceState,
  (state) => state?.data
);

export const selectExpenseById = (id: string | undefined) =>
  createSelector(
    selectAllExpenses,
    (state) => state.find((expense: any) => expense.expensesId === id) as any
  );

export const expensesSlice = createSlice({
  name: "expenses",
  initialState,
  reducers: {
    resetExpensesState: () => initialState,
  },
  extraReducers: (builder) =>
    builder
      .addMatcher(
        appEndpoints.endpoints.getPaginatedExpenses.matchFulfilled,
        (state, { payload }) => {
          state.error = undefined;
          state.isLoading = false;
          state.data = payload?.data;
          state.isSuccess = true;
        }
      )
      .addMatcher(
        appEndpoints.endpoints.getPaginatedExpenses.matchRejected,
        (state, { payload }: any) => {
          return { ...state, error: payload, isLoading: true };
        }
      )
      .addMatcher(
        appEndpoints.endpoints.getPaginatedExpenses.matchRejected,
        (state, { payload }: any) => {
          return { ...state, error: payload };
        }
      ),
});

export const { resetExpensesState } = expensesSlice.actions;
