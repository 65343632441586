import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Pagination,
  PaginationItem,
  Stack,
  useTheme,
} from "@mui/material";
import { tokens } from "../../core/AppStyles";
import TableDetailsMobileModal from "../../shared/custom/CustomTableDetailModalMobile";
import { useState } from "react";
import { PrevIcon, NextIcon } from "../../shared/custom/CustomEditIcons";
import CustomSelector from "../../shared/custom/CustomSelector";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { MoreVert } from "@mui/icons-material";
import AlertDialog from "../../shared/modal/AlertDialog";
import {
  useDeleteExpenseMutation,
  useLazyDownloadFileByUserIdQuery,
} from "../../data/endpoints/app.endpoints";
import { useSelector } from "react-redux";
import { selectLoggedUser } from "../../data/store/authSlice";
import FilePreviewModal from "../file-manager/FilePreviewModal";
import { Expense } from "../../data/models/expense.interface";

interface ICustomExpensesTableMobile {
  column1Name?: string;
  column2Name?: string;
  data?: Expense[];
  isPaginated: boolean;
  rowsPerPage?: number;
  page?: number;
  count?: number;
  mt?: number;
  handleChangePage?: (event: any, newPage: number) => void;
  handleChangeRowsPerPage?: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
}

export default function CustomExpensesTableMobile({
  column1Name,
  column2Name,
  data,
  isPaginated,
  rowsPerPage,
  page,
  count,
  mt,
  handleChangePage,
  handleChangeRowsPerPage,
}: ICustomExpensesTableMobile) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const loggedUser = useSelector(selectLoggedUser);

  const [downloadFile, result] = useLazyDownloadFileByUserIdQuery();
  const [deleteExpense] = useDeleteExpenseMutation();

  const [rowDetails, setRowDetails] = useState<any>();
  const [header, setHeader] = useState<string>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [fileUrl, setFileUrl] = useState("");
  const [_fileId, setFileId] = useState("");
  const [_fileName, setFileName] = useState("");
  const [_expensesId, setExpensesId] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const fillActionsStates = (
    _fileId: string,
    _fileName: string,
    _expenseId: string
  ) => {
    setFileId(_fileId);
    setFileName(_fileName);
    setExpensesId(_expenseId);
  };

  const closePreviewModal = () => {
    setPreviewOpen(false);
  };

  const handleDownload = async () => {
    try {
      const response = await downloadFile({
        fileId: _fileId,
        fileName: _fileName,
      });

      if ("data" in response) {
        const { downloadUrl } = response.data;
        if (downloadUrl) {
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.download = _fileName;
          link.click();
        }
      }
    } catch (error) {
      alert("Error downloading file");
    }
  };

  const { t } = useTranslation();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleModalOpen = () => {
    setOpenModal(true);
  };
  const handleDelete = () => {
    deleteExpense({
      expensesId: _expensesId,
    });
    setOpenModal(false);
    handleClose();
  };

  const handleViewFile = async () => {
    try {
      const response = await downloadFile({
        fileId: _fileId,
        fileName: _fileName,
      });

      if ("data" in response) {
        const { downloadUrl } = response.data;
        if (downloadUrl) {
          setFileUrl(downloadUrl);
          setPreviewOpen(true);
        }
      }
    } catch (error) {
      alert("Error downloading file");
    }
  };

  const handleDetailsModal = (id: string, expensesId: string) => {
    const _rowDetails = data?.filter(
      (info: any) => info?.userId === id && info?.expensesId === expensesId
    );

    const rowExpensesData: any[] = [];

    _rowDetails?.map((info: any) => {
      return rowExpensesData.push({
        data: [
          {
            key: t("EMPLOYEE"),
            value: info?.user?.firstName + " " + info?.user?.lastName,
          },
          {
            key: t("TYPE"),
            value: info?.type,
          },
          {
            key: t("VEHICLE.TITLE"),
            value: info?.vehicle?.model || "-",
          },
          {
            key: t("DATE"),
            value: moment(info?.createdAt).format("DD MMM YYYY"),
          },
          {
            key: t("VEHICLE.TABLE_TITLES.KILOMETERS"),
            value: info?.distance || "-",
          },
          {
            key: t("EXPENSES.ADD_EXPENSE.MATERIAL"),
            value: info?.material || "-",
          },
          {
            key: t("EXPENSES.ADD_EXPENSE.COST") + " (CHF)",
            value: info?.cost + " CHF",
          },
        ],
      });
    });

    setRowDetails(rowExpensesData);
    setHeader(rowExpensesData?.[0]?.data?.[0]?.value);
  };

  return (
    <Paper
      sx={{
        borderRadius: "20px",
        boxShadow: `${colors.shadow} 1px 1px 5px 1px`,
        marginTop: `${mt}%`,
        backgroundColor: colors.component,
      }}
    >
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 300, backgroundColor: colors.component }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell>{column1Name}</TableCell>
              <TableCell align="left">{column2Name}</TableCell>
              <TableCell align="left">{t("ACTIONS")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row: Expense, index: number) => (
              <TableRow key={index}>
                <TableCell
                  align="center"
                  width="50px"
                  onClick={() =>
                    handleDetailsModal(row?.userId, row?.expensesId)
                  }
                >
                  <TableDetailsMobileModal
                    dataInfo={rowDetails}
                    header={header}
                  />
                </TableCell>
                <TableCell align="left">
                  <b>{row?.user?.firstName + " " + row?.user?.lastName}</b>{" "}
                  <br />
                  {row?.cost + " CHF"}
                </TableCell>
                <TableCell align="left">
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? "long-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={(event) => {
                      handleClick(event);
                      fillActionsStates(
                        row?.file?.fileId,
                        row?.file?.fileName,
                        row?.expensesId
                      );
                    }}
                  >
                    <MoreVert sx={{ width: "17px", height: "17px" }} />
                  </IconButton>
                  <Menu
                    id="long-menu"
                    MenuListProps={{
                      "aria-labelledby": "long-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                  >
                    <MenuItem
                      onClick={() => {
                        handleDownload();
                        handleClose();
                      }}
                    >
                      {t("DOWNLOAD")}
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleViewFile();
                        handleClose();
                      }}
                    >
                      {t("VIEW")}
                    </MenuItem>
                    {loggedUser?.role === "Admin" && (
                      <AlertDialog
                        openModal={openModal}
                        setOpenModal={setOpenModal}
                        buttonTitle=""
                        handleSubmitDialog={() => handleDelete()}
                        ButtonComponent={(props: any) =>
                          loggedUser?.role === "Admin" && (
                            <MenuItem onClick={handleModalOpen}>
                              {t("DELETE")}
                            </MenuItem>
                          )
                        }
                        mt="20px"
                      >
                        {t("EXPENSES.ADD_EXPENSE.MODAL_DELETE_CONFIRMATION")}
                      </AlertDialog>
                    )}
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isPaginated ? (
        <Stack
          flexDirection="row"
          width="100%"
          justifyContent="center"
          padding={2}
          sx={{ backgroundColor: colors.component }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <span
              style={{
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              <p
                style={{
                  color: "gray",
                }}
              >
                {t("ROWS")}:
              </p>
              <CustomSelector
                size="medium"
                value={rowsPerPage}
                onSelectChange={handleChangeRowsPerPage}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={count}>{t("STATUSES.ALL")}</MenuItem>
              </CustomSelector>
            </span>
            <Pagination
              siblingCount={0}
              size="small"
              color="secondary"
              shape="rounded"
              count={count && rowsPerPage && Math.ceil(count / rowsPerPage)}
              page={page}
              onChange={handleChangePage}
              renderItem={(item) => (
                <PaginationItem
                  sx={{ backgroundColor: colors.primary[400] }}
                  slots={{ previous: PrevIcon, next: NextIcon }}
                  {...item}
                />
              )}
            />
          </Box>
        </Stack>
      ) : null}
      <FilePreviewModal
        open={previewOpen}
        handleClose={closePreviewModal}
        fileUrl={fileUrl}
        fileName={_fileName}
      />
    </Paper>
  );
}
