import { Box, Typography, colors, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { selectEmployeeById } from "../../data/store/employeeSlice";
import CustomHeader from "../../shared/custom/CustomHeader";
import { tokens } from "../../core/AppStyles";
import useIsMobile from "../../shared/hooks/useIsMobile";
import { useState } from "react";
import "jspdf-autotable";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import CustomNavigationButton from "../../shared/custom/CustomNavigationButton";
import { BLUE_COLOR_ACCENT } from "../../utils/constants/colors";
import useIsTablet from "../../shared/hooks/useIsTablet";
import useIsLargeScreen from "../../shared/hooks/useIsLargeScreen";
import useIsDesktop from "../../shared/hooks/useIsDesktop";
import { useTranslation } from "react-i18next";
import { Menu, MenuItem, MenuItemStyles } from "react-pro-sidebar";
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
import DnsIcon from '@mui/icons-material/Dns';
import SmartButtonIcon from '@mui/icons-material/SmartButton';
import ViewEmployeeDetails from "./ViewEmployeeDetails";
import ViewEmployeeLogs from "./ViewEmployeeLogs";
import ViewEmployeeActions from "./ViewEmployeeActions";


const ViewUser = () => {
  const { id } = useParams();
  const theme = useTheme();
  const color = tokens(theme.palette.mode);
  const isMobile = useIsMobile();
  const isDesktop = useIsDesktop();
  const isLargeScreen = useIsLargeScreen();
  const { t } = useTranslation();
  const [selected, setSelected] = useState(`/view-user/${id}`)
  const navigation = useNavigate()


  const menuItemStyles: MenuItemStyles = {
    subMenuContent: {
      backgroundColor: color.component,
    },
    button: ({ active }) => {
      if (active) {
        return {
          backgroundColor: color.sideNav,
          borderRadius: "0 10px 10px 0",
          transition: "ease-in-out 0.2s",
          "&:hover": {
            backgroundColor: "#b7dbff80",
          },
        };
      }
      return {
        borderRadius: "0 10px 10px 0",
        "&:hover": {
          backgroundColor: "rgba(183, 219, 255, 0.2)",
          transition: "ease-in-out 0.2s",
        },
      };
    },
    root: {
      margin: "0 0 5px 0",
      borderRadius: "0 10px 10px 0",
      width: "100%",
      fontSize: "15px",
      fontWeight: 400,
      backgroundColor: color.component,
      border: "none",
    },
    SubMenuExpandIcon: {
      color: "#b6b7b9",
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };

  const {
    firstName,
    lastName,
  } = useSelector(selectEmployeeById(id));



  const isTablet = useIsTablet();
  const handleItemClick = (to: any) => {
    setSelected(to);
    navigation(to);
  };



  return (
    <>
      <CustomHeader
        title={t("USERS_ADMIN.VIEW_USER.TITLE")}
        subtitle={t("USERS_ADMIN.VIEW_USER.SUBTITLE")}
      >
        <CustomNavigationButton
          customStyle={{
            backgroundColor: BLUE_COLOR_ACCENT,
            width: isTablet ? "20%" : "10%",
            display: "flex",
            alignSelf: "center",
            fontSize: "12px",
            ":hover": {
              bgcolor: "#1d61c6",
              color: "white",
            },
          }}
          navigateTo={"/employees"}
        >
          <KeyboardArrowLeftIcon />

          {isMobile ? null : `${t("GO_BACK")}`}
        </CustomNavigationButton>
      </CustomHeader>
      <Box
        style={{
          width: "100%",
          height: isMobile ? "auto" : "80vh",
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "flex-start",
          overflow: "hidden",
          gap: isMobile ? 10 : 30,
          marginTop: isDesktop || isLargeScreen ? "2.5%" : "0%",
        }}
      >
        <Box
          style={{
            width: isMobile ? "100%" : "25%",
            height: isMobile ? "auto" : isLargeScreen ? "80vh" : "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: 20,
            overflow: "hidden",
            boxShadow: "0px 0px 5px 0.5px rgba(0,0,0,0.12)",
            borderRadius: "5px",
            padding: isMobile ? "5%" : "1%",
            marginTop: isMobile ? "1vh" : "0",
            background: color.component,
          }}
        >
          <Box
            sx={{
              width: "100%",
            }}
          >
            {/* <small>{t("USERS_ADMIN.VIEW_USER.USER_DETAILS")}</small> */}
            <Box
              style={{
                width: "80px",
                height: "80px",
                borderRadius: "50%",
                marginTop: "2vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: color.invert,
                color: color.white,
              }}
            >
              <h1>{firstName[0] + lastName[0]}</h1>
            </Box>
            <h3>{firstName + " " + lastName}</h3>
          </Box>
          <Menu menuItemStyles={menuItemStyles}>
            <MenuItem
              active={selected === `/view-user/${id}`}
              onClick={() => handleItemClick(`/view-user/${id}`)}
              icon={<ContactEmergencyIcon />}
            >
              {t("USERS_ADMIN.VIEW_USER.USER_DETAILS")}
            </MenuItem>
            <MenuItem
              active={selected === `/view-user/${id}/logs`}
              onClick={() => handleItemClick(`/view-user/${id}/logs`)}
              icon={<DnsIcon />}
            >
              {t("Logs")}
            </MenuItem>
            <MenuItem
              active={selected === `/view-user/${id}/actions`}
              onClick={() => handleItemClick(`/view-user/${id}/actions`)}
              icon={<SmartButtonIcon />}
            >
              {t("ACTIONS")}
            </MenuItem>
          </Menu>


        </Box>

        <Box
          style={{
            width: "100%",
            height: isMobile ? "auto" : "80vh",
            overflow: "hidden",
            marginTop: isMobile ? "2vh" : "0",
            boxShadow: "0px 0px 5px 0.5px rgba(0,0,0,0.12)",
            borderRadius: "5px",
            padding: isMobile ? "5%" : "1%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            color: "gray",
            background: color.component,
          }}
        >
          {<Routes>
            <Route path="" Component={ViewEmployeeDetails} />
            <Route path="logs" Component={ViewEmployeeLogs} />
            <Route path="actions" Component={ViewEmployeeActions} />
          </Routes>}

        </Box>
      </Box>
    </>
  );
};

export default ViewUser;
