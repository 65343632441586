import { createSelector, createSlice } from "@reduxjs/toolkit";
import { appEndpoints } from "../endpoints/app.endpoints";
import { RootState } from "./store";
import { Equipment } from "../models/equipment.interface";

const initialState: {
  equipments: Equipment[];
  isError: boolean;
  isSuccess: boolean;
  isLoading: boolean;
} = {
  equipments: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
};

const selectSelf = (state: RootState) => state.equipments;

export const selectEquipmentsState = createSelector(
  selectSelf,
  (state) => state
);

export const selectAllEquipments = createSelector(
  selectEquipmentsState,
  (state) => state.equipments
);

export const selectEquipmentById = (id: string | undefined) =>
  createSelector(
    selectAllEquipments,
    (state) =>
      state.find((equipment) => equipment.equipmentId === id) as Equipment
  );

export const equipmentsSlice = createSlice({
  name: "equipments",
  initialState,
  reducers: {
    resetEquipmentsState: () => initialState,
  },
  extraReducers: (builder) =>
    builder
      .addMatcher(
        appEndpoints.endpoints.getEquipments.matchFulfilled,
        (state, { payload }) => {
          state.isError = false;
          state.isLoading = false;
          state.equipments = payload?.data;
          state.isSuccess = true;
        }
      )
      .addMatcher(
        appEndpoints.endpoints.getEquipments.matchPending,
        (state, { payload }) => {
          state.isError = false;
          state.isLoading = true;
          state.equipments = [];
          state.isSuccess = false;
        }
      )
      .addMatcher(
        appEndpoints.endpoints.getEquipments.matchRejected,
        (state, { payload }) => {
          state.isError = true;
          state.isLoading = false;
          state.equipments = [];
          state.isSuccess = false;
        }
      ),
});

export const { resetEquipmentsState } = equipmentsSlice.actions;
