import { useState, useMemo, useEffect } from "react";
import CustomTable from "../../shared/custom/CustomTable";
import Box from "@mui/material/Box";
import { MenuItem, SelectChangeEvent } from "@mui/material";
import CustomHeader from "../../shared/custom/CustomHeader";
import SearchBar from "../../shared/custom/SearchBar";
import EmployeeTableRow from "./EmployeeTableRow";
import CustomSelector from "../../shared/custom/CustomSelector";
import { useSelector } from "react-redux";
import { useLazyGetPaginatedUsersQuery } from "../../data/endpoints/app.endpoints";
import BoxContainer from "../../shared/BoxContainer";
import CustomButton from "../../shared/custom/CustomButton";
import {
  BLUE_COLOR_ACCENT,
  BLUE_HOVER_COLOR_ACCENT,
} from "../../utils/constants/colors";
import { useNavigate } from "react-router";
import CustomTableMobile from "../../shared/custom/CustomTableMobile";
import useIsMobile from "../../shared/hooks/useIsMobile";
import CustomHeaderMobile from "../../shared/custom/CustomHeaderMobile";
import useIsTablet from "../../shared/hooks/useIsTablet";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import NoDataComponent from "../../shared/NoDataComponent";
import LoadingSpinner from "../../shared/LoadingSpinner";
import { QueryStatus } from "@reduxjs/toolkit/query";
import { UserStatus } from "../../utils/enums/user-status.enum";

const Employees = () => {
  const { departments } = useSelector((state: any) => state.department);
  const [getPaginatedUsers, result] = useLazyGetPaginatedUsersQuery();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();

  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [q, setQ] = useState("");
  const [status, setStatus] = useState<any>("ACTIVE");
  const [role, setRole] = useState<any>("ALL");
  const [department, setDepartment] = useState<any>("ALL");
  const [limit, setLimit] = useState<any>(10);

  const { t } = useTranslation();

  const handleRowPerPageChange = (event: SelectChangeEvent) => {
    setLimit(event.target.value);
    setPage(1);
  };

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    getPaginatedUsers({
      limitTo: limit,
      currentPage: page,
      q,
      status: status === "ALL" ? "" : status,
      role: role === "ALL" ? "" : role,
      department: department === "ALL" ? "" : department,
    });
  }, [q, status, limit, department, role, page]);

  const handleStatusChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value);
    setPage(1);
  };

  const handleRoleChange = (event: SelectChangeEvent) => {
    setRole(event.target.value);
    setPage(1);
  };

  const handleSelectDepartment = (event: SelectChangeEvent) => {
    setDepartment(event.target.value);
    setPage(1);
  };

  const employeeTitles = [
    t("FIRST_NAME"),
    t("LAST_NAME"),
    t("USERS_ADMIN.REMAINING_VACATIONS"),
    t("USERS_ADMIN.TOTAL_OVERTIME"),
    t("ROLE"),
    t("DEPARTMENT"),
    t("USERS_ADMIN.HIRING_DATE"),
    t("USERS_ADMIN.END_CONTRACT"),
    t("STATUS"),
    t("EDIT"),
    t("VIEW"),
  ];

  const employeesBodyRenderer = useMemo(() => {
    const dataRenderer: JSX.Element[] = [];
    result?.data?.data?.map((row: any, index: number) =>
      dataRenderer.push(<EmployeeTableRow key={index} row={row} />)
    );
    return dataRenderer;
  }, [result?.data?.data]);

  const dataValues = useMemo(() => {
    let data: Object[] = [];
    if (result?.data) {
      result?.data?.data?.map((info: any, index: number) => {
        data.push({
          user: info?.firstName + " " + info?.lastName,
          department: info?.department?.name,
          email: info?.email,
          hiringDate: info?.hiringDate,
          endOfContract: info?.endOfContract,
          role: t(`ROLES.${info?.role?.toUpperCase()}`),
          status: t(`STATUSES.${info?.status?.toUpperCase()}`),
          totalOvertime: (info?.totalOvertimes / 60)
            .toFixed(2)
            .replace(/(\.0+|0+)$/, ""),
          totalVacations: info?.totalVacations
            .toFixed(2)
            .replace(/(\.0+|0+)$/, ""),
          workingHours: info?.workingHours,
        });
      });
    }
    return data;
  }, [result?.data?.data]);

  const headers = [
    { label: `${t("FIRST_NAME")}`, key: "user" },
    { label: `${t("DEPARTMENT")}`, key: "department" },
    { label: `${t("HIRING_DATE")}`, key: "hiringDate" },
    { label: `${t("END_CONTRACT")}`, key: "endOfContract" },
    { label: `${t("ROLE")}`, key: "role" },
    { label: `${t("STATUS")}`, key: "status" },
    { label: `${t("USERS_ADMIN.TOTAL_OVERTIME")}`, key: "totalOvertime" },
    { label: `${t("USERS_ADMIN.REMAINING_VACATIONS")}`, key: "totalVacations" },
    { label: t("WORKING_HOURS"), key: "workingHours" },
  ];

  return (
    <BoxContainer>
      {isMobile || isTablet ? (
        <CustomHeaderMobile
          title={t("USERS")}
          subtitle={t("USERS_ADMIN.MANAGE_USERS")}
          btnTitle="+"
          btnNavLink="/add-user"
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "100%",
              marginTop: "1vh",
            }}
          >
            <SearchBar
              setSearchEvent={setQ}
              border="1px solid"
              borderColor="transparent"
              setPage={setPage}
            />
            <Box
              sx={{
                width: "100%",
                marginTop: "2vh",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                gap: 2,
              }}
            >
              <CustomSelector
                label={t("STATUS")}
                value={status}
                onSelectChange={handleStatusChange}
                size="small"
                width="100%"
              >
                <MenuItem value={"ALL"}>
                  {t("STATUSES.ALL").toUpperCase()}
                </MenuItem>
                <MenuItem value={"ACTIVE"}>{t("STATUSES.ACTIVE")}</MenuItem>
                <MenuItem value={"PASSIVE"}>{t("STATUSES.PASSIVE")}</MenuItem>
              </CustomSelector>
              <CustomSelector
                size="small"
                label={t("DEPARTMENT")}
                value={department}
                onSelectChange={handleSelectDepartment}
                width="100%"
              >
                <MenuItem value={"ALL"}>{t("STATUSES.ALL")}</MenuItem>
                {departments?.map((department: any, index: number) => {
                  return (
                    <MenuItem key={index} value={department?.name}>
                      {department?.name}
                    </MenuItem>
                  );
                })}
              </CustomSelector>
              <CustomSelector
                label={t("ROLE")}
                value={role}
                onSelectChange={handleRoleChange}
                size="small"
                width="100%"
              >
                <MenuItem value={"ALL"}>{t("ROLES.ALL")}</MenuItem>
                <MenuItem value={"Employee"}>{t("ROLES.EMPLOYEE")}</MenuItem>
                <MenuItem value={"Admin"}>{t("ROLES.ADMIN")}</MenuItem>
              </CustomSelector>
            </Box>
          </Box>
        </CustomHeaderMobile>
      ) : (
        <CustomHeader
          title={t("USERS")}
          subtitle={t("USERS_ADMIN.MANAGE_USERS")}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              width: "70%",
              alignItems: "center",
              gap: 1,
            }}
          >
            <SearchBar
              setSearchEvent={setQ}
              setPage={setPage}
              border="1px solid"
              borderColor="transparent"
            />
            <CustomSelector
              label={t("STATUS")}
              value={status}
              onSelectChange={handleStatusChange}
              size="small"
              width="25%"
            >
              <MenuItem value={"ALL"}>
                {t("STATUSES.ALL").toUpperCase()}
              </MenuItem>
              <MenuItem value={"ACTIVE"}>{t("STATUSES.ACTIVE")}</MenuItem>
              <MenuItem value={"PASSIVE"}>{t("STATUSES.PASSIVE")}</MenuItem>
            </CustomSelector>
            <CustomSelector
              size="small"
              label={t("DEPARTMENT")}
              value={department}
              onSelectChange={handleSelectDepartment}
              width="25%"
            >
              <MenuItem value={"ALL"}>{t("STATUSES.ALL")}</MenuItem>
              {departments?.map((department: any, index: number) => {
                return (
                  <MenuItem key={index} value={department?.name}>
                    {department?.name}
                  </MenuItem>
                );
              })}
            </CustomSelector>
            <CustomSelector
              label={t("ROLE")}
              value={role}
              onSelectChange={handleRoleChange}
              size="small"
              width="25%"
            >
              <MenuItem value={"ALL"}>{t("ROLES.ALL")}</MenuItem>
              <MenuItem value={"Employee"}>{t("ROLES.EMPLOYEE")}</MenuItem>
              <MenuItem value={"Admin"}>{t("ROLES.ADMIN")}</MenuItem>
            </CustomSelector>
            <CustomButton
              title={t("USERS_ADMIN.ADD")}
              width="35%"
              color={BLUE_COLOR_ACCENT}
              onClick={() => navigate("/add-user")}
              colorHover={BLUE_HOVER_COLOR_ACCENT}
            />
          </Box>
        </CustomHeader>
      )}
      <>
        {result?.status === QueryStatus.fulfilled ? (
          !result?.data?.data?.length ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: isMobile ? "50vh" : "75vh",
              }}
            >
              <NoDataComponent
                title={t("USERS_ADMIN.NO_USERS_FOUND", {
                  status:
                    status === UserStatus.ALL
                      ? ""
                      : t(`STATUSES.${status}`).toLowerCase(),
                })}
              />
            </Box>
          ) : isMobile || isTablet ? (
            <>
              <CSVLink
                data={dataValues}
                headers={headers}
                filename={t("SIDENAV.ACTIVITY")}
                style={{
                  background: "#43b685",
                  color: "white",
                  fontWeight: "500",
                  width: "auto",
                  display: "flex",
                  justifyContent: "center",
                  alignSelf: "end",
                  alignItems: "center",
                  textDecoration: "none",
                  padding: "0.4% 0.8%",
                  borderRadius: "5px",
                  float: "right",
                  marginBottom: "1vh",
                }}
              >
                {t("EXPORT_TO_EXCEL")}
              </CSVLink>
              <CustomTableMobile
                mt={2}
                column1Name={t("DETAILS")}
                column2Name={t("USERS")}
                isPaginated={result?.data?.totalRecords > 10 ? true : false}
                page={page}
                data={result?.data?.data}
                handleChangePage={handleChangePage}
                rowsPerPage={limit}
                handleChangeRowsPerPage={handleRowPerPageChange}
                count={result?.data?.totalRecords}
              />
            </>
          ) : (
            <>
              <CSVLink
                data={dataValues}
                headers={headers}
                filename={t("USERS")}
                style={{
                  background: "#43b685",
                  color: "white",
                  fontWeight: "500",
                  width: "auto",
                  display: "flex",
                  justifyContent: "center",
                  alignSelf: "end",
                  alignItems: "center",
                  textDecoration: "none",
                  padding: "0.4% 0.8%",
                  borderRadius: "5px",
                  float: "right",
                }}
              >
                {t("EXPORT_TO_EXCEL")}
              </CSVLink>

              <Box mt={2}>
                <CustomTable
                  page={page}
                  isPaginated={result?.data?.totalRecords > 10 ? true : false}
                  handleChangePage={handleChangePage}
                  rowsPerPage={limit}
                  handleChangeRowsPerPage={handleRowPerPageChange}
                  tableTitles={employeeTitles}
                  tableBodyRenderer={employeesBodyRenderer}
                  count={result?.data?.totalRecords}
                  style={{
                    borderBottomLeftRadius:
                      result?.data?.totalRecords > 9 ? "0px" : "10px",
                    borderBottomRightRadius:
                      result?.data?.totalRecords > 9 ? "0px" : "10px",
                  }}
                />
              </Box>
            </>
          )
        ) : (
          <LoadingSpinner />
        )}
      </>
    </BoxContainer>
  );
};

export default Employees;
