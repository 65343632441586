import { useContext } from "react";
import { Box, CircularProgress, Grid, TextField } from "@mui/material";
import AlertDialog from "../../shared/modal/AlertDialog";
import { RootContext } from "../../context/RootContext";

import useMediaQuery from "@mui/material/useMediaQuery";
import {
  BLUE_COLOR_ACCENT,
  BLUE_HOVER_COLOR_ACCENT,
} from "../../utils/constants/colors";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

interface EmployeeFormFieldsProps {
  handleSubmit: any;
  values: any;
  touched: any;
  handleChange: any;
  errors: any;
  setFieldValue: any;
  children?: any;
  id?: string;
  handleMutation: any;
  buttonTitle: string;
  resetForm?: any;
  submitIsLoading?: boolean;
}

const VehicleFormFields = ({
  handleSubmit,
  values,
  touched,
  handleChange,
  errors,
  setFieldValue,
  children,
  id,
  handleMutation,
  buttonTitle,
  resetForm,
  submitIsLoading,
}: EmployeeFormFieldsProps) => {
  const {
    showSnackbar,
    setOpenModal,
    state: { openModal },
  } = useContext(RootContext);

  const navigation = useNavigate();

  const handleSubmitDialog = () => {
    const { kilometers, model, plate, outOfService } = values;

    const params = {
      model,
      plate,
      kilometers,
      outOfService: outOfService ? outOfService : null,
    };

    if (id)
      handleMutation({
        vehicleId: id,
        ...params,
      }).then((res: any) => {
        if (!res.error) {
          showSnackbar(
            t("VEHICLE.FORMS.RESPONSE_TEXT.EDIT_SUCCESS"),
            false,
            true
          );
          navigation(-1);
        } else if (res.error) {
          showSnackbar(res.error.data.error.message, true, true);
        }
        setOpenModal(false);
      });
    else {
      handleMutation(params).then((res: any) => {
        if (!res.error) {
          showSnackbar(
            t("VEHICLE.FORMS.RESPONSE_TEXT.ADDED_SUCCESS"),
            false,
            true
          );
          resetForm();
        } else if (res.error) {
          showSnackbar(res.error.data.error.message, true, true);
        }
        setOpenModal(false);
      });
    }

    setOpenModal(false);
  };
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const { t } = useTranslation();
  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Box
        display="grid"
        gap="30px"
        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
        }}
      >
        <Grid item xs={12} sx={{ gridColumn: "span 2" }}>
          <TextField
            inputProps={{ style: { fontSize: "16px" } }}
            fullWidth
            variant="outlined"
            size="small"
            type="text"
            label={t("VEHICLE.FORMS.MODEL")}
            id="model"
            color="info"
            onChange={handleChange}
            value={values.model}
            name="model"
            error={!!touched.model && !!errors.model}
            helperText={touched.model && errors.model}
          />
        </Grid>
        <Grid item xs={12} sx={{ gridColumn: "span 2" }}>
          <TextField
            inputProps={{ style: { fontSize: "16px" } }}
            fullWidth
            variant="outlined"
            size="small"
            type="text"
            label={t("VEHICLE.FORMS.CAR_PLATE")}
            id="plate"
            color="info"
            onChange={handleChange}
            value={values.plate}
            name="plate"
            error={!!touched.plate && !!errors.plate}
            helperText={touched.plate && errors.plate}
          />
        </Grid>
        <Grid item xs={12} sx={{ gridColumn: "span 2" }}>
          <TextField
            fullWidth
            variant="outlined"
            inputProps={{ style: { fontSize: "16px" } }}
            type="number"
            label={t("VEHICLE.FORMS.KILOMETERS")}
            size="small"
            id="kilometers"
            color="info"
            onChange={handleChange}
            value={values.kilometers}
            name="kilometers"
            error={!!touched.kilometers && !!errors.kilometers}
            helperText={touched.kilometers && errors.kilometers}
          />
        </Grid>
        {children}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <AlertDialog
          openModal={openModal}
          setOpenModal={setOpenModal}
          buttonTitle={
            submitIsLoading ? (
              <CircularProgress color="inherit" size={25} />
            ) : (
              buttonTitle
            )
          }
          values={values}
          handleSubmitDialog={handleSubmitDialog}
          color={BLUE_COLOR_ACCENT}
          colorHover={BLUE_HOVER_COLOR_ACCENT}
          mt="40px"
        >
          {id
            ? t("VEHICLE.FORMS.EDIT_MODAL_TEXT")
            : t("VEHICLE.FORMS.ADD_MODAL_TEXT")}
        </AlertDialog>
      </Box>
    </Box>
  );
};

export default VehicleFormFields;
