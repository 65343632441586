import { useEffect, useState } from "react";
import Timeline from "react-calendar-timeline";
import "react-calendar-timeline/lib/Timeline.css";
import {
  useLazyGetCalendarLeavesQuery,
  useLazyGetHolidaysQuery,
  useLazyGetPaginatedUsersQuery,
} from "../../data/endpoints/app.endpoints";
import moment from "moment";
import CustomHeader from "../../shared/custom/CustomHeader";
import {
  Box,
  MenuItem,
  Pagination,
  PaginationItem,
  SelectChangeEvent,
  useTheme,
} from "@mui/material";
import { tokens } from "../../core/AppStyles";
import BoxContainer from "../../shared/BoxContainer";
import { East, West } from "@mui/icons-material";
import useIsDesktop from "../../shared/hooks/useIsDesktop";
import useIsLargeScreen from "../../shared/hooks/useIsLargeScreen";
import useIsMobile from "../../shared/hooks/useIsMobile";
import useIsTablet from "../../shared/hooks/useIsTablet";
import { useSelector } from "react-redux";
import SearchBar from "../../shared/custom/SearchBar";
import CustomSelector from "../../shared/custom/CustomSelector";
import { useTranslation } from "react-i18next";
import { NextIcon, PrevIcon } from "../../shared/custom/CustomEditIcons";
import { CustomMarker } from "react-calendar-timeline";
import NoDataComponent from "../../shared/NoDataComponent";
import LoadingSpinner from "../../shared/LoadingSpinner";
import { QueryStatus } from "@reduxjs/toolkit/query";
import dayjs from "dayjs";

const LeaveCalendar = () => {
  const { departments } = useSelector((state: any) => state.department);
  const [getHolidays, holidayResult] = useLazyGetHolidaysQuery();
  const [getPaginatedUsers, result] = useLazyGetPaginatedUsersQuery();
  const [getLeaves, leaveResult] = useLazyGetCalendarLeavesQuery();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isDesktop = useIsDesktop();
  const isLargeScreen = useIsLargeScreen();
  const [page, setPage] = useState(1);
  const [q, setQ] = useState("");
  const [status, setStatus] = useState<any>("ACTIVE");
  const [role, setRole] = useState<any>("Employee");
  const [department, setDepartment] = useState<any>("ALL");
  const [limit, setLimit] = useState<any>(15);

  const [visibleTimeStart, setVisibleTimeStart] = useState(
    moment().startOf("month").valueOf()
  );
  const [visibleTimeEnd, setVisibleTimeEnd] = useState(
    moment().endOf("month").valueOf()
  );
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    getPaginatedUsers({
      limitTo: limit,
      currentPage: page,
      q,
      status: status === "ALL" ? "" : status,
      role,
      department: department === "ALL" ? "" : department,
    });
  }, [q, status, limit, department, role, page]);

  useEffect(() => {
    getLeaves({
      startDate: moment(visibleTimeStart).format("YYYY-MM-DD"),
      endDate: moment(visibleTimeEnd).format("YYYY-MM-DD"),
    });
  }, [visibleTimeStart, visibleTimeEnd]);

  useEffect(() => {
    getHolidays({
      startDate: moment(visibleTimeStart).format("YYYY-MM-DD"),
      endDate: moment(visibleTimeEnd).format("YYYY-MM-DD"),
    });
  }, [visibleTimeStart, visibleTimeEnd]);

  const leaves = leaveResult?.data;
  const users = result?.data?.data;

  const getColorForVacationStatus = (status: any) => {
    switch (status.toLowerCase()) {
      case "pending":
        return {
          background: "rgba(203, 56, 55,0.4)",
          border: "none",
          height: "100px",
        };
      case "approved":
        return {
          background: "rgb(203, 56, 55)",
          border: "none",
        };
      default:
        return {
          background: "#616161",
          border: "none",
        };
    }
  };

  const groups: any =
    users &&
    users?.map((user: any) => ({
      id: user.userId,
      title: `${user.firstName} ${user.lastName}`,
    }));

  const items =
    leaveResult.status === QueryStatus.fulfilled &&
    leaves?.map((leave: any) => ({
      id: leave.leaveId,
      group: leave.userId,
      title: leave.type,
      start_time: moment(leave.startDate).startOf("day"),
      end_time: moment(leave.endDate).endOf("day"),
      itemProps: {
        style: getColorForVacationStatus(leave.status),
      },
    }));

  const handleRowPerPageChange = (event: SelectChangeEvent) => {
    setLimit(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleStatusChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value);
    setPage(1);
  };

  const handleSelectDepartment = (event: SelectChangeEvent) => {
    setDepartment(event.target.value);
    setPage(1);
  };

  const handleTimeChange = (
    visibleTimeStart: any,
    visibleTimeEnd: any,
    updateScrollCanvas: any
  ) => {
    updateScrollCanvas(visibleTimeStart, visibleTimeEnd);
  };

  const onPrevClick = () => {
    setVisibleTimeStart(
      moment(visibleTimeStart).subtract(1, "month").startOf("month").valueOf()
    );
    setVisibleTimeEnd(
      moment(visibleTimeEnd).subtract(1, "month").endOf("month").valueOf()
    );
  };

  const onNextClick = () => {
    setVisibleTimeStart(
      moment(visibleTimeStart).add(1, "month").startOf("month").valueOf()
    );
    setVisibleTimeEnd(
      moment(visibleTimeEnd).add(1, "month").endOf("month").valueOf()
    );
  };

  const currentMonth = () => {
    setVisibleTimeStart(moment().startOf("month").valueOf());
    setVisibleTimeEnd(moment().endOf("month").valueOf());
  };

  return (
    <BoxContainer>
      <CustomHeader
        title={t("CALENDAR.TITLE")}
        subtitle={t("CALENDAR.SUBTITLE")}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            width: "50%",
            alignItems: "center",
            gap: 1,
          }}
        >
          <SearchBar
            setSearchEvent={setQ}
            setPage={setPage}
            border="1px solid"
            borderColor="transparent"
          />
          <CustomSelector
            label={t("STATUS")}
            value={status}
            onSelectChange={handleStatusChange}
            size="small"
            width="25%"
          >
            <MenuItem value={"ALL"}>{t("STATUSES.ALL").toUpperCase()}</MenuItem>
            <MenuItem value={"ACTIVE"}>{t("STATUSES.ACTIVE")}</MenuItem>
            <MenuItem value={"PASSIVE"}>{t("STATUSES.PASSIVE")}</MenuItem>
          </CustomSelector>
          <CustomSelector
            size="small"
            label={t("DEPARTMENT")}
            value={department}
            onSelectChange={handleSelectDepartment}
            width="25%"
          >
            <MenuItem value={"ALL"}>{t("STATUSES.ALL")}</MenuItem>
            {departments?.map((department: any, index: number) => {
              return (
                <MenuItem key={index} value={department?.name}>
                  {department?.name}
                </MenuItem>
              );
            })}
          </CustomSelector>
        </Box>
      </CustomHeader>
      {holidayResult.status !== QueryStatus.fulfilled ? (
        <LoadingSpinner />
      ) : leaveResult.status === QueryStatus.fulfilled &&
        users &&
        users.length > 0 ? (
        <Box
          sx={{
            mt: 3,
            padding: 3,
            background: colors.component,
            border: "none",
            borderRadius: "5px",
            width:
              isMobile || isTablet
                ? "auto"
                : isDesktop
                ? "72vw"
                : isLargeScreen
                ? "100%"
                : "84vw",
          }}
        >
          <Box
            sx={{
              padding: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
              marginLeft: 17,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                background: colors.timeline,
                padding: "4px 8px",
                borderRadius: "7px",
                gap: 1,
              }}
              onClick={onPrevClick}
            >
              <West sx={{ width: "15px" }} />
              {dayjs(visibleTimeStart)
                .subtract(1, "month")
                .locale(i18n.language)
                .format("MMMM")}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                fontWeight: 500,
                background: colors.timeline,
                padding: "4px 8px",
                borderRadius: "7px",
              }}
              onClick={currentMonth}
            >
              {t("CALENDAR.CURRENT_MONTH")}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                background: colors.timeline,
                padding: "4px 8px",
                borderRadius: "7px",
                gap: 1,
              }}
              onClick={onNextClick}
            >
              {dayjs(visibleTimeStart)
                .add(1, "month")
                .locale(i18n.language)
                .format("MMMM")}{" "}
              <East sx={{ width: "15px" }} />
            </Box>
          </Box>
          <Timeline
            groups={groups}
            items={items}
            verticalLineClassNamesForTime={(start: number, end: number) => {
              const hasHoliday = holidayResult?.data?.find((holiday) =>
                moment(
                  moment(holiday?.date).format("YYYY-MM-DD"),
                  "YYYY-MM-DD"
                ).isSame(
                  moment(moment(start).format("YYYY-MM-DD"), "YYYY-MM-DD")
                )
              );
              return !!hasHoliday ? ["holiday-style"] : [""];
            }}
            visibleTimeStart={visibleTimeStart}
            visibleTimeEnd={visibleTimeEnd}
            canMove={false}
            canResize={false}
            stackItems
            itemHeightRatio={1}
            sidebarWidth={150}
            onTimeChange={handleTimeChange}
            itemRenderer={({ item, itemContext, getItemProps }: any) => {
              const startDateTime = item.start_time;
              const endDateTime = item.end_time;
              const differenceInMilliseconds = Math.abs(
                endDateTime - startDateTime
              );
              const differenceInDays = Math.ceil(
                differenceInMilliseconds / (1000 * 60 * 60 * 24)
              );
              return (
                <Box {...getItemProps(item.itemProps)}>
                  <Box
                    className="rct-item-content"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      boxShadow: "none",
                    }}
                  >
                    {differenceInDays > 1
                      ? itemContext.title
                      : itemContext.title[0]}
                  </Box>
                </Box>
              );
            }}
          >
            <CustomMarker date={moment().valueOf()}>
              {({ styles }) => {
                const newStyles = {
                  ...styles,
                  backgroundColor: colors.redAccent[600],
                };
                return <div style={newStyles} />;
              }}
            </CustomMarker>
          </Timeline>
          <style>
            {`

        .react-calendar-timeline .rct-dateHeader {
          background-color: ${colors.component};
          color:${colors.invert};
          border-color: transparent; 
          font-weight:600;
          box-shadow: none;
          border:0.5px solid ${colors.timeline};
        }
        .react-calendar-timeline .rct-header-root{
          background-color: ${colors.component};
          color:${colors.invert};
          border:0.5px solid ${colors.timeline};
          font-weight:600;
          box-shadow: none;
          border:none;
          border-radius:5px;
        }
        .react-calendar-timeline .rct-sidebar{
          border:0px solid white;
        }
        .react-calendar-timeline .rct-scroll {
          overflow: hidden
        }
        .react-calendar-timeline .rct-horizontal-lines .rct-hl-even{
          border:0.5px solid ${colors.timeline};
        }
        .react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-even{
          border:0.5px solid ${colors.timeline};
        }
        .react-calendar-timeline .rct-calendar-header{
          border:0.5px solid ${colors.timeline};
        }
        .react-calendar-timeline .rct-vertical-lines .rct-vl{
          border:0.5px solid ${colors.timeline};
        }
        .react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-6{
          background: ${colors.weekendCalendar}
        }
        .react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-0{
          background: ${colors.weekendCalendar}
        }
        .react-calendar-timeline .rct-sidebar .rct-sidebar-row{
          border:0.5px solid ${colors.timeline};
        }
        .react-calendar-timeline .rct-horizontal-lines .rct-hl-odd{
          background: ${colors.oddCalendarRow};
          border:0.5px solid ${colors.timeline};
        }
        .react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd{
          background: ${colors.oddCalendarRow};
        }
        .holiday-style{
          background:${colors.holidayCalendar};
        }
        .react-calendar-timeline .rct-header-root .rct-hr.rct-day-6{
          background:${colors.holidayCalendar};
        }
      `}
          </style>
          {result?.data && result?.data?.totalRecords > 10 && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                gap: 1,
              }}
            >
              <p
                style={{
                  color: "gray",
                }}
              >
                {t("ROWS")}:
              </p>
              <CustomSelector
                size="medium"
                value={limit}
                onSelectChange={handleRowPerPageChange}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={15}>15</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={result?.data?.totalRecords}>
                  {t("LEAVE_REQUESTS.STATUS.ALL")}
                </MenuItem>
              </CustomSelector>

              <Pagination
                color="secondary"
                shape="rounded"
                count={
                  result?.data?.totalRecords &&
                  limit &&
                  Math.ceil(result?.data?.totalRecords / limit)
                }
                page={page}
                onChange={handleChangePage}
                renderItem={(item) => (
                  <PaginationItem
                    sx={{ backgroundColor: colors.timeline }}
                    slots={{ previous: PrevIcon, next: NextIcon }}
                    {...item}
                  />
                )}
              />
            </Box>
          )}
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: isMobile ? "50vh" : "75vh",
          }}
        >
          <NoDataComponent title="No calendar data" />
        </Box>
      )}
    </BoxContainer>
  );
};

export default LeaveCalendar;
