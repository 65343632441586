import { useEffect } from "react";
import "./App.css";
import Login from "./features/login/Login";
import { useSelector } from "react-redux";
import { HashRouter, Navigate, Route, Routes } from "react-router-dom";
import { store } from "./data/store/store";
import { selectLoggedUser } from "./data/store/authSlice";
import {
  appEndpoints,
  useLazyGetUserByIdQuery,
  useLazyRefreshTokenQuery,
} from "./data/endpoints/app.endpoints";
import { decodeToken } from "react-jwt";
import { DecodedJwt } from "./data/models/decoded-jwt.interface";

import useValidatedRoutes from "./core/hooks/useValidatedRoutes";
import PageNotFound from "./core/Errors/PageNotFound";
import io from "socket.io-client";
export const socket =
  process.env.NODE_ENV === "production" ? io() : io("http://localhost:4000");
const App = () => {
  const refreshToken = JSON.parse(
    localStorage.getItem("jvelektro-ems-app.refresh-token") as string
  );

  const user = useSelector(selectLoggedUser);

  const [getTokens] = useLazyRefreshTokenQuery();
  const [getUserData] = useLazyGetUserByIdQuery();
  const routes = useValidatedRoutes({ refreshToken });

  useEffect(() => {
    if (refreshToken) {
      getTokens(refreshToken).then((res: any) => {
        if (res.data) {
          const decoded = decodeToken<DecodedJwt>(res.data.accessToken);
          if (decoded) {
            getUserData(decoded.sub).then((response: any) => {
              if (response.data) {
                localStorage.setItem(
                  "jvelektro-ems-app.refresh-token",
                  JSON.stringify(res.data?.refreshToken)
                );
              }
            });
          }
        } else {
          // localStorage.removeItem("jvelektro-ems-app.refresh-token");
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshToken]);

  useEffect(() => {
    if (user?.userId) {
      store.dispatch(
        appEndpoints.endpoints.getUserWeeklyWorkingHours.initiate(user?.userId)
      );
      store.dispatch(appEndpoints.endpoints.getDepartments.initiate(true));
      store.dispatch(appEndpoints.endpoints.getOneToOneChats.initiate(true));
      store.dispatch(appEndpoints.endpoints.getAllVehicles.initiate(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.userId]);

  return (
    <HashRouter>
      <Routes>
        <Route
          path="/login"
          element={!!refreshToken ? <Navigate to="/dashboard" /> : <Login />}
        />
        <Route path="/" element={<Navigate to="/login" />} />
        {routes}
        {!refreshToken && <Route path="/*" element={<PageNotFound />} />}
      </Routes>
    </HashRouter>
  );
};

export default App;
