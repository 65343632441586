import { memo, useContext, useState } from "react";
import { CircularProgress, TableCell, TableRow } from "@mui/material";
import { LeaveRequest } from "../../data/models/leave-request.interface";
import AlertDialog from "../../shared/modal/AlertDialog";
import {
  useChangeStatusLeaveRequestMutation,
  useDeleteLeaveRequestMutation,
} from "../../data/endpoints/app.endpoints";
import { RootContext } from "../../context/RootContext";
import { LeaveStatus } from "../../utils/enums/leave.enum";
import { Box } from "@mui/material";
import {
  BLUE_COLOR_ACCENT,
  BLUE_HOVER_COLOR_ACCENT,
  GREEN_COLOR_ACCENT,
  RED_COLOR_ACCENT,
  RED_HOVER_COLOR_ACCENT,
  STATUS_PENDING_COLOR,
} from "../../utils/constants/colors";
import { useTheme } from "@mui/material";
import { tokens } from "../../core/AppStyles";
import { Link } from "react-router-dom";
import moment from "moment";
import { IconEdit } from "../../shared/custom/CustomEditIcons";
import { Clear, Done } from "@mui/icons-material";
import CustomTooltip from "../../shared/custom/CustomDescriptionModal";
import { useTranslation } from "react-i18next";
import CustomButton from "../../shared/custom/CustomButton";
interface AllLeaveRequestsTableRowProps {
  row: LeaveRequest;
}

const AllLeaveRequestsTableRow = ({ row }: AllLeaveRequestsTableRowProps) => {
  const {
    leaveId,
    description,
    endDate,
    startDate,
    status,
    type,
    user,
    daysRequired,
  } = row;
  const [updateLeaveRequest, resultUpdate] =
    useChangeStatusLeaveRequestMutation();
  const [deleteLeaveRequest, resultDelete] = useDeleteLeaveRequestMutation();
  const { showSnackbar } = useContext(RootContext);
  const { t } = useTranslation();

  const [openApproveModal, setOpenApproveModal] = useState(false);
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleShowApprovedAlertDialog = () => {
    setOpenApproveModal(true);
  };
  const handleShowRejectedAlertDialog = () => {
    setOpenRejectModal(true);
  };
  const handleShowCancelAlertDialog = () => {
    setOpenCancelModal(true);
  };
  const handleShowDeleteAlertDialog = () => {
    setOpenDeleteModal(true);
  };
  const handleApproveSubmitDialog = () => {
    updateLeaveRequest({
      status: LeaveStatus.APPROVED,
      userId: row.user.userId,
      leaveId,
    }).then((res: any) => {
      if (!res.error) {
        showSnackbar(`${t("LEAVE_REQUESTS.LEAVES_APPROVED")}`, false, true);
      } else if (res.error) {
        showSnackbar(res.error.data.error.message, true, true);
      }
    });
    setOpenApproveModal(false);
  };
  const handleRejectSubmitDialog = () => {
    updateLeaveRequest({
      status: LeaveStatus.REJECTED,
      userId: row.user.userId,
      leaveId,
    }).then((res: any) => {
      if (!res.error) {
        showSnackbar(`${t("LEAVE_REQUESTS.LEAVES_REJECTED")}`, false, true);
      } else if (res.error) {
        showSnackbar(res.error.data.error.message, true, true);
      }
    });
    setOpenRejectModal(false);
  };
  const handleCancelSubmitDialog = () => {
    updateLeaveRequest({
      status: LeaveStatus.PENDING,
      userId: row.user.userId,
      leaveId,
    }).then((res: any) => {
      if (!res.error) {
        showSnackbar(`${t("LEAVE_REQUESTS.LEAVES_CANCELED")}`, false, true);
      } else if (res.error) {
        showSnackbar(res.error.data.error.message, true, true);
      }
    });
    setOpenCancelModal(false);
  };
  const handleDeleteSubmitDialog = () => {
    deleteLeaveRequest(leaveId).then((res: any) => {
      if (!res.error) {
        showSnackbar(`${t("LEAVE_REQUESTS.LEAVES_DELETED")}`, false, true);
      } else if (res.error) {
        showSnackbar(res.error.data.error.message, true, true);
      }
    });
    setOpenDeleteModal(false);
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      <TableCell component="th" scope="row">
        {user?.firstName + " " + user?.lastName}
      </TableCell>
      <TableCell component="th" scope="row">
        {moment(startDate).format("DD MMM YYYY")}
      </TableCell>
      <TableCell component="th" scope="row">
        {moment(endDate).format("DD MMM YYYY")}
      </TableCell>
      <TableCell component="th" scope="row">
        {type}
      </TableCell>
      <TableCell component="th" scope="row">
        {daysRequired}
      </TableCell>
      <TableCell component="th" scope="row">
        {description ? <CustomTooltip descriptionText={description} /> : "-"}
      </TableCell>
      <TableCell
        sx={{
          color:
            status === "Pending"
              ? STATUS_PENDING_COLOR
              : status === "Approved"
              ? GREEN_COLOR_ACCENT
              : RED_COLOR_ACCENT,
          fontWeight: "500",
        }}
      >
        {t(`LEAVE_REQUESTS.STATUS.${status.toUpperCase()}`)}
      </TableCell>
      <TableCell
        style={{ paddingTop: 0, paddingBottom: 0 }}
        component="th"
        scope="row"
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 0,
          }}
        >
          {status !== LeaveStatus.PENDING ? (
            <AlertDialog
              openModal={openCancelModal}
              setOpenModal={setOpenCancelModal}
              buttonTitle={
                resultUpdate?.isLoading ? (
                  <CircularProgress color="inherit" size={25} />
                ) : (
                  t("CANCEL")
                )
              }
              values={{}}
              handleShowAlertDialog={handleShowCancelAlertDialog}
              handleSubmitDialog={handleCancelSubmitDialog}
              color={BLUE_COLOR_ACCENT}
              colorHover={BLUE_HOVER_COLOR_ACCENT}
            >
              {t("LEAVE_REQUESTS.CANCEL_MODAL_SUBTITLE")}
            </AlertDialog>
          ) : resultUpdate?.isLoading ? (
            <CircularProgress color="inherit" size={25} />
          ) : (
            <>
              <AlertDialog
                openModal={openApproveModal}
                setOpenModal={setOpenApproveModal}
                buttonTitle={<Done />}
                width="5%"
                values={{}}
                handleShowAlertDialog={handleShowApprovedAlertDialog}
                handleSubmitDialog={handleApproveSubmitDialog}
                color={GREEN_COLOR_ACCENT}
                colorHover={colors.greenAccent[700]}
              >
                {t("LEAVE_REQUESTS.APPROVE_MODAL_SUBTITLE")}
              </AlertDialog>
              <AlertDialog
                openModal={openRejectModal}
                setOpenModal={setOpenRejectModal}
                buttonTitle={<Clear />}
                width="5%"
                values={{}}
                handleShowAlertDialog={handleShowRejectedAlertDialog}
                handleSubmitDialog={handleRejectSubmitDialog}
                color={RED_COLOR_ACCENT}
                colorHover={colors.redAccent[600]}
              >
                {t("LEAVE_REQUESTS.REJECT_MODAL_SUBTITLE")}
              </AlertDialog>
            </>
          )}
        </Box>
      </TableCell>
      {status !== LeaveStatus.PENDING ? (
        <TableCell style={{ paddingTop: 0, paddingBottom: 0 }}>
          <IconEdit fill="grey" />
        </TableCell>
      ) : (
        <TableCell>
          <Link
            style={{ color: colors.primary[100] }}
            to={`/leaves/update/${leaveId}`}
          >
            <IconEdit fill={colors.invert} />
          </Link>
        </TableCell>
      )}
      <TableCell style={{ paddingTop: 0, paddingBottom: 0 }}>
        {status === LeaveStatus.PENDING ? (
          <>
            <AlertDialog
              openModal={openDeleteModal}
              setOpenModal={setOpenDeleteModal}
              width="100%"
              handleSubmitDialog={handleDeleteSubmitDialog}
              disabled={false}
              ButtonComponent={(props: any) => (
                <CustomButton
                  color={RED_COLOR_ACCENT}
                  colorHover={colors.redAccent[600]}
                  onClick={handleShowDeleteAlertDialog}
                  title={"Delete"}
                  width="100%"
                />
              )}
            >
              {t("LEAVE_REQUESTS.DELETE_MODAL_SUBTITLE")}
            </AlertDialog>
          </>
        ) : (
          <AlertDialog
            openModal={openDeleteModal}
            setOpenModal={setOpenDeleteModal}
            buttonTitle={
              resultDelete?.isLoading ? (
                <CircularProgress color="inherit" size={25} />
              ) : (
                t("DELETE")
              )
            }
            values={{}}
            handleShowAlertDialog={handleShowDeleteAlertDialog}
            handleSubmitDialog={handleDeleteSubmitDialog}
            color={RED_COLOR_ACCENT}
            colorHover={RED_HOVER_COLOR_ACCENT}
            disabled={true}
          ></AlertDialog>
        )}
      </TableCell>
    </TableRow>
  );
};

export default memo(AllLeaveRequestsTableRow);
