import { memo, useState } from "react";
import TableRow from "@mui/material/TableRow/TableRow";
import TableCell from "@mui/material/TableCell/TableCell";
import { Department } from "../../data/models/department.interface";
import { tokens } from "../../core/AppStyles";
import { Box, useTheme } from "@mui/material";
import AlertDialog from "../../shared/modal/AlertDialog";
import UpdateDepartment from "./UpdateDepartment";
import {
  BLUE_COLOR_ACCENT,
  BLUE_HOVER_COLOR_ACCENT,
} from "../../utils/constants/colors";
import { useTranslation } from "react-i18next";

interface CustomTableRowProps {
  row: Department;
  index: number;
}

const DepartmentTableRow = ({ row, index }: CustomTableRowProps) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  const [updateModal, setUpdateModal] = useState(false);

  return (
    <TableRow
      key={row.departmentId}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        {index}
      </TableCell>
      <TableCell component="th" scope="row">
        {row.name}
      </TableCell>
      <TableCell align="left">
        {
          <AlertDialog
            openModal={updateModal}
            setOpenModal={setUpdateModal}
            isConfirm={false}
            handleShowAlertDialog={() => setUpdateModal(true)}
            buttonTitle={t("USERS_ADMIN.DEPARTMENTS.UPDATE")}
            color={BLUE_COLOR_ACCENT}
            colorHover={BLUE_HOVER_COLOR_ACCENT}
          >
            <Box sx={{ padding: 3, background: colors.component }}>
              <UpdateDepartment
                updateModal={updateModal}
                setUpdateModal={setUpdateModal}
                department={row}
              />
            </Box>
          </AlertDialog>
        }
      </TableCell>
    </TableRow>
  );
};

export default memo(DepartmentTableRow);
