import { useEffect, useMemo, useState } from "react";
import BoxContainer from "../../shared/BoxContainer";
import CustomHeader from "../../shared/custom/CustomHeader";

import { Box, MenuItem, SelectChangeEvent } from "@mui/material";
import LoadingSpinner from "../../shared/LoadingSpinner";
import CustomTable from "../../shared/custom/CustomTable";
import EquipmentTableRow from "./EquipmentTableRow";
import SearchBar from "../../shared/custom/SearchBar";
import CustomButton from "../../shared/custom/CustomButton";
import {
  BLUE_COLOR_ACCENT,
  BLUE_HOVER_COLOR_ACCENT,
} from "../../utils/constants/colors";
import { useNavigate } from "react-router";
import useIsMobile from "../../shared/hooks/useIsMobile";
import CustomHeaderMobile from "../../shared/custom/CustomHeaderMobile";
import CustomEquipementsTableMobile from "./CustomEquipementsTableMobile";
import { useLazyGetEquipmentsQuery } from "../../data/endpoints/app.endpoints";
import CustomSelector from "../../shared/custom/CustomSelector";
import useIsTablet from "../../shared/hooks/useIsTablet";
import NoDataComponent from "../../shared/NoDataComponent";
import { useTranslation } from "react-i18next";

const Equipments = () => {
  const [getEquipments, result] = useLazyGetEquipmentsQuery();
  const { t } = useTranslation();
  const equipmentTableTitles = [
    `${t("FULL_NAME")}`,
    `${t("START_DATE")}`,
    `${t("END_DATE")}`,
    `${t("EQUIPMENTS.ITEM_NAME")}`,
    `${t("EQUIPMENTS.SERIAL_NUMBER")}`,
    `${t("EQUIPMENTS.QUANTITY")}`,
    `${t("EQUIPMENTS.LOCATION")}`,
    `${t("STATUS")}`,
    `${t("DESCRIPTION")}`,
    `${t("EQUIPMENTS.CHANGE_STATUS")}`,
    `${t("EDIT")}`,
    `${t("DELETE")}`,
  ];

  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const [page, setPage] = useState(1);
  const [q, setQ] = useState("");
  const [status, setStatus] = useState("ALL");
  const [limit, setLimit] = useState<any>(10);

  const handleRowPerPageChange = (event: SelectChangeEvent) => {
    setLimit(event.target.value);
    setPage(1);
  };

  const navigate = useNavigate();
  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleStatusChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value);
    setPage(1);
  };

  useEffect(() => {
    if (result?.data?.data?.length === 0 && page !== 1) {
      setPage(page - 1);
    }
  }, [result?.data]);

  useEffect(() => {
    getEquipments({
      currentPage: page,
      limitTo: limit,
      status: status === "ALL" ? "" : status,
      q,
    });
  }, [q, page, limit, status]);

  const equipmentBodyRenderer = useMemo(() => {
    const equipmentDataRenderer: JSX.Element[] = [];
    result?.data?.data?.map((row: any, index: number) => {
      return equipmentDataRenderer.push(
        <EquipmentTableRow key={index} row={row} />
      );
    });
    return equipmentDataRenderer;
  }, [result]);

  return (
    <BoxContainer>
      {isMobile || isTablet ? (
        <CustomHeaderMobile
          title={t("EQUIPMENTS.TITLE")}
          subtitle={t("EQUIPMENTS.SUBTITLE")}
          btnTitle="+"
          btnNavLink="/add-equipment"
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              width: "100%",
              height: "100px",
              marginTop: "1vh",
            }}
          >
            <SearchBar setSearchEvent={setQ} setPage={setPage} />
            <CustomSelector
              label="Status"
              value={status}
              onSelectChange={handleStatusChange}
              size="small"
              width="100%"
            >
              <MenuItem value={"ALL"}>{t("STATUSES.ALL")}</MenuItem>
              <MenuItem value={"In Possession"}>
                {t("EQUIPMENTS.STATUSES.IN_POSSESSION")}
              </MenuItem>
              <MenuItem value={"Handed Over"}>
                {t("EQUIPMENTS.STATUSES.HANDED_OVER")}
              </MenuItem>
              <MenuItem value={"Damaged"}>
                {t("EQUIPMENTS.STATUSES.DAMAGED")}
              </MenuItem>
            </CustomSelector>
          </Box>
        </CustomHeaderMobile>
      ) : (
        <CustomHeader
          title={t("EQUIPMENTS.TITLE")}
          subtitle={t("EQUIPMENTS.SUBTITLE")}
        >
          <Box
            sx={{
              width: "30%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <SearchBar setSearchEvent={setQ} setPage={setPage} />
          </Box>
          <CustomSelector
            label={t("STATUS")}
            value={status}
            onSelectChange={handleStatusChange}
            size="small"
            width="20%"
          >
            <MenuItem value={"ALL"}>{t("STATUSES.ALL")}</MenuItem>
            <MenuItem value={"In Possession"}>
              {t("EQUIPMENTS.STATUSES.IN_POSSESSION")}
            </MenuItem>
            <MenuItem value={"Handed Over"}>
              {t("EQUIPMENTS.STATUSES.HANDED_OVER")}
            </MenuItem>
            <MenuItem value={"Damaged"}>
              {t("EQUIPMENTS.STATUSES.DAMAGED")}
            </MenuItem>
          </CustomSelector>
          <CustomButton
            title={t("EQUIPMENTS.ADD_EQUIPMENTS.ADD_EQUIPMENT")}
            width="auto"
            color={BLUE_COLOR_ACCENT}
            onClick={() => navigate("/add-equipment")}
            colorHover={BLUE_HOVER_COLOR_ACCENT}
          />
        </CustomHeader>
      )}
      {result?.data ? (
        result?.data?.data?.length > 0 ? (
          isMobile || isTablet ? (
            <CustomEquipementsTableMobile
              column1Name={t("DETAILS")}
              column2Name={t("EQUIPMENT")}
              column3Name={t("EQUIPMENTS.CHANGE_STATUS")}
              data={result?.data?.data}
              isPaginated={result?.data?.totalRecords > 10 ? true : false}
              handleChangePage={handleChangePage}
              rowsPerPage={limit}
              handleChangeRowsPerPage={handleRowPerPageChange}
              page={page}
              count={result?.data?.totalRecords}
            />
          ) : (
            <Box mt={3}>
              <CustomTable
                isPaginated={result?.data?.totalRecords > 10 ? true : false}
                tableBodyRenderer={equipmentBodyRenderer}
                tableTitles={equipmentTableTitles}
                handleChangePage={handleChangePage}
                rowsPerPage={limit}
                handleChangeRowsPerPage={handleRowPerPageChange}
                page={page}
                count={result?.data?.totalRecords}
                style={{
                  borderBottomLeftRadius:
                    result?.data?.totalRecords > 9 ? "0px" : "10px",
                  borderBottomRightRadius:
                    result?.data?.totalRecords > 9 ? "0px" : "10px",
                }}
              />
            </Box>
          )
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: isMobile ? "50vh" : "75vh",
            }}
          >
            <NoDataComponent title={t("EQUIPMENTS.NO_EQUIPMENTS_FOUND")} />
          </Box>
        )
      ) : (
        <LoadingSpinner />
      )}
    </BoxContainer>
  );
};

export default Equipments;
