/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Divider, useTheme } from "@mui/material";
import CustomHeader from "../../../../shared/custom/CustomHeader";
import { tokens } from "../../../../core/AppStyles";
import useIsMobile from "../../../../shared/hooks/useIsMobile";
import useIsTablet from "../../../../shared/hooks/useIsTablet";
import moment from "moment";
import { useEffect, useState } from "react";
import { useLazyCheckUsersWithUncomletedTTQuery } from "../../../../data/endpoints/app.endpoints";
import { BLUE_COLOR_ACCENT } from "../../../../utils/constants/colors";
import { Idata } from "./UsersNotCompletedTimeTracker";
import { useTranslation } from "react-i18next";
import ManageTimeTrackersModal from "../../../time-tracker/ManageTimeTrackerModal";
import { TimeTrackerStructure } from "../../../../data/models/time-tracker-structure.interface";
import { useSelector } from "react-redux";
import { selectAllEmployees } from "../../../../data/store/employeeSlice";

const UncompletedTimeTrackers = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const employees = useSelector(selectAllEmployees);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<any>(null);
  const [selectedEmployee, setSelectedEmployee] = useState<any>(null);

  const openModalManageTimeTracker = (employee: any, date: any) => {
    setIsModalOpen(true);
    setSelectedDate(date);
    setSelectedEmployee(employee);
  };

  const [selectedTimeTracker, setSelectedTimeTracker] = useState<
    TimeTrackerStructure | undefined | null
  >(undefined);

  const [IncompleteTT, resultIncompleteTT] =
    useLazyCheckUsersWithUncomletedTTQuery();

  const firstDayOfMonth = moment().startOf("month").subtract(2, "weeks");

  const endDate = moment().subtract(1, "days");
  const { t } = useTranslation();

  useEffect(() => {
    IncompleteTT({
      startDate: firstDayOfMonth.format("YYYY-MM-DD"),
      endDate: endDate.format("YYYY-MM-DD"),
    });
  }, []);

  const UnCompletTimeTrackers = resultIncompleteTT?.data;

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
      }}
    >
      <CustomHeader
        title={t("UNCOMPLETED_TIME_TRACKER.TITLE")}
        subtitle={t("UNCOMPLETED_TIME_TRACKER.SUBTITLE")}
      />

      <Box
        sx={{
          width: "100%",
          height: "90%",
          flexDirection: isMobile ? "column" : "row",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          marginTop: "3vh",
        }}
      >
        <Box
          sx={{
            width: isMobile ? "100%" : "30%",
            height: isMobile ? "45vh" : "80vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            padding: "1%",
            boxShadow: "0 0 1px 0.5px rgba(0,0,0,0.1)",
          }}
        >
          <Box
            sx={{
              width: "98%",
              height: "6vh",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0% 2%",
              background: colors.blueAccent[800],
              color: colors.black,
              borderRadius: "5px",
            }}
          >
            <h4>{t("UNCOMPLETED_TIME_TRACKER.USERS")}</h4>
            <h4>{t("UNCOMPLETED_TIME_TRACKER.TOTALS")}</h4>
          </Box>
          <Box
            sx={{
              width: "98%",
              height: "calc(80vh - 6vh)",

              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              overflowY: "auto",
              gap: 1,
            }}
          >
            {UnCompletTimeTrackers?.map((list: any, index: number) => {
              return (
                <Box
                  sx={{
                    width: "100%",
                    height: "5vh",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0% 2%",
                    borderRadius: "5px",
                    color: colors.invert,
                    marginTop: "1vh",
                    cursor: "pointer",
                  }}
                  key={index}
                >
                  <p>{list?.fullName}</p>
                  <p
                    style={{
                      margin: 0,
                      padding: "2px 5px",
                      background: colors.blueAccent[800],
                      borderRadius: "5px",
                      color: colors.black,
                    }}
                  >
                    {list?.incompleteDates?.length}{" "}
                    {t("UNCOMPLETED_TIME_TRACKER.UNCOMPLETE")}
                  </p>
                </Box>
              );
            })}
          </Box>
        </Box>

        {isMobile || isTablet ? <Divider /> : ""}

        <Box
          sx={{
            width: isMobile ? "100%" : "70%",
            height: "80vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            background: colors.component,
            borderRadius: "8px",
          }}
        >
          <Box
            sx={{
              width: "96%",
              height: "6vh",
              display: "flex",
              alignItems: "center",
              borderBottom: "2px solid lightgray",
              color: colors.invert,
              marginTop: isMobile ? "2vh" : "0vh",
            }}
          >
            <p style={{ width: "33%" }}>{t("NAME")}</p>
            <p style={{ width: "46%" }}>{t("DATE")}</p>
            <p style={{ width: "20%" }}>
              {t("UNCOMPLETED_TIME_TRACKER.ACTION")}
            </p>
          </Box>
          <Box
            sx={{
              width: "96%",
              height: "calc(80vh - 6vh)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
              overflowY: "scroll",
            }}
          >
            {UnCompletTimeTrackers?.map((users: Idata) => {
              return users?.incompleteDates?.map(
                (date: string, index: number) => {
                  return (
                    <Box
                      sx={{
                        width: "100%",
                        height: "6vh",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        color: colors.invert,
                      }}
                      key={index}
                    >
                      <p style={{ width: "33%" }}>{users?.fullName}</p>
                      <p style={{ width: "46%" }}>
                        {moment(date).format("DD-MMM-YYYY")}
                      </p>
                      <button
                        style={{
                          border: "none",
                          cursor: "pointer",
                          padding: "1vh 2vw",
                          borderRadius: "5px",
                          color: colors.black,
                          background: BLUE_COLOR_ACCENT,
                        }}
                        onClick={() => {
                          const employee = employees.find(
                            (employee) => employee?.userId === users?.userId
                          );
                          openModalManageTimeTracker(employee, date);
                        }}
                      >
                        {t("UNCOMPLETED_TIME_TRACKER.COMPLETE")}
                      </button>
                    </Box>
                  );
                }
              );
            })}
          </Box>
        </Box>
      </Box>
      <ManageTimeTrackersModal
        isOpen={isModalOpen}
        handleClose={() => {
          setIsModalOpen(false);
          setSelectedEmployee(null);
          setSelectedDate(null);
          setSelectedTimeTracker(null);
        }}
        selectedUser={selectedEmployee}
        selectedDate={selectedDate}
        selectedTimeTracker={selectedTimeTracker}
        setSelectedTimeTracker={setSelectedTimeTracker}
      />
    </Box>
  );
};

export default UncompletedTimeTrackers;
