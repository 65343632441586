import { IconButton, Menu, MenuItem, TableCell, TableRow } from "@mui/material";
import {
  useDeleteExpenseMutation,
  useLazyDownloadFileByUserIdQuery,
} from "../../data/endpoints/app.endpoints";
import { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { MoreVert } from "@mui/icons-material";

import AlertDialog from "../../shared/modal/AlertDialog";
import { useSelector } from "react-redux";
import { selectLoggedUser } from "../../data/store/authSlice";
import FilePreviewModal from "../file-manager/FilePreviewModal";

const ExpensesTableRow = ({ row }: any) => {
  const [downloadFile, result] = useLazyDownloadFileByUserIdQuery();
  const [deleteExpense] = useDeleteExpenseMutation();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [previewOpen, setPreviewOpen] = useState(false);
  const [fileUrl, setFileUrl] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const loggedUser = useSelector(selectLoggedUser);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleModalOpen = () => {
    setOpenModal(true);
  };

  const handleDownload = async () => {
    try {
      const response = await downloadFile({
        fileId: row?.file?.fileId,
        fileName: row?.file?.fileName,
      });

      if ("data" in response) {
        const { downloadUrl } = response.data;
        if (downloadUrl) {
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.download = row?.file?.fileName;
          link.click();
        }
      }
    } catch (error) {
      alert("Error downloading file");
    }
  };

  const handleDelete = () => {
    deleteExpense({
      expensesId: row?.expensesId,
    });
    setOpenModal(false);
    handleClose();
  };

  const handleViewFile = async () => {
    try {
      const response = await downloadFile({
        fileId: row?.file?.fileId,
        fileName: row?.file?.fileName,
      });

      if ("data" in response) {
        const { downloadUrl } = response.data;
        if (downloadUrl) {
          setFileUrl(downloadUrl);
          setPreviewOpen(true);
        }
      }
    } catch (error) {
      alert("Error downloading file");
    }
  };

  const closePreviewModal = () => {
    setPreviewOpen(false);
  };

  return (
    <>
      {!row ? (
        <p>There are no files in this folder.</p>
      ) : (
        <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
          {loggedUser?.role === "Admin" && (
            <TableCell component="th" scope="row">
              {row?.user?.firstName + " " + row?.user?.lastName}
            </TableCell>
          )}
          <TableCell component="th" scope="row">
            {row?.type}
          </TableCell>
          <TableCell component="th" scope="row">
            {row?.vehicle?.model ? row?.vehicle?.model : "-"} -{" "}
            {row?.vehicle?.plate}
          </TableCell>
          <TableCell component="th" scope="row">
            {moment(row?.createdAt).format("DD-MMM-YYYY")}
          </TableCell>
          <TableCell component="th" scope="row">
            {row?.distance ? row?.distance : "-"}
          </TableCell>
          <TableCell component="th" scope="row">
            {row?.material ? row?.material : "-"}
          </TableCell>
          <TableCell component="th" scope="row">
            {row?.cost}
          </TableCell>
          <TableCell component="th" scope="row">
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVert sx={{ width: "17px", height: "17px" }} />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  handleDownload();
                  handleClose();
                }}
              >
                Download
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleViewFile();
                  handleClose();
                }}
              >
                View
              </MenuItem>
              <AlertDialog
                openModal={openModal}
                setOpenModal={setOpenModal}
                buttonTitle=""
                handleSubmitDialog={handleDelete}
                ButtonComponent={(props: any) =>
                  loggedUser?.role === "Admin" && (
                    <MenuItem onClick={handleModalOpen}>Delete</MenuItem>
                  )
                }
                mt="20px"
              >
                {t("EXPENSES.ADD_EXPENSE.MODAL_DELETE_CONFIRMATION")}
              </AlertDialog>
            </Menu>
          </TableCell>
          <FilePreviewModal
            open={previewOpen}
            handleClose={closePreviewModal}
            fileUrl={fileUrl}
            fileName={row?.file?.fileName}
          />
        </TableRow>
      )}
    </>
  );
};

export default memo(ExpensesTableRow);
