import { useEffect, useState } from "react";
import { Box, MenuItem, TextField, Typography } from "@mui/material";
import { FieldArray, Field } from "formik";
import FieldArrayTimePicker from "./FieldArrayTimePicker";
import CustomSelector from "./custom/CustomSelector";
import {
  useLazyGetAllProjectsQuery,
  useLazyGetProjectsByDepartmentIdQuery,
} from "../data/endpoints/app.endpoints";
import { useSelector } from "react-redux";
import { selectLoggedUser } from "../data/store/authSlice";
import moment from "moment";
import { tokens } from "../core/AppStyles";
import { useTheme } from "@mui/material";
import { checkTimeIntervalToDisplayInvalid } from "../helper/reusable-functions";
import { ProjectStatus } from "../utils/enums/project-status.enum";
import useIsMobile from "./hooks/useIsMobile";
import useIsTablet from "./hooks/useIsTablet";
import { BLUE_COLOR_ACCENT } from "../utils/constants/colors";
import { useTranslation } from "react-i18next";

interface FieldArrayComponentProps {
  values: any;
  fieldName: string;
  errors: any;
  touched: any;
  userId?: string;
  userDate?: string;
}

const FieldArrayComponent = ({
  values,
  fieldName,
  errors,
  touched,
  userId,
  userDate,
}: FieldArrayComponentProps) => {
  const user = useSelector(selectLoggedUser);
  const [renderMenuItems, setRenderMenuItems] = useState<JSX.Element[]>([]);
  const { t } = useTranslation();
  const [getProjects] = useLazyGetProjectsByDepartmentIdQuery();
  const [getAllProjects] = useLazyGetAllProjectsQuery();

  useEffect(() => {
    const items: JSX.Element[] = [];
    if (user.role === "Employee")
      getProjects(user.departmentId).then((res) => {
        if (res.data) {
          // eslint-disable-next-line array-callback-return
          res.data
            .filter((project) => project.status === ProjectStatus.INPROGRESS)
            .map((project) => {
              return items.push(
                <MenuItem key={project.projectId} value={project.projectId}>
                  {project.title}
                </MenuItem>
              );
            });
        }
      });
    else {
      getAllProjects(undefined).then((res) => {
        if (res.data) {
          // eslint-disable-next-line array-callback-return
          res.data.map((project: any) => {
            items.push(
              <MenuItem key={project.projectId} value={project.projectId}>
                {project.title}
              </MenuItem>
            );
          });
        }
      });
    }
    setRenderMenuItems(items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        flexWrap: "wrap",
      }}
    >
      <FieldArray
        name={fieldName}
        render={({ remove, insert, push }) => (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                // backgroundColor: colors.blueAccent[800],
                justifyContent: "space-between",
                alignItems: "center",
                borderRadius: 1,
              }}
            >
              <Typography
                sx={{
                  fontWeight: 600,
                  color: colors.grey[200],
                }}
                variant="h5"
              >
                {fieldName === "worked"
                  ? t("CREATE_TIME_TRACKER.WORKING_TIME")
                  : t("CREATE_TIME_TRACKER.OPTIONAL_BREAK")}
              </Typography>
            </Box>
            {values.length > 0 ? (
              <Box
                sx={{
                  marginTop: 2,
                  // p: 1,
                  overflowY: isMobile
                    ? "none"
                    : values.length > 2
                    ? "scroll"
                    : "none",
                  maxHeight: `${isMobile ? "auto" : "200px"}`,
                  display: "flex",
                  flexDirection: "column",
                  gap: 3,
                  width: "100%",
                }}
              >
                {values && values.length > 0
                  ? values.map((worked: object, index: number) => (
                      <Box key={index}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            gap: 0.5,
                          }}
                        >
                          <Box
                            sx={{
                              width: `${isMobile ? "100%" : "auto"}`,
                              display: "flex",
                              flexDirection:
                                fieldName === "worked"
                                  ? "row"
                                  : isMobile
                                  ? "column"
                                  : "row",
                              justifyContent: isMobile
                                ? "auto"
                                : fieldName === "worked"
                                ? "space-between"
                                : "auto",
                              flexWrap: `${isMobile ? "wrap" : "nowrap"}`,
                              height: `${
                                isMobile
                                  ? fieldName === "worked"
                                    ? "130px"
                                    : "85px"
                                  : "auto"
                              }`,
                              alignItems: "center",
                              gap: 2,
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection:
                                  fieldName === "worked"
                                    ? "row"
                                    : isMobile
                                    ? "column"
                                    : "row",
                                gap: 2,
                              }}
                            >
                              <FieldArrayTimePicker
                                errors={
                                  errors[fieldName] && errors[fieldName][index]
                                }
                                touched={
                                  touched[fieldName] &&
                                  touched[fieldName][index]?.startTime
                                }
                                sx={{
                                  width: isMobile
                                    ? fieldName === "worked"
                                      ? "auto"
                                      : "100%"
                                    : "auto",
                                }}
                                name={`${fieldName}[${index}].startTime`}
                                label={t("TIME_TRACKER.USER.START_TIME")}
                              />

                              <FieldArrayTimePicker
                                sx={{
                                  width: isMobile
                                    ? fieldName === "worked"
                                      ? "auto"
                                      : "100%"
                                    : "auto",
                                }}
                                name={`${fieldName}[${index}].endTime`}
                                errors={
                                  errors[fieldName] && errors[fieldName][index]
                                }
                                touched={
                                  touched[fieldName] &&
                                  touched[fieldName][index]
                                }
                                label={t("TIME_TRACKER.USER.END_TIME")}
                              />
                            </Box>

                            {fieldName === "worked" ? (
                              <>
                                <Field
                                  name={`worked[${index}].projectId`}
                                  children={({ field, form }: any) => (
                                    <CustomSelector
                                      width={isMobile ? "100%" : "40%"}
                                      value={field.value}
                                      onSelectChange={(newValue: any) => {
                                        form.setFieldValue(
                                          field.name,
                                          newValue.target.value,
                                          true
                                        );
                                      }}
                                      size="medium"
                                      label={t(
                                        "TIME_TRACKER.USER.SELECT_PROJECT"
                                      )}
                                      errors={
                                        errors[fieldName] &&
                                        errors[fieldName][index]
                                      }
                                      touched={
                                        touched[fieldName] &&
                                        touched[fieldName][index]?.startTime
                                      }
                                      showError={false}
                                    >
                                      {renderMenuItems}
                                    </CustomSelector>
                                  )}
                                />
                                <Field
                                  name={`worked[${index}].description`}
                                  children={({ field, form }: any) => (
                                    <TextField
                                      variant="outlined"
                                      inputProps={{
                                        style: { fontSize: "16px" },
                                      }}
                                      sx={{
                                        width: `${isMobile ? "55%" : "50%"}`,
                                      }}
                                      label={t("DESCRIPTION_OPTIONAL")}
                                      size="small"
                                      name={field.name}
                                      color="info"
                                      value={field.value}
                                      onChange={(newValue) => {
                                        form.setFieldValue(
                                          field.name,
                                          newValue.target.value,
                                          true
                                        );
                                      }}
                                    />
                                  )}
                                />
                              </>
                            ) : null}

                            <Typography
                              sx={{
                                width: `${isMobile ? "20%" : "10%"}`,
                                fontWeight: "900",
                                border: `1px solid ${colors.grey[500]}`,
                                borderRadius: 1,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontSize: "1rem",
                                p: isMobile || isTablet ? 0.8 : 1,
                                color: colors.grey[200],
                              }}
                            >
                              {checkTimeIntervalToDisplayInvalid(
                                values[index].startTime,
                                values[index].endTime
                              )}
                            </Typography>
                            <Box>
                              {index > 0 ? (
                                <button
                                  type="button"
                                  onClick={() => remove(index)}
                                  style={{
                                    fontSize: "1rem",
                                    fontWeight: 300,
                                    color: colors.black,
                                    backgroundColor: "#f8683c",
                                    width: isMobile ? "30px" : "65px",
                                    border: "0",
                                    display: "flex",
                                    justifyContent: "center",
                                    padding: "6px 8px",
                                    borderRadius: 2,
                                    cursor: "pointer",
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={isMobile ? "18" : "25"}
                                    height={isMobile ? "18" : "25"}
                                    fill="white"
                                    className="bi bi-trash-fill"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                                  </svg>
                                </button>
                              ) : fieldName === "optionalBreak" ? (
                                <button
                                  type="button"
                                  onClick={() => remove(index)}
                                  style={{
                                    fontSize: "1rem",
                                    fontWeight: 300,
                                    color: colors.black,
                                    backgroundColor: "#f8683c",
                                    width: isMobile ? "30px" : "65px",
                                    border: "0",
                                    display: "flex",
                                    justifyContent: "center",
                                    padding: "6px 8px",
                                    borderRadius: 2,
                                    cursor: "pointer",
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={isMobile ? "18" : "25"}
                                    height={isMobile ? "18" : "25"}
                                    fill="white"
                                    className="bi bi-trash-fill"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                                  </svg>
                                </button>
                              ) : (
                                <Box
                                  sx={{
                                    width: isMobile ? "30px" : "65px",
                                  }}
                                ></Box>
                              )}
                            </Box>
                          </Box>
                        </Box>

                        <Typography
                          sx={{
                            textAlign: "left",
                            color: "red",
                            fontSize: "12px",
                            paddingTop: isMobile ? "15px" : "",
                          }}
                        >
                          {fieldName === "optionalBreak"
                            ? errors &&
                              errors.optionalBreak &&
                              errors.optionalBreak[index]
                            : errors && errors.worked && errors.worked[index]}
                        </Typography>
                      </Box>
                    ))
                  : null}
              </Box>
            ) : null}
            <div
              style={{
                fontSize: isMobile ? "0.9rem" : "1rem",
                color: BLUE_COLOR_ACCENT,
                borderRadius: "5px",
                marginLeft: "10px",
                width: "auto",
                padding: 0,
                justifyContent: "left",
                marginTop: "10px",
              }}
            >
              <span
                style={{ cursor: "pointer" }}
                onClick={
                  fieldName === "worked"
                    ? () =>
                        push({
                          startTime: "",
                          endTime: "",
                          description: "",
                          projectId: "",
                          type: "Work",
                          date: userDate
                            ? userDate
                            : moment().format("YYYY-MM-DD"),
                          userId: userId ? userId : user.userId,
                        })
                    : fieldName === "optionalBreak"
                    ? () =>
                        push({
                          startTime: "",
                          endTime: "",
                          type: "Break Time",
                          date: userDate
                            ? userDate
                            : moment().format("YYYY-MM-DD"),
                          userId: userId ? userId : user.userId,
                        })
                    : () => {}
                }
              >
                + {t("ADD_MORE")}
              </span>
            </div>
          </Box>
        )}
      ></FieldArray>
    </Box>
  );
};

export default FieldArrayComponent;
