import { Box, createSvgIcon } from "@mui/material";
import { useTranslation } from "react-i18next";

export const IconEdit = ({ fill, onClick, ...props }: any) => {
  return (
    <svg
      style={{ cursor: "pointer" }}
      onClick={onClick}
      width={18}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      fill={fill}
    >
      <path d="M221.3,11.9c-4.6,1-7.9,3-12.5,7.8c-4,4.1-4.2,4.3-4.2,6.3v2l12.4,12.3l12.3,12.4h2.3c2.2,0,2.3,0,6.4-4.3c4.5-4.6,6.3-7.4,7.4-11.6c1.8-7,0-13.8-5.2-19.2c-2.9-3-6.9-5.2-10.9-5.9C225.9,11.1,224.9,11.1,221.3,11.9z" />
      <path d="M58.6,27.8c-21.3,3.1-39.7,19-46.2,39.6c-2.6,8.4-2.6,7.8-2.4,71.4l0.1,58.4l1.2,4.4c3.2,11.6,7.7,19.3,15.9,27.2c8.3,7.9,18,12.9,29.6,15.1c4.1,0.8,8.7,0.8,65,0.8c56.3,0,61,0,65-0.8c5.8-1.1,11.7-3.1,16.7-5.7c13.3-6.9,24.2-19.9,28.1-33.8c2.1-7.4,2.2-8.2,2.2-56.2v-45.5l-14,14L206,130.8l-0.1,31.4c-0.1,30.9-0.2,31.4-1.2,34.4c-1.6,4.6-4.1,8.5-7.8,11.9c-3.8,3.5-7.7,5.7-12.9,7c-3.5,0.9-5.1,0.9-63.1,0.8l-59.4-0.1l-3.9-1.4c-4.9-1.8-8.4-4-11.9-7.7c-3.1-3.3-5.3-7.3-6.4-11.2c-1-4-1.1-114.8,0-118.7c1.9-7.4,7.8-14.8,14.5-18.1c6.8-3.4,4.8-3.2,41.8-3.4l33.2-0.2l14.1-14.1l14-14l-47.7,0C82.8,27.4,60.1,27.6,58.6,27.8z" />
      <path d="M188.6,40.5c-0.8,0.4-20.6,20-44.1,43.5c-34,34-42.7,43.1-43,44.2c-0.2,1,0,2,0.5,3c0.4,0.8,5.9,6.8,12.4,13.1L126,156h2.3h2.3l43.2-43.2c29.4-29.4,43.4-43.7,43.7-44.7c0.3-1,0.3-1.8,0-2.8c-0.6-1.5-22.6-23.8-24.5-24.9C191.3,39.6,190.4,39.7,188.6,40.5z" />
      <path d="M87.2,146.9c-0.8,0.7-3.3,6-7.7,16.4c-3.5,8.4-6.5,15.6-6.5,16c0,2.2,2.7,4.8,5,4.8c1.4,0,31.1-12.7,32.2-13.8c1.1-1.1,1.6-2.7,1.2-4.4c-0.1-0.6-4.1-4.9-9.8-10.6c-9.4-9.3-9.6-9.5-11.4-9.5C89,145.8,88,146.2,87.2,146.9z" />
    </svg>
  );
};
export const PrevIcon = () => {
  const { t } = useTranslation()
  return <Box sx={{ fontSize: "12px" }}>{t("PREVIOUS")}</Box>
};

export const NextIcon = () => {
  const { t } = useTranslation()
  return <Box sx={{ fontSize: "12px" }}>{t("NEXT")}</Box>
};
export const GBIcon = createSvgIcon(
  <svg
    viewBox="0 0 36 36"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    className="iconify iconify--twemoji"
    preserveAspectRatio="xMidYMid meet"
    fill="#000000"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      <path
        fill="#00247D"
        d="M0 9.059V13h5.628zM4.664 31H13v-5.837zM23 25.164V31h8.335zM0 23v3.941L5.63 23zM31.337 5H23v5.837zM36 26.942V23h-5.631zM36 13V9.059L30.371 13zM13 5H4.664L13 10.837z"
      ></path>
      <path
        fill="#CF1B2B"
        d="M25.14 23l9.712 6.801a3.977 3.977 0 0 0 .99-1.749L28.627 23H25.14zM13 23h-2.141l-9.711 6.8c.521.53 1.189.909 1.938 1.085L13 23.943V23zm10-10h2.141l9.711-6.8a3.988 3.988 0 0 0-1.937-1.085L23 12.057V13zm-12.141 0L1.148 6.2a3.994 3.994 0 0 0-.991 1.749L7.372 13h3.487z"
      ></path>
      <path
        fill="#EEE"
        d="M36 21H21v10h2v-5.836L31.335 31H32a3.99 3.99 0 0 0 2.852-1.199L25.14 23h3.487l7.215 5.052c.093-.337.158-.686.158-1.052v-.058L30.369 23H36v-2zM0 21v2h5.63L0 26.941V27c0 1.091.439 2.078 1.148 2.8l9.711-6.8H13v.943l-9.914 6.941c.294.07.598.116.914.116h.664L13 25.163V31h2V21H0zM36 9a3.983 3.983 0 0 0-1.148-2.8L25.141 13H23v-.943l9.915-6.942A4.001 4.001 0 0 0 32 5h-.663L23 10.837V5h-2v10h15v-2h-5.629L36 9.059V9zM13 5v5.837L4.664 5H4a3.985 3.985 0 0 0-2.852 1.2l9.711 6.8H7.372L.157 7.949A3.968 3.968 0 0 0 0 9v.059L5.628 13H0v2h15V5h-2z"
      ></path>
      <path fill="#CF1B2B" d="M21 15V5h-6v10H0v6h15v10h6V21h15v-6z"></path>
    </g>
  </svg>,
  ""
);

export const GermanIcon = createSvgIcon(
  <svg width="130" height="80" viewBox="0 0 130 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="130" height="80" rx="7" fill="#D9D9D9" />
    <path d="M0 5C0 2.23858 2.23858 0 5 0H125C127.761 0 130 2.23858 130 5V27H0V5Z" fill="black" />
    <rect y="27" width="130" height="27" fill="#D00000" />
    <path d="M0 53H130V75C130 77.7614 127.761 80 125 80H5C2.23858 80 0 77.7614 0 75V53Z" fill="#FFD521" />
    <path d="M65 0H125C127.761 0 130 2.23858 130 5V75C130 77.7614 127.761 80 125 80H65V0Z" fill="#FF0000" />
    <rect x="91" y="21" width="13" height="39" fill="white" />
    <rect x="78" y="47" width="13" height="39" transform="rotate(-90 78 47)" fill="white" />
  </svg>
  ,
  ""
);

export const ItalyIcon = createSvgIcon(
  <svg viewBox="0 -4 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {" "}
      <g clipPath="url(#clip0_503_4138)">
        {" "}
        <rect
          x="0.25"
          y="0.25"
          width="27.5"
          height="19.5"
          rx="1.75"
          fill="white"
          stroke="#F5F5F5"
          strokeWidth="0.5"
        ></rect>{" "}
        <mask
          id="mask0_503_4138"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="28"
          height="20"
        >
          {" "}
          <rect
            x="0.25"
            y="0.25"
            width="27.5"
            height="19.5"
            rx="1.75"
            fill="white"
            stroke="white"
            strokeWidth="0.5"
          ></rect>{" "}
        </mask>{" "}
        <g mask="url(#mask0_503_4138)">
          {" "}
          <rect
            x="18.6665"
            width="9.33333"
            height="20"
            fill="#E43D4C"
          ></rect>{" "}
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 20H9.33333V0H0V20Z"
            fill="#1BB65D"
          ></path>{" "}
        </g>{" "}
      </g>{" "}
      <defs>
        {" "}
        <clipPath id="clip0_503_4138">
          {" "}
          <rect width="28" height="20" rx="2" fill="white"></rect>{" "}
        </clipPath>{" "}
      </defs>{" "}
    </g>
  </svg>,
  ""
);

export const FranceIcon = createSvgIcon(
  <svg viewBox="0 -4 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {" "}
      <g clipPath="url(#clip0_503_2485)">
        {" "}
        <rect
          x="0.25"
          y="0.25"
          width="27.5"
          height="19.5"
          rx="1.75"
          fill="white"
          stroke="#F5F5F5"
          strokeWidth="0.5"
        ></rect>{" "}
        <mask
          id="mask0_503_2485"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="28"
          height="20"
        >
          {" "}
          <rect
            x="0.25"
            y="0.25"
            width="27.5"
            height="19.5"
            rx="1.75"
            fill="white"
            stroke="white"
            strokeWidth="0.5"
          ></rect>{" "}
        </mask>{" "}
        <g mask="url(#mask0_503_2485)">
          {" "}
          <rect
            x="18.6667"
            width="9.33333"
            height="20"
            fill="#F44653"
          ></rect>{" "}
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 20H9.33333V0H0V20Z"
            fill="#1035BB"
          ></path>{" "}
        </g>{" "}
      </g>{" "}
      <defs>
        {" "}
        <clipPath id="clip0_503_2485">
          {" "}
          <rect width="28" height="20" rx="2" fill="white"></rect>{" "}
        </clipPath>{" "}
      </defs>{" "}
    </g>
  </svg>,
  ""
);
