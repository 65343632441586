import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Box,
  MenuItem,
  Pagination,
  PaginationItem,
  Stack,
  useTheme,
} from "@mui/material";
import { tokens } from "../../core/AppStyles";
import { useContext, useState } from "react";
import { Delete } from "@mui/icons-material";
import TableDetailsMobileModal from "../../shared/custom/CustomTableDetailModalMobile";
import { useDeleteEquipmentMutation } from "../../data/endpoints/app.endpoints";
import { RootContext } from "../../context/RootContext";
import {
  IconEdit,
  PrevIcon,
  NextIcon,
} from "../../shared/custom/CustomEditIcons";
import CustomSelector from "../../shared/custom/CustomSelector";
import { useTranslation } from "react-i18next";
import FormAlertDialog from "../../shared/modal/FormAlertDialog";
import {
  BLUE_COLOR_ACCENT,
  BLUE_HOVER_COLOR_ACCENT,
} from "../../utils/constants/colors";
import moment from "moment";

interface ICustomEquipementsTableMobile {
  column1Name?: string;
  column2Name?: string;
  column3Name?: string;
  data?: any;
  isPaginated: boolean;
  rowsPerPage?: number;
  page?: number;
  count?: number;
  mt?: number;
  handleChangePage?: (event: any, newPage: number) => void;
  handleChangeRowsPerPage?: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
}

const CustomEquipementsTableMobile = ({
  column1Name,
  column2Name,
  column3Name,
  data,
  isPaginated,
  rowsPerPage,
  page,
  count,
  mt,
  handleChangePage,
  handleChangeRowsPerPage,
}: ICustomEquipementsTableMobile) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [rowDetails, setRowDetails] = useState<any>();
  const [header, setHeader] = useState<string>();
  const { t } = useTranslation();

  const [openChangeStatusModal, setChangeStatusModal] = useState(false);
  const [openDeleteModal, setDeleteModal] = useState(false);

  const {
    showSnackbar,
    setOpenModal,
    state: { openModal },
  } = useContext(RootContext);

  const [deleteEquipment] = useDeleteEquipmentMutation();

  const handleSubmitDialog = () => {};
  const handleChangeStatusShowAlertDialog = () => {
    setChangeStatusModal(true);
  };

  const handleDelete = (equipmentId: string) => {
    deleteEquipment(equipmentId).then((res: any) => {
      if (!res.error) {
        setDeleteModal(false);
        showSnackbar("Equipmenet deleted successfully", false, true);
      } else if (res.error) {
        showSnackbar(res.error.data.error.message, true, true);
      }
    });
    setOpenModal(false);
  };

  const handleDetailsModal = (id: string) => {
    const _rowDetails = data?.filter((info: any) => info?.equipmentId === id);

    const rowProjectData: any[] = [];

    _rowDetails?.map((info: any) => {
      return rowProjectData.push({
        data: [
          {
            key: `${t("EQUIPMENTS.ITEM_NAME")}`,
            value: info?.itemName,
          },
          {
            key: t("USER"),
            value: info?.user?.firstName + " " + info?.user?.lastName,
          },
          {
            key: `${t("EQUIPMENTS.SERIAL_NUMBER")}`,
            value: info?.serialNumber,
          },
          {
            key: `${t("EQUIPMENTS.QUANTITY")}`,
            value: info?.quantity,
          },
          {
            key: t("STATUS"),
            value: info?.status?.toUpperCase(),
          },
          {
            key: `${t("EQUIPMENTS.LOCATION")}`,
            value: info?.location,
          },
          {
            key: `${t("START_DATE")}`,
            value: moment(info?.startDate).format("DD MMM YYYY"),
          },
          {
            key: `${t("END_DATE")}`,
            value: moment(info?.endDate).format("DD MMM YYYY"),
          },
        ],
        actions: [
          {
            key: <IconEdit fill={colors.invert} />,
            path: `edit-equipment/${info?.equipmentId}`,
          },
        ],
        functions: [
          {
            key: <Delete sx={{ color: colors.redAccent[500] }} />,
            func: handleDelete,
            id: info?.equipmentId,
          },
        ],
      });
    });

    setRowDetails(rowProjectData);
    setHeader(rowProjectData?.[0]?.data?.[0]?.value);
  };

  return (
    <Paper
      sx={{
        borderRadius: "20px",
        boxShadow: `${colors.shadow} 1px 1px 5px 1px`,
        marginTop: `${mt}%`,
        backgroundColor: colors.component,
      }}
    >
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 300, backgroundColor: colors.component }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell>{column1Name}</TableCell>
              <TableCell>{column2Name}</TableCell>
              <TableCell>{column3Name}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row: any, index: number) => (
              <TableRow key={index}>
                <TableCell
                  align="center"
                  width="50px"
                  onClick={() => handleDetailsModal(row?.equipmentId)}
                >
                  <TableDetailsMobileModal
                    dataInfo={rowDetails}
                    header={header}
                  />
                </TableCell>
                <TableCell align="left">
                  <b>{row?.itemName}</b> <br />
                  {row?.user?.firstName + " " + row?.user?.lastName}
                </TableCell>
                <TableCell component="th" scope="row">
                  <FormAlertDialog
                    width="auto"
                    buttonTitle={t("EQUIPMENTS.MODIFY")}
                    openModal={openChangeStatusModal}
                    handleShowAlertDialog={handleChangeStatusShowAlertDialog}
                    handleSubmitDialog={handleSubmitDialog}
                    color={BLUE_COLOR_ACCENT}
                    colorHover={BLUE_HOVER_COLOR_ACCENT}
                    setOpenModal={setChangeStatusModal}
                    equipmentId={row?.equipmentId}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isPaginated ? (
        <Stack
          flexDirection="row"
          width="100%"
          justifyContent="center"
          padding={2}
          sx={{ backgroundColor: colors.component }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <span
              style={{
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              <p
                style={{
                  color: "gray",
                }}
              >
                {t("ROWS")}:
              </p>
              <CustomSelector
                size="medium"
                value={rowsPerPage}
                onSelectChange={handleChangeRowsPerPage}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={count}>ALL</MenuItem>
              </CustomSelector>
            </span>
            <Pagination
              siblingCount={0}
              size="small"
              color="secondary"
              shape="rounded"
              count={count && rowsPerPage && Math.ceil(count / rowsPerPage)}
              page={page}
              onChange={handleChangePage}
              renderItem={(item) => (
                <PaginationItem
                  sx={{ backgroundColor: colors.primary[400] }}
                  slots={{ previous: PrevIcon, next: NextIcon }}
                  {...item}
                />
              )}
            />
          </Box>
        </Stack>
      ) : null}
    </Paper>
  );
};

export default CustomEquipementsTableMobile;
