import {
  DialogContent,
  DialogActions,
  Button,
  Dialog,
  DialogProps,
  Box,
  colors,
  useTheme,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import LegendComponent from "./LegendComponent";
import {
  AirplaneTicket,
  Apartment,
  InfoOutlined,
  LegendToggle,
  LockClock,
} from "@mui/icons-material";
import { tokens } from "../../../../core/AppStyles";

const ActivityLegend = () => {
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState<DialogProps["scroll"]>("paper");

  const theme = useTheme();
  const customColors = tokens(theme.palette.mode);

  const handleClickOpen = (scrollType: DialogProps["scroll"]) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = useRef<HTMLElement>(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  });

  return (
    <div>
      <InfoOutlined
        onClick={handleClickOpen("paper")}
        sx={{ cursor: "pointer", marginLeft: "10px" }}
      />
      <Dialog
        PaperProps={{ style: { background: customColors.component } }}
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        style={{ width: "auto" }}
      >
        <DialogContent
          dividers={scroll === "paper"}
          sx={{
            width: "auto",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "60vh",
              display: "flex",
              flexDirection: "column",
              overflowY: "auto",
            }}
          >
            <p
              style={{
                width: "100%",
                textAlign: "justify",
                height: "auto",
                padding: "2%",
              }}
            >
              Activity columns explanations are as below:
            </p>
            <LegendComponent
              icon={<LockClock sx={{ color: customColors.white }} />}
              title="Expected working"
              description="This column is an automatic calculation for each employee, on how many hours one employee has to work form start of the month to the end of it."
              backgroundColor={colors.blue[50]}
            />
            <LegendComponent
              title="Leaves"
              description="Leaves column displays total hours an employee has not been working based on approved leave requests."
              icon={<AirplaneTicket sx={{ color: customColors.white }} />}
              backgroundColor={colors.green[50]}
            />{" "}
            <LegendComponent
              title="Worked"
              description="In Worked column is displayed a sum of total hours an employee has submitted on daily time trackers."
              icon={<Apartment sx={{ color: customColors.white }} />}
              backgroundColor={colors.purple[50]}
            />{" "}
            <LegendComponent
              title="Overtime"
              description="This column shows a sum of each overtime we get from daily completed time trackers of employers."
              icon={<LegendToggle sx={{ color: customColors.white }} />}
              backgroundColor={colors.blue[50]}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button style={{ color: customColors.invert }} onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ActivityLegend;
