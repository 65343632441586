import { useState, useContext } from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useCreateUserMutation } from "../../data/endpoints/app.endpoints";
import CustomHeader from "../../shared/custom/CustomHeader";
import CustomNavigationButton from "../../shared/custom/CustomNavigationButton";
import { BLUE_COLOR_ACCENT } from "../../utils/constants/colors";
import UserFormFields from "./EmployeeFormFields";
import {
  EMAIL_REGEX,
  handleEmployeeWorkingHoursValidation,
  handleRegexValidation,
  handleRequiredValidation,
  PASSWORD_REGEX,
} from "../../utils/regex/formRegex";
import { Formik } from "formik";
import { RootContext } from "../../context/RootContext";
import PasswordTestField from "../../shared/PasswordTestField";
import BoxContainer from "../../shared/BoxContainer";
import { Box, Grid, useTheme } from "@mui/material";
import useIsMobile from "../../shared/hooks/useIsMobile";
import { useTranslation } from "react-i18next";
import useIsDesktop from "../../shared/hooks/useIsDesktop";
import useIsLargeScreen from "../../shared/hooks/useIsLargeScreen";
import { tokens } from "../../core/AppStyles";

export const AddEmployee = () => {
  const [createUser, result] = useCreateUserMutation();
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [visibleConfirmPassword, setVisibleConfirmPassword] = useState(false);
  const isMobile = useIsMobile();
  const isDesktop = useIsDesktop();
  const isLargeScreen = useIsLargeScreen();
  const togglePassword = () => setVisiblePassword(!visiblePassword);
  const toggleConfirmPassword = () =>
    setVisibleConfirmPassword(!visibleConfirmPassword);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { t } = useTranslation();

  const { setOpenModal } = useContext(RootContext);

  const initialValues = {
    email: "",
    password: "",
    firstName: "",
    confirmPassword: "",
    lastName: "",
    totalVacations: "",
    totalOvertimes: "",
    workingHours: "",
    role: "",
    hiringDate: new Date(),
    departmentId: "",
    percentage: ''
  };

  const onSubmitForm = () => {
    setOpenModal(true);
  };

  const validate = (values: typeof initialValues) => {
    const errors = {} as any;
    //email
    handleRegexValidation(
      values,
      errors,
      "email",
      EMAIL_REGEX,
      t(`FORM_VALIDATIONS.USERS.EMAIL_ERROR_MESSAGE`),
      t(`FORM_VALIDATIONS.REQUIRED`)
    );
    //password
    handleRegexValidation(
      values,
      errors,
      "password",
      PASSWORD_REGEX,
      t(`FORM_VALIDATIONS.USERS.PASSWORD_ERROR_MESSAGE`),
      t(`FORM_VALIDATIONS.REQUIRED`)
    );

    if (!values.confirmPassword) {
      errors.confirmPassword = t(`FORM_VALIDATIONS.REQUIRED`);
    } else if (values.confirmPassword !== values.password) {
      errors.confirmPassword = t(`FORM_VALIDATIONS.USERS.PASSWORD_NOT_MATCH`);
    }
    handleRequiredValidation(
      values,
      errors,
      "firstName",
      t("FORM_VALIDATIONS.REQUIRED")
    );
    handleRequiredValidation(
      values,
      errors,
      "lastName",
      t("FORM_VALIDATIONS.REQUIRED")
    );
    handleRequiredValidation(
      values,
      errors,
      "percentage",
      t("FORM_VALIDATIONS.REQUIRED")
    );
    handleRequiredValidation(
      values,
      errors,
      "role",
      t("FORM_VALIDATIONS.REQUIRED")
    );

    handleEmployeeWorkingHoursValidation(values, errors, "workingHours", {
      required: t("FORM_VALIDATIONS.REQUIRED"),
      negativeValue: t("FORM_VALIDATIONS.USERS.WORKING_HOURS_POSITIVE"),
      greaterThan24: "Value cannot be greater than 24",
    });
    handleRequiredValidation(
      values,
      errors,
      "departmentId",
      t("FORM_VALIDATIONS.REQUIRED")
    );
    handleRequiredValidation(
      values,
      errors,
      "hiringDate",
      t("FORM_VALIDATIONS.REQUIRED")
    );

    return errors;
  };

  return (
    <BoxContainer>
      <CustomHeader
        title={t("USERS_ADMIN.CREATE_USER.TITLE")}
        subtitle={t("USERS_ADMIN.CREATE_USER.SUBTITLE")}
      >
        <CustomNavigationButton
          customStyle={{
            backgroundColor: BLUE_COLOR_ACCENT,
            width: "10%",
            display: "flex",
            gap: 1,
            alignSelf: "center",
            fontSize: "12px",
            ":hover": {
              bgcolor: "#1d61c6",
              color: "white",
            },
          }}
          navigateTo={"/employees"}
        >
          <KeyboardArrowLeftIcon /> {isMobile ? null : t("GO_BACK")}
        </CustomNavigationButton>
      </CustomHeader>
      <Box
        sx={{
          width: "auto",
          height: "auto",
          marginTop: isDesktop || isLargeScreen ? 4 : 0,
          backgroundColor: colors.component,
          padding: 3,
          paddingTop: 5,
          borderRadius: 3,
        }}
      >
        <Formik
          initialValues={initialValues}
          validate={validate}
          onSubmit={onSubmitForm}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            setFieldValue,
            resetForm,
          }: any) => (
            <UserFormFields
              buttonTitle={t("USERS_ADMIN.CREATE_USER.TITLE")}
              errors={errors}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              setFieldValue={setFieldValue}
              handleMutation={createUser}
              touched={touched}
              values={values}
              resetForm={resetForm}
              submitIsLoading={result?.isLoading}
              initialValues={initialValues}
            >
              <Grid item xs={12} sx={{ gridColumn: "span 4" }}>
                <PasswordTestField
                  label={t("USERS_ADMIN.CREATE_USER.PASSWORD")}
                  variableName="password"
                  error={errors.password}
                  handleChange={handleChange}
                  isVisible={visiblePassword}
                  toggleVisibility={togglePassword}
                  value={values.password}
                  touched={touched.password}
                />
              </Grid>
              <Grid item xs={12} sx={{ gridColumn: "span 4" }}>
                <PasswordTestField
                  label={t("USERS_ADMIN.CREATE_USER.CONFIRM_PASSWORD")}
                  variableName="confirmPassword"
                  error={errors.confirmPassword}
                  handleChange={handleChange}
                  isVisible={visibleConfirmPassword}
                  toggleVisibility={toggleConfirmPassword}
                  value={values.confirmPassword}
                  touched={touched.password}
                />
              </Grid>
            </UserFormFields>
          )}
        </Formik>
      </Box>
    </BoxContainer>
  );
};

export default AddEmployee;
