import { Box, useTheme } from "@mui/material";
import { tokens } from "../../../../core/AppStyles";
import useIsMobile from "../../../../shared/hooks/useIsMobile";
import useIsTablet from "../../../../shared/hooks/useIsTablet";
import useIsLargeScreen from "../../../../shared/hooks/useIsLargeScreen";

interface ISingleCard {
  icon?: any;
  number: string;
  smallString?: string;
  title: string;
  clickFunction?: () => void;
}

const SingleCardDashboard = ({
  icon,
  number,
  smallString,
  title,
  clickFunction,
}: ISingleCard) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isLargeScreen = useIsLargeScreen();
  return (
    <Box
      sx={{
        width: `${
          isMobile ? "17vh" : isLargeScreen ? "30%" : isTablet ? "30%" : "16vh"
        }`,
        height: `${isMobile ? "120px" : isTablet ? "170px" : "100%"}`,
        boxShadow: " rgba(0, 0, 0, 0.08) 1px 1px 3px 1px",
        background: colors.component,
        borderRadius: "6px",
        display: "flex",
        flexDirection: "column",
        justifyContent: isLargeScreen ? "space-evenly" : "center",
        margin: 0,
        padding: 2,
        overflow: "hidden",
        cursor: "pointer",
      }}
      onClick={clickFunction}
    >
      {icon}
      <h1
        style={{
          padding: 0,
          margin: 0,
          fontSize: isLargeScreen ? "2.3rem" : "auto",
        }}
      >
        {number}
      </h1>
      <h6
        style={{
          padding: 0,
          margin: 0,
          fontSize: isLargeScreen ? "1rem" : "auto",
        }}
      >
        {smallString ? (
          <>
            <small style={{ padding: 0 }}>{smallString}</small> <br />{" "}
          </>
        ) : (
          ""
        )}
        {title}
      </h6>
    </Box>
  );
};

export default SingleCardDashboard;
