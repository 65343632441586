import {
  Folder,
  Dashboard,
  Groups,
  AccountTreeOutlined,
  ApartmentOutlined,
  TableChartOutlined,
  ManageHistory,
  History,
  Celebration,
  AirplaneTicket,
  HomeRepairService,
  CloudUpload,
  CloudDownload,
  MoreTime,
  LogoutOutlined,
  AccessTime,
  PersonPin,
  PermContactCalendar,
  Topic,
  Work,
  LineAxis,
  EventBusy,
  Badge,
  CalendarMonth,
  Money,
  DirectionsCarFilled,
  HistoryEdu,
} from "@mui/icons-material";
import { Box, Tooltip, useTheme } from "@mui/material";
import { useContext, useState } from "react";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  MenuItemStyles,
} from "react-pro-sidebar";
import { tokens } from "./AppStyles";
import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { useLazyLogoutQuery } from "../data/endpoints/app.endpoints";
import { selectLoggedUser } from "../data/store/authSlice";
import { RootContext } from "../context/RootContext";
import { useDispatch } from "react-redux";
import { UserRole } from "../utils/enums/user-role.enum";
import AlertDialog from "../shared/modal/AlertDialog";
import { SidebarHeader } from "./sidebar/SidenavHeader";
import useIsMobile from "../shared/hooks/useIsMobile";
import { useTranslation } from "react-i18next";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
export const SideNav: React.FC = () => {
  const { pathname } = useLocation();
  const navigation = useNavigate();
  let [collapsed, setCollapsed] = useState<boolean>(false);
  const [selected, setSelected] = useState(pathname);
  const [openLogOutModal, setLogOutModal] = useState(false);
  const user = useSelector(selectLoggedUser);
  const [logoutUser] = useLazyLogoutQuery();
  const { logoutAndClearState } = useContext(RootContext);
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const [openSubMenu, setOpenSubMenu] = useState<any>(
    getLastOpenSubMenuFromSession()
  );

  const handleModalOpen = () => {
    setLogOutModal(true);
  };

  const handleItemClick = (to: any) => {
    setSelected(to);
    navigation(to);
  };

  function getLastOpenSubMenuFromSession() {
    return sessionStorage.getItem("lastOpenSubMenu") || null;
  }

  function setLastOpenSubMenuToSession(submenu: any) {
    sessionStorage.setItem("lastOpenSubMenu", submenu);
  }

  const handleSubMenuClick = (name: any) => {
    setOpenSubMenu(name === openSubMenu ? null : name);
    setLastOpenSubMenuToSession(name === openSubMenu ? null : name);
  };

  const menuItemStyles: MenuItemStyles = {
    subMenuContent: {
      backgroundColor: colors.component,
    },
    button: ({ active }) => {
      if (active) {
        return {
          backgroundColor: colors.sideNav,
          borderRadius: "0 10px 10px 0",
          transition: "ease-in-out 0.2s",
          "&:hover": {
            backgroundColor: "#b7dbff80",
          },
        };
      }
      return {
        borderRadius: "0 10px 10px 0",
        "&:hover": {
          backgroundColor: "rgba(183, 219, 255, 0.2)",
          transition: "ease-in-out 0.2s",
        },
      };
    },
    root: {
      margin: "0 0 5px 0",
      borderRadius: "0 10px 10px 0",
      width: "90%",
      fontSize: "15px",
      fontWeight: 400,
      backgroundColor: colors.component,
      border: "none",
    },
    SubMenuExpandIcon: {
      color: "#b6b7b9",
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };

  return (
    <Box
      sx={{
        display: "flex",
        height: "100% auto",
        boxShadow: `${colors.shadow} 0px 6px 24px 0px, ${colors.shadow} 0px 0px 0px 1px`,
        backgroundColor: colors.component,
      }}
    >
      <Sidebar
        collapsed={isMobile ? (collapsed = true) : collapsed}
        style={{
          border: "none",
          backgroundColor: colors.component,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            backgroundColor: colors.component,
          }}
        >
          <SidebarHeader
            handleItemClick={handleItemClick}
            collapsed={collapsed}
            setCollapsed={setCollapsed}
          />
          <div
            style={{
              flex: 1,
              marginBottom: "32px",
              marginTop: "25px",
              backgroundColor: colors.component,
            }}
          >
            <Menu menuItemStyles={menuItemStyles}>
              <MenuItem
                active={selected === "/dashboard"}
                onClick={() => handleItemClick("/dashboard")}
                icon={<Dashboard />}
              >
                {t("SIDENAV.DASHBOARD")}
              </MenuItem>
              {user?.role === UserRole.ADMIN ? (
                <>
                  <MenuItem
                    active={selected === "/employees"}
                    onClick={() => handleItemClick("/employees")}
                    icon={<Groups />}
                  >
                    {t("SIDENAV.USERS")}
                  </MenuItem>
                  <MenuItem
                    active={selected === "/departments"}
                    onClick={() => handleItemClick("/departments")}
                    icon={<ApartmentOutlined />}
                  >
                    {t("SIDENAV.DEPARTMENTS")}
                  </MenuItem>
                  <SubMenu
                    onClick={() => handleSubMenuClick("Projects")}
                    open={openSubMenu === "Projects"}
                    label={t("SIDENAV.PROJECTS")}
                    icon={<AccountTreeOutlined />}
                  >
                    <MenuItem
                      active={selected === "/projects"}
                      onClick={() => handleItemClick("/projects")}
                      icon={<Folder />}
                    >
                      {t("SIDENAV.ALL_PROJECTS")}
                    </MenuItem>
                    <Tooltip
                      placement="right"
                      title={t("SIDENAV.PROJECT_TRACKER")}
                    >
                      <Box>
                        <MenuItem
                          active={selected === "/ongoing-projects"}
                          onClick={() => handleItemClick("/ongoing-projects")}
                          icon={<LineAxis />}
                        >
                          {t("SIDENAV.PROJECT_TRACKER")}
                        </MenuItem>
                      </Box>
                    </Tooltip>
                    <Tooltip
                      placement="right"
                      title={t("SIDENAV.PROJECT_INSIGHTS")}
                    >
                      <Box>
                        <MenuItem
                          active={selected === "/projects-employees"}
                          onClick={() => handleItemClick("/projects-employees")}
                          icon={<Topic />}
                        >
                          {t("SIDENAV.PROJECT_INSIGHTS")}
                        </MenuItem>
                      </Box>
                    </Tooltip>

                    <Tooltip
                      placement="right"
                      title={t("SIDENAV.USER_IN_PROJECT")}
                    >
                      <Box>
                        <MenuItem
                          active={selected === "/user-project"}
                          onClick={() => handleItemClick("/user-project")}
                          icon={<PermContactCalendar />}
                        >
                          {t("SIDENAV.USER_IN_PROJECT")}
                        </MenuItem>
                      </Box>
                    </Tooltip>
                  </SubMenu>
                  <SubMenu
                    label={t("SIDENAV.EXPENSES")}
                    icon={<Money />}
                    onClick={() => handleSubMenuClick("Expenses")}
                    open={openSubMenu === "Expenses"}
                  >
                    <MenuItem
                      active={selected === "/expenses"}
                      onClick={() => handleItemClick("/expenses")}
                      icon={<PriceChangeIcon />}
                    >
                      {t("SIDENAV.EXPENSES_BILL")}
                    </MenuItem>
                    <MenuItem
                      active={selected === "/vehicle"}
                      onClick={() => handleItemClick("/vehicle")}
                      icon={<DirectionsCarFilled />}
                    >
                      {t("SIDENAV.VEHICLES")}
                    </MenuItem>
                    <MenuItem
                      active={selected === "/user-expenses"}
                      onClick={() => handleItemClick("/user-expenses")}
                      icon={<HistoryEdu />}
                    >
                      {t("SIDENAV.EXPENSES_USER")}
                    </MenuItem>
                  </SubMenu>

                  <MenuItem
                    active={selected === "/activity"}
                    onClick={() => handleItemClick("/activity")}
                    icon={<TableChartOutlined />}
                  >
                    {t("SIDENAV.ACTIVITY")}
                  </MenuItem>
                  <SubMenu
                    onClick={() => handleSubMenuClick("Timetracker")}
                    open={openSubMenu === "Timetracker"}
                    label={t("SIDENAV.TIME_TRACKER")}
                    icon={<AccessTime />}
                  >
                    <Tooltip
                      placement="right"
                      title={t("SIDENAV.MANAGE_TIME_TRACKERS")}
                    >
                      <Box>
                        <MenuItem
                          active={selected === "/manage-time-trackers"}
                          onClick={() =>
                            handleItemClick("/manage-time-trackers")
                          }
                          icon={<ManageHistory />}
                        >
                          {t("SIDENAV.MANAGE_TIME_TRACKERS")}
                        </MenuItem>
                      </Box>
                    </Tooltip>
                    <Tooltip
                      placement="right"
                      title={t("SIDENAV.VIEW_TIME_TRACKERS")}
                    >
                      <Box>
                        <MenuItem
                          active={selected === "/view-time-trackers"}
                          onClick={() => handleItemClick("/view-time-trackers")}
                          icon={<History />}
                        >
                          {t("SIDENAV.VIEW_TIME_TRACKERS")}
                        </MenuItem>
                      </Box>
                    </Tooltip>
                  </SubMenu>
                  <SubMenu
                    onClick={() => handleSubMenuClick("Timeoff")}
                    open={openSubMenu === "Timeoff"}
                    label={t("SIDENAV.ABSENCE_MANAGEMENT")}
                    icon={<Badge />}
                  >
                    {!isMobile && (
                      <MenuItem
                        active={selected === "/overview-calendar"}
                        onClick={() => handleItemClick("/overview-calendar")}
                        icon={<CalendarMonth />}
                      >
                        Calendar
                      </MenuItem>
                    )}
                    <Tooltip
                      placement="right"
                      title={t("SIDENAV.LEAVE_REQUEST")}
                    >
                      <Box>
                        <MenuItem
                          active={selected === "/leave-requests"}
                          onClick={() => handleItemClick("/leave-requests")}
                          icon={<AirplaneTicket />}
                        >
                          {t("SIDENAV.LEAVE_REQUEST")}
                        </MenuItem>
                      </Box>
                    </Tooltip>
                    <Tooltip
                      placement="right"
                      title={t("SIDENAV.ABSENCE_TODAY")}
                    >
                      <Box>
                        <MenuItem
                          active={selected === "/absents"}
                          onClick={() => handleItemClick("/absents")}
                          icon={<EventBusy />}
                        >
                          {t("SIDENAV.ABSENCE_TODAY")}
                        </MenuItem>
                      </Box>
                    </Tooltip>
                    <Tooltip
                      placement="right"
                      title={t("SIDENAV.NATIONAL_HOLIDAYS")}
                    >
                      <Box>
                        <MenuItem
                          active={selected === "/holidays"}
                          onClick={() => handleItemClick("/holidays")}
                          icon={<Celebration />}
                        >
                          {t("SIDENAV.NATIONAL_HOLIDAYS")}
                        </MenuItem>
                      </Box>
                    </Tooltip>
                  </SubMenu>

                  <MenuItem
                    active={selected === "/equipments"}
                    onClick={() => handleItemClick("/equipments")}
                    icon={<HomeRepairService />}
                  >
                    {t("SIDENAV.EQUIPMENTS")}
                  </MenuItem>
                  {/* <SubMenu
                    onClick={() => handleSubMenuClick("Filemanger")}
                    open={openSubMenu === "Filemanger"}
                    label={t("SIDENAV.FILE_MANAGER")}
                    icon={<Folder />}
                  >
                    <MenuItem
                      active={selected === "/file-manager/upload"}
                      onClick={() => handleItemClick("/file-manager/upload")}
                      icon={<CloudUpload />}
                    >
                      {t("SIDENAV.FILE_UPLOAD")}
                    </MenuItem>
                    <MenuItem
                      active={selected === "/file-manager/download"}
                      onClick={() => handleItemClick("/file-manager/download")}
                      icon={<CloudDownload />}
                    >
                      {t("SIDENAV.FILE_DOWNLOAD")}
                    </MenuItem>
                  </SubMenu> */}
                  <MenuItem
                    active={selected === "/file-manager"}
                    onClick={() => handleItemClick("/file-manager")}
                    icon={<Folder />}
                  >
                    {t("SIDENAV.FILE_MANAGER")}
                  </MenuItem>
                </>
              ) : user?.role === UserRole.EMPLOYEE ? (
                <>
                  <MenuItem
                    active={selected === "/time-tracker"}
                    onClick={() => handleItemClick("/time-tracker")}
                    icon={<MoreTime />}
                  >
                    {t("SIDENAV.TIME_TRACKER")}
                  </MenuItem>
                  <MenuItem
                    active={selected === "/my-work-time"}
                    onClick={() => handleItemClick("/my-work-time")}
                    icon={<Work />}
                  >
                    {t("SIDENAV.MY_WORK_TIME")}
                  </MenuItem>
                  <MenuItem
                    active={selected === "/my-profile"}
                    onClick={() => handleItemClick("/my-profile")}
                    icon={<PersonPin />}
                  >
                    {t("SIDENAV.MY_PROFILE")}{" "}
                  </MenuItem>
                  <MenuItem
                    active={selected === "/leave-request"}
                    onClick={() => handleItemClick("/leave-request")}
                    icon={<AirplaneTicket />}
                  >
                    {t("SIDENAV.LEAVE_REQUEST")}
                  </MenuItem>

                  <MenuItem
                    active={selected === "/user-equipments"}
                    onClick={() => handleItemClick("/user-equipments")}
                    icon={<HomeRepairService />}
                  >
                    {t("SIDENAV.EQUIPMENTS")}
                  </MenuItem>
                  <MenuItem
                    active={selected === "/expenses"}
                    onClick={() => handleItemClick("/expenses")}
                    icon={<PriceChangeIcon />}
                  >
                    {t("SIDENAV.EXPENSES")}
                  </MenuItem>
                  {/* <SubMenu
                    onClick={() => handleSubMenuClick("Filemanger")}
                    open={openSubMenu === "Filemanger"}
                    label={t("SIDENAV.FILE_MANAGER")}
                    icon={<Folder />}
                  >
                    <MenuItem
                      active={selected === "/file-manager/upload"}
                      onClick={() => handleItemClick("/file-manager/upload")}
                      icon={<CloudUpload />}
                    >
                      {t("SIDENAV.FILE_UPLOAD")}
                    </MenuItem>
                    <MenuItem
                      active={selected === "/file-manager/download"}
                      onClick={() => handleItemClick("/file-manager/download")}
                      icon={<CloudDownload />}
                    >
                      {t("SIDENAV.FILE_DOWNLOAD")}
                    </MenuItem>
                  </SubMenu> */}
                  <MenuItem
                    active={selected === "/file-manager"}
                    onClick={() => handleItemClick("/file-manager")}
                    icon={<Folder />}
                  >
                    {t("SIDENAV.FILE_MANAGER")}
                  </MenuItem>
                </>
              ) : null}
              <AlertDialog
                openModal={openLogOutModal}
                setOpenModal={setLogOutModal}
                buttonTitle=""
                handleSubmitDialog={() => {
                  logoutUser(undefined).then(() => {
                    logoutAndClearState(navigation, dispatch);
                  });
                }}
                ButtonComponent={(props: any) => (
                  <MenuItem onClick={handleModalOpen} icon={<LogoutOutlined />}>
                    {t("SIDENAV.LOGOUT")}
                  </MenuItem>
                )}
                mt="20px"
              >
                {t("LOGOUT_MODAL_TEXT")}
              </AlertDialog>
            </Menu>
          </div>
        </div>
      </Sidebar>
    </Box>
  );
};
