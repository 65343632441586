import { useContext } from "react";
import BoxContainer from "../../shared/BoxContainer";
import CustomHeader from "../../shared/custom/CustomHeader";
import CustomNavigationButton from "../../shared/custom/CustomNavigationButton";
import {
  BLUE_COLOR_ACCENT,
  BLUE_HOVER_COLOR_ACCENT,
} from "../../utils/constants/colors";
import useIsMobile from "../../shared/hooks/useIsMobile";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { FieldArray, Formik } from "formik";
import { RootContext } from "../../context/RootContext";
import { Box, Typography } from "@mui/material";
import { Grid } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../core/AppStyles";
import AlertDialog from "../../shared/modal/AlertDialog";
import CustomDatePicker from "../../shared/CustomDatePicker";
import { useSelector } from "react-redux";
import { selectLoggedUser } from "../../data/store/authSlice";
import CustomTextField from "../../shared/custom/CustomTextField";
import { useAddEquipmentMutation } from "../../data/endpoints/app.endpoints";
import {
  equipmentsFieldArrayValidator,
  handleRequiredValidation,
} from "../../utils/regex/formRegex";
import { CircularProgress } from "@mui/material";
import { selectAllEmployees } from "../../data/store/employeeSlice";
import useIsTablet from "../../shared/hooks/useIsTablet";
import { useNavigate } from "react-router";
import CustomSearchSelector from "../../shared/custom/CustomSearchSelector";
import { useTranslation } from "react-i18next";
import useIsDesktop from "../../shared/hooks/useIsDesktop";
import useIsLargeScreen from "../../shared/hooks/useIsLargeScreen";
const AddEquipment = () => {
  const isMobile = useIsMobile();
  const isDesktop = useIsDesktop();
  const isLargeScreen = useIsLargeScreen();
  const user = useSelector(selectLoggedUser);
  const navigate = useNavigate();
  const [addEquipment, result] = useAddEquipmentMutation();
  const {
    setOpenModal,
    showSnackbar,
    state: { openModal },
  } = useContext(RootContext);

  const users = useSelector(selectAllEmployees);
  const { t } = useTranslation();
  const initialValues = {
    selectUserId: "",
    equipments: [
      {
        startDate: "",
        itemName: "",
        serialNumber: "",
        quantity: "",
        location: "",
        userId: "",
      },
    ],
  };
  const validate = (values: typeof initialValues) => {
    const errors = {};
    handleRequiredValidation(
      values,
      errors,
      "selectUserId",
      t("FORM_VALIDATIONS.REQUIRED")
    );

    equipmentsFieldArrayValidator(values, errors, "equipments", {
      fieldsMessage: t("FORM_VALIDATIONS.EQUIPMENTS.FIELDS_REQUIRED"),
      quantitySmallerMessage: t("FORM_VALIDATIONS.EQUIPMENTS.QUANTITY_SMALLER"),
      quantityWholeNumMessage: t(
        "FORM_VALIDATIONS.EQUIPMENTS.QUANTITY_WHOLE_NUM"
      ),
    });
    //
    return errors;
  };
  const onSubmit = () => {
    setOpenModal(true);
  };
  const handleSubmitDialog = (values: typeof initialValues) => {
    const { equipments, selectUserId } = values;
    addEquipment({
      equipments: equipments.map((equipment: Object, index: number) => {
        return { ...equipment, key: index, userId: (selectUserId as any)?.id };
      }),
    }).then((onfulfilled: any) => {
      if (onfulfilled?.data) {
        showSnackbar(
          `${t("EQUIPMENTS.UPDATE_EQUIPMENTS.EQUIPMENT_CREATE")}`,
          false,
          true
        );
        navigate(-1);
      } else if (onfulfilled?.error) {
        showSnackbar(
          `${t("EQUIPMENTS.UPDATE_EQUIPMENTS.EQUIPMENT_CREATE_ERROR")}`,
          true,
          true
        );
      }
    });
    setOpenModal(false);
  };
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isTablet = useIsTablet();

  return (
    <BoxContainer>
      <CustomHeader
        title={t("EQUIPMENTS.ADD_EQUIPMENTS.ADD_EQUIPMENT")}
        subtitle={t("EQUIPMENTS.ADD_EQUIPMENTS.SUBTITLE")}
      >
        <CustomNavigationButton
          customStyle={{
            backgroundColor: BLUE_COLOR_ACCENT,
            width: "10%",
            display: "flex",
            gap: 1,
            alignSelf: "center",
            fontSize: "12px",
            ":hover": {
              bgcolor: BLUE_HOVER_COLOR_ACCENT,
              color: "white",
            },
          }}
          navigateTo={"/equipments"}
        >
          <KeyboardArrowLeftIcon /> {isMobile ? null : t("GO_BACK")}
        </CustomNavigationButton>
      </CustomHeader>
      <Box
        sx={{
          width: "auto",
          height: "auto",
          marginTop: isDesktop || isLargeScreen ? 4 : 0,
        }}
      >
        <Formik
          initialValues={initialValues}
          validate={validate}
          onSubmit={onSubmit}
        >
          {({
            errors,
            values,
            handleChange,
            handleSubmit,
            touched,
            setFieldValue,
          }) => (
            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{
                backgroundColor: colors.component,
                padding: 3,
                paddingTop: 5,
                borderRadius: 3,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  flexDirection: "column",
                  height: "auto",
                }}
              >
                <CustomSearchSelector
                  label={t("EQUIPMENTS.ADD_EQUIPMENTS.EMPLOYEE")}
                  datas={users}
                  onSelectChange={(event: any, value: any) => {
                    setFieldValue("selectUserId", value, true);
                  }}
                  width={isMobile || isTablet ? "100%" : "25%"}
                  value={values.selectUserId}
                />
                {
                  <Typography color="error" variant="body2">
                    {errors && touched && errors?.selectUserId}
                  </Typography>
                }

                <FieldArray
                  name={"equipments"}
                  render={({ remove, push }: any) => (
                    <>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          maxHeight: isMobile ? "auto" : "550px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            borderRadius: 2,
                          }}
                        >
                          <Typography
                            sx={{
                              p: 2,
                              fontWeight: 600,
                              color: colors.grey[200],
                            }}
                            variant="h5"
                          >
                            {t("EQUIPMENTS.ADD_EQUIPMENTS.EQUIPMENTS")}
                          </Typography>
                        </Box>
                        {values.equipments.length > 0 ? (
                          <Box
                            sx={{
                              marginTop: 2,
                              overflowY: isMobile
                                ? "none"
                                : values.equipments.length > 2
                                ? "scroll"
                                : "none",
                              width: "100%",
                              gap: 3,
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between",
                            }}
                          >
                            {values && values.equipments.length > 0
                              ? values.equipments.map(
                                  (equipment: object, index: number) => {
                                    return (
                                      <Box key={index}>
                                        <Grid
                                          container
                                          gap={isTablet ? 1 : 2}
                                          key={index}
                                        >
                                          <Grid item xs={12} sm={2} md={2}>
                                            <CustomDatePicker
                                              views={["day"]}
                                              openTo="day"
                                              onChange={(value) =>
                                                setFieldValue(
                                                  `equipments[${index}].startDate`,
                                                  value
                                                    ? value.format("YYYY-MM-DD")
                                                    : "",
                                                  true
                                                )
                                              }
                                              value={
                                                values.equipments[index]
                                                  ?.startDate
                                              }
                                              error={
                                                !!(
                                                  touched.equipments &&
                                                  touched.equipments[index]
                                                ) &&
                                                !!(
                                                  errors.equipments &&
                                                  errors.equipments[index]
                                                )
                                              }
                                              touched={
                                                !!(
                                                  touched.equipments &&
                                                  touched.equipments[index]
                                                )
                                              }
                                              label={t("START_DATE")}
                                              name={`equipments[${index}].startDate`}
                                            />
                                          </Grid>

                                          <Grid item xs={12} sm={2} md={2}>
                                            <CustomTextField
                                              name={`equipments[${index}].itemName`}
                                              value={
                                                values.equipments[index]
                                                  ?.itemName
                                              }
                                              onChange={handleChange}
                                              placeholder={t(
                                                "EQUIPMENTS.ADD_EQUIPMENTS.INSERT_ITEM_NAME"
                                              )}
                                              errors={
                                                !!(
                                                  touched.equipments &&
                                                  touched.equipments[index]
                                                ) &&
                                                !!(
                                                  errors.equipments &&
                                                  errors.equipments[index]
                                                )
                                              }
                                              touched={
                                                !!(
                                                  touched.equipments &&
                                                  touched.equipments[index]
                                                )
                                              }
                                            />
                                          </Grid>
                                          <Grid item xs={12} sm={2} md={2}>
                                            <CustomTextField
                                              name={`equipments[${index}].serialNumber`}
                                              value={
                                                values.equipments[index]
                                                  ?.serialNumber
                                              }
                                              onChange={handleChange}
                                              placeholder={t(
                                                "EQUIPMENTS.ADD_EQUIPMENTS.INSERT_SERIAL_NUMBER"
                                              )}
                                              errors={
                                                !!(
                                                  touched.equipments &&
                                                  touched.equipments[index]
                                                ) &&
                                                !!(
                                                  errors.equipments &&
                                                  errors.equipments[index]
                                                )
                                              }
                                              touched={
                                                !!(
                                                  touched.equipments &&
                                                  touched.equipments[index]
                                                )
                                              }
                                            />
                                          </Grid>
                                          <Grid item xs={12} sm={2} md={2}>
                                            <CustomTextField
                                              name={`equipments[${index}].quantity`}
                                              value={
                                                values.equipments[index]
                                                  ?.quantity
                                              }
                                              onChange={handleChange}
                                              placeholder={t(
                                                "EQUIPMENTS.ADD_EQUIPMENTS.INSERT_QUANTITY_NUMBER"
                                              )}
                                              errors={
                                                !!(
                                                  touched.equipments &&
                                                  touched.equipments[index]
                                                ) &&
                                                !!(
                                                  errors.equipments &&
                                                  errors.equipments[index]
                                                )
                                              }
                                              touched={
                                                !!(
                                                  touched.equipments &&
                                                  touched.equipments[index]
                                                )
                                              }
                                              type="number"
                                            />
                                          </Grid>
                                          <Grid item xs={10} sm={2} md={2}>
                                            <CustomTextField
                                              name={`equipments[${index}].location`}
                                              value={
                                                values.equipments[index]
                                                  ?.location
                                              }
                                              onChange={handleChange}
                                              placeholder={t(
                                                "EQUIPMENTS.ADD_EQUIPMENTS.INSERT_LOCATION"
                                              )}
                                              errors={
                                                !!(
                                                  touched.equipments &&
                                                  touched.equipments[index]
                                                ) &&
                                                !!(
                                                  errors.equipments &&
                                                  errors.equipments[index]
                                                )
                                              }
                                              touched={
                                                !!(
                                                  touched.equipments &&
                                                  touched.equipments[index]
                                                )
                                              }
                                            />
                                          </Grid>
                                          <Grid
                                            item
                                            xs={1}
                                            sm={1}
                                            md={0.5}
                                            style={{ alignSelf: "center" }}
                                          >
                                            {index > 0 ? (
                                              <button
                                                type="button"
                                                onClick={() => remove(index)}
                                                style={{
                                                  fontSize: "1rem",
                                                  fontWeight: 300,
                                                  color: colors.black,
                                                  backgroundColor: "#f8683c",
                                                  border: "0",
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  padding: "6px 8px",
                                                  borderRadius: 3,
                                                  cursor: "pointer",
                                                }}
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width={isMobile ? "18" : "25"}
                                                  height={
                                                    isMobile ? "18" : "25"
                                                  }
                                                  fill="white"
                                                  className="bi bi-trash-fill"
                                                  viewBox="0 0 16 16"
                                                >
                                                  <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                                                </svg>
                                              </button>
                                            ) : null}
                                          </Grid>
                                        </Grid>
                                        <Typography
                                          color="error"
                                          sx={{
                                            textAlign: "center",
                                            width: "100%",
                                          }}
                                        >
                                          {!!touched?.equipments &&
                                            !!touched?.equipments[index] &&
                                            !!errors?.equipments &&
                                            (errors?.equipments[index] as any)}
                                        </Typography>
                                      </Box>
                                    );
                                  }
                                )
                              : null}
                          </Box>
                        ) : null}
                        <div
                          style={{
                            fontSize: isMobile ? "0.9rem" : "1rem",
                            color: BLUE_COLOR_ACCENT,
                            borderRadius: "5px",
                            marginLeft: "10px",
                            width: "auto",
                            padding: 0,
                            justifyContent: "left",
                            marginTop: "10px",
                          }}
                        >
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              push({
                                startDate: "",
                                itemName: "",
                                serialNumber: "",
                                quantity: "",
                                location: "",
                                user: user,
                                userId: user?.userId,
                              })
                            }
                          >
                            +{t("ADD_MORE")}
                          </span>
                        </div>
                      </Box>
                    </>
                  )}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <AlertDialog
                  openModal={openModal}
                  setOpenModal={setOpenModal}
                  buttonTitle={
                    result?.isLoading ? (
                      <CircularProgress color="inherit" size={25} />
                    ) : (
                      t("SUBMIT")
                    )
                  }
                  width="10%"
                  handleSubmitDialog={() => handleSubmitDialog(values)}
                  color={BLUE_COLOR_ACCENT}
                  colorHover={BLUE_HOVER_COLOR_ACCENT}
                  mt="20px"
                >
                  Are you sure you want to add this equipment?
                </AlertDialog>
              </Box>
            </Box>
          )}
        </Formik>
      </Box>
    </BoxContainer>
  );
};

export default AddEquipment;
