import {
  DialogContent,
  Dialog,
  DialogContentText,
  DialogProps,
  Box,
  useTheme,
  Slide,
  Button,
} from "@mui/material";
import { useState, useRef, useEffect, useContext, forwardRef } from "react";
import CustomButton from "../../shared/custom/CustomButton";
import { Formik } from "formik";
import PasswordTestField from "../../shared/PasswordTestField";
import {
  PASSWORD_REGEX,
  handleRegexValidation,
} from "../../utils/regex/formRegex";
import { useChangeUserPasswordMutation } from "../../data/endpoints/app.endpoints";
import { RootContext } from "../../context/RootContext";
import LockIcon from "@mui/icons-material/Lock";
import {
  BLUE_COLOR_ACCENT,
  BLUE_HOVER_COLOR_ACCENT,
} from "../../utils/constants/colors";
import { useSelector } from "react-redux";
import { selectLoggedUser } from "../../data/store/authSlice";
import { tokens } from "../../core/AppStyles";
import useIsMobile from "../../shared/hooks/useIsMobile";
import { useTranslation } from "react-i18next";
import { TransitionProps } from "@mui/material/transitions";
import { Password } from "@mui/icons-material";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface IuserId {
  id: string | undefined;
  name: string;
}

const ChangePasword = ({ id, name }: IuserId) => {
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState<DialogProps["scroll"]>("paper");
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [visibleConfirmPassword, setVisibleConfirmPassword] = useState(false);
  const togglePassword = () => setVisiblePassword(!visiblePassword);
  const toggleConfirmPassword = () =>
    setVisibleConfirmPassword(!visibleConfirmPassword);
  const [changePassword] = useChangeUserPasswordMutation();
  const { showSnackbar } = useContext(RootContext);
  const loggedUser = useSelector(selectLoggedUser);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const handleClickOpen = (scrollType: DialogProps["scroll"]) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = useRef<HTMLElement>(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  });

  const initialValues = {
    id,
    password: "",
    confirmPassword: "",
    fullName: loggedUser?.firstName + " " + loggedUser?.lastName,
  };
  const onSubmit = ({
    id,
    password,
    confirmPassword,
    fullName,
  }: typeof initialValues) => {
    if (password !== confirmPassword) {
      showSnackbar(
        `${t("USERS_ADMIN.VIEW_USER.PASSWORD_NOT_MATCH")}`,
        true,
        true
      );
    } else {
      changePassword({ id, password, fullName }).then((res: any) => {
        if (res?.data) {
          showSnackbar(
            `${t("USERS_ADMIN.VIEW_USER.PASSWORD_SUCCESS")}`,
            false,
            true
          );
        }
      });
      setOpen(false);
    }
  };

  const validate = (values: typeof initialValues) => {
    const errors: Partial<typeof values> = {};

    handleRegexValidation(
      values,
      errors,
      "password",
      PASSWORD_REGEX,
      t(`FORM_VALIDATIONS.USERS.PASSWORD_ERROR_MESSAGE`),
      t(`FORM_VALIDATIONS.REQUIRED`)
    );
    handleRegexValidation(
      values,
      errors,
      "confirmPassword",
      PASSWORD_REGEX,
      t(`FORM_VALIDATIONS.USERS.PASSWORD_ERROR_MESSAGE`),
      t(`FORM_VALIDATIONS.REQUIRED`)
    );
    if (!values.confirmPassword) {
      errors.confirmPassword = t(`FORM_VALIDATIONS.REQUIRED`);
    } else if (values.confirmPassword !== values.password) {
      errors.confirmPassword = t(`FORM_VALIDATIONS.USERS.PASSWORD_NOT_MATCH`);
    }
    return errors;
  };

  return (
    <>
      <Button
        sx={{
          background: BLUE_COLOR_ACCENT,
          color: "white",
          width: "auto",
          maxWidth: isMobile ? "100%" : "150px",
          height: "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: isMobile ? "flex-start" : "space-between",
          gap: 1,
          ":hover": {
            background: BLUE_HOVER_COLOR_ACCENT,
          },
        }}
        onClick={handleClickOpen("paper")}
      >
        <Password />
        {t("USERS_ADMIN.VIEW_USER.CHANGE_PASSWORD")?.toUpperCase()}
      </Button>

      <Dialog
        TransitionComponent={Transition}
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        PaperProps={{
          style: { background: colors.component, margin: 0, padding: 0 },
        }}
      >
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <Box
              style={{
                width: isMobile ? "70vw" : "100%",
                minHeight: "35vh auto",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <h3
                style={{
                  margin: 0,
                  display: "flex",
                  alignItems: "center",
                  color: colors.invert,
                  fontSize: isMobile ? "12px" : "auto",
                }}
              >
                <LockIcon
                  sx={{
                    marginRight: "0.5vw",
                  }}
                />{" "}
                <span>
                  {t("USERS_ADMIN.VIEW_USER.PASSWORD_TEXT", { name: name })}
                </span>
              </h3>
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <Formik
                  initialValues={initialValues}
                  onSubmit={onSubmit}
                  validate={validate}
                >
                  {({
                    values,
                    handleSubmit,
                    handleChange,
                    touched,
                    errors,
                  }) => (
                    <Box component="form" onSubmit={handleSubmit}>
                      <PasswordTestField
                        label={t("USERS_ADMIN.VIEW_USER.NEW_PASSWORD")}
                        variableName="password"
                        value={values.password}
                        handleChange={handleChange}
                        isVisible={visiblePassword}
                        toggleVisibility={togglePassword}
                        hasMargin={true}
                        error={errors.password}
                        touched={touched.password}
                      />
                      <PasswordTestField
                        label={t("USERS_ADMIN.VIEW_USER.CONFIRM_NEW_PASSWORD")}
                        variableName="confirmPassword"
                        value={values.confirmPassword}
                        handleChange={handleChange}
                        isVisible={visibleConfirmPassword}
                        toggleVisibility={toggleConfirmPassword}
                        error={errors.confirmPassword}
                        hasMargin={true}
                        touched={touched.confirmPassword}
                      />
                      <CustomButton
                        type="submit"
                        title={t("USERS_ADMIN.VIEW_USER.CONFIRM_CHANGE")}
                        width="100%"
                        margin="4vh 0 0 0"
                        color={BLUE_COLOR_ACCENT}
                        colorHover={BLUE_HOVER_COLOR_ACCENT}
                      />
                    </Box>
                  )}
                </Formik>
              </Box>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ChangePasword;
