import { Box, Drawer, useTheme } from "@mui/material";
import ChatHeader from "./ChatHeader";
import ChatMessagesBox from "./ChatMessagesBox";
import ChatBoxSentMessage from "./ChatBoxSentMessage";
import { useState } from "react";
import ChatedWithHomePage from "./ChatedWithHomePage";
import { tokens } from "../../../../../core/AppStyles";

const KinetonChatMobile = ({ collapsed, setCollapsed }: any) => {
  const [chatType, setchatType] = useState<boolean>(false);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const decideChatType = (value: boolean) => {
    setchatType(value);
  };

  const toggleDrawer = (open: boolean) => (event: MouseEvent) => {
    setCollapsed({ ...collapsed, bottom: open });
  };

  return (
    <Drawer
      sx={{ zIndex: 1999 }}
      PaperProps={{
        style: { borderRadius: "15px 15px 0 0", backgroundColor: "#535ac8" },
      }}
      anchor="bottom"
      open={collapsed.bottom}
      onClose={toggleDrawer(false)}
    >
      <Box
        sx={{
          width: "100%",
          height: "60vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          overflow: "hidden",

          background: colors.primary[400],
        }}
      >
        <ChatHeader decideChatType={decideChatType} />
        {!chatType ? <ChatMessagesBox /> : <ChatedWithHomePage />}
        {!chatType ? <ChatBoxSentMessage /> : ""}
      </Box>
    </Drawer>
  );
};

export default KinetonChatMobile;
