import { useContext } from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useUpdateVehicleMutation } from "../../data/endpoints/app.endpoints";
import CustomHeader from "../../shared/custom/CustomHeader";
import CustomNavigationButton from "../../shared/custom/CustomNavigationButton";
import { BLUE_COLOR_ACCENT } from "../../utils/constants/colors";
import VehicleFormFields from "./VehicleFormFields";
import { Formik } from "formik";
import { RootContext } from "../../context/RootContext";
import BoxContainer from "../../shared/BoxContainer";
import useIsMobile from "../../shared/hooks/useIsMobile";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { selectVehicleById } from "../../data/store/vehicleSlice";
import {
  handleIntervalDateValidation,
  handleRequiredValidation,
} from "../../utils/regex/formRegex";
import CustomDatePicker from "../../shared/CustomDatePicker";

export const UpdateVehicle = () => {
  const { id } = useParams();

  const [updateVehicle, result] = useUpdateVehicleMutation();

  const isMobile = useIsMobile();

  const selectedVehicle = useSelector(selectVehicleById(id));

  const { t } = useTranslation();

  const { setOpenModal } = useContext(RootContext);

  const initialValues = {
    // id
    model: selectedVehicle?.model ?? "",
    plate: selectedVehicle?.plate ?? "",
    kilometers: selectedVehicle?.kilometers ?? "",
    dateInUse: selectedVehicle?.kilometers ?? "",
    outOfService: selectedVehicle?.outOfService ?? "",
  };

  const onSubmitForm = () => {
    setOpenModal(true);
  };

  const validate = (values: typeof initialValues) => {
    const errors = {} as any;

    handleRequiredValidation(
      values,
      errors,
      "model",
      t("FORM_VALIDATIONS.REQUIRED")
    );
    handleRequiredValidation(
      values,
      errors,
      "plate",
      t("FORM_VALIDATIONS.REQUIRED")
    );
    handleRequiredValidation(
      values,
      errors,
      "dateInUse",
      t("FORM_VALIDATIONS.REQUIRED")
    );
    handleRequiredValidation(
      values,
      errors,
      "kilometers",
      t("FORM_VALIDATIONS.REQUIRED")
    );

    if (values?.outOfService) {
      handleIntervalDateValidation(
        errors,
        "outOfService",
        {
          startDate: values?.dateInUse,
          endDate: values?.outOfService,
        },
        t("FORM_VALIDATIONS.DATE_VALIDATION.END_DATE_GREATER")
      );
    }

    return errors;
  };

  return (
    <BoxContainer>
      <CustomHeader
        title={t("VEHICLE.UPDATE_VEHICLE.TITLE")}
        subtitle={t("VEHICLE.UPDATE_VEHICLE.SUBTITLE")}
      >
        <CustomNavigationButton
          customStyle={{
            backgroundColor: BLUE_COLOR_ACCENT,
            width: "10%",
            display: "flex",
            gap: 1,
            alignSelf: "center",
            fontSize: "12px",
            ":hover": {
              bgcolor: "#1d61c6",
              color: "white",
            },
          }}
          navigateTo={-1}
        >
          <KeyboardArrowLeftIcon /> {isMobile ? null : t("GO_BACK")}
        </CustomNavigationButton>
      </CustomHeader>

      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={onSubmitForm}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
          resetForm,
        }: any) => (
          <VehicleFormFields
            buttonTitle={t("SUBMIT")}
            errors={errors}
            id={selectedVehicle?.vehicleId}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            setFieldValue={setFieldValue}
            handleMutation={updateVehicle}
            touched={touched}
            values={values}
            resetForm={resetForm}
            submitIsLoading={result?.isLoading}
          >
            <Grid item xs={12} sx={{ gridColumn: "span 4" }}>
              <CustomDatePicker
                onChange={(value: any) =>
                  setFieldValue(
                    "outOfService",
                    value ? value.format("YYYY-MM-DD") : "",
                    true
                  )
                }
                fullWidth
                value={values?.outOfService}
                error={errors?.outOfService}
                touched={!!touched?.outOfService}
                label={`${t("VEHICLE.FORMS.OUT_OF_SERVICE")} (Optional)`}
                name="outOfService"
                views={["day"]}
                openTo="day"
              />
            </Grid>
          </VehicleFormFields>
        )}
      </Formik>
    </BoxContainer>
  );
};

export default UpdateVehicle;
