import { Box, useTheme } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import PieChartAdmin from "./PieChart";
import { useSelector } from "react-redux";
import { useLazyGetUserProjectsQuery } from "../../../../data/endpoints/app.endpoints";
import CustomSearchSelector from "../../../../shared/custom/CustomSearchSelector";
import { tokens } from "../../../../core/AppStyles";
import useIsMobile from "../../../../shared/hooks/useIsMobile";
import { selectAllEmployees } from "../../../../data/store/employeeSlice";
import CustomHeader from "../../../../shared/custom/CustomHeader";
import moment from "moment";
import CustomDatePicker from "../../../../shared/CustomDatePicker";
import CustomHeaderMobile from "../../../../shared/custom/CustomHeaderMobile";
import useIsTablet from "../../../../shared/hooks/useIsTablet";
import { handleIntervalDateValidation } from "../../../../utils/regex/formRegex";
import { useTranslation } from "react-i18next";

const UserProjects = () => {
  const allUserResult = useSelector(selectAllEmployees);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const [errors, setErrors] = useState<any>({});
  const [intervalStartTime, setIntervalStartTime] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [intervalEndTime, setIntervalEndTime] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );

  const { user } = useSelector((state: any) => state.auth);
  const { t } = useTranslation();
  const userid = user.userId;

  const UsersWithoutLogedInUser = allUserResult?.filter(
    (user: any) => user.userId !== userid
  );
  const [selectedUser, setSelectedUser] = useState<any>("");

  const handleSelectedUser = (event: any, newValue: any) => {
    setSelectedUser(newValue);
  };

  const [getUserProjectHours, getUserProjectHoursResult] =
    useLazyGetUserProjectsQuery();

  const validateDate = useCallback(() => {
    const error = {};
    handleIntervalDateValidation(
      error,
      "endDate",
      {
        startDate: intervalStartTime,
        endDate: intervalEndTime,
      },
      t("FORM_VALIDATIONS.DATE_VALIDATION.END_DATE_GREATER")
    );
    setErrors(error);
    return Object.keys(errors).length === 0;
  }, [intervalStartTime, intervalEndTime]);

  useEffect(() => {
    if (selectedUser && validateDate()) {
      getUserProjectHours({
        userId: selectedUser?.id,
        startDate: intervalStartTime,
        endDate: intervalEndTime,
      });
    }
  }, [selectedUser, intervalStartTime, intervalEndTime]);

  const data2 = getUserProjectHoursResult?.data;

  const graphData = data2?.projectsWorkedByUser.map((proj: any) => {
    return {
      name: proj?.projectTitle,
      value: proj?.workedOnProjetcs?.totalTimeWorked?.hours,
    };
  });

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      {isMobile || isTablet ? (
        <CustomHeaderMobile
          title={t("USERS_ADMIN.PROJECTS.EMPLOYEES_PROJECTS.TITLE")}
          subtitle={t("USERS_ADMIN.PROJECTS.EMPLOYEES_PROJECTS.SUBTITLE")}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "90px",
              marginTop: "2vh",
              justifyContent: "space-between",
            }}
          >
            <CustomDatePicker
              onChange={(value) => {
                setIntervalStartTime(value ? value.format("YYYY-MM-DD") : "");
              }}
              value={intervalStartTime}
              label={t("START_DATE")}
              views={["day"]}
              openTo="day"
            />

            <CustomDatePicker
              onChange={(value) => {
                setIntervalEndTime(value ? value.format("YYYY-MM-DD") : "");
              }}
              value={intervalEndTime}
              label={t("END_DATE")}
              views={["day"]}
              openTo="day"
            />
          </Box>
        </CustomHeaderMobile>
      ) : (
        <CustomHeader
          title={t("USERS_ADMIN.PROJECTS.EMPLOYEES_PROJECTS.TITLE")}
          subtitle={t("USERS_ADMIN.PROJECTS.EMPLOYEES_PROJECTS.SUBTITLE")}
        >
          <Box
            sx={{
              width: "40%",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <CustomDatePicker
              onChange={(value) => {
                setIntervalStartTime(value ? value.format("YYYY-MM-DD") : "");
              }}
              value={intervalStartTime}
              label={t("START_DATE")}
              views={["day"]}
              openTo="day"
            />

            <CustomDatePicker
              onChange={(value) => {
                setIntervalEndTime(value ? value.format("YYYY-MM-DD") : "");
              }}
              value={intervalEndTime}
              label={t("END_DATE")}
              views={["day"]}
              openTo="day"
            />
          </Box>
        </CustomHeader>
      )}
      <Box
        sx={{
          width: "100%",
          height: `${isMobile ? "90%" : "75vh"}`,
          padding: "2vh 1vw",
          display: "flex",
          flexDirection: `${isMobile ? "column" : "row"}`,
          justifyContent: "space-around",
          backgroundColor: isMobile ? "transparent" : colors.component,
          borderRadius: "10px",
          border: isMobile ? "none" : "1px solid lightgray",
          marginTop: "2vh",
        }}
      >
        <Box
          sx={{
            width: `${isMobile ? "100%" : "30%"}`,
            height: `${isMobile ? "60%" : "80%"}`,
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
          }}
        >
          <Box
            style={{
              width: "100%",
              height: isMobile ? "11vh" : "7vh",
              display: "flex",
              alignItems: "center",
            }}
          >
            <CustomSearchSelector
              label={t("USERS_ADMIN.PROJECTS.EMPLOYEES_PROJECTS.SEARCH_USER")}
              width={420}
              value={selectedUser?.firstName}
              onSelectChange={handleSelectedUser}
              datas={UsersWithoutLogedInUser}
            />
          </Box>
          <Box
            sx={{
              width: "100%",
              height: `${isMobile ? "7vh" : "5vh"}`,
              marginTop: "1vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              background: colors.blueAccent[800],
              borderRadius: "5px",
              color: "white",
            }}
          >
            <b>No.</b>
            <b>{t("USERS_ADMIN.PROJECTS.PROJECT_INSIGHTS.PROJECT_NAME")}</b>
            <b>{t("USERS_ADMIN.PROJECTS.EMPLOYEES_PROJECTS.WORKED")}</b>
          </Box>
          <Box
            sx={{
              width: "100%",
              height: `${isMobile ? "100%" : "80%"}`,
              marginTop: "1vh",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              overflowY: "scroll",
            }}
          >
            {graphData?.map((proj: any, index: number) => (
              <Box
                style={{
                  width: "100%",
                  height: "5vh",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  marginTop: "1vh",
                  background: colors.cardHover,
                  color: colors.black,
                  borderRadius: "5px",
                }}
                key={index}
              >
                <p
                  style={{
                    width: "10%",
                    textAlign: "center",
                  }}
                >
                  {index + 1}
                </p>
                <p
                  style={{
                    width: "60%",
                    textAlign: "center",
                  }}
                >
                  {proj?.name}
                </p>
                <p
                  style={{
                    width: "28%",
                    textAlign: "center",
                  }}
                >
                  {proj?.value + " h"}
                </p>
              </Box>
            ))}
          </Box>
        </Box>
        <Box
          sx={{
            width: `${isMobile ? "100%" : "60%"}`,
            height: `${isMobile ? "40%" : "75vh"}`,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {graphData?.length > 0 ? (
            <PieChartAdmin
              data={graphData}
              width={isMobile ? 200 : 300}
              height={isMobile ? 200 : 300}
              outerRadius={isMobile ? 70 : 150}
            />
          ) : (
            <b>
              {errors ? (
                <p
                  style={{
                    margin: 0,
                    padding: 0,
                    color: "red",
                  }}
                >
                  {errors?.endDate}
                </p>
              ) : (
                `${t(
                  "USERS_ADMIN.PROJECTS.EMPLOYEES_PROJECTS.EMPLOYEES_PROJECTS.NO_DATA"
                )}`
              )}
            </b>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default UserProjects;
