import darkLogo from "../assets/jv-elektro-dark.png";
import whiteLogo from "../assets/jv-elektro-light.png";
import JVElektroDark from "../assets/jv-elektro-dark-loading.png";
import JVElektroLight from "../assets/jv-elektro-light-loading.png";
import { GRAY_COLOR_PRIMARY } from "../utils/constants/colors";

export const tokens = (mode: any) => ({
  ...(mode === "dark"
    ? {
      kinetonImage: {
        logo: darkLogo,
        dashboardLogo: JVElektroDark,
      },
      white: "#151632",
      pageNotFound: GRAY_COLOR_PRIMARY,
      invert: "#ffffff",
      card: "#2e3d4b",
      cardHover: "#314554",
      component: "#1d2d44",
      shadow: "rgba(0, 0, 0, 0.08)",
      hover: "#ffffff",
      sideNav: "#748cab",
      black: "#ffffff",
      timeline: "#404258",
      log: "#c2c2c2",
      redBackground: "",
      greenBackground: "rgba(99,173,111,.1)",
      orangeBackground: "rgba(249,194,86,.1)",
      holiday: "#832f2c",
      overWorked: "#6870fa",
      weekend: "#3da58a",
      filepond: "#1d2d44",
      error: "#d53636",
      holidayCalendar: "rgba(109, 88, 71, 0.7)",
      oddCalendarRow: "#20334d",
      weekendCalendar: "rgba(64, 66, 88, 0.7)",
      grey: {
        100: "#e0e0e0",
        200: "#c2c2c2",
        300: "#a3a3a3",
        400: "#858585",
        500: "#666666",
        600: "#525252",
        700: "#3d3d3d",
        800: "#292929",
        900: "#141414",
        search: "#292929",
      },
      primary: {
        100: "#d0d1d5",
        200: "#a1a4ab",
        300: "#727681",
        400: "#1F2A40",
        500: "#404258",
        600: "#101624",
        700: "#0c101b",
        800: "#080b12",
        900: "#040509",
      },
      greenAccent: {
        100: "#dbf5ee",
        200: "#b7ebde",
        300: "#94e2cd",
        400: "#70d8bd",
        500: "#4cceac",
        600: "#3da58a",
        700: "#1f978b",
        800: "#1e5245",
        900: "#0f2922",
      },
      redAccent: {
        100: "#f8dcdb",
        200: "#f1b9b7",
        300: "#e99592",
        400: "#e2726e",
        500: "#db4f4a",
        600: "#af3f3b",
        700: "#832f2c",
        800: "#58201e",
        900: "#2c100f",
      },
      blueAccent: {
        100: "#e1e2fe",
        200: "#c3c6fd",
        300: "#a4a9fc",
        400: "#007ffc",
        500: "#6870fa",
        600: "#535ac8",
        700: "#3e4396",
        800: "#26577C",
        900: "#151632",
      },
    }
    : {
      kinetonImage: {
        logo: whiteLogo,
        dashboardLogo: JVElektroLight,
      },
      white: "#FFFFFF",
      pageNotFound: "#727681",
      card: "#FFFFFF",
      cardHover: "#3e5c76",
      shadow: "rgba(0, 0, 0, 0.08)",
      component: "#ffffff",
      sideNav: "#b7dbff80",
      black: "#ffffff",
      invert: "#000000",
      timeline: "#e0e0e0",
      log: "#505050",
      redBackground: "#fff2f2",
      greenBackground: "#e7faf8",
      orangeBackground: "#fffaf7",
      holiday: "#ffe5e3",
      overWorked: "#e1e2fe",
      weekend: "#b7ebde",
      filepond: "#e8e8e8",
      error: "#d53636",
      holidayCalendar: "rgba(255, 228, 201,0.7)",
      oddCalendarRow: "rgba(220,220,192,0.3)",
      weekendCalendar: "rgba(224, 224, 224, 0.7)",

      grey: {
        100: "#141414",
        200: "#292929",
        300: "#3d3d3d",
        400: "#525252",
        500: "#666666",
        600: "#858585",
        700: "#a3a3a3",
        800: "#c2c2c2",
        900: "#e0e0e0",
        search: "#f2f0f0",
      },
      subtitle: {
        sp: "#2ca6ff",
      },
      primary: {
        100: "#040509",
        200: "#080b12",
        300: "#0c101b",
        400: "#f2f0f0", // manually changed
        500: "##F5F5F9",
        600: "#1F2A40",
        700: "#727681",
        800: "#a1a4ab",
        900: "#d0d1d5",
      },
      greenAccent: {
        100: "#0f2922",
        200: "#1e5245",
        300: "#2e7c67",
        400: "#3da58a",
        500: "#4cceac",
        600: "#70d8bd",
        700: "#1f978b",
        800: "#b7ebde",
        900: "#dbf5ee",
      },
      redAccent: {
        100: "#2c100f",
        200: "#58201e",
        300: "#832f2c",
        400: "#af3f3b",
        500: "#db4f4a",
        600: "#e2726e",
        700: "#e99592",
        800: "#f1b9b7",
        900: "#f8dcdb",
      },
      blueAccent: {
        100: "#151632",
        200: "#2a2d64",
        300: "#3e4396",
        400: "#007ffc",
        500: "#6870fa",
        600: "#868dfb",
        700: "#a4a9fc",
        800: "#26577C",
        900: "#e1e2fe",
      },
    }),
});

// mui theme settings
export const themeSettings = (mode: any) => {
  const colors = tokens(mode);
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
          // palette values for dark mode
          primary: {
            main: colors.primary[500],
          },
          secondary: {
            main: colors.blueAccent[400],
          },
          neutral: {
            dark: colors.grey[700],
            main: colors.grey[500],
            light: colors.grey[100],
          },
          background: {
            default: "#0d1321",
          },
        }
        : {
          // palette values for light mode
          primary: {
            main: colors.primary[100],
          },
          secondary: {
            main: colors.blueAccent[400],
          },
          neutral: {
            dark: colors.grey[700],
            main: colors.grey[500],
            light: colors.grey[100],
          },
          background: {
            default: "#f7f7f7",
          },
        }),
    },
    typography: {
      fontFamily: ["Roboto", "sans-serif"].join(","),
      fontSize: 12,
      h1: {
        fontFamily: ["Roboto", "sans-serif"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["Roboto", "sans-serif"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["Roboto", "sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["Roboto", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Roboto", "sans-serif"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["Roboto", "sans-serif"].join(","),
        fontSize: 14,
      },
    },
  };
};
