import { useContext } from "react";
import CustomHeader from "../../shared/custom/CustomHeader";
import { Box, MenuItem, TextField } from "@mui/material";
import CustomNavigationButton from "../../shared/custom/CustomNavigationButton";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import {
  BLUE_COLOR_ACCENT,
  BLUE_HOVER_COLOR_ACCENT,
} from "../../utils/constants/colors";
import BoxContainer from "../../shared/BoxContainer";
import useIsMobile from "../../shared/hooks/useIsMobile";
import { useMediaQuery } from "@mui/material";
import { RootContext } from "../../context/RootContext";
import { useUpdateLeaveRequestMutation } from "../../data/endpoints/app.endpoints";
import { useSelector } from "react-redux";
import { selectLeaveById } from "../../data/store/leaveRequestSlice";
import { useNavigate, useParams } from "react-router";
import { Formik } from "formik";
import { Grid } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../core/AppStyles";
import AlertDialog from "../../shared/modal/AlertDialog";
import CustomDatePicker from "../../shared/CustomDatePicker";
import CustomSelector from "../../shared/custom/CustomSelector";
import { OutOfOffice } from "../../utils/enums/out-of-office.enum";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  handleIntervalDateValidation,
  handleRequiredValidation,
} from "../../utils/regex/formRegex";
import { LeaveDays } from "../../utils/enums/leave.enum";
import useIsDesktop from "../../shared/hooks/useIsDesktop";
import useIsLargeScreen from "../../shared/hooks/useIsLargeScreen";

const UpdateLeaveRequest = ({ children }: any) => {
  const isMobile = useIsMobile();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const isDesktop = useIsDesktop();
  const isLargeScreen = useIsLargeScreen();
  const { id } = useParams();

  const {
    setOpenModal,
    showSnackbar,
    state: { openModal },
  } = useContext(RootContext);
  const [updateLeaveRequest] = useUpdateLeaveRequestMutation();
  const leave = useSelector(selectLeaveById(id));
  const initialValues = {
    type: leave?.type,
    startDate: leave?.startDate,
    endDate: leave?.endDate,
    description: leave?.description,
    interval: leave?.interval,
  };

  const navigate = useNavigate();
  const { t } = useTranslation();
  const validate = (values: typeof initialValues) => {
    const errors = {};
    handleRequiredValidation(
      values,
      errors,
      "startDate",
      t("FORM_VALIDATIONS.REQUIRED")
    );

    if (leave?.startDate !== leave?.endDate) {
      handleRequiredValidation(
        values,
        errors,
        "endDate",
        t("FORM_VALIDATIONS.REQUIRED")
      );
    }
    if (values?.interval === "Many Days") {
      handleIntervalDateValidation(
        errors,
        "endDate",
        {
          startDate: values?.startDate,
          endDate: values?.endDate,
        },
        "End date cannot be smaller then start date"
      );
    }

    return errors;
  };
  const onSubmitForm = () => {
    setOpenModal(true);
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const handleSubmitDialog = (values: typeof initialValues) => () => {
    let valueToUpdate = {};
    if (values?.interval === LeaveDays.MANY_DAYS) {
      valueToUpdate = {
        type: values?.type,
        startDate: values?.startDate,
        endDate: values?.endDate,
        description: values?.description,
        userId: leave?.user.userId,
        leaveId: leave?.leaveId,
        interval: leave?.interval,
      };
    } else {
      valueToUpdate = {
        type: values?.type,
        startDate: values?.startDate,
        endDate: values?.startDate,
        description: values?.description,
        userId: leave?.user.userId,
        leaveId: leave?.leaveId,
        interval: leave?.interval,
      };
    }
    updateLeaveRequest(valueToUpdate).then((res: any) => {
      if (!res.error) {
        showSnackbar(
          `${t("LEAVE_REQUESTS.UPDATE_LEAVE_REQUEST.RESPONSE.SUCCESS")}`,
          false,
          true
        );
        navigate(-1);
      } else if (res.error) {
        showSnackbar(res.error.data.error.message, true, true);
      }
    });
    setOpenModal(false);
  };

  return (
    <BoxContainer>
      <CustomHeader
        title={t("LEAVE_REQUESTS.UPDATE_LEAVE_REQUEST.TITLE")}
        subtitle={t("LEAVE_REQUESTS.UPDATE_LEAVE_REQUEST.SUBTITLE")}
      >
        <CustomNavigationButton
          customStyle={{
            backgroundColor: BLUE_COLOR_ACCENT,
            width: "10%",
            display: "flex",
            gap: 1,
            alignSelf: "center",
            fontSize: "12px",
            ":hover": {
              bgcolor: BLUE_HOVER_COLOR_ACCENT,
              color: "white",
            },
          }}
          navigateTo={-1}
        >
          <KeyboardArrowLeftIcon /> {isMobile ? null : `${t("GO_BACK")}`}
        </CustomNavigationButton>
      </CustomHeader>
      <Box
        sx={{
          width: "auto",
          height: "auto",
          marginTop: isDesktop || isLargeScreen ? 4 : 0,
          backgroundColor: colors.component,
          padding: 3,
          paddingTop: 5,
          borderRadius: 3,
        }}
      >
        <Formik
          initialValues={initialValues}
          validate={validate}
          onSubmit={onSubmitForm}
        >
          {({
            errors,
            values,
            handleChange,
            handleSubmit,
            touched,
            setFieldValue,
          }) => (
            <Box component="form" onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                fontSize="16px"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
                <Grid
                  item
                  xs={12}
                  sx={{
                    gridColumn:
                      leave?.startDate === leave?.endDate ? "span 4" : "span 2",
                  }}
                >
                  <CustomDatePicker
                    onChange={(value) => {
                      const dateSelected = value
                        ? value.format("YYYY-MM-DD")
                        : "";
                      setFieldValue("startDate", dateSelected, true);
                    }}
                    value={moment(values?.startDate) as any}
                    error={errors?.startDate}
                    label={t("START_DATE")}
                    name="startDate"
                    touched={!!touched?.startDate}
                    views={["day"]}
                    openTo="day"
                  />
                </Grid>
                {leave?.startDate !== leave?.endDate && (
                  <Grid item xs={12} sx={{ gridColumn: "span 2" }}>
                    <CustomDatePicker
                      onChange={(value: any) => {
                        const dateSelected = value
                          ? value.format("YYYY-MM-DD")
                          : "";
                        setFieldValue("endDate", dateSelected, true);
                      }}
                      value={moment(values?.endDate) as any}
                      error={errors?.endDate}
                      label={t("END_DATE")}
                      name="endDate"
                      touched={!!touched?.endDate}
                      views={["day"]}
                      openTo="day"
                    />
                  </Grid>
                )}

                <Grid item xs={12} sx={{ gridColumn: "span 4" }}>
                  <CustomSelector
                    label={t("LEAVE_REQUESTS.UPDATE_LEAVE_REQUEST.REASON")}
                    name="type"
                    value={values?.type}
                    onSelectChange={handleChange}
                    size="medium"
                    width="100%"
                    errors={errors?.type}
                    touched={!!touched?.type}
                  >
                    {Object?.values(
                      values?.interval === LeaveDays.HALF_DAY
                        ? {
                            VACATION: OutOfOffice.VACATION,
                            COMPENSATION: OutOfOffice.COMPENSATION,
                          }
                        : OutOfOffice
                    ).map((value, index: number) => (
                      <MenuItem key={index} value={value}>
                        {t(
                          `LEAVE_REQUESTS.OUT_OF_OFFICE.${value
                            ?.replace(" ", "_")
                            .replace("/", "_")
                            .toUpperCase()}`
                        )}
                      </MenuItem>
                    ))}
                  </CustomSelector>
                </Grid>
                <Grid item xs={12} sx={{ gridColumn: "span 4" }}>
                  <TextField
                    variant="outlined"
                    placeholder={t(
                      "LEAVE_REQUESTS.UPDATE_LEAVE_REQUEST.INSERT_DESCRIPTION"
                    )}
                    multiline
                    inputProps={{ style: { fontSize: "16px" } }}
                    fullWidth
                    size="small"
                    rows={3}
                    value={values.description}
                    name="description"
                    onChange={handleChange}
                    color="info"
                  />
                </Grid>
              </Box>

              <AlertDialog
                openModal={openModal}
                setOpenModal={setOpenModal}
                buttonTitle={t("SUBMIT")}
                width="10%"
                handleSubmitDialog={handleSubmitDialog(values)}
                color={BLUE_COLOR_ACCENT}
                colorHover={BLUE_HOVER_COLOR_ACCENT}
                mt="20px"
              >
                {t("LEAVE_REQUESTS.UPDATE_LEAVE_REQUEST.MODAL_TEXT")}
              </AlertDialog>
            </Box>
          )}
        </Formik>
      </Box>
    </BoxContainer>
  );
};

export default UpdateLeaveRequest;
