import { useContext } from "react";
import CustomNavigationButton from "../../shared/custom/CustomNavigationButton";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import {
  BLUE_COLOR_ACCENT,
  BLUE_HOVER_COLOR_ACCENT,
} from "../../utils/constants/colors";
import CustomHeader from "../../shared/custom/CustomHeader";
import ProjectFormFields from "./ProjectFormField";
import { useSelector } from "react-redux";
import { handleRequiredValidation, timeIntervalEndTimeSmaller } from "../../utils/regex/formRegex";
import { selectDepartments } from "../../data/store/departmentSlice";
import { Formik } from "formik";
import { RootContext } from "../../context/RootContext";
import { useCreateProjectMutation } from "../../data/endpoints/app.endpoints";
import BoxContainer from "../../shared/BoxContainer";
import useIsMobile from "../../shared/hooks/useIsMobile";
import { useTranslation } from "react-i18next";
import useIsDesktop from "../../shared/hooks/useIsDesktop";
import useIsLargeScreen from "../../shared/hooks/useIsLargeScreen";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../core/AppStyles";

const AddProject = () => {
  const [createProject, result] = useCreateProjectMutation();
  const isMobile = useIsMobile();
  const isDesktop = useIsDesktop();
  const isLargeScreen = useIsLargeScreen();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { setOpenModal } = useContext(RootContext);
  const { t } = useTranslation();
  const existingDepartments = useSelector(selectDepartments);
  const initialValues = {
    company: "",
    title: "",
    description: "",
    departments: [],
    anticipatedDuration: "",
    startTime: "",
    endTime: "",
  };

  const onSubmitForm = () => {
    setOpenModal(true);
  };

  const validate = (values: typeof initialValues) => {
    const errors: Partial<typeof values> = {};
    handleRequiredValidation(values, errors, "company", t("FORM_VALIDATIONS.REQUIRED"));
    handleRequiredValidation(values, errors, "title", t("FORM_VALIDATIONS.REQUIRED"));

    if (values?.endTime && values?.startTime) {
      timeIntervalEndTimeSmaller(
        errors,
        "endTime",
        {
          startTime: values.startTime,
          endTime: values.endTime,
        },
        {
          startTimeNotValid: t(
            "FORM_VALIDATIONS.TIME_TRACKER_VALIDATIONS.NOT_VALID.START_DATE"
          ),
          endTimeNotValid: t(
            "FORM_VALIDATIONS.TIME_TRACKER_VALIDATIONS.NOT_VALID.END_DATE"
          ),
        },
        t("FORM_VALIDATIONS.END_DATE_SMALLER_OR_EQUAL")
      );
    }

    return errors;
  };
  return (
    <BoxContainer>
      <CustomHeader
        title={t("USERS_ADMIN.PROJECTS.CREATE_EDIT_PROJECT.CREATE_PROJECT")}
        subtitle={t(
          "USERS_ADMIN.PROJECTS.CREATE_EDIT_PROJECT.CREATE_NEW_PROJECT"
        )}
      >
        <CustomNavigationButton
          customStyle={{
            backgroundColor: BLUE_COLOR_ACCENT,
            width: "10%",
            display: "flex",
            gap: 1,
            alignSelf: "center",
            fontSize: "12px",
            ":hover": {
              bgcolor: BLUE_HOVER_COLOR_ACCENT,
              color: "white",
            },
          }}
          navigateTo={"/projects"}
        >
          <KeyboardArrowLeftIcon /> {isMobile ? null : t("GO_BACK")}
        </CustomNavigationButton>
      </CustomHeader>
      <Box
        sx={{
          width: "auto",
          height: "auto",
          marginTop: isDesktop || isLargeScreen ? 3 : 0,
          backgroundColor: colors.component,
          padding: 3,
          paddingTop: 5,
          borderRadius: 3,
        }}
      >
        <Formik
          initialValues={initialValues}
          validate={validate}
          onSubmit={onSubmitForm}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            setFieldValue,
            resetForm,
          }: any) => (
            <ProjectFormFields
              buttonTitle={t("USERS_ADMIN.PROJECTS.CREATE_PROJECT")}
              errors={errors}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              setFieldValue={setFieldValue}
              touched={touched}
              values={values}
              existingDepartments={existingDepartments}
              handleMutation={createProject}
              resetForm={resetForm}
              submitIsLoading={result?.isLoading}
            />
          )}
        </Formik>
      </Box>
    </BoxContainer>
  );
};

export default AddProject;
