/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography } from "@mui/material";
import CustomHeader from "../../shared/custom/CustomHeader";
import { useEffect, useMemo } from "react";
import {
  useLazyGetHolidaysQuery,
  useLazyGetMultipleTimeTrackersByIntervalUserQuery,
  useLazyGetTimeTrackersQuery,
} from "../../data/endpoints/app.endpoints";
import moment from "moment";
import { useSelector } from "react-redux";
import { selectLoggedUser } from "../../data/store/authSlice";
import CustomTable from "../../shared/custom/CustomTable";
import ActivityTableRow from "../activity/ActivityTableRow";
import { useState } from "react";
import ViewTimeTrackersTableRowUser from "../time-tracker/ViewTimeTrackersTableRowUser";
import { CSVLink } from "react-csv";
import useIsMobile from "../../shared/hooks/useIsMobile";
import useIsTablet from "../../shared/hooks/useIsTablet";
import { useTranslation } from "react-i18next";
import UserPersonalActivityTableMobile from "./UserPersonalActvityTableMobile";
import UserPersonalCompletedTTtableMobile from "./UserPersonalCompletedTTtableMobile";
import ManageTimeTrackersModal from "../time-tracker/ManageTimeTrackerModal";
import { TimeTrackerStructure } from "../../data/models/time-tracker-structure.interface";
import CustomDatePicker from "../../shared/CustomDatePicker";
import CustomHeaderMobile from "../../shared/custom/CustomHeaderMobile";

const UserWorkTimeActivity = () => {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const [userActivity, resultUserActivity] = useLazyGetTimeTrackersQuery();
  const [userTimeTrackers, userTimeTrackersResults] =
    useLazyGetMultipleTimeTrackersByIntervalUserQuery();
  const [getHolidays, holidayResult] = useLazyGetHolidaysQuery();

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [error, setError] = useState<{ message: string; hasError: boolean }>({
    hasError: false,
    message: "",
  });
  const { t } = useTranslation();
  const loggedUser = useSelector(selectLoggedUser);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<any>(null);
  const [selectedEmployee, setSelectedEmployee] = useState<any>(null);

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };
  const [intervalStartTime, setIntervalStartTime] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [intervalEndTime, setIntervalEndTime] = useState(
    moment().format("YYYY-MM-DD")
  );
  const openModalManageTimeTracker = (employee: any, date: any) => {
    setIsModalOpen(true);
    setSelectedDate(date);
    setSelectedEmployee(employee);
  };

  const [selectedTimeTracker, setSelectedTimeTracker] = useState<
    TimeTrackerStructure | undefined | null
  >(undefined);

  const handleRowPerPageChange = (event: any) => {
    setLimit(event.target.value);
    setPage(1);
  };

  useEffect(() => {
    getHolidays({ startDate: intervalStartTime, endDate: intervalEndTime });
    userActivity({
      startDate: intervalStartTime,
      endDate: intervalEndTime,
      userId: loggedUser?.userId,
    });
  }, [loggedUser, intervalStartTime, intervalEndTime]);

  useEffect(() => {
    userTimeTrackers({
      userId: loggedUser?.userId,
      startDate: intervalStartTime,
      endDate: intervalEndTime,
      currentPage: page,
      limitTo: limit,
    });
  }, [page, limit, intervalStartTime, intervalEndTime]);

  const timeTrackersBodyRenderer = useMemo(() => {
    const timeTrackersDataRenderer: JSX.Element[] = [];
    userTimeTrackersResults?.data?.data?.map((row: any, index: number) => {
      return timeTrackersDataRenderer.push(
        <ViewTimeTrackersTableRowUser
          key={index}
          employee={row?.user}
          timeTracker={row}
          openModalManageTimeTracker={openModalManageTimeTracker}
        />
      );
    });
    return timeTrackersDataRenderer;
  }, [userTimeTrackersResults]);

  const activityTableTitles = [
    `${t("ACTIVITY.TABLE_TITLES.EXPECTED_WORKING")}(h)`,
    `${t("ACTIVITY.TABLE_TITLES.LEAVES")}(h)`,
    "Compensation (h)",
    `${t("ACTIVITY.TABLE_TITLES.WORKED")}(h)`,
    `${t("ACTIVITY.TABLE_TITLES.EQUIVALENT_WORKING")}(h)`,
    `${t("ACTIVITY.TABLE_TITLES.OVERTIME")}(h)`,
  ];
  const tableTitles: string[] = [
    `${t("DATE")}`,
    `${t("START_TIME")}`,
    `${t("END_TIME")}`,
    `${t("WORKED_HOURS")}`,
    `${t("LEAVES")}`,
    `${t("OVERTIME")}`,
    `${t("VIEW")}`,
  ];
  const dataValues = useMemo(() => {
    let data: Object[] = [];
    if (userTimeTrackersResults?.data?.data) {
      userTimeTrackersResults?.data?.data?.map((info: any, index: number) => {
        return data.push({
          user: info?.user?.firstName + " " + info?.user?.lastName,
          date: info?.date,
          startTime: moment(info?.firstStartTime)?.format("HH:mm"),
          endDate: moment(info?.lastStartTime)?.format("HH:mm"),
          workedHours: info?.work_time / 60,
          overtimeWorked: info?.work_time / 60 - loggedUser?.workingHours || 0,
        });
      });
    }
    return data;
  }, [userTimeTrackersResults?.data?.data]);

  const headers = [
    { label: `${t("FULL_NAME")}`, key: "user" },
    { label: `${t("DATE")}`, key: "date" },
    { label: `${t("START_TIME")}`, key: "startTime" },
    { label: `${t("END_DATE")}`, key: "endDate" },
    { label: `${t("WORKED_HOURS")}`, key: "workedHours" },
    { label: `${t("OVERTIME")}`, key: "overtimeWorked" },
  ];

  return (
    <>
      {isMobile || isTablet ? (
        <CustomHeaderMobile
          title={t("MY_WORK_TIME.TITLE")}
          subtitle={t("MY_WORK_TIME.SUBTITLE")}
        >
          <Box
            sx={{
              marginTop: "3vh",
              height: "auto",
              width: "100%",
              display: "flex",
              alignItems: "center",
              gap: 3,
              justifyContent: "space-between",
            }}
          >
            <CustomDatePicker
              onChange={(value) => {
                setIntervalStartTime(value ? value.format("YYYY-MM-DD") : "");
              }}
              value={intervalStartTime}
              label={t("START_TIME")}
              views={["day"]}
              openTo="day"
            />

            <CustomDatePicker
              onChange={(value) =>
                setIntervalEndTime(value ? value.format("YYYY-MM-DD") : "")
              }
              value={intervalEndTime}
              label={t("END_TIME")}
              views={["day"]}
              openTo="day"
            />
            {error && error?.hasError && error?.message.length ? (
              <Typography marginTop={1} color="error.main" variant="subtitle2">
                {error?.message}
              </Typography>
            ) : null}
          </Box>
        </CustomHeaderMobile>
      ) : (
        <CustomHeader
          title={t("MY_WORK_TIME.TITLE")}
          subtitle={t("MY_WORK_TIME.SUBTITLE")}
        >
          <Box
            sx={{
              width: "40%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <CustomDatePicker
              onChange={(value) => {
                setIntervalStartTime(value ? value.format("YYYY-MM-DD") : "");
              }}
              value={intervalStartTime}
              label={t("START_TIME")}
              views={["day"]}
              openTo="day"
            />

            <CustomDatePicker
              onChange={(value) =>
                setIntervalEndTime(value ? value.format("YYYY-MM-DD") : "")
              }
              value={intervalEndTime}
              label={t("END_TIME")}
              views={["day"]}
              openTo="day"
            />
            {error && error?.hasError && error?.message.length ? (
              <Typography marginTop={1} color="error.main" variant="subtitle2">
                {error?.message}
              </Typography>
            ) : null}
          </Box>
        </CustomHeader>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          width: "100%",
          height: "auto",
        }}
      >
        <Box
          style={{
            margin: "2vh 0 1vh 0",
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            alignItems: isMobile ? "flex-start" : "center",
          }}
        >
          <h4 style={{ margin: 0, padding: 0 }}>
            {`${t("MY_WORK_TIME.MY_WORK_ACTIVITY")}`}
          </h4>
        </Box>
        {isMobile ? (
          <UserPersonalActivityTableMobile
            column1Name={t("DETAILS")}
            column2Name={t("ACTIVITY.TITLE")}
            isPaginated={false}
            data={resultUserActivity?.data}
          />
        ) : (
          <CustomTable
            tableTitles={activityTableTitles}
            tableBodyRenderer={[
              <ActivityTableRow row={resultUserActivity?.data} />,
            ]}
            isPaginated={false}
            width="100%"
          />
        )}

        <>
          <Box
            sx={{
              width: "100%",
              height: "8vh",
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              alignItems: isMobile ? "flex-start " : "flex-end",
              justifyContent: isMobile ? "center" : "space-between",
              paddingBottom: isMobile || isTablet ? "0vh" : "1vh",
              mt: 2,
            }}
          >
            <Box
              style={{
                width: "100%",
                margin: 0,
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
                justifyContent: "space-between",
              }}
            >
              <h4
                style={{
                  margin: 0,
                  padding: 0,
                }}
              >
                {`${t("MY_WORK_TIME.COMPLETED_TIME_TRACKERS")}`}
              </h4>

              <CSVLink
                data={dataValues}
                headers={headers}
                filename={`${
                  loggedUser?.firstName + " " + loggedUser?.lastName
                }: ${t(
                  "MY_WORK_TIME.COMPLETED_TIME_TRACKERS"
                )?.toLowerCase()} `}
                style={{
                  background: "#43b685",
                  color: "white",
                  fontWeight: "500",
                  width: isMobile ? "80px" : "auto",
                  display: "flex",
                  justifyContent: "center",
                  alignSelf: isMobile || isTablet ? "start" : "end",
                  textDecoration: "none",
                  padding: "0.4% 0.8%",
                  borderRadius: "5px",
                  // marginTop: "2%",
                }}
              >
                {isMobile ? "Excel" : `${t("EXPORT_TO_EXCEL")}`}
              </CSVLink>
            </Box>
          </Box>
          {isMobile ? (
            <UserPersonalCompletedTTtableMobile
              openModalManageTimeTracker={openModalManageTimeTracker}
              column1Name={t("DETAILS")}
              column2Name={t("TIME_TRACKER.TITLE")}
              data={userTimeTrackersResults?.data?.data}
              isPaginated={userTimeTrackersResults?.data?.totalRecords > 5}
              count={userTimeTrackersResults?.data?.totalRecords}
              handleChangePage={handleChangePage}
              rowsPerPage={limit}
              page={page}
              handleChangeRowsPerPage={handleRowPerPageChange}
            />
          ) : (
            <CustomTable
              tableTitles={tableTitles}
              tableBodyRenderer={timeTrackersBodyRenderer}
              isPaginated={userTimeTrackersResults?.data?.totalRecords > 5}
              count={userTimeTrackersResults?.data?.totalRecords}
              handleChangePage={handleChangePage}
              width="100%"
              rowsPerPage={limit}
              page={page}
              handleChangeRowsPerPage={handleRowPerPageChange}
            />
          )}
        </>
        <ManageTimeTrackersModal
          isOpen={isModalOpen}
          handleClose={() => {
            setIsModalOpen(false);
            setSelectedEmployee(null);
            setSelectedDate(null);
            setSelectedTimeTracker(null);
          }}
          selectedUser={selectedEmployee}
          selectedDate={selectedDate}
          selectedTimeTracker={selectedTimeTracker}
          setSelectedTimeTracker={setSelectedTimeTracker}
        />
      </Box>
    </>
  );
};

export default UserWorkTimeActivity;
