import { createContext, useEffect, useMemo, useState } from "react";
import CustomSnackBar from "../shared/snackbar/CustomSnackBar";
import { ThemeProvider, createTheme } from "@mui/material";
import { themeSettings } from "../core/AppStyles";
import { appEndpoints } from "../data/endpoints/app.endpoints";
import { resetAuthState } from "../data/store/authSlice";
import { resetDepartmentState } from "../data/store/departmentSlice";
import { resetEmployeeState } from "../data/store/employeeSlice";
import { resetHomeState } from "../data/store/homeSlice";
import { resetProjectState } from "../data/store/projectSlice";
import { NavigateFunction } from "react-router-dom";
import { Action, Dispatch } from "@reduxjs/toolkit";
import { resetDashboardState } from "../data/store/userDashboardSlice";
import { useTranslation } from "react-i18next";

interface RootContextState {
  setIsError: React.Dispatch<boolean>;
  setSnackBarMessage: React.Dispatch<React.SetStateAction<string>>;
  setSnackBarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  logoutAndClearState: (
    navigation: NavigateFunction,
    dispatch: Dispatch<Action>
  ) => void;
  showSnackbar: (
    snackBarTextMessage: string,
    isSnackError: boolean,
    isSnackBarOpen: boolean
  ) => void;
  state: {
    isError: boolean;
    snackBarMessage: string;
    snackBarOpen: boolean;
    openModal: boolean;
  };
  colorMode: {
    toggleColorMode: () => void;
  };
}

//@ts-ignore
export const RootContext = createContext<RootContextState>();

export const RootProvider = ({ children }: any) => {
  const [isError, setIsError] = useState<boolean>(false);
  const [snackBarMessage, setSnackBarMessage] = useState<string>("");
  const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { t } = useTranslation();
  const localStorageMode = JSON.parse(
    localStorage.getItem("cms-mode") as string
  );

  const [mode, setMode] = useState(
    localStorageMode ? localStorageMode : "light"
  );
  useEffect(() => {
    localStorage.setItem("cms-mode", JSON.stringify(mode));
  }, [mode]);

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () =>
        setMode((prev: any) => (prev === "light" ? "dark" : "light")),
    }),
    []
  );

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  const showSnackbar = (
    snackBarTextMessage: string,
    isSnackError: boolean,
    isSnackBarOpen: boolean
  ) => {
    setSnackBarMessage(snackBarTextMessage);
    setIsError(isSnackError);
    setSnackBarOpen(isSnackBarOpen);
  };

  const logoutAndClearState = (navigation: any, dispatch: any) => {
    dispatch(resetAuthState());
    dispatch(resetEmployeeState());
    localStorage.removeItem("jvelektro-ems-app.refresh-token");
    dispatch(appEndpoints.util.resetApiState());
    dispatch(resetHomeState());
    dispatch(resetDepartmentState());
    dispatch(resetProjectState());
    dispatch(resetEmployeeState());
    dispatch(resetDashboardState());

    showSnackbar(t("LOGOUT_SUCCESS"), false, true);
    navigation("/login");
  };

  const state = { isError, snackBarMessage, snackBarOpen, openModal };

  const actions = {
    setIsError,
    setSnackBarMessage,
    setSnackBarOpen,
    showSnackbar,
    setOpenModal,
    colorMode,
    logoutAndClearState,
  };

  const provider = { state, ...actions };

  return (
    <RootContext.Provider value={provider}>
      <CustomSnackBar
        snackBarMessage={snackBarMessage}
        isError={isError}
        snackBarOpen={snackBarOpen}
        setSnackBarOpen={setSnackBarOpen}
      />
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </RootContext.Provider>
  );
};
