import { Box } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";

interface IMessage {
  message?: string;
  user?: string;
  data?: any;
  margin?: string;
  bColor?: string;
  color?: string;
}

const Message = ({ message, user, data, margin, bColor, color }: IMessage) => {
  const linkRegex = /https?:\/\/[^\s/$.?#].[^\s]*/g;
  const links = message?.match(linkRegex);
  const messageWithoutLinks = message?.replace(linkRegex, "");

  return (
    <Box
      sx={{
        width: "60%",
        minHeight: "7vh auto",
        padding: "1.5vh",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "1vh",
        backgroundColor: `${bColor}`,
        marginLeft: `${margin}`,
        color: `${color}`,
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "40%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          overflow: "hidden",
          fontSize: "12px",
        }}
      >
        <small>{user}</small>
        <small>{data}</small>
      </Box>

      <p
        style={{
          margin: 0,
          padding: "1vh 0 0 0",
          width: "100%",
          wordBreak: "break-word",
        }}
      >
        {messageWithoutLinks}
      </p>
      {links && (
        <>
          <LinkIcon />
          {links.map((link, index) => (
            <a
              style={{ wordBreak: "break-word" }}
              key={index}
              href={link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {link}
            </a>
          ))}
        </>
      )}
    </Box>
  );
};

export default Message;
