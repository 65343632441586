import { createSlice, createSelector } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { Department } from "../models/department.interface";
import { DepartmentState } from "../models/states/department-state";
import { appEndpoints } from "../endpoints/app.endpoints";

const initialState: DepartmentState = {
  departments: [],
  isLoading: true,
  error: undefined,
};

const selectSelf: any = (state: RootState) => state.department;

export const selectDepartments = createSelector(
  selectSelf,
  (state) => state.departments
);

export const selectDepartmentSliceState = createSelector(
  selectSelf,
  (state) => state as DepartmentState
);
export const selectDepartmentById = (id: string | undefined) =>
  createSelector(
    selectDepartments,
    (state) =>
      state.find(
        (department: Department) => department.departmentId === id
      ) as Department
  );

export const departmentSlice = createSlice({
  name: "department",
  initialState,
  reducers: {
    resetDepartmentState: () => initialState,
  },
  extraReducers: (builder) =>
    builder
      .addMatcher(
        appEndpoints.endpoints.getDepartments.matchFulfilled,
        (state, { payload }) => {
          return {
            ...state,
            departments: payload,
            error: undefined,
            isLoading: false,
          };
        }
      )
      .addMatcher(
        appEndpoints.endpoints.getDepartments.matchRejected,
        (state, { payload }: any) => {
          return { ...state, error: payload.data, isLoading: true };
        }
      ),
});

export const { resetDepartmentState } = departmentSlice.actions;
