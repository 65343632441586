import { Box, useTheme } from "@mui/material";
import { tokens } from "../../../../../core/AppStyles";
import PeopleIcon from "@mui/icons-material/People";
import { useState } from "react";
import ChatIcon from "@mui/icons-material/Chat";

const ChatHeader = ({ decideChatType }: any) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [childState, setChildState] = useState<boolean>(true);

  const handleChatType = () => {
    setChildState(!childState);
    decideChatType(childState);
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "15%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        background: colors.blueAccent[400],
        padding: "0 5% 0 5%",
      }}
    >
      <p
        style={{
          color: colors.black,
          fontSize: "20px",
        }}
      >
        Chat
      </p>
      {childState ? (
        <ChatIcon
          color="inherit"
          style={{
            marginLeft: "5vw",
            color: "white",
            cursor: "pointer",
          }}
          onClick={handleChatType}
        />
      ) : (
        <PeopleIcon
          color="inherit"
          style={{
            marginLeft: "5vw",
            color: "white",
          }}
          onClick={handleChatType}
        />
      )}
    </Box>
  );
};

export default ChatHeader;
