/* eslint-disable react-hooks/exhaustive-deps */
import { Box, useTheme } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useSelector } from "react-redux";
import SearchBar from "../../../../shared/custom/SearchBar";
import moment from "moment";
import CustomHeader from "../../../../shared/custom/CustomHeader";
import useIsMobile from "../../../../shared/hooks/useIsMobile";
import CustomHeaderMobile from "../../../../shared/custom/CustomHeaderMobile";
import CustomProjectTrackerMobile from "./CustomProjectTrackerMobile";
import CustomDatePicker from "../../../../shared/CustomDatePicker";
import { KeyboardArrowLeft } from "@mui/icons-material";
import { tokens } from "../../../../core/AppStyles";
import { useLazyGetAllProjectTrackersByDepartmentQuery } from "../../../../data/endpoints/app.endpoints";
import { useTranslation } from "react-i18next";
import CustomTable from "../../../../shared/custom/CustomTable";
import ProjectTrackerTableRow from "../../../projects/ProjectTrackerTableRow";
import NoDataComponent from "../../../../shared/NoDataComponent";
import { selectDepartments } from "../../../../data/store/departmentSlice";

const ProjectWorkingHoursDashboardComponent = () => {
  const isMobile = useIsMobile();
  const [value, setValue] = useState("1");
  const [title, setTitle] = useState("");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selectedYear, setSelectedYear] = useState<any>(moment().format());
  const departments = useSelector(selectDepartments);
  const [departmentId, setDepartmentId] = useState<string>(
    departments[0]?.departmentId
  );
  const { t } = useTranslation();

  const [projectTracker, projectTrackerResult] =
    useLazyGetAllProjectTrackersByDepartmentQuery();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleDepartmentChange = (id: string) => {
    setDepartmentId(id);
  };

  const projectTrackerTableTitles = [
    t("USERS_ADMIN.PROJECTS.PROJECT_TRACKER.ALL_PROJECTS"),
    t("USERS_ADMIN.PROJECTS.PROJECT_TRACKER.WORKED_HOURS"),
    t("USERS_ADMIN.PROJECTS.PROJECT_TRACKER.PROJECT_PROGRESS"),
    t("USERS_ADMIN.PROJECTS.PROJECT_TRACKER.TOTAL_HOURS"),
    t("USERS_ADMIN.PROJECTS.PROJECT_TRACKER.MONTHS.JAN"),
    t("USERS_ADMIN.PROJECTS.PROJECT_TRACKER.MONTHS.FEB"),
    t("USERS_ADMIN.PROJECTS.PROJECT_TRACKER.MONTHS.MAR"),
    t("USERS_ADMIN.PROJECTS.PROJECT_TRACKER.MONTHS.APR"),
    t("USERS_ADMIN.PROJECTS.PROJECT_TRACKER.MONTHS.MAY"),
    t("USERS_ADMIN.PROJECTS.PROJECT_TRACKER.MONTHS.JUN"),
    t("USERS_ADMIN.PROJECTS.PROJECT_TRACKER.MONTHS.JUL"),
    t("USERS_ADMIN.PROJECTS.PROJECT_TRACKER.MONTHS.AUG"),
    t("USERS_ADMIN.PROJECTS.PROJECT_TRACKER.MONTHS.SEP"),
    t("USERS_ADMIN.PROJECTS.PROJECT_TRACKER.MONTHS.OCT"),
    t("USERS_ADMIN.PROJECTS.PROJECT_TRACKER.MONTHS.NOV"),
    t("USERS_ADMIN.PROJECTS.PROJECT_TRACKER.MONTHS.DEC"),
  ];

  const filteredData = projectTrackerResult?.data?.filter((item: any) => {
    return item?.title.toLowerCase().includes(title.toLowerCase());
  });

  const projectTrackerBodyRenderer = useMemo(() => {
    const projectTrackerDataRenderer: JSX.Element[] = [];

    const projectTotals: number[] = [];

    filteredData?.forEach((entry: any, index: number) => {
      const entryHours = entry.data.reduce((entryTotal: any, item: any) => {
        return entryTotal + (item.total_hours || 0);
      }, 0);
      projectTotals.push(entryHours);
    });
    filteredData?.map((row: any, index: number) => {
      return projectTrackerDataRenderer.push(
        <ProjectTrackerTableRow
          key={index}
          row={row}
          projectTotals={projectTotals}
          index={index}
        />
      );
    });
    return projectTrackerDataRenderer;
  }, [filteredData]);

  useEffect(() => {
    projectTracker({
      year: moment(selectedYear).year(),
      departmentId,
    });
  }, [departmentId, selectedYear]);

  return (
    <Box>
      <Box
        style={{
          width: "100%",
          marginBottom: isMobile ? "0" : "1%",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: isMobile ? "8vh" : "auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: isMobile ? "120px" : "0px",
          }}
        >
          {isMobile ? (
            <CustomHeaderMobile
              title={t("USERS_ADMIN.PROJECTS.PROJECT_TRACKER.TITLE")}
              subtitle={t("USERS_ADMIN.PROJECTS.PROJECT_TRACKER.SUBTITLE")}
              btnNavLink="/"
              btnTitle={<KeyboardArrowLeft />}
            >
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  width: "100%",
                  marginTop: "1vh",
                }}
              >
                <SearchBar setSearchEvent={setTitle} />
                <Box
                  sx={{
                    width: "100%",
                    marginTop: "2vh",
                    display: "flex",
                    justifyContent: "space-between",
                    height: "150px",
                  }}
                >
                  <CustomDatePicker
                    onChange={(value) => {
                      setSelectedYear(value ? value.format("YYYY-MM-DD") : "");
                    }}
                    label={t("YEAR")}
                    views={["year"]}
                    value={selectedYear}
                    inputFormat="YYYY"
                    openTo="year"
                  />
                </Box>
              </Box>
            </CustomHeaderMobile>
          ) : (
            <Box sx={{ width: "100%" }}>
              <CustomHeader
                title={t("USERS_ADMIN.PROJECTS.PROJECT_TRACKER.TITLE")}
                subtitle={t("USERS_ADMIN.PROJECTS.PROJECT_TRACKER.SUBTITLE")}
              >
                <CustomDatePicker
                  onChange={(value) => {
                    setSelectedYear(value ? value.format("YYYY-MM-DD") : "");
                  }}
                  label={t("YEAR")}
                  views={["year"]}
                  value={selectedYear}
                  inputFormat="YYYY"
                  openTo="year"
                />

                <Box
                  sx={{
                    width: "30%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <SearchBar setSearchEvent={setTitle} />
                </Box>
              </CustomHeader>
            </Box>
          )}
        </Box>
      </Box>

      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handleChange}
            aria-label="lab API tabs example"
            TabIndicatorProps={{
              style: {
                background: colors.invert,
              },
            }}
            variant="scrollable"
          >
            {departments?.map((dep: any, index: string) => (
              <Tab
                key={dep?.departmentId}
                label={<p style={{ color: colors.invert }}>{dep?.name}</p>}
                value={`${index + 1}`}
                onClick={() => handleDepartmentChange(dep?.departmentId)}
              />
            ))}
          </TabList>
        </Box>
        {departments?.map((dep: any, index: number) => (
          <TabPanel key={index} value={`${index + 1}`} style={{ padding: 0 }}>
            {filteredData?.length > 0 ? (
              isMobile ? (
                <CustomProjectTrackerMobile
                  tableData={filteredData}
                  column1Name={t("DETAILS")}
                  column2Name={t("PROJECTS")}
                  isPaginated={false}
                />
              ) : (
                <Box mt={3}>
                  <CustomTable
                    isPaginated={false}
                    tableBodyRenderer={projectTrackerBodyRenderer}
                    tableTitles={projectTrackerTableTitles}
                  />
                </Box>
              )
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: isMobile ? "40vh" : "65vh",
                }}
              >
                <NoDataComponent
                  title={t("USERS_ADMIN.PROJECTS.PROJECT_TRACKER.NO_DATA")}
                />
              </Box>
            )}
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  );
};

export default ProjectWorkingHoursDashboardComponent;
