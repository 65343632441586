import { Box, colors, useTheme } from '@mui/material';
import React, { useEffect, useMemo, useRef } from 'react'
import { tokens } from '../../core/AppStyles';
import useIsMobile from '../../shared/hooks/useIsMobile';
import { useTranslation } from 'react-i18next';
import UserLogger from './UserLogger';
import { useLazyGetUserLogsQuery } from '../../data/endpoints/app.endpoints';
import "jspdf-autotable";
import jsPDF from 'jspdf';
import { useSelector } from 'react-redux';
import { selectEmployeeById } from '../../data/store/employeeSlice';
import { useParams } from 'react-router';


const ViewEmployeeLogs = () => {

    const theme = useTheme();
    const color = tokens(theme.palette.mode);
    const isMobile = useIsMobile();
    const { t } = useTranslation();
    const [userLog, userLogResults] = useLazyGetUserLogsQuery();
    const { id } = useParams();
    const logData = userLogResults?.data;
    useEffect(() => {
        if (id)
            userLog(id);
    }, [id]);


    const pdRef = useRef();

    const {
        firstName,
        lastName,
    } = useSelector(selectEmployeeById(id));
    const headers = [
        `${t("ACTION")}`,
        `${t("DESCRIPTION")}`,
        `${t("MY_PROFILE.PDF_HEADERS.ACTIONBY")}`,
        `${t("MY_PROFILE.PDF_HEADERS.TIME")}`,
    ];
    const dataValues = useMemo(() => {
        let data: Object[] = [];
        if (logData) {
            logData?.map((info: any) => {
                data.push({
                    [headers[0]]: info?.activityType as string,
                    [headers[1]]: info?.description as string,
                    [headers[2]]: info?.actionBy as string,
                    [headers[3]]: (info?.createdAt.split("T")[0] +
                        " | " +
                        info?.createdAt.split("T")[1].split(".")[0]) as string,
                });
            });
            return data;
        }
    }, [logData]);


    const downloadPdf = () => {

        const doc = new jsPDF({ orientation: "landscape" });

        const tableData = dataValues;
        tableData &&
            doc.table(1, 1, tableData as any, headers, { autoSize: true }) as any;
        doc.save(` ${firstName + " " + lastName}: ProfileData.pdf`);
    };

    return (
        <>
            {logData?.length > 0 ? (
                <button
                    onClick={downloadPdf}
                    style={{
                        border: "none",
                        fontWeight: 600,
                        padding: "1%",
                        borderRadius: "5px",
                        background: colors.red[50],
                        color: colors.red[700],
                        alignSelf: "flex-end",
                        cursor: "pointer",
                    }}
                >
                    {t("USERS_ADMIN.VIEW_USER.DOWNLOAD_PDF")}
                </button>
            ) : (
                ""
            )}
            <Box
                ref={pdRef}
                sx={{
                    width: "100%",
                    height: "100%",
                }}
            >
                <Box
                    style={{
                        width: "92%",
                        height: "10%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        marginBottom: "1vh",
                        marginTop: "1vh",
                    }}
                >
                    <p
                        style={{
                            color: color.invert,
                            fontSize: isMobile ? "1.2rem" : "1.5rem",
                            fontWeight: 500,
                        }}
                    >
                        {t("USERS_ADMIN.VIEW_USER.USER_LOG_TIMELINE")}
                    </p>
                </Box>
                <Box
                    style={{
                        width: "100%",
                        maxHeight: isMobile ? "60vh" : "calc(90% - 2vh)",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        position: "relative",
                        overflow: "auto",
                    }}
                >
                    <Box
                        sx={{
                            width: "100%",
                            height: "100%",
                            overflowY: "auto",
                            marginBottom: "7vh",
                        }}
                    >
                        {logData
                            ?.slice("")
                            .reverse()
                            .map((log: any, index: number) => {
                                return (
                                    <UserLogger
                                        key={index}
                                        activityType={log?.activityType}
                                        actionBy={log?.actionBy}
                                        createdAt={log?.createdAt}
                                        description={log?.description}
                                        hours={log?.hours}
                                        logDate={log?.logDate}
                                    />
                                );
                            })}
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default React.memo(ViewEmployeeLogs)