import { createSlice, createSelector } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { appEndpoints } from "../endpoints/app.endpoints";
import { AuthState } from "../models/states/auth-state.model";
import { User } from "../models/user.interface";

const initialState: AuthState = {
  user: undefined,
  tokens: undefined,
  rememberMe: false,
  error: undefined,
};

const selectSelf: any = (state: RootState) => state.auth;

export const selectLoggedUser = createSelector(
  selectSelf,
  (state) => state.user as User
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetAuthState: () => initialState,
    addUser: (state, { payload }) => {
      return { ...state, user: payload };
    },
    setTokens: (state, { payload }) => {
      state.tokens = payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addMatcher(
        appEndpoints.endpoints.login.matchFulfilled,
        (state, { payload }) => {
          return { ...state, tokens: payload, error: undefined };
        }
      )
      .addMatcher(
        appEndpoints.endpoints.refreshToken.matchFulfilled,
        (state, { payload }) => {
          return { ...state, tokens: payload, error: undefined };
        }
      )
      .addMatcher(
        appEndpoints.endpoints.getUserById.matchFulfilled,
        (state, { payload }) => {
          // localStorage.setItem("jvelektro-ems-app.user-data", JSON.stringify(payload));
          return { ...state, user: payload, error: undefined };
        }
      )
      .addMatcher(
        appEndpoints.endpoints.login.matchRejected,
        (state, { payload }: any) => {
          return { ...state, error: payload.data.error };
        }
      ),
});

export const { resetAuthState, addUser, setTokens } = authSlice.actions;
