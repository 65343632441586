import {
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  useTheme,
} from "@mui/material";
import OptionsMenu from "./sidebar/OptionsMenu";
import { useLazyLogoutQuery } from "../data/endpoints/app.endpoints";
import LightDarkModeToggle from "../shared/LightDarkModeToggle";
import { useState } from "react";
import useIsMobile from "../shared/hooks/useIsMobile";
import useIsTablet from "../shared/hooks/useIsTablet";
import { tokens } from "./AppStyles";
import {
  MenuOutlined,
  ModeCommentOutlined,
  Language as LanguageIcon,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { GBIcon, GermanIcon } from "../shared/custom/CustomEditIcons";
import { useNavigate } from "react-router";

const TopBar = ({ setCollapsed }: any) => {
  const [logoutUser] = useLazyLogoutQuery();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const languages = {
    en: {
      nativeName: "English",
    },
    de: {
      nativeName: "Deutch",
    },
    it: {
      nativeName: "Italian",
    },
  };
  const { t, i18n } = useTranslation();
  const [anchorElLang, setAnchorElLang] = useState<null | HTMLElement>(null);
  const openLang = Boolean(anchorElLang);
  const handleClickLang = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElLang(event.currentTarget);
  };
  const navigate = useNavigate();
  const handleChangeLang = (lng: string) => {
    setAnchorElLang(null);
    i18n.changeLanguage(lng);
  };
  const handleCloseLang = () => {
    setAnchorElLang(null);
  };

  const handleNavigateDashboard = () => {
    navigate("/dashboard");
  };

  return (
    <Box
      p={isMobile || isTablet ? 1 : 2}
      sx={{
        display: "flex",
        position: isMobile ? "sticky" : "static",
        top: "10px",
        background: `${
          isMobile || isTablet ? colors.component : "transparent"
        }`,
        width: `${isMobile || isTablet ? "100%" : "100%"}`,
        borderRadius: "10px",
        boxShadow: `${
          isMobile || isTablet ? "0 0 11px 1px rgba(0,0,0,0.08)" : "none"
        }`,
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: `${
            isMobile || isTablet ? "space-between" : "flex-end"
          }`,
        }}
      >
        {isMobile || isTablet ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconButton
              onClick={() => {
                setCollapsed({ left: true });
              }}
            >
              <MenuOutlined
                sx={{
                  color: "grey",
                }}
              />
            </IconButton>
            <IconButton onClick={handleNavigateDashboard}>
              <img
                src={colors.kinetonImage.dashboardLogo}
                alt="jv-elektro logo"
                width="20px"
              />
            </IconButton>
          </Box>
        ) : (
          ""
        )}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
            marginRight: "1vh",
          }}
        >
          {isMobile || isTablet ? (
            <IconButton
              onClick={() => {
                setCollapsed({ bottom: true });
              }}
            >
              <ModeCommentOutlined sx={{ color: "grey" }} />
            </IconButton>
          ) : (
            ""
          )}
          <LightDarkModeToggle />
          {/* <IconButton>
            <NotificationsOutlined sx={{ color: "grey" }} />
          </IconButton> */}
          <IconButton
            aria-controls={openLang ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openLang ? "true" : undefined}
            onClick={handleClickLang}
          >
            <LanguageIcon sx={{ color: "grey" }} />
          </IconButton>
          <Menu
            sx={{ width: "inherit" }}
            id="basic-menu"
            anchorEl={anchorElLang}
            open={openLang}
            onClose={handleCloseLang}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            PaperProps={{
              sx: { borderRadius: "10px", background: colors.component },
            }}
          >
            {Object.keys(languages).map((lng, index: number) => {
              switch (lng) {
                case "en": {
                  return (
                    <MenuItem
                      key={index}
                      disabled={i18n.resolvedLanguage === lng}
                      onClick={() => handleChangeLang("en")}
                    >
                      <GBIcon />
                    </MenuItem>
                  );
                }
                case "de": {
                  return (
                    <MenuItem
                      key={index}
                      disabled={i18n.resolvedLanguage === lng}
                      onClick={() => handleChangeLang("de")}
                    >
                      <GermanIcon />
                    </MenuItem>
                  );
                }
                default:
                  return null;
              }
            })}
          </Menu>
          <Box style={{ color: "gray" }}>{t("KEY")}</Box>
          <IconButton onClick={handleClick}>
            <Avatar sx={{ width: "23px", height: "23px" }} />
          </IconButton>
        </div>

        <OptionsMenu
          anchorEl={anchorEl}
          handleClose={handleClose}
          logoutUser={logoutUser}
          openMenu={openMenu}
        />
      </Box>
    </Box>
  );
};

export default TopBar;
