import { useEffect, useMemo, useState } from "react";
import BoxContainer from "../../shared/BoxContainer";
import CustomHeader from "../../shared/custom/CustomHeader";
import { useSelector } from "react-redux";
import { Box, MenuItem, SelectChangeEvent } from "@mui/material";
import LoadingSpinner from "../../shared/LoadingSpinner";
import CustomTable from "../../shared/custom/CustomTable";
import EquipmentTableRow from "./EquipmentTableRow";
import { useLazyGetEquipmentsByUserIdQuery } from "../../data/endpoints/app.endpoints";
import { selectLoggedUser } from "../../data/store/authSlice";
import useIsMobile from "../../shared/hooks/useIsMobile";
import CustomUserEquipementTableMobile from "./CustomUserEquipementTableMobile";
import SearchBar from "../../shared/custom/SearchBar";
import CustomSelector from "../../shared/custom/CustomSelector";
import CustomHeaderMobile from "../../shared/custom/CustomHeaderMobile";
import useIsTablet from "../../shared/hooks/useIsTablet";
import NoDataComponent from "../../shared/NoDataComponent";
import { useTranslation } from "react-i18next";

const UserEquipments = () => {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const user = useSelector(selectLoggedUser);
  const [page, setPage] = useState(1);
  const [q, setQ] = useState("");
  const [status, setStatus] = useState("ALL");
  const [limit, setLimit] = useState<any>(10);
  const { t } = useTranslation();
  const handleRowPerPageChange = (event: SelectChangeEvent) => {
    setLimit(event.target.value);
    setPage(1);
  };

  const equipmentTableTitles = [
    `${t("START_DATE")}`,
    `${t("END_DATE")}`,
    `${t("EQUIPMENTS.ITEM_NAME")}`,
    `${t("EQUIPMENTS.SERIAL_NUMBER")}`,
    `${t("EQUIPMENTS.QUANTITY")}`,
    `${t("EQUIPMENTS.LOCATION")}`,
    `${t("STATUS")}`,
    `${t("DESCRIPTION")}`,
  ];

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleStatusChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value);
    setPage(1);
  };

  useEffect(() => {
    if (user) {
      getEquipmentByUserId({
        id: user?.userId,
        currentPage: page,
        limitTo: limit,
        status: status === "ALL" ? "" : status,
        q,
      });
    } else {
    }
  }, [user, q, page, limit, status]);
  const [getEquipmentByUserId, result] = useLazyGetEquipmentsByUserIdQuery();
  const { data, isSuccess } = result;

  const equipmentBodyRenderer = useMemo(() => {
    const equipmentDataRenderer: JSX.Element[] = [];
    data?.data?.map((row: any, index: number) => {
      return equipmentDataRenderer.push(
        <EquipmentTableRow key={row?.equipmentId} row={row} />
      );
    });
    return equipmentDataRenderer;
  }, [data]);

  return (
    <BoxContainer>
      {isMobile || isTablet ? (
        <CustomHeaderMobile
          title={t("EQUIPMENTS.TITLE")}
          subtitle={t("EQUIPMENTS.SUBTITLE")}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "100%",
              marginTop: "1vh",
              height: "90px",
            }}
          >
            <SearchBar setSearchEvent={setQ} setPage={setPage} />
            <CustomSelector
              label={t("STATUS")}
              value={status}
              onSelectChange={handleStatusChange}
              size="small"
              width="100%"
            >
              <MenuItem value={"ALL"}>{t("STATUSES.ALL")}</MenuItem>
              <MenuItem value={"In Possession"}>
                {t("EQUIPMENTS.STATUSES.IN_POSSESSION")}
              </MenuItem>
              <MenuItem value={"Handed Over"}>
                {t("EQUIPMENTS.STATUSES.HANDED_OVER")}
              </MenuItem>
              <MenuItem value={"Damaged"}>
                {t("EQUIPMENTS.STATUSES.DAMAGED")}
              </MenuItem>
            </CustomSelector>
          </Box>
        </CustomHeaderMobile>
      ) : (
        <CustomHeader
          title={t("EQUIPMENTS.TITLE")}
          subtitle={t("EQUIPMENTS.SUBTITLE")}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              width: "60%",
              marginTop: "1vh",
              gap: 10,
            }}
          >
            <SearchBar setSearchEvent={setQ} setPage={setPage} />
            <CustomSelector
              label={t("STATUS")}
              value={status}
              onSelectChange={handleStatusChange}
              size="small"
              width="50%"
            >
              <MenuItem value={"ALL"}>{t("STATUSES.ALL")}</MenuItem>
              <MenuItem value={"In Possession"}>
                {t("EQUIPMENTS.STATUSES.IN_POSSESSION")}
              </MenuItem>
              <MenuItem value={"Handed Over"}>
                {t("EQUIPMENTS.STATUSES.HANDED_OVER")}
              </MenuItem>
              <MenuItem value={"Damaged"}>
                {t("EQUIPMENTS.STATUSES.DAMAGED")}
              </MenuItem>
            </CustomSelector>
          </Box>
        </CustomHeader>
      )}
      {isSuccess ? (
        data?.data?.length > 0 ? (
          isMobile || isTablet ? (
            <CustomUserEquipementTableMobile
              column1Name={t("DETAILS")}
              column2Name={t("EQUIPMENTS.ADD_EQUIPMENTS.EQUIPMENTS")}
              data={result?.data?.data}
              rowsPerPage={limit}
              handleChangeRowsPerPage={handleRowPerPageChange}
              handleChangePage={handleChangePage}
              isPaginated={result?.data?.totalRecords > 10 ? true : false}
              count={result?.data?.totalRecords}
              page={page}
            />
          ) : (
            <Box mt={3}>
              <CustomTable
                isPaginated={result?.data?.totalRecords > 10 ? true : false}
                tableBodyRenderer={equipmentBodyRenderer}
                handleChangePage={handleChangePage}
                rowsPerPage={limit}
                handleChangeRowsPerPage={handleRowPerPageChange}
                tableTitles={equipmentTableTitles}
                count={result?.data?.totalRecords}
                page={page}
                style={{
                  borderBottomLeftRadius:
                    result?.data?.totalRecords > 9 ? "0px" : "10px",
                  borderBottomRightRadius:
                    result?.data?.totalRecords > 9 ? "0px" : "10px",
                }}
              />
            </Box>
          )
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: isMobile ? "50vh" : "75vh",
            }}
          >
            <NoDataComponent title={`${t("EQUIPMENTS.NO_EQUIPMENTS_FOUND")}`} />
          </Box>
        )
      ) : (
        <LoadingSpinner />
      )}
    </BoxContainer>
  );
};
export default UserEquipments;
