import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { AuthTokens } from "../models/auth-tokens.interface";
import { Department } from "../models/department.interface";
import { LoginData } from "../models/login-data.interface";
import { Project } from "../models/project.interface";
import { User } from "../models/user.interface";
import { RootState } from "../store/store";
import { Mutex } from "async-mutex";
import { resetAuthState, setTokens } from "../store/authSlice";
import { resetHomeState } from "../store/homeSlice";
import { resetDepartmentState } from "../store/departmentSlice";
import { resetEmployeeState } from "../store/employeeSlice";
import { resetProjectState } from "../store/projectSlice";
import { PaginatedResponse } from "../models/paginated-response.interface";
import { ActivityResponsePaginated } from "../models/activity-response-paginated.interface";
import { Holiday } from "../models/holiday.interface";
import { LeaveRequest } from "../models/leave-request.interface";
import { Equipment } from "../models/equipment.interface";
import { TimeTracker } from "../models/time-tracker.interface";
import { resetEquipmentsState } from "../store/equipmentSlice";
import { resetHolidaysState } from "../store/holidaysSlice";
import { UserStatus } from "../../utils/enums/user-status.enum";
import { UserRole } from "../../utils/enums/user-role.enum";
import { ProjectStatus } from "../../utils/enums/project-status.enum";
import { Vehicle } from "../models/vehicle.interface";
import { VehicleStatus } from "../../utils/enums/vehicle-status.enum";
import { Expense } from "../models/expense.interface";
import { url } from "inspector";

const mutex = new Mutex();

interface CheckTimeTrackerRequest {
  date: string;
  userId: string;
}

interface GetTimeTrackersRequest {
  startDate: string;
  endDate: string;
  userId: string;
  department?: any;
  limitTo?: number;
  q?: any;
}

interface GetEmployeeTimeTrackersRequest {
  startDate: string;
  endDate: string;
  currentPage: number;
  limitTo?: number;
  q?: any;
  department?: any;
}
const baseUrl =
  process.env.NODE_ENV === "production"
    ? "api/"
    : process.env.REACT_APP_BASE_URL;

const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers, { getState }) => {
    const accessToken = (getState() as RootState).auth.tokens?.accessToken;

    if (accessToken && headers.get("authorization") === null) {
      headers.set("authorization", `Bearer ${accessToken}`);
    }
    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 403) {
    // try to get a new token
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshToken = (api.getState() as RootState).auth.tokens
          ?.refreshToken;
        const refreshResult = await baseQuery(
          {
            url: "/refresh-token",

            headers: { Authorization: `Bearer ${refreshToken}` },
          },
          api,
          extraOptions
        );
        if (refreshResult.data) {
          api.dispatch(setTokens(refreshResult.data));
          // retry the initial query
          result = await baseQuery(args, api, extraOptions);
        } else {
          api.dispatch(appEndpoints.util.resetApiState());
          api.dispatch(resetAuthState());
          api.dispatch(resetHomeState());
          api.dispatch(resetDepartmentState());
          api.dispatch(resetEmployeeState());
          api.dispatch(resetProjectState());
          api.dispatch(resetEquipmentsState());
          api.dispatch(resetHolidaysState());
          localStorage.removeItem("jvelektro-ems-app.refresh-token");
        }
      } finally {
        // release must be called once the mutex should be released again.
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

export const appEndpoints = createApi({
  reducerPath: "appEndpoints",
  tagTypes: [
    "User",
    "LoggedUser",
    "Project",
    "ProjectTracker",
    "Department",
    "Token",
    "Tracker",
    "Leaves",
    "Holidays",
    "Equipments",
    "FileManager",
    "FileManagerUpload",
    "Chats",
    "SocketChats",
    "Conversations",
    "ChatsOneToOne",
    "UserDashboard",
    "UserWeeklyHours",
    "EmployeePrjectsHours",
    "AdminDashboard",
    "Expenses",
    "Vehicle",
  ],
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getPaginatedUsers: builder.query<
      PaginatedResponse<User>,
      {
        limitTo: number;
        currentPage: number;
        q?: string;
        status?: UserStatus;
        department?: string;
        role?: UserRole;
      }
    >({
      query: (options) => ({
        url: "/users",
        params: options,
      }),
      providesTags: ["User"],
    }),
    getAllUsers: builder.query<any, any>({
      query: () => "/all-users",
      providesTags: ["User"],
    }),
    getUserById: builder.query<User, string>({
      query: (id: string) => `/user/${id}`,
      providesTags: ["LoggedUser"],
    }),
    login: builder.query<AuthTokens, LoginData>({
      query: (loginData: LoginData) => ({
        url: "/login",
        method: "POST",
        body: loginData,
      }),
      providesTags: ["Token"],
    }),
    refreshToken: builder.query<AuthTokens, string>({
      query: (refreshToken: string) => ({
        url: "/refresh-token",
        method: "GET",
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        },
      }),
      providesTags: ["Token"],
    }),
    createUser: builder.mutation<User, Partial<User>>({
      query: (user: Partial<User>) => ({
        url: "/user",
        method: "POST",
        body: user,
      }),
      invalidatesTags: ["User"],
    }),
    updateUser: builder.mutation<User, User>({
      query: (user: User) => ({
        url: `/user/update/${user.userId}`,
        method: "PATCH",
        body: user,
      }),
      invalidatesTags: ["User"],
    }),
    changeUserStatus: builder.mutation({
      query: ({ id, endOfContract, fullName }) => ({
        url: `/user/status/${id}`,
        method: "PATCH",
        body: { endOfContract, fullName },
      }),
      invalidatesTags: ["User"],
    }),
    changeUserPassword: builder.mutation<any, any>({
      query: ({ id, password, fullName }) => ({
        url: `/admin/password/${id}`,
        method: "PATCH",
        body: { password, fullName },
      }),
      invalidatesTags: ["User"],
    }),
    getUserLogs: builder.query<any, any>({
      query: (id) => ({
        url: `/logs/user/${id}`,
      }),
      providesTags: ["User"],
    }),
    userCompensation: builder.mutation<any, any>({
      query: (options) => ({
        url: `/logs`,
        method: "POST",
        body: options,
      }),
      invalidatesTags: ["User"],
    }),
    getPaginatedProjects: builder.query<
      PaginatedResponse<Project>,
      {
        limitTo: number;
        currentPage: number;
        status?: ProjectStatus;
        q?: any;
        department?: any;
      }
    >({
      query: (options) => ({
        url: "projects",
        params: options,
      }),
      providesTags: ["Project"],
    }),
    getProjectById: builder.query<Project[], string>({
      query: (id: string) => `/project/${id}`,
      providesTags: ["Project"],
    }),
    createProject: builder.mutation<Project, Partial<Project>>({
      query: (project: Partial<Project>) => ({
        url: "/project",
        method: "POST",
        body: project,
      }),
      invalidatesTags: ["Project"],
    }),
    getAllProjects: builder.query<any, any>({
      query: () => ({
        url: "/all-projects",
        method: "GET",
      }),
      providesTags: ["Project"],
    }),
    getInsightProjects: builder.query<any, any>({
      query: (options) => ({
        url: "/employees-on-project",
        params: options,
        method: "GET",
      }),
      providesTags: ["Project"],
    }),
    getEmployeesHoursInProject: builder.query<any, any>({
      query: (options) => ({
        url: `/employees-on-project/${options?.projectId}`,
        params: { startDate: options.startDate, endDate: options.endDate },
        method: "GET",
      }),
      providesTags: ["Tracker"],
    }),
    getAllProjectTrackersByDepartment: builder.query<any, any>({
      query: ({ year, departmentId }) => ({
        url: `/projectsTracker/${year}/${departmentId}`,
        method: "GET",
      }),
      providesTags: ["ProjectTracker"],
    }),
    updateProject: builder.mutation<Project, Project>({
      query: (project: Project) => ({
        url: `/project/update/${project.projectId}`,
        method: "PUT",
        body: project,
      }),
      invalidatesTags: ["Project"],
    }),
    getDepartments: builder.query<Department[], any>({
      query: () => "/departments",
      providesTags: ["Department"],
    }),
    checkUsersWithUncomletedTT: builder.query<any, any>({
      query: (options) => ({
        url: `/timetracker/users/check-incomplete-admin`,
        params: { startDate: options?.startDate, endDate: options.endDate },
        method: "GET",
      }),
      providesTags: ["Tracker"],
    }),
    checkUncompleatedTTById: builder.query<any, any>({
      query: (options) => ({
        url: `/timetracker/users/check-incomplete/${options.userId}`,
        params: { startDate: options?.startDate, endDate: options.endDate },
        method: "GET",
      }),
      providesTags: ["Tracker"],
    }),
    getDepartmentById: builder.query<Department[], string>({
      query: (id: string) => `/department/${id}`,
      providesTags: ["Department"],
    }),
    createDepartment: builder.mutation<Department, Partial<Department>>({
      query: (department: Partial<Department>) => ({
        url: "/department",
        method: "POST",
        body: department,
      }),
      invalidatesTags: ["Department"],
    }),
    updateDepartment: builder.mutation<Department, Department>({
      query: (department: Department) => ({
        url: `/department/update/${department.departmentId}`,
        method: "PUT",
        body: department,
      }),
      invalidatesTags: ["Department"],
    }),
    deleteDepartment: builder.mutation<any, string>({
      query: (id: string) => ({
        url: `/department/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Department"],
    }),
    logout: builder.query({
      query: () => ({
        url: "/logout",
        method: "DELETE",
      }),
    }),
    getProjectsByDepartmentId: builder.query<Project[], string>({
      query: (departmentId: string) => `/timetracker/projects/${departmentId}`,
    }),
    addTime: builder.mutation<any, any>({
      query: (Timetracker: any) => ({
        url: "/timetracker",
        method: "POST",
        body: Timetracker,
      }),
      invalidatesTags: ["Tracker"],
    }),
    createSingleTimeTracker: builder.mutation<TimeTracker, any>({
      query: (timeTrackerData: { userId: string; timeTracker: any }) => ({
        url: "/single/time-tracker",
        method: "POST",
        body: timeTrackerData,
      }),
      invalidatesTags: ["Tracker"],
    }),
    getMultipleTimeTrackersByInterval: builder.query<
      any,
      {
        startDate: string;
        endDate: string;
        currentPage: number;
        limitTo: number;
        q?: any;
        department?: any;
      }
    >({
      query: (timeTrackerInterval) => ({
        url: "/multiple/time-tracker",
        method: "GET",
        params: {
          startDate: timeTrackerInterval.startDate,
          endDate: timeTrackerInterval.endDate,
          currentPage: timeTrackerInterval.currentPage,
          limitTo: timeTrackerInterval.limitTo,
          q: timeTrackerInterval.q,
          department: timeTrackerInterval.department,
        },
      }),
      providesTags: ["Tracker"],
    }),
    getTimeTrackers: builder.query<any, any>({
      query: (options) => ({
        url: `/timetracker/users/single-user/${options?.userId}`,
        params: {
          startDate: options?.startDate,
          endDate: options?.endDate,
        },
      }),
      providesTags: ["Tracker"],
    }),
    getTimeTrackersForEmployees: builder.query<
      ActivityResponsePaginated,
      GetEmployeeTimeTrackersRequest
    >({
      query: (options) => ({
        url: "/timetracker/users/all-employees",
        params: options,
      }),
      providesTags: ["Tracker"],
    }),
    getMultipleTimeTrackersByIntervalUser: builder.query<any, any>({
      query: (options) => ({
        url: `/multiple/time-tracker/${options?.userId}`,
        params: {
          startDate: options?.startDate,
          endDate: options?.endDate,
          currentPage: options?.currentPage,
          limitTo: options?.limitTo,
        },
      }),
      providesTags: ["Tracker"],
    }),
    getContractsByUserId: builder.query<any, { userId: string }>({
      query: (options) => `/contracts/${options.userId}`,
      providesTags: ["User"],
    }),
    createContract: builder.mutation<
      any,
      {
        startDate: any;
        workingHours: number;
        userId: string;
        percentage: number;
      }
    >({
      query: (options) => ({
        url: "/contracts/create",
        body: options,
        method: "POST",
      }),
      invalidatesTags: ["Tracker", "User"],
    }),
    // changeUserWorkingHoursFromDate: builder.mutation<any, { date: string, userId: string, workingHours: number }>({
    //   query: (options) => ({
    //     url: `time-tracker/change-overtime/${options.userId}`,
    //     method: 'PATCH',
    //     body: {
    //       workingHours: options.workingHours,
    //       date: options.date
    //     },
    //   }),
    //   invalidatesTags: ['Tracker']
    // }),
    getDailyTimeTracker: builder.query<any, { date: string; userId: string }>({
      query: (options) => ({
        url: `/daily-timetracker/user/${options.userId}`,
        params: { date: options.date },
        method: "GET",
      }),
      providesTags: ["Tracker"],
    }),
    checkTimeTrackerSubmitted: builder.query<void, CheckTimeTrackerRequest>({
      query: (options) => ({
        url: `/timetracker/check-timetracker/${options.userId}`,
        params: { date: options?.date },
        method: "GET",
      }),
      providesTags: ["Tracker"],
    }),
    updateSingleTimeTracker: builder.mutation<any, Partial<TimeTracker>>({
      query: (options) => ({
        url: `/timetracker/update/${options.timeTrackerId}`,
        method: "PATCH",
        body: {
          startTime: options?.startTime,
          endTime: options?.endTime,
          description: options?.description,
          projectId: options?.projectId,
        },
      }),
      invalidatesTags: ["Tracker"],
    }),
    deleteOneTimeTracker: builder.mutation<TimeTracker, any>({
      query: (timeTrackerId) => ({
        url: `/timetracker/delete/${timeTrackerId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Tracker"],
    }),
    deleteDailyTimeTracker: builder.mutation<
      any,
      { userId: string; date: string }
    >({
      query: (timeTrackersToDelete) => ({
        url: `/timetracker/delete-by-date/${timeTrackersToDelete.userId}`,
        method: "DELETE",
        params: { date: timeTrackersToDelete.date },
      }),
      invalidatesTags: ["Tracker"],
    }),
    getAllLeaves: builder.query<any, any>({
      query: (options) => ({
        url: `/leaves-paginated`,
        method: "GET",
        params: {
          currentPage: options?.currentPage,
          limitTo: options?.limitTo,
          q: options?.q,
          status: options?.status,
          type: options?.type,
        },
      }),
      providesTags: ["Leaves"],
    }),

    getCalendarLeaves: builder.query<any, any>({
      query: (options) => ({
        url: `/calendar-leaves/${options.startDate}/${options.endDate}`,
        method: "GET",
      }),
      providesTags: ["Leaves"],
    }),

    getLeavesDashboard: builder.query<any, any>({
      query: () => ({
        url: `/leaves`,
      }),
      providesTags: ["Leaves"],
    }),

    getLeavesByUserId: builder.query<any, any>({
      query: (options) => ({
        url: `/leaves/user/${options.id}`,
        method: "GET",
        params: {
          currentPage: options?.currentPage,
          limitTo: options?.limitTo,
          q: options?.q,
          status: options?.status,
          type: options?.type,
        },
      }),

      providesTags: ["Leaves"],
    }),
    sendLeaveRequest: builder.mutation<LeaveRequest, any>({
      query: (leaveRequest) => ({
        url: "/leaves",
        method: "POST",
        body: leaveRequest,
      }),
      invalidatesTags: ["Leaves"],
    }),
    updateLeaveRequest: builder.mutation<LeaveRequest, any>({
      query: (leaveRequest) => ({
        url: `/leaves/update/${leaveRequest?.leaveId}`,
        method: "PATCH",
        body: leaveRequest,
      }),
      invalidatesTags: ["Leaves"],
    }),
    changeStatusLeaveRequest: builder.mutation<LeaveRequest, any>({
      query: (leaveRequest) => ({
        url: `/leaves/change-status/${leaveRequest?.leaveId}`,
        method: "PATCH",
        body: leaveRequest,
      }),
      invalidatesTags: ["Leaves"],
    }),
    deleteLeaveRequest: builder.mutation({
      query: (leaveRequest) => ({
        url: `/leaves/delete/${leaveRequest}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Leaves"],
    }),
    getHolidays: builder.query<Holiday[], any>({
      query: (options) => ({
        url: `/holidays/interval/`,
        params: { startDate: options?.startDate, endDate: options?.endDate },
        method: "GET",
      }),
      providesTags: ["Holidays"],
    }),
    addHoliday: builder.mutation<any, any>({
      query: (options) => ({
        url: `/holidays`,
        method: "POST",
        body: options,
      }),
      invalidatesTags: ["Holidays"],
    }),
    updateHoliday: builder.mutation<any, any>({
      query: ({ holidayId, name, date }) => ({
        url: `/holiday/update/${holidayId}`,
        method: "PUT",
        body: { name, date },
      }),
      invalidatesTags: ["Holidays"],
    }),
    deleteHoliday: builder.mutation<any, any>({
      query: (holiday) => ({
        url: `/holiday/delete/${holiday}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Holidays"],
    }),
    getEquipments: builder.query<any, any>({
      query: (options) => ({
        url: `/equipments-paginated`,
        method: "GET",
        params: {
          currentPage: options.currentPage,
          limitTo: options?.limitTo,
          q: options?.q,
          status: options?.status,
        },
      }),
      providesTags: ["Equipments"],
    }),
    getEquipmentsByUserId: builder.query<any, any>({
      query: (options) => ({
        url: `/equipment/user/${options.id}`,
        method: "GET",
        params: {
          currentPage: options.currentPage,
          limitTo: options?.limitTo,

          q: options?.q,
          status: options?.status,
        },
      }),
      providesTags: ["Equipments"],
    }),
    addEquipment: builder.mutation<any, any>({
      query: (equipment) => ({
        url: `/equipments`,
        body: equipment,
        method: "POST",
      }),
      invalidatesTags: ["Equipments"],
    }),
    updateEquipment: builder.mutation<Equipment, any>({
      query: (equipment: any) => ({
        url: `/equipment/update/${equipment.equipmentId}`,
        method: "PATCH",
        body: equipment,
      }),
      invalidatesTags: ["Equipments"],
    }),
    deleteEquipment: builder.mutation<any, any>({
      query: (id: string) => ({
        url: `/equipment/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Equipments"],
    }),
    getAllFiles: builder.query<any, any>({
      query: (options) => ({
        url: `/files`,
        params: {
          currentPage: options?.currentPage,
          limitTo: options?.limitTo,
          q: options?.q,
          type: options?.type,
        },
        method: "GET",
      }),
      providesTags: ["FileManager"],
    }),
    getAllFilesByUserId: builder.query<any, any>({
      query: (options) => ({
        url: `/files/${options.userId}`,
        params: {
          currentPage: options?.currentPage,
          limitTo: options?.limitTo,
          q: options?.q,
          type: options?.type,
        },
        method: "GET",
      }),
      providesTags: ["FileManager"],
    }),
    getAllFilesByfileId: builder.query<any, any>({
      query: (options) => ({
        url: `/file/${options.userId}`,
        method: "GET",
      }),
      providesTags: ["FileManager"],
    }),

    downloadFileByUserId: builder.query<any, any>({
      query: (object) => ({
        url: `/download/${object.fileId}`,
        method: "GET",
        responseHandler: async (response) => {
          const file = await response.blob();

          // const { file, data } = response;
          const downloadUrl = file ? URL.createObjectURL(file) : null;

          return { downloadUrl };
        },
      }),
      providesTags: ["FileManager"],
    }),
    deleteFilePondFiles: builder.mutation<any, any>({
      query: (file) => ({
        url: `/files/${file?.fileId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["FileManager"],
    }),
    getUserDashboard: builder.query<any, any>({
      query: (userId) => ({
        url: `/employee-dashboard/${userId}`,
        method: "GET",
      }),
      providesTags: ["UserDashboard"],
    }),
    getUserWeeklyWorkingHours: builder.query<any, any>({
      query: (userId) => ({
        url: `/employee-dashboard-weeks/${userId}`,
        method: "GET",
      }),
      providesTags: ["UserWeeklyHours"],
    }),
    getAdminDashboard: builder.query<any, any>({
      query: () => ({
        url: `/admin-dashboard`,
        method: "GET",
      }),
      providesTags: ["AdminDashboard"],
    }),
    getUserProjects: builder.query<any, any>({
      query: (options) => ({
        url: `/employee-projects/${options?.userId}`,
        params: { startDate: options?.startDate, endDate: options?.endDate },
        method: "GET",
      }),
      providesTags: ["EmployeePrjectsHours"],
    }),

    sentChats: builder.mutation<any, any>({
      query: (chat) => ({
        url: `/create-chat`,
        method: "POST",
        body: chat,
      }),
      invalidatesTags: ["SocketChats"],
    }),
    getChats: builder.query<any, any>({
      query: () => ({
        url: "/get-chats",
        method: "GET",
      }),
      providesTags: ["Chats"],
    }),
    getConversations: builder.query<any, any>({
      query: (senderId) => ({
        url: `/chatConverations/${senderId}`,
        method: "GET",
      }),
      providesTags: ["Conversations"],
    }),
    getOneToOneChats: builder.query<any, any>({
      query: ({ senderId, reciverId }) => ({
        url: `/individual-chats/${senderId}/${reciverId}`,
        method: "GET",
      }),
      providesTags: ["ChatsOneToOne"],
    }),
    sentOneToOneChats: builder.mutation<any, any>({
      query: (oneToOneChats) => ({
        url: `/send-chats`,
        method: "POST",
        body: oneToOneChats,
      }),
      invalidatesTags: ["ChatsOneToOne"],
    }),
    uploadFile: builder.mutation<any, any>({
      query: ({ userId, type }) => ({
        url: `/upload`,
        method: "POST",
        body: { userId, type },
      }),
      invalidatesTags: ["FileManagerUpload"],
    }),

    addExpense: builder.mutation<any, any>({
      query: (expense) => ({
        url: `/expenses`,
        method: "POST",
        body: expense,
      }),
      invalidatesTags: ["Expenses"],
    }),
    getPaginatedExpenses: builder.query<any, any>({
      query: (options) => ({
        url: "/expenses-paginated",
        params: options,
        method: "GET",
      }),
      providesTags: ["Expenses"],
    }),
    getAllUserReceiptsById: builder.query<any, any>({
      query: (userId) => ({
        url: `user-receipts/${userId}`,
        method: "GET",
      }),
      providesTags: ["Expenses"],
    }),

    deleteExpense: builder.mutation<any, any>({
      query: (expense) => ({
        url: `/expenses/delete/${expense?.expensesId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Expenses"],
    }),
    updateExpense: builder.mutation<any, any>({
      query: (expense) => ({
        url: `/expenses/update/${expense?.expensesId}`,
        method: "PATCH",
        body: expense,
      }),
      invalidatesTags: ["Expenses"],
    }),
    getExpenseByExpensesId: builder.query<any, any>({
      query: (expense) => ({
        url: `/expenses/${expense?.expensesId}`,
        method: "GET",
      }),
      providesTags: ["Expenses"],
    }),
    getAllExpensesByUserId: builder.query<Expense[], any>({
      query: (expenses) => ({
        url: `/user-expenses/${expenses?.userId}`,
        params: { startDate: expenses.startDate, endDate: expenses.endDate },
        method: "GET",
      }),
      providesTags: ["Expenses"],
    }),
    getExpensesByUserId: builder.query<any, any>({
      query: (options) => ({
        url: `/expenses/user/${options?.userId}`,
        params: options,
        method: "GET",
      }),
      providesTags: ["Expenses"],
    }),
    getPreviousKilometersByVehicleId: builder.query<any, any>({
      query: (options) => ({
        url: `/previous-kilometers/${options?.vehicleId}`,
        method: "GET",
      }),
      providesTags: ["Expenses"],
    }),
    getPaginatedVehicles: builder.query<
      PaginatedResponse<Vehicle>,
      {
        limitTo: number;
        currentPage: number;
        status?: VehicleStatus;
        q?: any;
      }
    >({
      query: (options) => ({
        url: "vehicles",
        params: options,
      }),
      providesTags: ["Vehicle"],
    }),
    getVehicleById: builder.query<Vehicle, string>({
      query: (id: string) => `/vehicle/${id}`,
      providesTags: ["Vehicle"],
    }),
    createVehicle: builder.mutation<Vehicle, Partial<Vehicle>>({
      query: (vehicle: Partial<Vehicle>) => ({
        url: "/vehicle",
        method: "POST",
        body: vehicle,
      }),
      invalidatesTags: ["Vehicle"],
    }),
    updateVehicle: builder.mutation<Vehicle, Vehicle>({
      query: (vehicle: Vehicle) => ({
        url: `/vehicle/update/${vehicle?.vehicleId}`,
        method: "PUT",
        body: vehicle,
      }),
      invalidatesTags: ["Vehicle"],
    }),
    getAllVehicles: builder.query<any, any>({
      query: () => ({
        url: `/all-vehicles`,
        method: "GET",
      }),
      providesTags: ["Vehicle"],
    }),
  }),
});

export const {
  useLazyGetPaginatedUsersQuery,
  useLazyGetAllUsersQuery,
  useLazyGetUserByIdQuery,
  useLazyLoginQuery,
  useLazyRefreshTokenQuery,
  useCreateUserMutation,
  useChangeUserStatusMutation,
  useUpdateUserMutation,
  useLazyGetPaginatedProjectsQuery,
  useGetProjectByIdQuery,
  useCreateProjectMutation,
  useUpdateProjectMutation,
  useLazyGetAllProjectsQuery,
  useLazyGetInsightProjectsQuery,
  useCreateDepartmentMutation,
  useChangeUserPasswordMutation,
  useGetDepartmentsQuery,
  useDownloadFileByUserIdQuery,
  useLazyGetAllFilesByUserIdQuery,
  useLazyGetAllFilesByfileIdQuery,
  useUpdateDepartmentMutation,
  useLazyLogoutQuery,
  useLazyGetProjectsByDepartmentIdQuery,
  useLazyGetAllProjectTrackersByDepartmentQuery,
  useLazyGetEmployeesHoursInProjectQuery,
  useAddTimeMutation,
  useCreateSingleTimeTrackerMutation,
  useDeleteOneTimeTrackerMutation,
  useSendLeaveRequestMutation,
  useLazyGetTimeTrackersQuery,
  useLazyGetTimeTrackersForEmployeesQuery,
  useLazyCheckTimeTrackerSubmittedQuery,
  useLazyGetDailyTimeTrackerQuery,
  useUpdateSingleTimeTrackerMutation,
  useDeleteDailyTimeTrackerMutation,
  useLazyGetHolidaysQuery,
  useAddHolidayMutation,
  useUpdateHolidayMutation,
  useDeleteHolidayMutation,
  useLazyGetAllLeavesQuery,
  useLazyGetLeavesDashboardQuery,
  useLazyGetCalendarLeavesQuery,
  useUpdateLeaveRequestMutation,
  useDeleteLeaveRequestMutation,
  useLazyGetLeavesByUserIdQuery,
  useChangeStatusLeaveRequestMutation,
  useLazyGetEquipmentsQuery,
  useUserCompensationMutation,
  useAddEquipmentMutation,
  useDeleteEquipmentMutation,
  useLazyGetEquipmentsByUserIdQuery,
  useLazyGetMultipleTimeTrackersByIntervalQuery,
  useLazyGetMultipleTimeTrackersByIntervalUserQuery,
  useUpdateEquipmentMutation,
  useLazyGetAllFilesQuery,
  useLazyDownloadFileByUserIdQuery,
  useDeleteFilePondFilesMutation,
  useSentChatsMutation,
  useLazyGetChatsQuery,
  useLazyGetOneToOneChatsQuery,
  useSentOneToOneChatsMutation,
  useLazyGetConversationsQuery,
  useLazyGetUserDashboardQuery,
  useLazyGetUserWeeklyWorkingHoursQuery,
  useLazyGetAdminDashboardQuery,
  useLazyGetUserProjectsQuery,
  useLazyGetUserLogsQuery,
  useUploadFileMutation,
  useLazyCheckUncompleatedTTByIdQuery,
  useLazyCheckUsersWithUncomletedTTQuery,
  useLazyGetAllUserReceiptsByIdQuery,
  useAddExpenseMutation,
  useLazyGetPaginatedExpensesQuery,
  useLazyGetExpensesByUserIdQuery,
  useLazyGetAllExpensesByUserIdQuery,
  useDeleteExpenseMutation,
  useUpdateExpenseMutation,
  useLazyGetExpenseByExpensesIdQuery,
  useLazyGetPreviousKilometersByVehicleIdQuery,
  useLazyGetAllVehiclesQuery,
  useLazyGetVehicleByIdQuery,
  useUpdateVehicleMutation,
  useCreateVehicleMutation,
  useLazyGetPaginatedVehiclesQuery,
  useCreateContractMutation,
  useLazyGetContractsByUserIdQuery,
  // useChangeUserWorkingHoursFromDateMutation
} = appEndpoints;
