import { Box, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import PDF from "../../assets/pdf.png";
import WORD from "../../assets/word.png";
import EXCEL from "../../assets/excel2.png";
import CSV from "../../assets/csv.png";
import IMAGE from "../../assets/img.png";
import FilePreviewModal from "./FilePreviewModal";
import { useState } from "react";
import {
  useDeleteFilePondFilesMutation,
  useLazyDownloadFileByUserIdQuery,
} from "../../data/endpoints/app.endpoints";
import { MoreVert } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { selectLoggedUser } from "../../data/store/authSlice";
import AlertDialog from "../../shared/modal/AlertDialog";
import { useTranslation } from "react-i18next";
import useIsMobile from "../../shared/hooks/useIsMobile";

interface IFileProperties {
  fileId: string;
  fileName: string;
  fileSize?: string;
  fileDate?: string;
  fileUrl?: string;
}

interface IFile {
  file: IFileProperties;
}

const File = ({ file }: IFile) => {
  const isMobile = useIsMobile();
  const fileType: string | undefined = file?.fileName
    ?.split(".")
    .pop()
    ?.toLowerCase();
  const [fileUrl, setFileUrl] = useState("");
  const [previewOpen, setPreviewOpen] = useState(false);
  const [downloadFile, result] = useLazyDownloadFileByUserIdQuery();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openModal, setOpenModal] = useState(false);
  const [deleteFile] = useDeleteFilePondFilesMutation();
  const open = Boolean(anchorEl);
  const loggedUser = useSelector(selectLoggedUser);
  const { t } = useTranslation();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownload = async () => {
    try {
      const response = await downloadFile({
        fileId: file?.fileId,
        fileName: file?.fileName,
      });

      if ("data" in response) {
        const { downloadUrl } = response.data;
        if (downloadUrl) {
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.download = file?.fileName;
          link.click();
        }
      }
    } catch (error) {
      alert("Error downloading file");
    }
  };

  const handleDelete = () => {
    deleteFile({
      fileId: file?.fileId,
    });
    setOpenModal(false);
    handleClose();
  };

  const handleModalOpen = () => {
    setOpenModal(true);
  };
  const closePreviewModal = () => {
    setPreviewOpen(false);
  };

  const handleViewFile = async () => {
    try {
      const response = await downloadFile({
        fileId: file?.fileId,
        fileName: file?.fileName,
      });

      if ("data" in response) {
        const { downloadUrl } = response.data;
        if (downloadUrl) {
          setFileUrl(downloadUrl);
          setPreviewOpen(true);
        }
      }
    } catch (error) {
      alert("Error downloading file");
    }
  };
  return (
    <Box
      sx={{
        width: "96px",
        height: isMobile ? "110px" : "130px",
        padding: "1%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        overflow: "hidden",
        cursor: "pointer",
      }}
      key={file?.fileId}
    >
      {isMobile ? (
        <img
          src={
            fileType === "pdf"
              ? PDF
              : fileType === "docx"
              ? WORD
              : fileType === "xlsx"
              ? EXCEL
              : fileType === "csv"
              ? CSV
              : IMAGE
          }
          alt="file logo"
          width="50px"
          onClick={() => handleViewFile()}
        />
      ) : (
        <img
          src={
            fileType === "pdf"
              ? PDF
              : fileType === "docx"
              ? WORD
              : fileType === "xlsx"
              ? EXCEL
              : fileType === "csv"
              ? CSV
              : IMAGE
          }
          alt="file logo"
          width="60px"
          onDoubleClick={() => handleViewFile()}
        />
      )}
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <Tooltip title={file?.fileName || file?.fileId}>
          <p
            style={{
              margin: 0,
              padding: 0,
              paddingLeft: "5px",
              fontSize: "12px",
            }}
          >
            {file?.fileName?.length > 7
              ? ((file?.fileName?.slice(0, 7) + "...") as any)
              : (file?.fileName as any) || file?.fileId.slice(0, 7) + "..."}
          </p>
        </Tooltip>
        <FilePreviewModal
          fileName={file?.fileName?.toLowerCase()}
          fileUrl={fileUrl}
          open={previewOpen}
          handleClose={closePreviewModal}
        />
        <>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? "long-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVert sx={{ width: "15px", height: "15px" }} />
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                handleDownload();
                handleClose();
              }}
            >
              Download
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleViewFile();
                handleClose();
              }}
            >
              View
            </MenuItem>
            {loggedUser?.role === "Admin" && (
              <AlertDialog
                openModal={openModal}
                setOpenModal={setOpenModal}
                buttonTitle=""
                handleSubmitDialog={handleDelete}
                ButtonComponent={(props: any) => (
                  <MenuItem onClick={handleModalOpen}>Delete</MenuItem>
                )}
                mt="20px"
              >
                {t("FILE_MANAGER.FILE_DOWNLOAD.MODAL_DELETE_CONFIRMATION")}
              </AlertDialog>
            )}
          </Menu>
        </>
      </Box>
    </Box>
  );
};

export default File;
