import moment from "moment";
const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
const PASSWORD_REGEX = /^[a-zA-Z0-9#@!.-_*%$]{6,20}$/;
// const PASSWORD_REGEX_CHANGING =
//   /^(?=.*[A-Z])(?=.*[0-9])(?=.*[@#!.\-_*$%])[a-zA-Z0-9#@!.\-_*$%]{6,20}$/;
const DEPARTMENT_NAME_REGEX = /^[a-zA-Z ]{3,23}$/;
const PASSWORD_ERROR_MESSAGE =
  "Password should be at least 6 to 20 characters.";
const CONFIRM_PASSWORD_ERROR_MESSAGE = "Passwords don't match";
const EMAIL_ERROR_MESSAGE = "Invalid email address";
const NAME_DEPARTMENT_ERROR_MESSAGE = "Invalid department name ";

// create employee validations
const handleRequiredValidation = (
  values: any,
  errors: any,
  formField: string,
  requiredMessage: string
): any => {
  if (!values[formField]) {
    errors[formField] = requiredMessage;
  }
};
const handleEquipmentsQuantityValidation = (
  values: any,
  errors: any,
  errorMessage: {
    required: string;
    valueSmallerOrEqualZero: string;
    shouldBeWholeNumber: string;
  }
): any => {
  if (values["quantity"] === "") {
    errors["quantity"] = errorMessage.required;
  } else if (values["quantity"] <= 0) {
    errors["quantity"] = errorMessage.valueSmallerOrEqualZero;
  } else if (!Number.isInteger(values["quantity"])) {
    errors["quantity"] = errorMessage.shouldBeWholeNumber;
  }
};

const handleIntervalDateValidation = (
  errors: any,
  objectName: string,
  interval: {
    startDate: any;
    endDate: any;
  },
  errorMessage: string
) => {
  const startDateMoment = moment(interval?.startDate);
  const endDateMoment = moment(interval?.endDate);
  const isValid = moment(endDateMoment).isBefore(startDateMoment);
  if (isValid) {
    errors[objectName] = errorMessage;
  }
};

// const customHandleRequiredValidation = (
//   values: any,
//   errors: any,
//   formField: string,
//   requiredMessage: string
// ): any => {
//   if (!values[formField]) {
//     errors[formField] = requiredMessage;
//   }
// };

const handleRegexValidation = (
  values: any,
  errors: any,
  formField: string,
  regex: RegExp,
  regexMessage: string,
  requiredMessage: string
): any => {
  if (!values[formField]) {
    errors[formField] = requiredMessage;
  } else if (!regex.test(values[formField])) {
    errors[formField] = regexMessage;
  }
};
//PROJECT

//timetracker validators

interface IntervalType {
  startTime: moment.Moment;
  endTime: moment.Moment;
}

const timeBetweenInterval = (time: moment.Moment, interval: IntervalType) => {
  return moment({
    hour: time.hour(),
    minute: time.minute(),
    second: time.second(),
  }).isBetween(interval?.startTime, interval?.endTime);
};

const requiredTimeInterval = (
  errors: any,
  objectName: string,
  interval: any,
  errorMessage: string
) => {
  if (!interval.startTime || !interval.endTime) {
    errors[objectName] = errorMessage;
  }
};

const timeIntervalEndTimeSmaller = (
  errors: any,
  objectName: string,
  interval: { startTime: any; endTime: any },
  intervalErrors: { startTimeNotValid: string; endTimeNotValid: string },
  errorMessage: string
) => {
  const startTimeMoment = moment(interval?.startTime);
  const endTimeMoment = moment(interval?.endTime);
  const endTimeEqualOrSame = endTimeMoment.isSameOrBefore(startTimeMoment);

  if (endTimeEqualOrSame) {
    errors[objectName] = errorMessage;
  }

  if (!startTimeMoment.isValid()) {
    errors[objectName] = intervalErrors?.startTimeNotValid;
  }

  if (!endTimeMoment.isValid()) {
    errors[objectName] = intervalErrors?.endTimeNotValid;
  }
};
const timeBetweenMinutes = (
  errors: any,
  objectName: string,
  interval: { startTime: any; endTime: any },
  startingBoundary: number,
  endingBoundary: number,
  errorMessage: string,
  intervalErrors: { startTimeNotValid: string; endTimeNotValid: string },
  endTimeErrorMessagge: string
) => {
  const startTimeMoment = moment(interval?.startTime);
  const endTimeMoment = moment(interval?.endTime);

  const difference = endTimeMoment.diff(startTimeMoment, "minutes");

  if (difference < startingBoundary || difference > endingBoundary) {
    errors[objectName] = errorMessage;
  }

  timeIntervalEndTimeSmaller(
    errors,
    objectName,
    interval,
    intervalErrors,
    endTimeErrorMessagge
  );
};

const fieldArrayTimeIntervalEndTimeSmaller = (
  errors: any,
  fieldArrayName: string,
  index: number,
  interval: IntervalType,
  intervalErrors: { startTimeNotValid: string; endTimeNotValid: string },
  endTimeErrorMessagge: string
) => {
  const endTimeEqualOrSame = interval.endTime.isSameOrBefore(
    interval.startTime
  );
  if (endTimeEqualOrSame) {
    errors[fieldArrayName][index] = endTimeErrorMessagge;
  }

  if (!interval.startTime.isValid()) {
    errors[fieldArrayName][index] = intervalErrors.startTimeNotValid;
  }

  if (!interval.endTime.isValid()) {
    errors[fieldArrayName][index] = intervalErrors.endTimeNotValid;
  }
};

const requiredStartAndEndTimeInsideArray = (
  errors: any,
  timeArray: string,
  index: number,
  interval: IntervalType,
  errorMessage: string
) => {
  if (!interval?.startTime || !interval?.endTime) {
    errors[timeArray][index] = errorMessage;
  }
};

const checkArrayTimeBetweenInterval = (
  condition: boolean,
  errors: any,
  timeArray: string,
  index: number,
  customString: string
) => {
  if (condition) {
    errors[timeArray][index] = customString;
  }
};

const timeIntervalSameAsOtherTimeInterval = (
  interval: IntervalType,
  intervalToValidate: IntervalType
) => {
  return (
    interval.startTime.isSame(intervalToValidate.startTime) &&
    interval.endTime.isSame(intervalToValidate.endTime)
  );
};

const timeTrackerFieldArrayValidator = (
  values: any,
  errors: any,
  timeArray: string,
  intervalErrors: { startTimeNotValid: string; endTimeNotValid: string },
  projectErrorMessage: string,
  endTimeErrorMessagge: string,
  timesRequiredMessage: string,
  lunchTimeErrorMessage: string,
  previousIntervalErrorMessage: string,
  sameIntervalErrorMessage: string
): any => {
  errors[timeArray] = [];

  // eslint-disable-next-line array-callback-return
  values[timeArray].map((value: any, index: number) => {
    const currentStartTime = moment(value?.startTime);
    const currentEndTime = moment(value?.endTime);
    const lunchEndTime = moment(values.lunchTime.endTime);
    const lunchStartTime = moment(values.lunchTime.startTime);
    const validateStartTime = timeBetweenInterval(currentStartTime, {
      startTime: lunchStartTime,
      endTime: lunchEndTime,
    });
    const validateEndTime = timeBetweenInterval(currentEndTime, {
      startTime: lunchStartTime,
      endTime: lunchEndTime,
    });
    const validateTimeIntervalInside = timeIntervalSameAsOtherTimeInterval(
      { startTime: currentStartTime, endTime: currentEndTime },
      { startTime: lunchStartTime, endTime: lunchEndTime }
    );
    const conditionToCheckValueBetweenLunchTime =
      validateStartTime || validateEndTime;
    const validateStartTimeLunch = timeBetweenInterval(lunchStartTime, value);
    const validateEndTimeLunch = timeBetweenInterval(lunchEndTime, value);
    const lunchIntervalInsideValue =
      validateStartTimeLunch ||
      validateEndTimeLunch ||
      validateTimeIntervalInside;

    if (timeArray === "worked") {
      if (!value?.projectId) errors[timeArray][index] = projectErrorMessage;
    }

    fieldArrayTimeIntervalEndTimeSmaller(
      errors,
      timeArray,
      index,
      {
        startTime: currentStartTime,
        endTime: currentEndTime,
      },
      intervalErrors,
      endTimeErrorMessagge
    );

    requiredStartAndEndTimeInsideArray(
      errors,
      timeArray,
      index,
      value,
      timesRequiredMessage
    );

    checkArrayTimeBetweenInterval(
      lunchIntervalInsideValue,
      errors,
      timeArray,
      index,
      lunchTimeErrorMessage
    );

    checkArrayTimeBetweenInterval(
      conditionToCheckValueBetweenLunchTime,
      errors,
      timeArray,
      index,
      lunchTimeErrorMessage
    );

    if (
      values[timeArray].length > 1 &&
      index > 0 &&
      value.startTime &&
      value.endTime
    ) {
      for (let i = index - 1; i >= 0; i--) {
        const previousStartTime = moment(values[timeArray][i]?.startTime);
        const previousEndTime = moment(values[timeArray][i]?.endTime);

        const currentStartTimeBetweenPreviousInterval = timeBetweenInterval(
          currentStartTime,
          { startTime: previousStartTime, endTime: previousEndTime }
        );

        const previousStartTimeBetweenPreviousInterval = timeBetweenInterval(
          previousStartTime,
          { startTime: currentStartTime, endTime: currentEndTime }
        );

        const currentEndTimeBetweenPreviousInterval = timeBetweenInterval(
          currentEndTime,
          { startTime: previousStartTime, endTime: previousEndTime }
        );

        const previousEndTimeBetweenPreviousInterval = timeBetweenInterval(
          previousEndTime,
          { startTime: currentStartTime, endTime: currentEndTime }
        );

        const previousSameAsCurrent = timeIntervalSameAsOtherTimeInterval(
          {
            endTime: previousEndTime,
            startTime: previousStartTime,
          },
          {
            endTime: currentEndTime,
            startTime: currentStartTime,
          }
        );
        const previousIntervalBetweenCurrentInterval =
          currentStartTimeBetweenPreviousInterval ||
          currentEndTimeBetweenPreviousInterval ||
          previousStartTimeBetweenPreviousInterval ||
          previousEndTimeBetweenPreviousInterval;

        checkArrayTimeBetweenInterval(
          previousIntervalBetweenCurrentInterval,
          errors,
          timeArray,
          index,
          previousIntervalErrorMessage
        );

        if (previousSameAsCurrent) {
          errors[timeArray][index] = sameIntervalErrorMessage;
        }
      }
    }
  });

  if (errors[timeArray].length === 0) delete errors[timeArray];
};

const requiredHolidayName = (
  errors: any,
  objectName: string,
  currentHolidayName: any,
  currentHolidayDate: any,
  index: any,
  errorMessage: string
) => {
  if (!currentHolidayName || !currentHolidayDate) {
    errors[objectName][index] = errorMessage;
  }
};

const holidayEqualDates = (
  errors: any,
  objectName: string,
  previousHolidayDate: any,
  currentHolidayDate: any,
  index: number,
  errorMessage: string
) => {
  const endTimeEqualOrSame = currentHolidayDate.isSame(previousHolidayDate);

  if (endTimeEqualOrSame) {
    errors[objectName][index] = errorMessage;
  }
};

const holidaysFieldArrayValidator = (
  values: any,
  errors: any,
  holidayArray: string,
  errorMessages: {
    holidayName: string;
    datesNotEqual: string;
  }
): any => {
  errors[holidayArray] = [];

  values[holidayArray].map((value: any, index: number) => {
    const currentHolidayDate = moment(value?.date);
    const currentHolidayName = value?.name;
    requiredHolidayName(
      errors,
      holidayArray,
      currentHolidayName,
      value?.date,
      index,
      errorMessages?.holidayName
    );
    for (let i = index - 1; i >= 0; i--) {
      const previousHolidayDate = moment(values[holidayArray][i]?.date);
      holidayEqualDates(
        errors,
        holidayArray,
        previousHolidayDate,
        currentHolidayDate,
        index,
        errorMessages?.datesNotEqual
      );
    }
  });
  if (errors[holidayArray].length === 0) delete errors[holidayArray];
};
const requiredEquipmentFields = (
  errors: any,
  objectName: string,
  currentEquipmentStartDate: any,
  currentEquipmentItemName: any,
  currentEquipmentSerialNumber: any,
  currentEquipmentQuantity: any,
  currentEquipmentLocation: any,
  index: any,
  errorMessages: {
    fieldsMessage: string;
    quantitySmallerMessage: string;
    quantityWholeNumMessage: string;
  }
) => {
  if (currentEquipmentQuantity === "") {
    errors[objectName][index] = errorMessages?.fieldsMessage;
  } else if (currentEquipmentQuantity <= 0) {
    errors[objectName][index] = errorMessages?.quantitySmallerMessage;
  } else if (!Number.isInteger(currentEquipmentQuantity)) {
    errors[objectName][index] = errorMessages?.quantityWholeNumMessage;
  }
  if (
    !currentEquipmentStartDate ||
    !currentEquipmentItemName ||
    !currentEquipmentSerialNumber ||
    !currentEquipmentLocation
  ) {
    errors[objectName][index] = errorMessages?.fieldsMessage;
  }
};

const equipmentsFieldArrayValidator = (
  values: any,
  errors: any,
  equipmentArray: string,
  errorMessages: {
    fieldsMessage: string;
    quantitySmallerMessage: string;
    quantityWholeNumMessage: string;
  }
): any => {
  errors[equipmentArray] = [];

  values[equipmentArray].map((value: any, index: number) => {
    const currentEquipmentStartDate = value?.startDate;
    const currentEquipmentItemName = value?.itemName;
    const currentEquipmentSerialNumber = value?.serialNumber;
    const currentEquipmentQuantity = value?.quantity;
    const currentEquipmentLocation = value?.location;
    requiredEquipmentFields(
      errors,
      equipmentArray,
      currentEquipmentStartDate,
      currentEquipmentItemName,
      currentEquipmentSerialNumber,
      currentEquipmentQuantity,
      currentEquipmentLocation,
      index,
      errorMessages
    );
  });

  if (errors[equipmentArray].length === 0) delete errors[equipmentArray];
};

const handleExpensesVehicleKM = (
  fieldValue: string,
  values: any,
  previousValue: number,
  errors: any,
  errorMessages: {
    required: string;
    currentValueGreaterThanPreviousValue: string;
  }
) => {
  if (!values[fieldValue]) {
    errors[fieldValue] = errorMessages?.required;
  } else if (values[fieldValue] < previousValue) {
    errors[fieldValue] = errorMessages?.currentValueGreaterThanPreviousValue;
  }
};

const handleExpensesCostValidations = (
  fieldValue: string,
  values: any,
  errors: any,
  errorMessages: {
    required: string;
    cost: string;
  }
) => {
  if (!values[fieldValue]) {
    errors[fieldValue] = errorMessages?.required;
  } else if (values[fieldValue] <= 0) {
    errors[fieldValue] = errorMessages?.cost;
  }
};

const handleEmployeeWorkingHoursValidation = (
  values: any,
  errors: any,
  fieldValue: string,
  errorMessages: {
    required: string;
    negativeValue: string;
    greaterThan24: string;
  }
) => {
  if (values[fieldValue] === "") {
    errors[fieldValue] = errorMessages?.required;
  }
  if (values[fieldValue] < 0) {
    errors[fieldValue] = errorMessages?.negativeValue;
  }
  if (values[fieldValue] > 24) {
    errors[fieldValue] = errorMessages?.greaterThan24;
  }
};

const handleVehicleKilometers = (
  values: any,
  errors: any,
  fieldValue: string,
  errorMessages: {
    required: string;
    negativeValue: string;
  }
) => {
  if (values[fieldValue] === "") {
    errors[fieldValue] = errorMessages.required;
  }
  if (values[fieldValue] < 0) {
    errors[fieldValue] = errorMessages.negativeValue;
  }
};

export {
  EMAIL_REGEX,
  PASSWORD_REGEX,
  handleRequiredValidation,
  // customHandleRequiredValidation,
  handleEmployeeWorkingHoursValidation,
  handleRegexValidation,
  PASSWORD_ERROR_MESSAGE,
  CONFIRM_PASSWORD_ERROR_MESSAGE,
  EMAIL_ERROR_MESSAGE,
  NAME_DEPARTMENT_ERROR_MESSAGE,
  DEPARTMENT_NAME_REGEX,
  timeTrackerFieldArrayValidator,
  requiredTimeInterval,
  timeIntervalEndTimeSmaller,
  holidaysFieldArrayValidator,
  requiredHolidayName,
  equipmentsFieldArrayValidator,
  handleIntervalDateValidation,
  handleEquipmentsQuantityValidation,
  timeBetweenMinutes,
  handleExpensesVehicleKM,
  handleExpensesCostValidations,
  handleVehicleKilometers,
};
