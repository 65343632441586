import { useContext } from "react";
import BoxContainer from "../../shared/BoxContainer";
import CustomHeader from "../../shared/custom/CustomHeader";
import {
  BLUE_COLOR_ACCENT,
  BLUE_HOVER_COLOR_ACCENT,
} from "../../utils/constants/colors";
import CustomNavigationButton from "../../shared/custom/CustomNavigationButton";
import useIsMobile from "../../shared/hooks/useIsMobile";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useUpdateEquipmentMutation } from "../../data/endpoints/app.endpoints";
import { useSelector } from "react-redux";
import { selectEquipmentById } from "../../data/store/equipmentSlice";
import { useParams } from "react-router";
import { Formik } from "formik";
import { Box, useMediaQuery, Grid, useTheme } from "@mui/material";
import CustomDatePicker from "../../shared/CustomDatePicker";
import CustomTextField from "../../shared/custom/CustomTextField";
import { RootContext } from "../../context/RootContext";
import AlertDialog from "../../shared/modal/AlertDialog";
import {
  handleEquipmentsQuantityValidation,
  handleRequiredValidation,
} from "../../utils/regex/formRegex";
import { useTranslation } from "react-i18next";
import useIsDesktop from "../../shared/hooks/useIsDesktop";
import useIsLargeScreen from "../../shared/hooks/useIsLargeScreen";
import { tokens } from "../../core/AppStyles";
import moment from "moment";

const UpdateEquipment = () => {
  const { id } = useParams();
  const isMobile = useIsMobile();
  const isDesktop = useIsDesktop();
  const isLargeScreen = useIsLargeScreen();
  const [updateEquipment] = useUpdateEquipmentMutation();
  const equipment = useSelector(selectEquipmentById(id));

  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const {
    setOpenModal,
    showSnackbar,
    state: { openModal },
  } = useContext(RootContext);

  const initialValues = {
    startDate: equipment?.startDate,
    itemName: equipment?.itemName,
    serialNumber: equipment?.serialNumber,
    quantity: equipment?.quantity,
    description: equipment?.description,
    location: equipment?.location,
  };

  const validate = (values: typeof initialValues) => {
    const errors: Partial<typeof values> = {};

    if (!moment(values?.startDate).isValid()) {
      errors.startDate = t(
        "FORM_VALIDATIONS.TIME_TRACKER_VALIDATIONS.NOT_VALID.START_DATE"
      );
    }

    handleRequiredValidation(
      values,
      errors,
      "startDate",
      t("FORM_VALIDATIONS.REQUIRED")
    );
    handleRequiredValidation(
      values,
      errors,
      "itemName",
      t("FORM_VALIDATIONS.REQUIRED")
    );
    handleRequiredValidation(
      values,
      errors,
      "serialNumber",
      t("FORM_VALIDATIONS.REQUIRED")
    );
    handleEquipmentsQuantityValidation(values, errors, {
      required: t("FORM_VALIDATIONS.EQUIPMENTS.FIELDS_REQUIRED"),
      valueSmallerOrEqualZero: t(
        "FORM_VALIDATIONS.EQUIPMENTS.QUANTITY_SMALLER"
      ),
      shouldBeWholeNumber: t("FORM_VALIDATIONS.EQUIPMENTS.QUANTITY_WHOLE_NUM"),
    });
    handleRequiredValidation(
      values,
      errors,
      "location",
      t("FORM_VALIDATIONS.REQUIRED")
    );

    return errors;
  };

  const handleSubmitDialog = (values: typeof initialValues) => {
    updateEquipment({
      startDate: values?.startDate,
      itemName: values?.itemName,
      serialNumber: values?.serialNumber,
      quantity: values?.quantity,
      description: values?.description,
      location: values?.location,
      equipmentId: equipment?.equipmentId,
    }).then((res: any) => {
      if (!res?.error) {
        showSnackbar(
          `${t("EQUIPMENTS.UPDATE_EQUIPMENTS.EQUIPMENT_UPDATE")}`,
          false,
          true
        );
      } else if (res?.error) {
        showSnackbar(res?.error?.data?.error?.message, true, true);
      }
    });
    setOpenModal(false);
  };
  const onSubmit = () => {
    setOpenModal(true);
  };
  return (
    <BoxContainer>
      <CustomHeader
        title={t("EQUIPMENTS.UPDATE_EQUIPMENTS.TITLE")}
        subtitle={t("EQUIPMENTS.UPDATE_EQUIPMENTS.SUBTITLE")}
      >
        <CustomNavigationButton
          customStyle={{
            backgroundColor: BLUE_COLOR_ACCENT,
            width: "10%",
            display: "flex",
            gap: 1,
            alignSelf: "center",
            fontSize: "12px",
            ":hover": {
              bgcolor: BLUE_HOVER_COLOR_ACCENT,
              color: "white",
            },
          }}
          navigateTo={-1}
        >
          <KeyboardArrowLeftIcon /> {isMobile ? null : t("GO_BACK")}
        </CustomNavigationButton>
      </CustomHeader>
      <Box
        sx={{
          width: "auto",
          height: "auto",
          marginTop: isDesktop || isLargeScreen ? 4 : 0,
        }}
      >
        <Formik
          initialValues={initialValues}
          validate={validate}
          onSubmit={onSubmit}
        >
          {({
            handleSubmit,
            values,
            errors,
            setFieldValue,
            touched,
            handleChange,
          }) => (
            <>
              <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                  backgroundColor: colors.component,
                  padding: 3,
                  paddingTop: 5,
                  borderRadius: 3,
                }}
              >
                <Box
                  display="grid"
                  gap="30px"
                  gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                  sx={{
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 4",
                    },
                  }}
                >
                  <Grid item xs={12} sx={{ gridColumn: "span 2" }}>
                    <CustomDatePicker
                      onChange={(value) =>
                        setFieldValue(
                          "startDate",
                          value ? value?.format("YYYY-MM-DD") : "",
                          true
                        )
                      }
                      value={values?.startDate as any}
                      error={errors?.startDate}
                      label={t("START_DATE")}
                      name="startDate"
                      touched={!!touched?.startDate}
                      views={["day"]}
                      openTo="day"
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ gridColumn: "span 2" }}>
                    <CustomTextField
                      value={values?.itemName}
                      onChange={handleChange}
                      errors={errors?.itemName}
                      touched={touched?.itemName}
                      label={t("EQUIPMENTS.ITEM_NAME")}
                      name="itemName"
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ gridColumn: "span 2" }}>
                    <CustomTextField
                      value={values?.serialNumber}
                      onChange={handleChange}
                      errors={errors?.serialNumber}
                      touched={touched?.serialNumber}
                      label={t("EQUIPMENTS.SERIAL_NUMBER")}
                      name="serialNumber"
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ gridColumn: "span 2" }}>
                    <CustomTextField
                      type="number"
                      value={values?.quantity}
                      onChange={handleChange}
                      errors={errors?.quantity}
                      touched={touched?.quantity}
                      label={t("EQUIPMENTS.QUANTITY")}
                      name="quantity"
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ gridColumn: "span 2" }}>
                    <CustomTextField
                      value={values?.location}
                      onChange={handleChange}
                      errors={errors?.location}
                      touched={touched?.location}
                      label={t("EQUIPMENTS.LOCATION")}
                      name="location"
                    />
                  </Grid>
                </Box>
                <AlertDialog
                  openModal={openModal}
                  setOpenModal={setOpenModal}
                  buttonTitle={t("SUBMIT")}
                  width="10%"
                  handleSubmitDialog={() => handleSubmitDialog(values)}
                  color={BLUE_COLOR_ACCENT}
                  colorHover={BLUE_HOVER_COLOR_ACCENT}
                  mt="20px"
                >
                  {t("EQUIPMENTS.UPDATE_EQUIPMENTS.EQUIPMENT_UPDATE_CONFIRM")}
                </AlertDialog>
              </Box>
            </>
          )}
        </Formik>
      </Box>
    </BoxContainer>
  );
};

export default UpdateEquipment;
