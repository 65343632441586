import { useContext } from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useCreateVehicleMutation } from "../../data/endpoints/app.endpoints";
import CustomHeader from "../../shared/custom/CustomHeader";
import CustomNavigationButton from "../../shared/custom/CustomNavigationButton";
import { BLUE_COLOR_ACCENT } from "../../utils/constants/colors";
import VehicleFormFields from "./VehicleFormFields";
import { Formik } from "formik";
import { RootContext } from "../../context/RootContext";
import BoxContainer from "../../shared/BoxContainer";
import useIsMobile from "../../shared/hooks/useIsMobile";
import { useTranslation } from "react-i18next";
import {
  handleRequiredValidation,
  handleVehicleKilometers,
} from "../../utils/regex/formRegex";

export const AddVehicle = () => {
  const [createVehicle, result] = useCreateVehicleMutation();
  const isMobile = useIsMobile();

  const { t } = useTranslation();

  const { setOpenModal } = useContext(RootContext);

  const initialValues = {
    kilometers: "",
    model: "",
    plate: "",
  };

  const onSubmitForm = () => {
    setOpenModal(true);
  };

  const validate = (values: typeof initialValues) => {
    const errors = {} as any;
    handleRequiredValidation(
      values,
      errors,
      "kilometers",
      t("FORM_VALIDATIONS.REQUIRED")
    );
    handleRequiredValidation(
      values,
      errors,
      "model",
      t("FORM_VALIDATIONS.REQUIRED")
    );
    handleRequiredValidation(
      values,
      errors,
      "plate",
      t("FORM_VALIDATIONS.REQUIRED")
    );

    handleVehicleKilometers(values, errors, "kilometers", {
      required: t("FORM_VALIDATIONS.REQUIRED"),
      negativeValue: t("FORM_VALIDATIONS.VEHICLES.NEGATIVE_VALUE"),
    });
    //email

    return errors;
  };

  return (
    <BoxContainer>
      <CustomHeader
        title={t("VEHICLE.ADD_VEHICLE.TITLE")}
        subtitle={t("VEHICLE.ADD_VEHICLE.SUBTITLE")}
      >
        <CustomNavigationButton
          customStyle={{
            backgroundColor: BLUE_COLOR_ACCENT,
            width: "10%",
            display: "flex",
            gap: 1,
            alignSelf: "center",
            fontSize: "12px",
            ":hover": {
              bgcolor: "#1d61c6",
              color: "white",
            },
          }}
          navigateTo={-1}
        >
          <KeyboardArrowLeftIcon /> {isMobile ? null : t("GO_BACK")}
        </CustomNavigationButton>
      </CustomHeader>

      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={onSubmitForm}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
          resetForm,
        }: any) => (
          <VehicleFormFields
            buttonTitle={t("VEHICLE.ADD_VEHICLE.TITLE")}
            errors={errors}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            setFieldValue={setFieldValue}
            handleMutation={createVehicle}
            touched={touched}
            values={values}
            resetForm={resetForm}
            submitIsLoading={result?.isLoading}
          />
        )}
      </Formik>
    </BoxContainer>
  );
};

export default AddVehicle;
