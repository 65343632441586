import { Box, useTheme } from "@mui/material";
import ChatHeader from "./ChatHeader";
import ChatMessagesBox from "./ChatMessagesBox";
import ChatBoxSentMessage from "./ChatBoxSentMessage";
import { useState } from "react";
import ChatedWithHomePage from "./ChatedWithHomePage";
import { tokens } from "../../../../../core/AppStyles";
import { selectLoggedUser } from "../../../../../data/store/authSlice";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const KinetonChatContanier = () => {
  const [chatType, setchatType] = useState<boolean>(false);

  const loggedUser = useSelector(selectLoggedUser);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const decideChatType = (value: boolean) => {
    setchatType(value);
  };
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        overflow: "hidden",
        borderRadius: "10px",
        background: colors.primary[400],
      }}
    >
      <ChatHeader decideChatType={decideChatType} />
      {!chatType ? (
        <ChatMessagesBox />
      ) : loggedUser?.role === "Admin" ? (
        <ChatedWithHomePage />
      ) : (
        <ChatedWithHomePage />
      )}
      {!chatType ? <ChatBoxSentMessage /> : ""}
    </Box>
  );
};

export default KinetonChatContanier;
