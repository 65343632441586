import TableRow from "@mui/material/TableRow/TableRow";
import TableCell from "@mui/material/TableCell/TableCell";
import moment from "moment";
import { Link } from "react-router-dom";
import { Project } from "../../data/models/project.interface";
import { ProjectStatus } from "../../utils/enums/project-status.enum";
import {
  PROGRESSBAR_COMPLETED,
  PROGRESSBAR_GREEN,
  PROGRESSBAR_ORANGE,
  PROGRESSBAR_RED,
  PROJECT_COMPLETED_COLOR,
  PROJECT_INPROGRESS_COLOR,
  PROJECT_PENDING_COLOR,
} from "../../utils/constants/colors";
import { tokens } from "../../core/AppStyles";
import { Box, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { useEffect, useState } from "react";
import { IconEdit } from "../../shared/custom/CustomEditIcons";
import { useTranslation } from "react-i18next";

interface CustomTableRowProps {
  row: Project;
}

const ProjectTableRow = ({ row }: CustomTableRowProps) => {
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 300 : 600],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: colorProgress(daysInPercent, theme),
    },
  }));

  const colorProgress = (daysInPercent: number, theme: any) => {
    let color = "";
    if (daysInPercent > 50 && daysInPercent < 70) {
      color = PROGRESSBAR_ORANGE;
    } else if (
      (daysInPercent >= 70 && hoursDifference > 0) ||
      (daysInPercent >= 70 && daysInPercent < 100)
    ) {
      color = PROGRESSBAR_RED;
    } else if (daysInPercent >= 100 && hoursDifference <= 0) {
      color = PROGRESSBAR_COMPLETED;
    } else {
      color = PROGRESSBAR_GREEN;
    }

    if (theme.palette.mode === "dark") {
      if (daysInPercent > 50 && daysInPercent < 70) {
        color = PROGRESSBAR_ORANGE;
      } else if (
        (daysInPercent >= 70 && hoursDifference > 0) ||
        (daysInPercent >= 70 && daysInPercent < 100)
      ) {
        color = PROGRESSBAR_RED;
      } else if (daysInPercent >= 100 && hoursDifference <= 0) {
        color = PROGRESSBAR_COMPLETED;
      } else {
        color = PROGRESSBAR_GREEN;
      }
    }
    return color;
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  const startDate = row?.startTime;
  const currentDate = moment();
  const endDate = row?.endTime;
  const [daysDifference, setDaysDifference] = useState<number>(0);
  const [hoursDifference, setHoursDifference] = useState<number>(0);
  let [daysInPercent, setdaysInPercent] = useState<number>(0);

  useEffect(() => {
    if (startDate && endDate) {
      const startDateMoment = moment(startDate);
      const endDateMoment = moment(endDate);
      const timeTillEnd = endDateMoment.diff(currentDate, "days");
      if (timeTillEnd === 0)
        setHoursDifference(endDateMoment.diff(currentDate, "hours"));
      else {
        setDaysDifference(endDateMoment.diff(currentDate, "days"));
      }
      const allTimeProject = endDateMoment.diff(startDateMoment, "days");
      setdaysInPercent(allTimeProject);
      setdaysInPercent(100 - (timeTillEnd / allTimeProject) * 100);
    } else {
      setdaysInPercent(0);
    }
  }, [startDate, currentDate, endDate]);

  const departmentNames = row?.departments
    ?.map((department) => {
      return department?.name;
    })
    .join(", ");

  return (
    <TableRow
      key={row.projectId}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        {row.company}
      </TableCell>
      <TableCell align="left">{row.title}</TableCell>
      <TableCell align="left">{departmentNames}</TableCell>
      <TableCell align="left">
        {row.startTime ? moment(row.startTime).format("DD MMM YYYY") : "-"}
      </TableCell>
      <TableCell align="left">
        {row.endTime ? moment(row.endTime).format("DD MMM YYYY") : "-"}
      </TableCell>
      <TableCell
        sx={{
          color:
            row.status === ProjectStatus.COMPLETED
              ? PROJECT_COMPLETED_COLOR
              : row.status === ProjectStatus.INPROGRESS
              ? PROJECT_INPROGRESS_COLOR
              : PROJECT_PENDING_COLOR,
          fontWeight: "500",
        }}
        align="left"
      >
        {t(`STATUSES.${row?.status?.toUpperCase()?.replace(" ", "_")}`)}
      </TableCell>
      <TableCell align="center">
        <Box
          sx={{
            width: "80%",
            height: "20px",
          }}
        >
          <BorderLinearProgress
            variant="determinate"
            value={daysInPercent >= 100 ? (daysInPercent = 100) : daysInPercent}
          />
          <p
            style={{
              fontSize: "10px",
              padding: 0,
              marginTop: "2px",
            }}
          >
            {row?.status === ProjectStatus.PENDING
              ? `${t(
                  "USERS_ADMIN.PROJECTS.ALL_PROJECTS.PROGRESS_STATUS.NOT_STARTED_YET"
                )}`
              : (daysInPercent >= 100 && hoursDifference < 0) ||
                (daysDifference < 0 && daysInPercent >= 100)
              ? `${t(
                  "USERS_ADMIN.PROJECTS.ALL_PROJECTS.PROGRESS_STATUS.COMPLETED"
                )}`
              : daysDifference !== 0
              ? `${daysDifference} ${t(
                  "USERS_ADMIN.PROJECTS.ALL_PROJECTS.PROGRESS_STATUS.DAYS_LEFT"
                )}`
              : hoursDifference <= 0
              ? ""
              : `${hoursDifference} ${t(
                  "USERS_ADMIN.PROJECTS.ALL_PROJECTS.PROGRESS_STATUS.HOURS_LEFT"
                )}`}
          </p>
        </Box>
      </TableCell>
      <TableCell style={{ paddingTop: 0, paddingBottom: 0 }} align="left">
        {
          <Link
            style={{ color: colors.primary[100] }}
            to={`edit-project/${row.projectId}`}
          >
            <IconEdit fill={colors.invert} />
          </Link>
        }
      </TableCell>
    </TableRow>
  );
};

export default ProjectTableRow;
