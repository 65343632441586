import { useContext, useState } from "react";
import {
  Autocomplete,
  Box,
  CircularProgress,
  Grid,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";

import AlertDialog from "../../shared/modal/AlertDialog";
import { Department } from "../../data/models/department.interface";
import { RootContext } from "../../context/RootContext";
import {
  BLUE_COLOR_ACCENT,
  BLUE_HOVER_COLOR_ACCENT,
} from "../../utils/constants/colors";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import useIsMobile from "../../shared/hooks/useIsMobile";
import { tokens } from "../../core/AppStyles";
import CustomDatePicker from "../../shared/CustomDatePicker";
import { Stack } from "@mui/system";

interface UserFormFieldsProps {
  handleSubmit: any;
  values: any;
  touched: any;
  handleChange: any;
  errors: any;
  setFieldValue: any;
  children?: any;
  buttonTitle: string;
  existingDepartments: Department[];
  handleMutation: any;
  id?: string;
  resetForm?: any;
  submitIsLoading?: boolean;
  initialValues?: any;
}

const ProjectFormFields = ({
  handleSubmit,
  values,
  touched,
  handleChange,
  errors,
  setFieldValue,
  children,
  buttonTitle,
  existingDepartments,
  handleMutation,
  id,
  resetForm,
  submitIsLoading,
  initialValues,
}: UserFormFieldsProps) => {
  const {
    showSnackbar,
    setOpenModal,
    state: { openModal },
  } = useContext(RootContext);
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { t } = useTranslation();
  const handleSubmitDialog = () => {
    const {
      departments,
      title,
      description,
      company,
      anticipatedDuration,
      startTime,
      endTime,
    } = values;

    const departmentIdsMutated: any = [];
    departments?.map((departmentId: string) => {
      return departmentIdsMutated.push(departmentId);
    });

    if (id)
      handleMutation({
        projectId: id,
        departments: departmentIdsMutated,
        title,
        description: description === "" ? null : description,
        company,
        anticipatedDuration:
          anticipatedDuration === "" ? null : anticipatedDuration,
        startTime: startTime === "" ? null : startTime,
        endTime: endTime === "" ? null : endTime,
      }).then((res: any) => {
        if (!res.error) {
          showSnackbar(
            `${t("TEXT_RESPONSE.PROJECT_UPDATED_SUCCESSFULLY")}`,
            false,
            true
          );
          navigate(-1);
        } else if (res.error) {
          showSnackbar(res?.error?.data?.error?.message, true, true);
        }
        setOpenModal(false);
      });
    else
      handleMutation({
        departments: departmentIdsMutated,
        title,
        description: description === "" ? null : description,
        company,
        anticipatedDuration:
          anticipatedDuration === "" ? null : anticipatedDuration,
        startTime: startTime === "" ? null : startTime,
        endTime: endTime === "" ? null : endTime,
      }).then((res: any) => {
        if (!res.error) {
          showSnackbar(
            `${t("TEXT_RESPONSE.PROJECT_CREATED_SUCCESSFULLY")}`,
            false,
            true
          );
          resetForm(initialValues);
        } else if (res.error) {
          showSnackbar(res.error.data.error.message, true, true);
        }
        setOpenModal(false);
      });
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedDepartments, setSelectedDepartments] = useState<string[]>([]);

  const handleSelectChange = (event: any, value: any) => {
    setSelectedDepartments(value as string[]);

    values.departments = value;
    if (values.departments.length > 0) {
      delete errors.departments;
    } else {
      errors.departments = "Required";
    }
  };
  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      noValidate
      fontSize="16px"
      sx={{
        backgroundColor: colors.component,
        padding: 3,
        paddingTop: 5,
        borderRadius: 3,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            inputProps={{ style: { fontSize: "16px" } }}
            autoComplete="company"
            name="company"
            fullWidth
            id="company"
            size="small"
            label={t("CLIENT")}
            value={values.company}
            onChange={handleChange}
            color="info"
          />
          <Typography
            sx={{ color: "#d9534f", fontSize: "12px" }}
            variant="inherit"
          >
            {errors.company as any}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            inputProps={{ style: { fontSize: "16px" } }}
            fullWidth
            size="small"
            id="title"
            label={t("PROJECT")}
            name="title"
            autoComplete="title"
            value={values.title}
            onChange={handleChange}
            color="info"
          />
          <Typography
            sx={{ color: "#d9534f", fontSize: "12px" }}
            variant="inherit"
          >
            {errors.title as any}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            id="description"
            label={t("DESCRIPTION")}
            name="description"
            autoComplete="description"
            inputProps={{ style: { fontSize: "16px" } }}
            color="info"
            value={values.description}
            size="small"
            onChange={handleChange}
          />
          <Typography
            sx={{ color: "#d9534f", fontSize: "12px" }}
            variant="inherit"
          >
            {errors.description as any}
          </Typography>
        </Grid>
        {children}

        <Grid item xs={12}>
          {id ? (
            <Autocomplete
              multiple
              size="small"
              limitTags={2}
              id="multiple-limit-tags"
              options={existingDepartments}
              value={values.departments}
              isOptionEqualToValue={(option, value) =>
                option.departmentId === value.departmentId
              }
              getOptionLabel={(option) => option.name}
              onChange={handleSelectChange}
              renderInput={(params: any) => (
                <TextField {...params} label={t("DEPARTMENT")} />
              )}
              sx={{ width: "100%" }}
            />
          ) : (
            <Autocomplete
              multiple
              limitTags={2}
              size="small"
              value={values.departments}
              isOptionEqualToValue={(option, value) =>
                option.departmentId === value.departmentId
              }
              onChange={handleSelectChange}
              id="multiple-limit-tags"
              options={existingDepartments}
              getOptionLabel={(option) => option.name}
              renderInput={(params: any) => (
                <TextField {...params} label={t("DEPARTMENT")} />
              )}
              sx={{ width: "100%" }}
            />
          )}
          <Typography
            sx={{ color: "#d9534f", fontSize: "12px" }}
            variant="inherit"
          >
            {errors.departments &&
              touched.departments &&
              (errors.departments as any)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            type="number"
            autoComplete="anticipatedDuration"
            name="anticipatedDuration"
            fullWidth
            size="small"
            id="anticipatedDuration"
            inputProps={{ style: { fontSize: "16px" } }}
            label={t(
              "USERS_ADMIN.PROJECTS.CREATE_EDIT_PROJECT.ANTICIPATED_DURATION"
            )}
            value={values.anticipatedDuration}
            onChange={handleChange}
            color="info"
          />
          <Typography
            sx={{ color: "#d9534f", fontSize: "12px" }}
            variant="inherit"
          >
            {errors.anticipatedDuration as any}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <>
            <Stack>
              <CustomDatePicker
                value={values.startTime}
                touched={false}
                error={false}
                label={t("START_TIME")}
                inputFormat="MM/DD/YYYY"
                onChange={(value) => {
                  setFieldValue(
                    "startTime",
                    value ? value.format("YYYY-MM-DD") : "",
                    true
                  );
                }}
                name="startTime"
              />
            </Stack>
            <Typography
              sx={{ color: "#d9534f", fontSize: "12px" }}
              variant="inherit"
            >
              {errors.startTime &&
                touched.startTime &&
                (errors.startTime as any)}
            </Typography>
          </>
        </Grid>
        <Grid item xs={12}>
          <>
            <Stack>
              <CustomDatePicker
                value={values.endTime}
                touched={false}
                error={false}
                label={t("END_TIME")}
                inputFormat="MM/DD/YYYY"
                onChange={(value) =>
                  setFieldValue(
                    "endTime",
                    value ? value.format("YYYY-MM-DD") : "",
                    true
                  )
                }
                name="endTime"
              />
            </Stack>

            <Typography
              sx={{ color: "#d9534f", fontSize: "12px" }}
              variant="inherit"
            >
              {errors.endTime && touched.endTime && (errors.endTime as any)}
            </Typography>
          </>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <AlertDialog
          openModal={openModal}
          setOpenModal={setOpenModal}
          buttonTitle={
            submitIsLoading ? (
              <CircularProgress color="inherit" size={25} />
            ) : (
              buttonTitle
            )
          }
          width={isMobile ? "100%" : "auto"}
          values={values}
          handleSubmitDialog={handleSubmitDialog}
          color={BLUE_COLOR_ACCENT}
          colorHover={BLUE_HOVER_COLOR_ACCENT}
          mt="20px"
        >
          {id
            ? `${t("USERS_ADMIN.PROJECTS.CREATE_EDIT_PROJECT.CONFIRM_UPDATE")}`
            : `${t("USERS_ADMIN.PROJECTS.CREATE_EDIT_PROJECT.CONFIRM_CREATE")}`}
        </AlertDialog>
      </Box>
    </Box>
  );
};

export default ProjectFormFields;
