import { memo } from "react";
import { LinearProgress, TableCell, TableRow } from "@mui/material";

interface EquipmentTableRowProps {
  row: any;
  projectTotals: any;
  index: number;
}

const ProjectTrackerTableRow = ({
  row,
  projectTotals,
  index,
}: EquipmentTableRowProps) => {
  return (
    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      <TableCell component="th" scope="row">
        {row?.title}
      </TableCell>
      <TableCell>
        {projectTotals[index].toFixed(2).replace(/(\.0+|0+)$/, "")}
      </TableCell>
      <TableCell>
        <LinearProgress
          value={
            (projectTotals[index] / row?.expected_hours) * 100 > 100
              ? 100
              : (projectTotals[index] / row?.expected_hours) * 100
          }
          variant="determinate"
          color="inherit"
        />
        <small
          style={{
            fontSize: "10px",
            margin: 0,
            padding: 0,
          }}
        >
          {row?.expected_hours
            ? ((projectTotals[index] / row?.expected_hours) * 100)
                .toFixed(2)
                .replace(/(\.0+|0+)$/, "") + "%"
            : "0.0%"}
        </small>
      </TableCell>
      <TableCell>{row?.expected_hours}</TableCell>
      {row?.data?.map(
        (month: { month: string; total_hours: number }, index: number) => (
          <TableCell key={index}>
            {month?.total_hours.toFixed(2).replace(/(\.0+|0+)$/, "")}
          </TableCell>
        )
      )}
    </TableRow>
  );
};

export default memo(ProjectTrackerTableRow);
