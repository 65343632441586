import { useState, useEffect, useRef, ChangeEvent, FormEvent } from "react";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../../../../core/AppStyles";
import { useSentChatsMutation } from "../../../../../data/endpoints/app.endpoints";
import { useSelector } from "react-redux";
import { selectLoggedUser } from "../../../../../data/store/authSlice";
import useIsMobile from "../../../../../shared/hooks/useIsMobile";
import useIsTablet from "../../../../../shared/hooks/useIsTablet";
import { BLUE_COLOR_ACCENT } from "../../../../../utils/constants/colors";
import { useTranslation } from "react-i18next";
import { socket } from "../../../../../App";
import moment from "moment";

const ChatBoxSentMessage = () => {
  const [message, setMessage] = useState<string>("");
  const [typingEffect, setTypingEffect] = useState<string>("");
  const [btnDisabled, setBtnDisabled] = useState<boolean>(true);
  const [addChat] = useSentChatsMutation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const { t } = useTranslation();
  const loggedUser = useSelector(selectLoggedUser);
  const user = loggedUser?.firstName + " " + loggedUser?.lastName;

  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const handleActivity = (name: string) => {
      setTypingEffect(`${name} is typing ...`);
      setTimeout(() => {
        setTypingEffect("");
      }, 2000);
    };

    socket.on("typing_activity", handleActivity);

    return () => {
      socket.off("typing_activity", handleActivity);
    };
  }, []);

  useEffect(() => {
    if (message.length < 1) {
      setBtnDisabled(true);
    } else {
      setBtnDisabled(false);
    }
  }, [message.length]);

  const handleMessageChange = (e: ChangeEvent<HTMLInputElement>) => {
    setMessage(e.target.value);
    socket.emit("activity", user);
  };

  const sendMessage = (e: FormEvent) => {
    e.preventDefault();
    if (message.trim() !== "") {
      socket.emit("message", {
        message,
        user,
        userId: loggedUser?.userId,
        createdAt: moment(),
      });
      addChat({ message, user });
      setMessage("");
    }
  };

  return (
    <Box
      sx={{
        width: "90%",
        height: "20%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <small
        style={{
          width: "100%",
          height: "25%",
          margin: 0,
          padding: 0,
          marginLeft: "20px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <i>{typingEffect}</i>
      </small>
      <form
        style={{
          width: "100%",
          height: "55%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          overflow: "hidden",
          borderRadius: "15px",
        }}
        onSubmit={sendMessage}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "100%",
            alignItems: "center",
            backgroundColor: colors.timeline,
          }}
        >
          <input
            type="text"
            placeholder={t("CHAT.ENTER_MESSAGE")}
            value={message}
            onChange={handleMessageChange}
            ref={inputRef}
            inputMode="text"
            style={{
              border: "none",
              width: "82%",
              height: `${isMobile || isTablet ? "40px" : "50px"}`,
              outline: "0",
              paddingLeft: `${isMobile || isTablet ? "3vw" : "1vw"}`,
              background: "none",
              backgroundColor: colors.timeline,
              wordWrap: "break-word",
              wordBreak: "break-word",
              color: colors.invert,
              fontSize: "16px",
            }}
          />
          <button
            disabled={btnDisabled}
            style={{
              width: "16%",
              height: "40px",
              fontSize: "15px",
              fontWeight: 600,
              border: "none",
              background: "none",
              color: BLUE_COLOR_ACCENT,
              display: `${btnDisabled ? "none" : "block"}`,
              cursor: "pointer",
            }}
            type="submit"
          >
            {t("SEND")}
          </button>
        </Box>
      </form>
    </Box>
  );
};

export default ChatBoxSentMessage;
