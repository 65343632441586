import { memo, useContext, useState } from "react";
import { TableCell, TableRow } from "@mui/material";
import { Equipment } from "../../data/models/equipment.interface";
import { useDeleteEquipmentMutation } from "../../data/endpoints/app.endpoints";
import AlertDialog from "../../shared/modal/AlertDialog";
import { RootContext } from "../../context/RootContext";
import {
  BLUE_COLOR_ACCENT,
  BLUE_HOVER_COLOR_ACCENT,
  GREEN_COLOR_ACCENT,
  RED_COLOR_ACCENT,
  STATUS_PENDING_COLOR,
} from "../../utils/constants/colors";
import { selectLoggedUser } from "../../data/store/authSlice";
import { useSelector } from "react-redux";
import FormAlertDialog from "../../shared/modal/FormAlertDialog";
import { EquipmentStatus } from "../../utils/enums/equipment-status.enum";
import { Link } from "react-router-dom";
import { Delete } from "@mui/icons-material";
import { useTheme } from "@mui/material";
import { tokens } from "../../core/AppStyles";
import { IconEdit } from "../../shared/custom/CustomEditIcons";
import moment from "moment";
import CustomTooltip from "../../shared/custom/CustomDescriptionModal";
import { useTranslation } from "react-i18next";

interface EquipmentTableRowProps {
  row: Equipment;
}

const EquipmentTableRow = ({ row }: EquipmentTableRowProps) => {
  const user = useSelector(selectLoggedUser);
  const [openChangeStatusModal, setChangeStatusModal] = useState(false);
  const [openDeleteModal, setDeleteModal] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  const [deleteEquipment] = useDeleteEquipmentMutation();
  const {
    showSnackbar,
    setOpenModal,
    state: { openModal },
  } = useContext(RootContext);
  const handleSubmitDialog = () => {};
  const handleChangeStatusShowAlertDialog = () => {
    setChangeStatusModal(true);
  };

  const handleEquipmentDelete = () => {
    deleteEquipment(row?.equipmentId).then((res: any) => {
      if (!res.error) {
        setDeleteModal(false);
        showSnackbar(`${t("EQUIPMENTS.EQUIPMENT_DELETE")}`, false, true);
      } else if (res.error) {
        showSnackbar(res.error.data.error.message, true, true);
      }
    });
    setOpenModal(false);
  };

  const handleModalOpen = () => {
    setDeleteModal(true);
  };

  const statusColor =
    row?.status === EquipmentStatus.DAMAGED
      ? RED_COLOR_ACCENT
      : row?.status === EquipmentStatus.IN_POSSESSION
      ? GREEN_COLOR_ACCENT
      : STATUS_PENDING_COLOR;
  return (
    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      {user?.role === "Admin" ? (
        <TableCell component="th" scope="row">
          {row?.user?.firstName + " " + row?.user?.lastName}
        </TableCell>
      ) : null}
      <TableCell component="th" scope="row">
        {moment(row?.startDate).format("DD MMM YYYY")}
      </TableCell>
      <TableCell component="th" scope="row">
        {row?.endDate && moment(row?.endDate).format("DD MMM YYYY")}
      </TableCell>
      <TableCell component="th" scope="row">
        {row?.itemName}
      </TableCell>
      <TableCell component="th" scope="row">
        {row?.serialNumber}
      </TableCell>
      <TableCell component="th" scope="row">
        {row?.quantity}
      </TableCell>
      <TableCell component="th" scope="row">
        {row?.location}
      </TableCell>
      <TableCell
        component="th"
        sx={{ color: statusColor, fontWeight: "900" }}
        scope="row"
      >
        {t(
          `EQUIPMENTS.STATUSES.${row?.status.replace(" ", "_").toUpperCase()}`
        )}
      </TableCell>
      <TableCell component="th" scope="row">
        <CustomTooltip descriptionText={row?.description} />
      </TableCell>
      {user?.role === "Admin" && (
        <>
          <TableCell
            style={{ paddingTop: 0, paddingBottom: 0 }}
            component="th"
            scope="row"
          >
            <FormAlertDialog
              width="29%"
              buttonTitle={t("EQUIPMENTS.MODIFY")}
              openModal={openChangeStatusModal}
              handleShowAlertDialog={handleChangeStatusShowAlertDialog}
              handleSubmitDialog={handleSubmitDialog}
              color={BLUE_COLOR_ACCENT}
              colorHover={BLUE_HOVER_COLOR_ACCENT}
              setOpenModal={setChangeStatusModal}
              equipmentId={row?.equipmentId}
            ></FormAlertDialog>
          </TableCell>
          <TableCell
            style={{ paddingTop: 0, paddingBottom: 0 }}
            component="th"
            scope="row"
          >
            <Link
              style={{ color: colors.primary[100], cursor: "pointer" }}
              to={`/equipments/edit-equipment/${row.equipmentId}`}
            >
              <IconEdit fill={colors.invert} />
            </Link>
          </TableCell>
          <TableCell
            style={{ paddingTop: 0, paddingBottom: 0 }}
            component="th"
            scope="row"
          >
            <AlertDialog
              openModal={openDeleteModal}
              setOpenModal={setDeleteModal}
              buttonTitle=""
              handleSubmitDialog={handleEquipmentDelete}
              ButtonComponent={(props: any) => (
                <Delete onClick={handleModalOpen} sx={{ cursor: "pointer" }} />
              )}
              mt="20px"
            >
              {t("EQUIPMENTS.EQUIPMENT_DELETE_CONFIRM")}
            </AlertDialog>
          </TableCell>
        </>
      )}
    </TableRow>
  );
};

export default memo(EquipmentTableRow);
