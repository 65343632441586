import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Box,
  Pagination,
  PaginationItem,
  Stack,
  useTheme,
} from "@mui/material";
import { tokens } from "../../../../core/AppStyles";
import TableDetailsMobileModal from "../../../../shared/custom/CustomTableDetailModalMobile";
import { useState } from "react";

import { PrevIcon, NextIcon } from "../../../../shared/custom/CustomEditIcons";
import NoDataComponent from "../../../../shared/NoDataComponent";

interface ICustomProjectTrackerMobile {
  column1Name?: string;
  column2Name?: string;
  tableData?: any;
  isPaginated: boolean;
  rowsPerPage?: number;
  page?: number;
  count?: number;
  mt?: number;
  handleChangePage?: (event: any, newPage: number) => void;
  handleChangeRowsPerPage?: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
}

const CustomProjectTrackerMobile = ({
  column1Name,
  column2Name,
  tableData,
  isPaginated,
  rowsPerPage,
  page,
  count,
  mt,
  handleChangePage,
}: ICustomProjectTrackerMobile) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [rowDetails, setRowDetails] = useState<any[]>([]);
  const [header, setHeader] = useState<string>();

  const projectTotals: number[] = [];

  tableData?.forEach((entry: any, index: number) => {
    const entryHours = entry.data.reduce((entryTotal: any, item: any) => {
      return entryTotal + (item.total_hours || 0);
    }, 0);
    projectTotals.push(entryHours);
  });

  const handleDetailsModal = (id: string) => {
    const _rowDetails = tableData?.filter((info: any) => info?.title === id);

    const rowEmployeeData: any[] = [];

    rowEmployeeData.push({
      data: _rowDetails[0]?.data,
    });

    setRowDetails(rowEmployeeData);
    setHeader(id);
  };

  return tableData?.length !== 0 ? (
    <Paper
      sx={{
        borderRadius: "20px",
        boxShadow: `${colors.shadow} 1px 1px 5px 1px`,
        marginTop: `${mt}%`,
        backgroundColor: colors.component,
      }}
    >
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 300, backgroundColor: colors.component }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell>{column1Name}</TableCell>
              <TableCell align="left">{column2Name}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData?.map((row: any, index: number) => (
              <TableRow key={index}>
                <TableCell
                  align="center"
                  width="50px"
                  onClick={() => handleDetailsModal(row?.title)}
                >
                  <TableDetailsMobileModal
                    dataInfo={rowDetails}
                    header={header}
                  />
                </TableCell>
                <TableCell align="left">
                  <b>{row?.title}</b> <br />
                  {projectTotals[index].toFixed(2).replace(/(\.0+|0+)$/, "") +
                    " h / " +
                    (row?.expected_hours ? row?.expected_hours + " h" : "-")}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isPaginated ? (
        <Stack
          flexDirection="row"
          width="100%"
          justifyContent="center"
          padding={2}
          sx={{ backgroundColor: colors.component }}
        >
          <Pagination
            siblingCount={0}
            size="small"
            color="secondary"
            shape="rounded"
            count={count && rowsPerPage && Math.ceil(count / rowsPerPage)}
            page={page}
            onChange={handleChangePage}
            renderItem={(item) => (
              <PaginationItem
                sx={{ backgroundColor: colors.primary[400] }}
                slots={{ previous: PrevIcon, next: NextIcon }}
                {...item}
              />
            )}
          />
        </Stack>
      ) : null}
    </Paper>
  ) : (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <NoDataComponent title={"No projects for this department"} />
    </Box>
  );
};

export default CustomProjectTrackerMobile;
