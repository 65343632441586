import { useContext, useState } from "react";
import { decodeToken } from "react-jwt";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { Formik } from "formik";
import { CssBaseline, useTheme } from "@mui/material";
import {
  useLazyGetUserByIdQuery,
  useLazyLoginQuery,
} from "../../data/endpoints/app.endpoints";
import { RootContext } from "../../context/RootContext";
import { DecodedJwt } from "../../data/models/decoded-jwt.interface";
import {
  EMAIL_REGEX,
  handleRegexValidation,
  handleRequiredValidation,
} from "../../utils/regex/formRegex";
import BoxContainer from "../../shared/BoxContainer";
import LightDarkModeToggle from "../../shared/LightDarkModeToggle";
import PasswordTestField from "../../shared/PasswordTestField";
import { tokens } from "../../core/AppStyles";
import {
  BLUE_COLOR_ACCENT,
  BLUE_HOVER_COLOR_ACCENT,
} from "../../utils/constants/colors";
import { useTranslation } from "react-i18next";

export default function SignIn() {
  const [loginUser] = useLazyLoginQuery();
  const [getUserData] = useLazyGetUserByIdQuery();
  const [visiblePassword, setVisiblePassword] = useState(false);
  const { showSnackbar } = useContext(RootContext);
  const togglePassword = () => setVisiblePassword(!visiblePassword);

  const { t } = useTranslation();

  const initialValues = {
    email: "",
    password: "",
  };
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const validate = (values: typeof initialValues) => {
    const errors = {};

    handleRequiredValidation(
      values,
      errors,
      "email",
      t("FORM_VALIDATIONS.REQUIRED")
    );
    handleRegexValidation(
      values,
      errors,
      "email",
      EMAIL_REGEX,
      t("FORM_VALIDATIONS.EMAIL_ERROR_MESSAGE"),
      t("FORM_VALIDATIONS.REQUIRED")
    );
    handleRequiredValidation(
      values,
      errors,
      "password",
      t("FORM_VALIDATIONS.REQUIRED")
    );

    return errors;
  };

  const onSubmit = ({ email, password }: typeof initialValues) => {
    loginUser({ email, password }).then((res) => {
      if (res.data) {
        const decoded = decodeToken<DecodedJwt>(res.data.accessToken);
        if (decoded) {
          getUserData(decoded.sub).then((response) => {
            if (response.data) {
              localStorage.setItem(
                "jvelektro-ems-app.refresh-token",
                JSON.stringify(res.data?.refreshToken)
              );
              showSnackbar(t("LOGIN.SUCCESS"), false, true);
            }
          });
        }
      }
      if (res.error) {
        //@ts-ignore
        showSnackbar(res.error.data.error?.message, true, true);
      }
    });
  };

  return (
    <Box
      sx={{
        maxHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Box component="main" sx={{ flex: 1 }}>
        <CssBaseline />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Box display="flex">
            <LightDarkModeToggle />
          </Box>
        </Box>
        <BoxContainer>
          <Box
            sx={{
              width: "100%",
              height: "80vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                padding: 2,
              }}
            >
              <img src={colors.kinetonImage.logo} width="150px" alt="" />

              <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validate={validate}
              >
                {({ values, errors, handleSubmit, handleChange, touched }) => (
                  <Box component="form" onSubmit={handleSubmit} noValidate>
                    <TextField
                      margin="normal"
                      fullWidth
                      inputProps={{ style: { fontSize: "16px" } }}
                      id="email"
                      label={t("LOGIN.EMAIL")}
                      size="small"
                      name="email"
                      autoComplete="email"
                      value={values.email?.toLowerCase()}
                      onChange={handleChange}
                      color="info"
                      variant="outlined"
                      error={!!touched.email && !!errors.email}
                      helperText={touched.email && errors.email}
                    />

                    <PasswordTestField
                      label={t("LOGIN.PASSWORD")}
                      variableName="password"
                      error={errors.password}
                      handleChange={handleChange}
                      isVisible={visiblePassword}
                      toggleVisibility={togglePassword}
                      value={values.password}
                      hasMargin={true}
                      touched={touched.password}
                    />

                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{
                        backgroundColor: BLUE_COLOR_ACCENT,
                        mt: 2,
                        mb: 2,
                        p: 1.2,
                        ":hover": {
                          bgcolor: BLUE_HOVER_COLOR_ACCENT,
                          color: "white",
                        },
                      }}
                    >
                      {t("LOGIN.LOG_IN")}
                    </Button>
                  </Box>
                )}
              </Formik>
            </Box>
          </Box>
        </BoxContainer>
      </Box>
    </Box>
  );
}
