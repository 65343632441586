import { Box, Typography, useTheme } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ChatSelectToChat from "./ChatSelectToChat";
import { useEffect, useState } from "react";
import { useLazyGetConversationsQuery } from "../../../../../data/endpoints/app.endpoints";
import { useSelector } from "react-redux";
import { selectLoggedUser } from "../../../../../data/store/authSlice";
import ChatOneOneBox from "./ChatOneOneBox";
import { tokens } from "../../../../../core/AppStyles";

const ChatedWithHomePage = () => {
  const [contactsList, setContactsList] = useState<boolean>(true);
  const [openchat, setOpenChat] = useState<boolean>(true);
  const [userChat, setUserChat] = useState<string>("");
  const [chatwith, setchatWith] = useState<string>("");
  const loggedUser = useSelector(selectLoggedUser);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [getChatConversations, chatConversationResult] =
    useLazyGetConversationsQuery();

  useEffect(() => {
    getChatConversations(loggedUser?.userId);
  }, []);

  const handleScreenChange = () => {
    setContactsList(!contactsList);
  };
  const openChatConvo = (userId: string, name: string) => {
    setUserChat(userId);
    setchatWith(name);
    setOpenChat(!openchat);
  };

  return openchat ? (
    <Box
      sx={{
        width: "90%",
        height: "80%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {contactsList ? (
        <Box
          sx={{
            width: "100%",
            height: "98%",
            display: "flex",
            position: "relative",
          }}
        >
          <Box
            sx={{
              width: "98%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
              padding: "1% 0",
              overflowY: "scroll",
            }}
          >
            {chatConversationResult?.data?.length !== 0 ? (
              chatConversationResult?.data
                ?.slice()
                ?.reverse()
                ?.map((convo: any, index: number) => {
                  return (
                    <Box
                      sx={{
                        width: "100%",
                        minHeight: "50px",
                        maxHeight: "100px",
                        borderRadius: "5px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                      }}
                      key={index}
                      onClick={() => openChatConvo(convo?.userId, convo?.name)}
                    >
                      <Box
                        sx={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                          background: "lightgray",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <h1>{convo.name[0]}</h1>
                      </Box>
                      <Box
                        sx={{
                          width: "80%",
                          overflow: "hidden",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          justifyContent: "center",
                          color: colors.grey[100],
                        }}
                      >
                        <b>
                          <p
                            style={{
                              margin: 0,
                              padding: 0,
                            }}
                          >
                            {convo?.name}
                          </p>
                        </b>
                        <small style={{ color: colors.log }}>
                          Click to open the conversation
                        </small>
                      </Box>
                    </Box>
                  );
                })
            ) : (
              <Typography>
                Press the plus icon to start a conversation with someone.
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              width: "50px",
              height: "50px",
              background: colors.grey[800],
              position: "absolute",
              borderRadius: "50%",
              marginTop: "55%",
              marginLeft: "75%",
              boxShadow: "0 0 5px 1px lightblue",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              overflow: "hidden",
              zIndex: "9999",
            }}
          >
            <AddIcon onClick={handleScreenChange} />
          </Box>
        </Box>
      ) : (
        <ChatSelectToChat />
      )}
    </Box>
  ) : (
    <ChatOneOneBox reciverId={userChat} coversation_with={chatwith} />
  );
};

export default ChatedWithHomePage;
