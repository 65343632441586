import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Box,
  MenuItem,
  Pagination,
  PaginationItem,
  Stack,
  useTheme,
} from "@mui/material";
import { tokens } from "../../core/AppStyles";
import { useState } from "react";
import TableDetailsMobileModal from "../../shared/custom/CustomTableDetailModalMobile";
import {
  PrevIcon,
  NextIcon,
  IconEdit,
} from "../../shared/custom/CustomEditIcons";
import CustomSelector from "../../shared/custom/CustomSelector";
import { useTranslation } from "react-i18next";
import moment from "moment";

interface ICustomTableMobile {
  column1Name?: string;
  column2Name?: string;
  data?: any;
  isPaginated: boolean;
  rowsPerPage?: number;
  page?: number;
  count?: number;
  mt?: number;
  handleChangePage?: (event: any, newPage: number) => void;
  handleChangeRowsPerPage?: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
}

const CustomTableProjectsMobile = ({
  column1Name,
  column2Name,
  data,
  isPaginated,
  rowsPerPage,
  page,
  count,
  mt,
  handleChangePage,
  handleChangeRowsPerPage,
}: ICustomTableMobile) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [rowDetails, setRowDetails] = useState<any>();
  const [header, setHeader] = useState<string>();
  const { t } = useTranslation();

  const handleDetailsModal = (title: string) => {
    const _rowDetails = data?.filter((info: any) => info?.title === title);

    const rowProjectData: any[] = [];

    _rowDetails?.map((info: any) => {
      return rowProjectData.push({
        data: [
          {
            key: t("PROJECT"),
            value: info?.title,
          },
          {
            key: t("CLIENT"),
            value: info?.company,
          },
          {
            key: t("STATUS"),
            value: info?.status,
          },
          {
            key: t(
              "USERS_ADMIN.PROJECTS.CREATE_EDIT_PROJECT.ANTICIPATED_DURATION"
            ),
            value: info?.anticipatedDuration || "-",
          },
          {
            key: t("START_TIME"),
            value: moment(info?.startTime)?.format("DD MMM YYYY"),
          },
          {
            key: t("END_TIME"),
            value: info?.endTime
              ? moment(info?.endTime)?.format("DD MMM YYYY")
              : "-",
          },
        ],
        actions: [
          {
            key: <IconEdit fill={colors.invert} />,
            path: `edit-project/${info?.projectId}`,
          },
        ],
      });
    });

    setRowDetails(rowProjectData);
    setHeader(rowProjectData?.[0]?.data?.[0]?.value);
  };

  return (
    <Paper
      sx={{
        borderRadius: "20px",
        boxShadow: `${colors.shadow} 1px 1px 5px 1px`,
        marginTop: `${mt}%`,
        backgroundColor: colors.component,
      }}
    >
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 300, backgroundColor: colors.component }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell>{column1Name}</TableCell>
              <TableCell align="left">{column2Name}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row: any, index: number) => (
              <TableRow key={index}>
                <TableCell
                  align="center"
                  width="50px"
                  onClick={() => handleDetailsModal(row?.title)}
                >
                  <TableDetailsMobileModal
                    dataInfo={rowDetails}
                    header={header}
                  />
                </TableCell>
                <TableCell align="left">
                  <b>{row?.title}</b> <br />
                  {row?.status}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isPaginated ? (
        <Stack
          flexDirection="row"
          width="100%"
          justifyContent="center"
          padding={2}
          sx={{ backgroundColor: colors.component }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <span
              style={{
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              <p
                style={{
                  color: "gray",
                }}
              >
                {t("ROWS")}:
              </p>
              <CustomSelector
                size="medium"
                value={rowsPerPage}
                onSelectChange={handleChangeRowsPerPage}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={count}>ALL</MenuItem>
              </CustomSelector>
            </span>
            <Pagination
              siblingCount={0}
              size="small"
              color="secondary"
              shape="rounded"
              count={count && rowsPerPage && Math.ceil(count / rowsPerPage)}
              page={page}
              onChange={handleChangePage}
              renderItem={(item) => (
                <PaginationItem
                  sx={{ backgroundColor: colors.primary[400] }}
                  slots={{ previous: PrevIcon, next: NextIcon }}
                  {...item}
                />
              )}
            />
          </Box>
        </Stack>
      ) : null}
    </Paper>
  );
};

export default CustomTableProjectsMobile;
