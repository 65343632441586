import { ReactElement, Ref, forwardRef, useContext } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import CustomButton from "../custom/CustomButton";
import { Box, DialogContent, MenuItem, Slide, useTheme } from "@mui/material";
import AlertActionButton from "./AlertActionButton";
import { Formik } from "formik";
import CustomDatePicker from "../CustomDatePicker";
import CustomTextField from "../custom/CustomTextField";
import CustomSelector from "../custom/CustomSelector";
import { useUpdateEquipmentMutation } from "../../data/endpoints/app.endpoints";
import { RootContext } from "../../context/RootContext";
import { handleRequiredValidation } from "../../utils/regex/formRegex";
import { tokens } from "../../core/AppStyles";
import { useTranslation } from "react-i18next";
import { TransitionProps } from "@mui/material/transitions";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement<any, any>;
  },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export const FormAlertDialog = ({
  buttonTitle,
  openModal,
  setOpenModal,
  handleSubmitDialog,
  children,
  width = "55%",
  handleShowAlertDialog,
  color,
  colorHover,
  disabled,
  mt,
  ButtonComponent = undefined,
  isConfirm = true,
  equipmentId,
}: any) => {
  const handleClose = () => {
    setOpenModal(false);
  };

  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [updateEquipmentStatus] = useUpdateEquipmentMutation();

  const { showSnackbar } = useContext(RootContext);

  const handleSubmitStatusDialog = (values: any) => {
    updateEquipmentStatus({ ...values, equipmentId }).then((res: any) => {
      if (res?.data) {
        showSnackbar(
          `${t("EQUIPMENTS.CHANGE_EQUIPMENT_STATUS.EQUIPMENT_MODIFY")}`,
          false,
          true
        );
        setOpenModal(false);
      } else if (res?.error) {
        showSnackbar(
          `${t("EQUIPMENTS.CHANGE_EQUIPMENT_STATUS.EQUIPMENT_MODIFY_ERROR")}`,
          true,
          true
        );
      }
    });
  };

  const validate = (values: any) => {
    const errors = {};

    handleRequiredValidation(
      values,
      errors,
      "endDate",
      t("FORM_VALIDATIONS.REQUIRED")
    );
    handleRequiredValidation(
      values,
      errors,
      "status",
      t("FORM_VALIDATIONS.REQUIRED")
    );

    return errors;
  };

  return (
    <>
      {ButtonComponent === undefined ? (
        <CustomButton
          type="submit"
          onClick={handleShowAlertDialog}
          width={width}
          title={buttonTitle}
          color={color}
          colorHover={colorHover}
          disabled={disabled}
          mt={mt}
        />
      ) : (
        <ButtonComponent />
      )}
      <Dialog
        TransitionComponent={Transition}
        fullWidth
        open={openModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {isConfirm ? (
          <Box sx={{ backgroundColor: colors.component }}>
            <DialogTitle
              sx={{ paddingLeft: 3, paddingRight: 3 }}
              id="alert-dialog-title"
            >
              {t("EQUIPMENTS.CHANGE_EQUIPMENT_STATUS.TITLE")}
            </DialogTitle>
            <Formik
              initialValues={{
                endDate: new Date(),
                description: "",
                status: "",
              }}
              onSubmit={handleSubmitStatusDialog}
              validate={validate}
            >
              {({
                errors,
                values,
                handleSubmit,
                handleChange,
                setFieldValue,
                touched,
              }) => (
                <>
                  <DialogContent
                    sx={{
                      paddingLeft: 3,
                      paddingRight: 3,
                      paddingBottom: 0,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                        marginTop: 1,
                      }}
                      component="form"
                      onSubmit={() => {}}
                    >
                      <CustomDatePicker
                        views={["day"]}
                        openTo="day"
                        onChange={(value) =>
                          setFieldValue(
                            "endDate",
                            value ? value.format("YYYY-MM-DD") : "",
                            true
                          )
                        }
                        value={values.endDate}
                        label={t("END_DATE")}
                        name="endDate"
                        error={errors?.endDate}
                        touched={!!touched?.endDate}
                        fullWidth={true}
                      />
                      <CustomSelector
                        name="status"
                        width={"auto"}
                        label={t("STATUS")}
                        value={values.status}
                        onSelectChange={handleChange}
                        errors={errors?.status}
                        touched={touched?.status}
                      >
                        <MenuItem value={"ALL"}>{t("STATUSES.ALL")}</MenuItem>
                        <MenuItem value={"In Possession"}>
                          {t("EQUIPMENTS.STATUSES.IN_POSSESSION")}
                        </MenuItem>
                        <MenuItem value={"Handed Over"}>
                          {t("EQUIPMENTS.STATUSES.HANDED_OVER")}
                        </MenuItem>
                        <MenuItem value={"Damaged"}>
                          {t("EQUIPMENTS.STATUSES.DAMAGED")}
                        </MenuItem>
                      </CustomSelector>
                      <CustomTextField
                        onChange={handleChange}
                        label={t("DESCRIPTION_OPTIONAL")}
                        name="description"
                        type="text"
                        variant="outlined"
                        placeholder={t("DESCRIPTION_OPTIONAL")}
                        value={values?.description}
                      />
                    </Box>
                  </DialogContent>
                  <DialogActions
                    sx={{ paddingLeft: 2, paddingRight: 2, paddingBottom: 2 }}
                  >
                    <AlertActionButton
                      title={t("CANCEL")}
                      variant="outlined"
                      onClick={handleClose}
                    />
                    <AlertActionButton
                      title={t("CONFIRM")}
                      variant="contained"
                      onClick={handleSubmit as any}
                    />
                  </DialogActions>
                </>
              )}
            </Formik>
          </Box>
        ) : (
          children
        )}
      </Dialog>
    </>
  );
};

export default FormAlertDialog;
