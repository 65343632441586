import { useTheme } from "@mui/material";
import styled from "@emotion/styled";
import { tokens } from "../AppStyles";
import { MenuOutlined } from "@mui/icons-material";
import useIsMobile from "../../shared/hooks/useIsMobile";
import useIsTablet from "../../shared/hooks/useIsTablet";
import useIsLargeScreen from "../../shared/hooks/useIsLargeScreen";

interface SidebarHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  collapsed?: any;
  setCollapsed?: any;
  handleItemClick: any;
}

const StyledSidebarHeader = styled.div`
  height: 64px;
  min-height: 64px;
  display: flex;
  align-items: center;
  padding: 0 20px;

  > div {
    width: 100%;
    overflow: hidden;
  }
`;

export const SidebarHeader: React.FC<SidebarHeaderProps> = ({
  collapsed,
  setCollapsed,
  handleItemClick,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isLargeScreen = useIsLargeScreen();
  return (
    <StyledSidebarHeader>
      <div
        style={{
          display: "flex",
          gap: isLargeScreen ? "20px" : "10px",
          padding: "7px",
          alignItems: "center",
          cursor: "pointer",
          marginTop: "30px",
        }}
      >
        <MenuOutlined
          onClick={() => {
            setCollapsed(!collapsed);
          }}
        />
        <img
          onClick={() => {
            handleItemClick("/dashboard");
            (isMobile || isTablet) && setCollapsed({ left: false });
          }}
          src={colors.kinetonImage["logo"]}
          alt=""
          width={160}
          height={65}
          style={{
            marginLeft: 5,
          }}
        />
      </div>
    </StyledSidebarHeader>
  );
};
