import { useContext, useMemo, useState } from "react";
import {
  Box,
  CircularProgress,
  Typography,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import { useSelector } from "react-redux";
import { selectLoggedUser } from "../../data/store/authSlice";
import FieldArrayComponent from "../../shared/FieldArrayComponent";
import FieldArrayTimePicker from "../../shared/FieldArrayTimePicker";
import { tokens } from "../../core/AppStyles";
import { useTheme } from "@mui/material";
import useIsTablet from "../../shared/hooks/useIsTablet";
import AlertDialog from "../../shared/modal/AlertDialog";
import { RootContext } from "../../context/RootContext";
import { useAddTimeMutation } from "../../data/endpoints/app.endpoints";
import { useNavigate, useParams } from "react-router";
import {
  BLUE_COLOR_ACCENT,
  BLUE_HOVER_COLOR_ACCENT,
} from "../../utils/constants/colors";
import { checkTimeIntervalToDisplayInvalid } from "../../helper/reusable-functions";
import { User } from "../../data/models/user.interface";
import useIsMobile from "../../shared/hooks/useIsMobile";
import { useTranslation } from "react-i18next";
interface TimeTrackerFormFieldsProps {
  values: any;
  handleChange: any;
  handleSubmit: any;
  setFieldValue: any;
  errors?: any;
  touched?: any;
  selectedUser?: User;
  userDate?: string;
}

const TimeTrackerFormFields = ({
  values,
  handleChange,
  handleSubmit,
  setFieldValue,
  touched,
  errors,
  selectedUser,
  userDate,
}: TimeTrackerFormFieldsProps) => {
  const user = useSelector(selectLoggedUser);

  const isNonMobile = useMediaQuery("(min-width:600px)");

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const { t } = useTranslation();
  const {
    setOpenModal,
    showSnackbar,
    state: { openModal },
  } = useContext(RootContext);
  const [addTime, result] = useAddTimeMutation();
  const navigate = useNavigate();
  const params = useParams();

  const [totalHours, setTotalHours] = useState<string>("00h : 00min");

  const handleSubmitDialog = () => {
    const { worked, optionalBreak, lunchTime, travelTime } = values;

    addTime({
      workingTime: worked,
      optionalBreak: optionalBreak,
      lunchTime,
      travelTime,
    }).then((res: any) => {
      if (!res.error) {
        setOpenModal(false);
        showSnackbar(
          `${t("TEXT_RESPONSE.TIMETRACKER_CREATED_SUCCESSFULLY")}`,
          false,
          true
        );
        if (params?.id && params?.date) {
          navigate(-1);
        } else {
          navigate("/dashboard");
        }
      } else if (res.error) {
        if (res.error.status === 500) {
          showSnackbar(`${t("TIME_TRACKER.USER.RESPONSE.ERROR")}`, true, true);
        } else showSnackbar(res.error.data.error.message, true, true);
      }
    });
  };

  useMemo(() => {
    let total = 0;
    values.worked.map((work: any) => {
      const endTimeMoment = moment(work.endTime);
      const startTimeMoment = moment(work.startTime);
      const difference = moment.duration(endTimeMoment.diff(startTimeMoment));
      if (endTimeMoment.isValid() && startTimeMoment.isValid())
        total += difference.asMilliseconds();
    });

    values.optionalBreak.map((breakTime: any) => {
      const endTimeMoment = moment(breakTime.endTime);
      const startTimeMoment = moment(breakTime.startTime);
      const difference = moment.duration(endTimeMoment.diff(startTimeMoment));
      if (endTimeMoment.isValid() && startTimeMoment.isValid())
        total -= difference.asMilliseconds();
    });

    setTotalHours(
      (total / (1000 * 60 * 60)).toFixed(2).replace(/(\.0+|0+)$/, "") +
        " " +
        t("DASHBOARD.HOURS").toLowerCase()
    );
  }, [values?.worked, values?.optionalBreak]);

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: "2%" }}>
      {useIsTablet() ? (
        <Box
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
          }}
        >
          <Box
            style={{
              display: "flex",
              gap: 5,
              alignItems: "center",
            }}
          >
            <Typography
              gutterBottom
              variant="h5"
              fontSize={isMobile || isTablet ? "14px" : "inherit"}
              sx={{ fontWeight: "600", color: colors.grey }}
            >
              {selectedUser &&
                `${selectedUser.firstName} ${selectedUser.lastName}`}
            </Typography>
          </Box>

          <Box
            style={{
              display: "flex",
              gap: 2,
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{ fontWeight: "400", color: colors.grey }}
              gutterBottom
              variant="h5"
            >
              {t("DATE")}:
            </Typography>
            <Typography
              gutterBottom
              variant="h5"
              sx={{
                fontWeight: "600",
                fontSize: "14px",
                color: colors.grey,
              }}
            >
              {userDate
                ? moment(userDate).format("DD/MM/yyyy")
                : moment(new Date()).format("DD/MM/yyyy")}
            </Typography>
          </Box>
        </Box>
      ) : null}
      <Box
        display="grid"
        gap="30px"
        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },

          backgroundColor: colors.component,
          padding: 3,
          borderRadius: 3,
        }}
      >
        <Box
          sx={{
            gridColumn: "span 4",
          }}
        >
          <FieldArrayComponent
            values={values.worked}
            touched={touched}
            fieldName={"worked"}
            errors={errors}
            userId={selectedUser?.userId}
            userDate={userDate}
          />
        </Box>

        <Box
          display="grid"
          gap="30px"
          gridTemplateColumns="repeat(4, minmax(0, 1fr))"
          sx={{
            gridColumn: "span 4",
          }}
        >
          <Box
            sx={{
              gridColumn: useIsTablet() ? "span 4" : "span 3",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderRadius: 1,
              }}
            >
              <Typography
                sx={{
                  fontWeight: 600,
                  color: colors.grey[200],
                }}
                variant="h5"
              >
                {t("PAUSE")}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                gap: 1,
                flexDirection: "row",
                marginBottom: 1,
                marginTop: 2,
              }}
            >
              <FieldArrayTimePicker
                name="lunchTime.startTime"
                label={t("TIME_TRACKER.USER.START_LUNCH")}
                sx={{ width: `${isMobile || isTablet ? "40%" : "50%"}` }}
                touched={touched?.lunchTime?.startTime}
                errors={errors?.lunchTime?.startTime}
              />
              <FieldArrayTimePicker
                name="lunchTime.endTime"
                label={t("TIME_TRACKER.USER.END_LUNCH")}
                sx={{ width: `${isMobile || isTablet ? "40%" : "50%"}` }}
                touched={touched?.lunchTime?.endTime}
                errors={errors?.lunchTime?.endTime}
              />
              <Typography
                sx={{
                  width: `${isMobile || isTablet ? "18%" : "8%"}`,
                  fontWeight: "900",
                  border: `1px solid ${colors.grey[500]}`,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 1,
                  color: colors.grey[200],
                }}
                variant="inherit"
              >
                {checkTimeIntervalToDisplayInvalid(
                  values.lunchTime.startTime,
                  values.lunchTime.endTime
                )}
              </Typography>
            </Box>
            <Typography
              sx={{
                textAlign: "left",
                color: "red",
                fontSize: "12px",
              }}
            >
              {errors && errors.lunchTime && errors.lunchTime}
            </Typography>
            {/* </Box> */}
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "13vh",
              border: `1px solid ${colors.grey[500]}`,
              borderRadius: 1,
              p: 3,
              display: useIsTablet() ? "none" : "flex",
              gap: 3,
              gridColumn: "span 1 ",
              color: colors.primary[100],
              justifyContent: "center",
              placeItems: "center",
            }}
          >
            <Box>
              <Typography
                gutterBottom
                variant="subtitle2"
                sx={{
                  color: colors.grey[200],
                }}
              >
                {t("FIRST_NAME")}
              </Typography>
              <Typography
                gutterBottom
                variant="h4"
                sx={{
                  fontWeight: "600",
                  color: colors.grey[200],
                }}
              >
                {selectedUser
                  ? `${selectedUser.firstName}  ${selectedUser.lastName}`
                  : `${user.firstName}  ${user.lastName}`}
              </Typography>
            </Box>
            <Box>
              <Typography
                gutterBottom
                variant="subtitle2"
                sx={{
                  color: colors.grey[200],
                }}
              >
                {t("DATE")}/
              </Typography>
              <Typography
                gutterBottom
                variant="h5"
                sx={{
                  fontWeight: "600",
                  color: colors.grey[200],
                }}
              >
                {userDate
                  ? moment(userDate).format("DD/MM/yyyy")
                  : moment(new Date()).format("DD/MM/yyyy")}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            border: "none",
            borderRadius: 1,
            gridColumn: "span 4",
          }}
        >
          <FieldArrayComponent
            values={values.optionalBreak}
            touched={touched}
            fieldName={"optionalBreak"}
            errors={errors}
            userId={selectedUser?.userId}
            userDate={userDate}
          />
        </Box>
        <Box sx={{ gridColumn: "span 2" }}></Box>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
            alignItems: "baseline",
            gap: 1,
            gridColumn: "span 2",
          }}
        >
          <Box
            sx={{
              padding: "8px",
              borderRadius: "5px",
              fontSize: "13px",
              fontWeight: 500,
              textAlign: "center",
              width: `${isMobile || isTablet ? "80%" : "60%"}`,
              boxShadow: `1px 1px 3px 0.5px ${colors.shadow}`,
            }}
          >
            {t("TIME_TRACKER.USER.WORK_TIME")} <b>{totalHours}</b>
          </Box>
          <AlertDialog
            width="30%"
            buttonTitle={
              result?.isLoading ? (
                <CircularProgress color="inherit" size={25} />
              ) : (
                `${t("SUBMIT")}`
              )
            }
            openModal={openModal}
            setOpenModal={setOpenModal}
            handleSubmitDialog={handleSubmitDialog}
            color={BLUE_COLOR_ACCENT}
            colorHover={BLUE_HOVER_COLOR_ACCENT}
            mt="20px"
          >
            {selectedUser ? (
              <p>
                {`${selectedUser.firstName} ${selectedUser.lastName}`}{" "}
                {t("TIME_TRACKER.USER.TOTAL_WORKING_TIME")}
              </p>
            ) : (
              <p>{t("TIME_TRACKER.USER.YOUR_TOTAL_WORKING_TIME")}</p>
            )}
            <strong>{totalHours}</strong>
            <p></p>
            <p>{t("TIME_TRACKER.USER.MODAL_TEXT")}</p>
          </AlertDialog>
        </Box>
      </Box>
    </Box>
  );
};

export default TimeTrackerFormFields;
