/* eslint-disable react-hooks/exhaustive-deps */
import { Box, MenuItem, useMediaQuery, useTheme } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { RootContext } from "../../context/RootContext";
import { useTranslation } from "react-i18next";
import { tokens } from "../../core/AppStyles";
import { useSelector } from "react-redux";
import { selectLoggedUser } from "../../data/store/authSlice";
import useIsMobile from "../../shared/hooks/useIsMobile";
import useIsTablet from "../../shared/hooks/useIsTablet";
import BoxContainer from "../../shared/BoxContainer";
import CustomHeader from "../../shared/custom/CustomHeader";
import {
  BLUE_COLOR_ACCENT,
  BLUE_HOVER_COLOR_ACCENT,
} from "../../utils/constants/colors";
import CustomNavigationButton from "../../shared/custom/CustomNavigationButton";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { Formik } from "formik";
import CustomSelector from "../../shared/custom/CustomSelector";
import { Expenses } from "../../utils/enums/expenses.enum";
import CustomTextField from "../../shared/custom/CustomTextField";
import AlertDialog from "../../shared/modal/AlertDialog";
import UploadFile from "../../components/UploadFile";
import {
  handleExpensesCostValidations,
  handleExpensesVehicleKM,
  handleRequiredValidation,
} from "../../utils/regex/formRegex";
import {
  useAddExpenseMutation,
  useLazyGetAllVehiclesQuery,
  useLazyGetPreviousKilometersByVehicleIdQuery,
} from "../../data/endpoints/app.endpoints";
import { FilePond } from "filepond";
import CustomSearchSelector from "../../shared/custom/CustomSearchSelector";
import { selectAllUsers } from "../../data/store/employeeSlice";

const AddExpenses = () => {
  const navigate = useNavigate();

  const formikRef = useRef<any>(null);
  const { showSnackbar } = useContext(RootContext);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme();
  const [vehicle, setVehicleId] = useState(undefined);
  const colors = tokens(theme.palette.mode);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const loggedUser = useSelector(selectLoggedUser);
  const [selectUser, setSelectUser] = useState<any>("");
  const [addExpense] = useAddExpenseMutation();
  const [allVehicles, allVehiclesResult] = useLazyGetAllVehiclesQuery();
  const [previousKilometers, previousKilometersResult] =
    useLazyGetPreviousKilometersByVehicleIdQuery();
  const users = useSelector(selectAllUsers);

  let pond = useRef<FilePond | null>(null);

  const onSubmit = async (values: typeof initialValues, resetForm: any) => {
    setIsLoading(true);
    setOpenModal(false);
    pond.current
      ?.processFile()
      .then(async (res: any) => {
        const file = JSON.parse(res.serverId);
        await handleExpensesForm(values, resetForm, file?.fileId);
      })
      .catch((error: unknown) => {
        setIsLoading(false);
        showSnackbar(`${t("FILE_MANAGER.FILE_UPLOAD.ERROR")}`, true, true);
      });
  };

  useEffect(() => {
    vehicle &&
      previousKilometers({
        vehicleId: vehicle,
      });
  }, [vehicle]);

  const handleSelectUserSelector = (event: any, value: any) => {
    if (formikRef.current) formikRef.current.setFieldValue("user", value, true);
  };

  const handleExpensesForm = async (
    values: typeof initialValues,
    resetForm: any,
    fileId: any
  ) => {
    await addExpense({
      ...values,
      type: values?.type,
      material: values?.material === "" ? null : values?.material,
      cost: values?.cost,
      vehicleId: values.vehicleId === "" ? null : values.vehicleId,
      distance: values.distance === "" ? null : values.distance,
      fileId: fileId,
      userId:
        loggedUser?.role === "Admin"
          ? (values?.user as any)?.id
          : loggedUser?.userId,
    }).then((onfulfilled: any) => {
      if (onfulfilled?.data) {
        resetForm(initialValues);
        setIsLoading(false);
        showSnackbar(`${t("EXPENSES.ADD_EXPENSE.SUCCESS")}`, false, true);
        navigate(-1);
      } else if (onfulfilled?.error) {
        setIsLoading(false);
        showSnackbar(
          `${t("EXPENSES.ADD_EXPENSE.ERROR")},
            ${onfulfilled?.error.data.error.message}`,
          true,
          true
        );
      }
    });
    setOpenModal(false);
  };

  useEffect(() => {
    allVehicles(undefined);
  }, []);

  const initialValues = {
    user: "",
    type: "",
    vehicleId: "",
    distance: "",
    material: "",
    cost: "",
    files: [],
  };

  const kilometers =
    previousKilometersResult?.data?.previousKilometers > 0
      ? previousKilometersResult?.data?.previousKilometers
      : previousKilometersResult?.data?.initialKilometers;

  const validate = (values: typeof initialValues) => {
    const errors: any = {};

    if (values?.type === "Fuel" && loggedUser?.role !== "Admin") {
      handleRequiredValidation(values, errors, "vehicleId", t("FORM_VALIDATIONS.REQUIRED"));
      handleExpensesVehicleKM("distance", values, kilometers, errors, {
        required: t("FORM_VALIDATIONS.REQUIRED"),
        currentValueGreaterThanPreviousValue: t("FORM_VALIDATIONS.EXPENSES.DISTANCE")
      });
    }
    if (values?.type === "Material") {
      handleRequiredValidation(values, errors, "material", t("FORM_VALIDATIONS.REQUIRED"));
    }

    handleRequiredValidation(values, errors, "type", t("FORM_VALIDATIONS.REQUIRED"));
    handleExpensesCostValidations("cost", values, errors, {
      required: t("FORM_VALIDATIONS.REQUIRED"),
      cost: t("FORM_VALIDATIONS.EXPENSES.COST"),
    });
    if (values?.files?.length === 0) {
      errors.files = t("FORM_VALIDATIONS.EXPENSES.FILE");
    }

    if (loggedUser?.role === "Admin") {
      handleRequiredValidation(values, errors, "user", t("FORM_VALIDATIONS.REQUIRED"));
    }

    return errors;
  };
  const handleShowAlertDialog = () => {
    setOpenModal(true);
  };

  const isMobile = useIsMobile();
  const isTablet = useIsTablet();

  return (
    <BoxContainer>
      <CustomHeader
        title={t("EXPENSES.ADD_EXPENSE.TITLE")}
        subtitle={t("EXPENSES.SUBTITLE")}
      >
        <CustomNavigationButton
          customStyle={{
            backgroundColor: BLUE_COLOR_ACCENT,
            width: isTablet ? "20%" : "10%",
            display: "flex",
            alignSelf: "center",
            fontSize: "12px",
            gap: 1,
            ":hover": {
              bgcolor: "#1d61c6",
              color: "white",
            },
          }}
          navigateTo={"/expenses"}
        >
          <KeyboardArrowLeftIcon />
          {isMobile ? null : t("GO_BACK")}
        </CustomNavigationButton>
      </CustomHeader>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validate={validate}
        onSubmit={handleShowAlertDialog}
      >
        {({
          errors,
          values,
          handleChange,
          handleSubmit,
          touched,
          setFieldValue,
          resetForm,
        }) => (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: isMobile ? 1 : 3,
                backgroundColor: colors.component,
                padding: isMobile ? 2 : 3,
                paddingTop: 3,
                borderRadius: 3,
                width: "100%",
                minHeight: "480px",
                flexDirection: isMobile ? "column" : "row",
                gap: isMobile ? 2 : 0,
                flexWrap: "wrap",
              }}
            >
              <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                  width: isMobile ? "100%" : "30%",
                  display: "flex",
                  flexDirection: "column",
                  gap: isMobile ? 2 : 5,
                }}
              >
                {loggedUser?.role === "Admin" && (
                  <CustomSearchSelector
                    datas={users}
                    name="user"
                    label="Select user"
                    value={values?.user}
                    errors={errors && errors?.user}
                    touched={touched && touched?.user}
                    width="100%"
                    onSelectChange={handleSelectUserSelector}
                  />
                )}
                <CustomSelector
                  label={t("EXPENSES.ADD_EXPENSE.EXPENSES_TYPE")}
                  value={values?.type}
                  onSelectChange={handleChange}
                  width={"100%"}
                  name="type"
                  errors={errors?.type}
                  touched={touched?.type}
                >
                  {Object?.values(Expenses)?.map((value, index: number) => (
                    <MenuItem key={index} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </CustomSelector>
                {values?.type === "Fuel" && (
                  <>
                    <CustomSelector
                      width={"100%"}
                      label={t("EXPENSES.ADD_EXPENSE.SELECT_AUTO")}
                      value={values?.vehicleId}
                      onSelectChange={handleChange}
                      name="vehicleId"
                      errors={errors?.vehicleId}
                      touched={touched?.vehicleId}
                    >
                      {allVehiclesResult?.data?.map(
                        (value: any, index: number) => (
                          <MenuItem
                            key={index}
                            value={value?.vehicleId}
                            onClick={() => setVehicleId(value?.vehicleId)}
                          >
                            {`${value?.model} - ${value.plate}`}
                          </MenuItem>
                        )
                      )}
                    </CustomSelector>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <CustomTextField
                        width={kilometers ? "47%" : "100%"}
                        type="number"
                        value={values?.distance}
                        onChange={handleChange}
                        label={t("VEHICLE.TABLE_TITLES.KILOMETERS")}
                        name="distance"
                        variant="outlined"
                        errors={errors?.distance}
                        touched={touched?.distance}
                      />
                      {kilometers && (
                        <CustomTextField
                          disabled
                          width={"47%"}
                          type="number"
                          value={kilometers}
                          onChange={handleChange}
                          label={t("VEHICLE.TABLE_TITLES.PREVIOUS_KILOMETERS")}
                          name="distance"
                          variant="outlined"
                        />
                      )}
                    </Box>
                  </>
                )}

                {values?.type === "Material" && (
                  <CustomTextField
                    width={"100%"}
                    type="text"
                    value={values?.material}
                    onChange={handleChange}
                    label={t("EXPENSES.ADD_EXPENSE.ENTER_MATERIAL")}
                    name="material"
                    variant="outlined"
                    errors={errors?.material}
                    touched={touched?.material}
                  />
                )}

                <CustomTextField
                  width={"100%"}
                  type="number"
                  value={values?.cost}
                  onChange={handleChange}
                  label={t("EXPENSES.ADD_EXPENSE.ENTER_COST") + " CHF"}
                  name="cost"
                  variant="outlined"
                  errors={errors?.cost}
                  touched={touched?.cost}
                />
              </Box>
              <Box
                sx={{
                  width: isMobile ? "100%" : "65%",
                  minHeight: "auto",
                }}
              >
                <UploadFile
                  name="file"
                  files={values?.files}
                  setFieldValue={setFieldValue}
                  user={loggedUser}
                  openModal={openModal}
                  setOpenModal={setOpenModal}
                  handleSubmit={handleSubmit}
                  pond={pond}
                  errors={errors?.files}
                />
              </Box>
              <Box sx={{ width: "100%" }}>
                <AlertDialog
                  openModal={openModal}
                  setOpenModal={setOpenModal}
                  buttonTitle={`${t("SUBMIT")}`}
                  width="30%"
                  handleSubmitDialog={() => onSubmit(values, resetForm)}
                  color={BLUE_COLOR_ACCENT}
                  colorHover={BLUE_HOVER_COLOR_ACCENT}
                  handleShowAlertDialog={handleSubmit}
                  disabled={isLoading}
                >
                  {t("EXPENSES.ADD_EXPENSE.SUBMIT_TEXT")}
                </AlertDialog>
              </Box>
            </Box>
          </>
        )}
      </Formik>
    </BoxContainer>
  );
};

export default AddExpenses;
