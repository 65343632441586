import { Box } from "@mui/system";
import { Formik } from "formik";
import {
  DEPARTMENT_NAME_REGEX,
  handleRegexValidation,
} from "../../utils/regex/formRegex";
import DepartmentForm from "./DepartmentForm";
import { useUpdateDepartmentMutation } from "../../data/endpoints/app.endpoints";
import { Department } from "../../data/models/department.interface";
import { useTranslation } from "react-i18next";

const UpdateDepartment = ({
  updateModal,
  setUpdateModal,
  department,
}: {
  department: Department;
  updateModal: boolean;
  setUpdateModal: any;
}) => {
  const [updateDepartment, result] = useUpdateDepartmentMutation();

  const initialValues = {
    name: "",
  };

  const { t } = useTranslation();

  const onFormSubmit = () => {};

  const validate = (values: typeof initialValues) => {
    const errors = {} as any;

    handleRegexValidation(
      values,
      errors,
      "name",
      DEPARTMENT_NAME_REGEX,
      t("FORM_VALIDATIONS.DEPARTMENT_VALIDATIONS.INVALID_NAME"),
      t("FORM_VALIDATIONS.REQUIRED")
    );
    return errors;
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        gap: 2,
      }}
    >
      <Formik
        onSubmit={onFormSubmit}
        initialValues={department}
        validate={validate}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          resetForm,
        }: any) => (
          <DepartmentForm
            buttonTitle="UPDATE"
            errors={errors}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            setFieldValue={setFieldValue}
            handleMutation={updateDepartment}
            touched={touched}
            values={values}
            resetForm={resetForm}
            id={department.departmentId}
            departmentName={department.name}
            updateModal={updateModal}
            setUpdateModal={setUpdateModal}
            submitIsLoading={result?.isLoading}
          />
        )}
      </Formik>
    </Box>
  );
};

export default UpdateDepartment;
