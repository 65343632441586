import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from "@mui/material";
import CustomHeader from "../../shared/custom/CustomHeader";
import CustomDatePicker from "../../shared/CustomDatePicker";
import { useEffect, useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { tokens } from "../../core/AppStyles";
import useIsMobile from "../../shared/hooks/useIsMobile";
import useIsTablet from "../../shared/hooks/useIsTablet";
import CustomHeaderMobile from "../../shared/custom/CustomHeaderMobile";
import CustomTextField from "../../shared/custom/CustomTextField";
import { useSelector } from "react-redux";
import {
  selectAllUsers,
  selectEmployeeById,
} from "../../data/store/employeeSlice";
import { ChevronRight, Person } from "@mui/icons-material";
import { User } from "../../data/models/user.interface";
import { useLazyGetAllExpensesByUserIdQuery } from "../../data/endpoints/app.endpoints";
import { Expense } from "../../data/models/expense.interface";

const UserExpenses = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const { t } = useTranslation();
  const [intervalStartTime, setIntervalStartTime] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [intervalEndTime, setIntervalEndTime] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );
  const [dateIntervalError, setDateIntervalError] = useState("");
  const [user, setUser] = useState<string>("");
  const [userId, setUserId] = useState<string>("");

  const [background, setBackground] = useState<string>("white");
  const [color, setColor] = useState<string>("black");

  const handleChange = (event: any) => {
    setUser(event.target.value);
  };

  const allUsers = useSelector(selectAllUsers);

  const filteredUsers = allUsers?.filter((_user: User) => {
    return (_user?.firstName + " " + _user?.lastName)
      ?.toLowerCase()
      .includes(user?.toLowerCase());
  });

  const handleClick = (_userId: string) => {
    const isMatching = allUsers.some((obj: User) => {
      setUserId(_userId);
      return obj?.userId === _userId;
    });

    if (isMatching) {
      setBackground(colors.timeline);
      setColor(colors.invert);
    } else {
      setBackground("#ffffff");
      setColor(colors.black);
    }
  };

  const selectedUser: User = useSelector(selectEmployeeById(userId));

  const [allExpenses, allExpensesResult] = useLazyGetAllExpensesByUserIdQuery();

  useEffect(() => {
    allExpenses({
      userId,
      startDate: intervalStartTime,
      endDate: intervalEndTime,
    });
  }, [userId, intervalStartTime, intervalEndTime]);

  const totalAmount: number | undefined = allExpensesResult?.data?.reduce(
    (total: number, expense: Expense) => total + expense?.cost,
    0
  );

  return (
    <Box
      sx={{
        width: "100%",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {isMobile || isTablet ? (
        <CustomHeaderMobile
          title={t("EXPENSES.USER_EXPENSES.TITLE")}
          subtitle={t("EXPENSES.USER_EXPENSES.SUBTITLE")}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "90px",
              marginTop: "2vh",
              justifyContent: "space-between",
            }}
          >
            <CustomDatePicker
              onChange={(value: any) => {
                setIntervalStartTime(moment(value?.$d).format("YYYY-MM-DD"));
              }}
              value={intervalStartTime}
              label={t("START_DATE")}
              views={["day"]}
              openTo="day"
            />

            <CustomDatePicker
              onChange={(value: any) =>
                setIntervalEndTime(moment(value?.$d).format("YYYY-MM-DD"))
              }
              value={intervalEndTime}
              label={t("END_DATE")}
              views={["day"]}
              openTo="day"
              touched={true}
              error={dateIntervalError}
            />
          </Box>
        </CustomHeaderMobile>
      ) : (
        <CustomHeader
          title={t("EXPENSES.USER_EXPENSES.TITLE")}
          subtitle={t("EXPENSES.USER_EXPENSES.SUBTITLE")}
        >
          {" "}
          <Box
            sx={{
              width: "40%",
              display: "flex",
              justifyContent: "space-around",
              gap: 2,
            }}
          >
            <CustomDatePicker
              onChange={(value: any) => {
                setIntervalStartTime(moment(value?.$d).format("YYYY-MM-DD"));
              }}
              value={intervalStartTime}
              label={t("START_DATE")}
              views={["day"]}
              openTo="day"
            />

            <CustomDatePicker
              onChange={(value: any) =>
                setIntervalEndTime(moment(value?.$d).format("YYYY-MM-DD"))
              }
              value={intervalEndTime}
              label={t("END_DATE")}
              views={["day"]}
              openTo="day"
              touched={true}
              error={dateIntervalError}
            />
          </Box>
        </CustomHeader>
      )}
      <Box
        sx={{
          width: "100%",
          height: `${isMobile || isTablet ? "auto" : "75vh"}`,
          padding: "2vh 1vw",
          display: "flex",
          flexDirection: `${isMobile || isTablet ? "column" : "row"}`,
          justifyContent: "space-between",
          borderRadius: "10px",
        }}
      >
        <Box
          sx={{
            width: isMobile || isTablet ? "100%" : "30%",
            height: "100%",
            background: colors.component,
            overflow: "hidden",
            padding: "2%",
            borderRadius: "2%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            boxShadow: "1px 1px 3px 0.5px rgba(0, 0, 0, 0.08)",
          }}
        >
          <CustomTextField
            label={t("EXPENSES.USER_EXPENSES.SEARCH_USER")}
            type="text"
            variant="outlined"
            name="User"
            value={user}
            onChange={handleChange}
          />
          <Box
            sx={{
              width: "100%",
              height: isMobile ? "100px" : isTablet ? "200px" : "85%",
              borderRadius: "2%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              overflowY: "auto",
            }}
          >
            <List
              subheader={
                filteredUsers?.length > 0
                  ? t("EXPENSES.USER_EXPENSES.USER_LIST")
                  : t("EXPENSES.USER_EXPENSES.NO_USERS")
              }
              sx={{ width: "100%" }}
            >
              {filteredUsers?.length > 0
                ? filteredUsers?.map((user: User, index: number) => {
                    return (
                      <ListItem
                        sx={{
                          width: "100%",
                        }}
                        key={index}
                        onClick={() => handleClick(user?.userId)}
                      >
                        <ListItemButton
                          sx={{
                            background: `${
                              userId === user?.userId
                                ? background
                                : colors.component
                            }`,
                            color: `${
                              userId === user?.userId ? color : colors.invert
                            }`,
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              color: `${
                                userId === user?.userId ? color : colors.invert
                              }`,
                              opacity: 0.6,
                            }}
                          >
                            <Person />
                          </ListItemIcon>
                          <ListItemText
                            primary={user?.firstName + " " + user?.lastName}
                          />

                          <ListItemIcon
                            sx={{
                              color: `${
                                userId === user?.userId ? color : colors.invert
                              }`,
                            }}
                          >
                            <ChevronRight />
                          </ListItemIcon>
                        </ListItemButton>
                      </ListItem>
                    );
                  })
                : ""}
            </List>
          </Box>
        </Box>

        <Box
          sx={{
            width: isMobile || isTablet ? "100%" : "60%",
            height: "100%",
            background: colors.component,
            overflow: "hidden",
            padding: "2%",
            borderRadius: "2%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            boxShadow: "1px 1px 3px 0.5px rgba(0, 0, 0, 0.08)",
            marginTop: isMobile || isTablet ? "3vh" : "0vh",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "40px",
              display: isMobile || isTablet ? "none" : "grid",
              gridTemplateColumns: "30% 30% 30%",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: 3,
            }}
          >
            <small>
              <b>{t("EXPENSES.USER_EXPENSES.DATES")}:</b>{" "}
              {moment(intervalStartTime)?.format("DD MMM YYYY") +
                "  --  " +
                moment(intervalEndTime)?.format("DD MMM YYYY")}
            </small>
            <small>
              <b>{t("EXPENSES.USER_EXPENSES.SELECTED_USER")}:</b>{" "}
              {selectedUser
                ? selectedUser?.firstName + " " + selectedUser?.lastName
                : t("EXPENSES.USER_EXPENSES.NOT_SELECTED")}
            </small>
            <small>
              <b>{t("EXPENSES.USER_EXPENSES.TOTAL_EXPENSES")}</b>{" "}
              {allExpensesResult?.data?.length || 0}
            </small>
          </Box>
          {allExpensesResult?.data?.length != 0 ? (
            <Box
              sx={{
                width: "100%",
                height: "40px",
                display: "grid",
                gridTemplateColumns: "25% 25% 25% 25%",
                alignItems: "center",
                textAlign: "center",
                background: colors.component,
                color: colors.invert,
                fontWeight: "bold",
              }}
            >
              <small>No.</small>
              <small>{t("EXPENSES.USER_EXPENSES.TYPE")}</small>
              <small>{t("EXPENSES.USER_EXPENSES.DATE")}</small>
              <small>{t("EXPENSES.USER_EXPENSES.AMOUNT")} CHF</small>
            </Box>
          ) : (
            <Box
              sx={{
                width: "100%",
                height: "40px",
                display: "flex",
                alignItems: "center",
                background: colors.timeline,
                justifyContent: "center",
                color: colors.invert,
              }}
            >
              {t("EXPENSES.USER_EXPENSES.NO_EXPENSES")}
            </Box>
          )}
          <Divider sx={{ width: "100%" }} />
          <Box
            sx={{
              width: "100%",
              height: "75%",
              display: "flex",
              flexDirection: "column",
              alignItems: "space-between",
              overflowY: "auto",
            }}
          >
            {allExpensesResult?.data?.map((expense: Expense, index: number) => {
              return (
                <Box
                  sx={{
                    width: "100%",
                    display: "grid",
                    gridTemplateColumns: "25% 25% 25% 25%",
                    alignItems: "center",
                    height: "40px",
                    marginTop: "2vh",
                    textAlign: "center",
                  }}
                  key={index}
                >
                  <small>{index + 1}</small>
                  <small>{expense?.type}</small>
                  <small>
                    {moment(expense?.createdAt)?.format("DD MMM YYYY")}
                  </small>
                  <small>{expense?.cost} CHF</small>
                </Box>
              );
            })}
          </Box>
          <Divider sx={{ width: "100%" }} />
          <Box
            sx={{
              width: "100%",
              height: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <p>{t("EXPENSES.USER_EXPENSES.TOTAL_AMOUNT")}</p>
            <b>: {totalAmount || 0} CHF</b>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default UserExpenses;
