import { configureStore } from "@reduxjs/toolkit";
import { homeSlice } from "./homeSlice";
import { authSlice } from "./authSlice";
import { employeeSlice } from "./employeeSlice";
import { appEndpoints } from "../endpoints/app.endpoints";
import { projectSlice } from "./projectSlice";
import { departmentSlice } from "./departmentSlice";
import { holidaysSlice } from "./holidaysSlice";
import { leavesSlice } from "./leaveRequestSlice";
import { equipmentsSlice } from "./equipmentSlice";
import { chatSlice } from "./chatSlice";
import { dashboardSlice } from "./userDashboardSlice";
import { admindashboardSlice } from "./adminDashboardSlice";
import { vehicleSlice } from "./vehicleSlice";
import { expensesSlice } from "./expensesSlice";

export const store = configureStore({
  reducer: {
    [appEndpoints.reducerPath]: appEndpoints.reducer,
    [authSlice.name]: authSlice.reducer,
    [homeSlice.name]: homeSlice.reducer,
    [employeeSlice.name]: employeeSlice.reducer,
    [projectSlice.name]: projectSlice.reducer,
    [departmentSlice.name]: departmentSlice.reducer,
    [holidaysSlice.name]: holidaysSlice.reducer,
    [leavesSlice.name]: leavesSlice.reducer,
    [equipmentsSlice.name]: equipmentsSlice.reducer,
    [expensesSlice.name]: expensesSlice.reducer,
    [chatSlice.name]: chatSlice.reducer,
    [dashboardSlice.name]: dashboardSlice.reducer,
    [admindashboardSlice.name]: admindashboardSlice.reducer,
    [vehicleSlice.name]: vehicleSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(appEndpoints.middleware),
  devTools: process.env.NODE_ENV === "development",
});

export type RootState = ReturnType<typeof store.getState>;
