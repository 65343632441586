/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import {
  useLazyGetExpensesByUserIdQuery,
  useLazyGetPaginatedExpensesQuery,
} from "../../data/endpoints/app.endpoints";
import { useSelector } from "react-redux";
import CustomSelector from "../../shared/custom/CustomSelector";
import { Box, MenuItem, SelectChangeEvent } from "@mui/material";
import CustomTable from "../../shared/custom/CustomTable";
import ExpensesTableRow from "./ExpensesTableRow";
import CustomHeader from "../../shared/custom/CustomHeader";
import { selectLoggedUser } from "../../data/store/authSlice";
import useIsMobile from "../../shared/hooks/useIsMobile";
import useIsTablet from "../../shared/hooks/useIsTablet";
import { useTranslation } from "react-i18next";
import NoDataComponent from "../../shared/NoDataComponent";
import SearchBar from "../../shared/custom/SearchBar";
import CustomNavigationButton from "../../shared/custom/CustomNavigationButton";
import { BLUE_COLOR_ACCENT } from "../../utils/constants/colors";
import CustomHeaderMobile from "../../shared/custom/CustomHeaderMobile";
import CustomExpensesTableMobile from "./CustomExpensesTableMobile";
import LoadingSpinner from "../../shared/LoadingSpinner";
import { QueryStatus } from "@reduxjs/toolkit/query";

const FileDownload = () => {
  const user = useSelector(selectLoggedUser);
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [q, setQ] = useState("");
  const [type, setType] = useState("ALL");
  const [limit, setLimit] = useState<any>(10);
  const [allExpenses, allExpensesResult] = useLazyGetPaginatedExpensesQuery();
  const [userExpenses, userExpensesResult] = useLazyGetExpensesByUserIdQuery();

  const handleRowPerPageChange = (event: SelectChangeEvent) => {
    setLimit(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleTypeChange = (event: SelectChangeEvent) => {
    setType(event.target.value);
    setPage(1);
  };

  useEffect(() => {
    if (allExpensesResult?.data?.data?.length === 0 && page !== 1) {
      setPage(page - 1);
    }
    if (userExpensesResult?.data?.data?.length === 0 && page !== 1) {
      setPage(page - 1);
    }
  }, [allExpensesResult?.data, userExpensesResult?.data]);

  useEffect(() => {
    user?.role === "Employee"
      ? userExpenses({
          userId: user?.userId,
          currentPage: page,
          limitTo: limit,
          q,
          type: type === "ALL" ? "" : type,
        })
      : allExpenses({
          currentPage: page,
          limitTo: limit,
          q,
          type: type === "ALL" ? "" : type,
        });
  }, [q, page, limit, type, user]);

  const expensesTitles =
    user?.role === "Admin"
      ? [
          `${t("FULL_NAME")}`,
          "Type",
          "Vehicle",
          `${t("DATE")}`,
          "Kilometers",
          "Material",
          "Cost (CHF)",
          `${t("ACTIONS")}`,
        ]
      : [
          "Type",
          "Vehicle",
          `${t("DATE")}`,
          "Kilometers",
          "Material",
          "Cost (CHF)",
          `${t("ACTIONS")}`,
        ];

  const expensesBodyRenderer = useMemo(() => {
    const newFilteredExpenses: JSX.Element[] = [];
    user?.role === "Employee"
      ? userExpensesResult?.data?.data?.map((file: any, index: number) => {
          return newFilteredExpenses?.push(
            <ExpensesTableRow row={file} key={index} />
          );
        })
      : allExpensesResult?.data?.data?.map((file: any, index: number) => {
          return newFilteredExpenses?.push(
            <ExpensesTableRow row={file} key={index} />
          );
        });
    return newFilteredExpenses;
  }, [allExpensesResult?.data?.data, userExpensesResult?.data?.data]);

  return (
    <div>
      {isMobile || isTablet ? (
        <CustomHeaderMobile
          title={t("EXPENSES.TITLE")}
          subtitle={t("EXPENSES.SUBTITLE")}
          btnNavLink={"/add-expenses"}
          btnTitle={"+"}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "1vh",
              gap: 2,
            }}
          >
            <SearchBar setSearchEvent={setQ} setPage={setPage} />
            <CustomSelector
              size="small"
              label={t("FILE_MANAGER.FILE_UPLOAD.SELECT_TYPE")}
              value={type}
              onSelectChange={handleTypeChange}
              width={isMobile || isTablet ? "100%" : "40%"}
            >
              <MenuItem value="ALL">{t("STATUSES.ALL")}</MenuItem>
              <MenuItem value="Material">
                {t("EXPENSES.ADD_EXPENSE.MATERIAL")}
              </MenuItem>
              <MenuItem value="Fuel">{t("EXPENSES.ADD_EXPENSE.FUEL")}</MenuItem>
            </CustomSelector>
          </Box>
        </CustomHeaderMobile>
      ) : (
        <CustomHeader
          title={t("EXPENSES.TITLE")}
          subtitle={t("EXPENSES.SUBTITLE")}
        >
          <Box
            sx={{
              width: "50%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 2,
            }}
          >
            <SearchBar setSearchEvent={setQ} setPage={setPage} />
            <CustomSelector
              size="small"
              label={t("FILE_MANAGER.FILE_UPLOAD.SELECT_TYPE")}
              value={type}
              onSelectChange={handleTypeChange}
              width="40%"
            >
              <MenuItem value="ALL">{t("STATUSES.ALL")}</MenuItem>
              <MenuItem value="Material">
                {t("EXPENSES.ADD_EXPENSE.MATERIAL")}
              </MenuItem>
              <MenuItem value="Fuel">{t("EXPENSES.ADD_EXPENSE.FUEL")}</MenuItem>
            </CustomSelector>

            <CustomNavigationButton
              customStyle={{
                backgroundColor: BLUE_COLOR_ACCENT,
                width: isTablet ? "20%" : "10%",
                display: "flex",
                alignSelf: "center",
                fontSize: "12px",
                gap: 1,
                ":hover": {
                  bgcolor: "#1d61c6",
                  color: "white",
                },
              }}
              navigateTo={"/add-expenses"}
            >
              {isMobile ? "+" : t("EXPENSES.ADD_EXPENSE.ADD")}
            </CustomNavigationButton>
          </Box>
        </CustomHeader>
      )}
      {allExpensesResult?.status === QueryStatus.fulfilled ||
      userExpensesResult?.status === QueryStatus.fulfilled ? (
        (allExpensesResult?.data?.data?.length ||
          userExpensesResult?.data?.data?.length) > 0 ? (
          <>
            <Box
              sx={{
                mt: "2%",
              }}
            ></Box>
            {isMobile || isTablet ? (
              <CustomExpensesTableMobile
                column1Name="Details"
                column2Name="Expenses"
                data={
                  user?.role === "Admin"
                    ? allExpensesResult?.data?.data
                    : userExpensesResult?.data?.data
                }
                isPaginated={
                  user?.role === "Admin"
                    ? allExpensesResult?.data?.data > 10
                    : userExpensesResult?.data?.data > 10
                }
              />
            ) : (
              <CustomTable
                isPaginated={
                  (allExpensesResult?.data?.totalRecords ||
                    userExpensesResult?.data?.totalRecords) > 10
                    ? true
                    : false
                }
                tableTitles={expensesTitles}
                tableBodyRenderer={expensesBodyRenderer}
                handleChangePage={handleChangePage}
                rowsPerPage={limit}
                handleChangeRowsPerPage={handleRowPerPageChange}
                page={page}
                count={
                  allExpensesResult?.data?.totalRecords ||
                  userExpensesResult?.data?.totalRecords
                }
              />
            )}
          </>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: isMobile ? "40vh" : "65vh",
            }}
          >
            <NoDataComponent
              title={`There are no files for the selected type !`}
            />
          </Box>
        )
      ) : (
        <LoadingSpinner />
      )}
    </div>
  );
};

export default FileDownload;
