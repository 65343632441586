import { PieChart, Pie, Tooltip, ResponsiveContainer, Cell } from "recharts";

interface IPieData {
  data: {
    name: string;
    value: number;
  }[];
  width: number;
  height: number;
  outerRadius: number;
}

const COLORS = ["#00C49F", "#8884d8", "#FF8042", "#0088FE", "#FFBB28"];


const PieChartAdmin: React.FC<IPieData> = ({
  data,
  width,
  height,
  outerRadius,
}) => {

  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart width={width} height={height}>
        <Pie
          dataKey="value"
          isAnimationActive={false}
          data={data}
          cx="50%"
          cy="50%"
          outerRadius={outerRadius}
          fill="#8884d8"
          label
        >
          {data?.map((entry: any, index: number) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default PieChartAdmin;
