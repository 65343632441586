/* eslint-disable react-hooks/exhaustive-deps */
import { Box, useTheme } from "@mui/material";
import CustomTextField from "../../../../shared/custom/CustomTextField";
import { useState, useEffect } from "react";
import projectImg from "../../../../assets/projectspng.png";
import {
  useLazyGetInsightProjectsQuery,
  useLazyGetEmployeesHoursInProjectQuery,
} from "../../../../data/endpoints/app.endpoints";
import { tokens } from "../../../../core/AppStyles";
import CustomHeader from "../../../../shared/custom/CustomHeader";
import useIsMobile from "../../../../shared/hooks/useIsMobile";
import CustomDatePicker from "../../../../shared/CustomDatePicker";
import moment from "moment";
import useIsTablet from "../../../../shared/hooks/useIsTablet";
import CustomHeaderMobile from "../../../../shared/custom/CustomHeaderMobile";
import { handleIntervalDateValidation } from "../../../../utils/regex/formRegex";
import { useTranslation } from "react-i18next";

const EmployeesOnProjects = () => {
  const [project, setProject] = useState<string>("");
  const [projectId, setProjectId] = useState<string>("");

  const [background, setBackgound] = useState<string>("white");
  const [color, setColor] = useState<string>("black");
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const [allProject, allProjectsResult] = useLazyGetInsightProjectsQuery();
  const [EmplyeeHoursInProject, EmployeesOnProjectsResults] =
    useLazyGetEmployeesHoursInProjectQuery();
  const [errors, setErrors] = useState<any>({});
  const [projectStatus, setProjectStatus] = useState<string>("In Progress");

  const [intervalStartTime, setIntervalStartTime] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [intervalEndTime, setIntervalEndTime] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );
  const validateDate = () => {
    const error = {};
    handleIntervalDateValidation(
      error,
      "endDate",
      {
        startDate: intervalStartTime,
        endDate: intervalEndTime,
      },
      t("FORM_VALIDATIONS.DATE_VALIDATION.END_DATE_GREATER")
    );

    setErrors(error);
    return Object.keys(errors).length === 0;
  };

  useEffect(() => {
    allProject({ status: projectStatus === "All" ? "" : projectStatus });
  }, [projectStatus]);

  const handleChange = (event: any) => {
    setProject(event.target.value);
  };

  const hoursEmployeeWorkedInProject =
    EmployeesOnProjectsResults?.data?.hoursWorkedOnProject;

  const projects = allProjectsResult?.data;

  const filteredData = projects?.filter((item: any) => {
    return item?.title.toLowerCase().includes(project.toLowerCase());
  });

  const handleClick = (projectid: string) => {
    const isMatching = projects.some((obj: any) => {
      setProjectId(projectid);
      return obj?.projectId === projectid;
    });

    if (isMatching) {
      setBackgound(colors.cardHover);
      setColor(colors.black);
    } else {
      setBackgound("#ffffff");
      setColor(colors.black);
    }
  };
  useEffect(() => {
    validateDate() &&
      EmplyeeHoursInProject({
        projectId,
        startDate: intervalStartTime,
        endDate: intervalEndTime,
      });
  }, [projectId, intervalStartTime, intervalEndTime]);

  return (
    <Box
      sx={{
        width: "100%",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {isMobile || isTablet ? (
        <CustomHeaderMobile
          title={t("USERS_ADMIN.PROJECTS.PROJECT_INSIGHTS.TITLE")}
          subtitle={t("USERS_ADMIN.PROJECTS.PROJECT_INSIGHTS.SUBTITLE")}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "90px",
              marginTop: "2vh",
              justifyContent: "space-between",
            }}
          >
            <CustomDatePicker
              onChange={(value) => {
                setIntervalStartTime(value ? value.format("YYYY-MM-DD") : "");
              }}
              value={intervalStartTime}
              label={t("START_DATE")}
              views={["day"]}
              openTo="day"
            />

            <CustomDatePicker
              onChange={(value) =>
                setIntervalEndTime(value ? value.format("YYYY-MM-DD") : "")
              }
              value={intervalEndTime}
              label={t("END_DATE")}
              views={["day"]}
              openTo="day"
            />
          </Box>
        </CustomHeaderMobile>
      ) : (
        <CustomHeader
          title={t("USERS_ADMIN.PROJECTS.PROJECT_INSIGHTS.TITLE")}
          subtitle={t("USERS_ADMIN.PROJECTS.PROJECT_INSIGHTS.SUBTITLE")}
        >
          <Box
            sx={{
              width: "40%",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <CustomDatePicker
              onChange={(value) => {
                setIntervalStartTime(value ? value.format("YYYY-MM-DD") : "");
              }}
              value={intervalStartTime}
              label={t("START_DATE")}
              views={["day"]}
              openTo="day"
            />

            <CustomDatePicker
              onChange={(value) =>
                setIntervalEndTime(value ? value.format("YYYY-MM-DD") : "")
              }
              value={intervalEndTime}
              label={t("END_DATE")}
              views={["day"]}
              openTo="day"
            />
          </Box>
        </CustomHeader>
      )}
      <Box
        sx={{
          width: "100%",
          height: `${isMobile ? "85vh" : "75vh"}`,
          padding: "2vh 1vw",
          display: "flex",
          flexDirection: `${isMobile ? "column-reverse" : "row"}`,
          justifyContent: "space-around",
          borderRadius: "10px",
        }}
      >
        <Box
          sx={{
            width: `${isMobile ? "100%" : "30%"}`,
            height: `${isMobile ? "55%" : "100%"}`,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CustomTextField
            label={t(
              "USERS_ADMIN.PROJECTS.PROJECT_INSIGHTS.SEARCH_FOR_PROJECT"
            )}
            type="text"
            variant="outlined"
            name="Projects"
            value={project}
            onChange={handleChange}
          />
          <Box
            sx={{
              width: "100%",
              height: `${isMobile ? "7vh" : "5vh"}`,
              marginTop: "1vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              background: colors.blueAccent[800],
              borderRadius: "5px",
              color: "white",
            }}
          >
            <b style={{ width: "25%" }}>No.</b>
            <b style={{ width: "40%" }}>
              {t("USERS_ADMIN.PROJECTS.PROJECT_INSIGHTS.PROJECT_NAME")}
            </b>
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "80%",
              marginTop: "1vh",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              overflowY: "auto",
            }}
          >
            {filteredData?.length !== 0 ? (
              filteredData?.map((proj: any, index: number) => (
                <Box
                  style={{
                    width: "100%",
                    height: "fit-content",
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    marginTop: "1vh",
                    background: `${
                      projectId === proj.projectId
                        ? background
                        : colors.component
                    }`,
                    color: `${
                      projectId === proj.projectId ? color : colors.invert
                    }`,
                    cursor: "pointer",
                    fontWeight: 500,
                    borderRadius: "5px",
                    boxShadow: "1px 1px 3px 0.5px rgba(0,0,0,0.08)",
                  }}
                  key={index}
                  onClick={() => handleClick(proj?.projectId)}
                >
                  <p
                    style={{
                      width: "10%",
                      textAlign: "center",
                    }}
                  >
                    {index + 1}
                  </p>
                  <p
                    style={{
                      width: "60%",
                      textAlign: "center",
                    }}
                  >
                    {proj?.title}
                  </p>
                </Box>
              ))
            ) : (
              <p>{t("USERS_ADMIN.PROJECTS.PROJECT_INSIGHTS.NO_PROJECTS")}</p>
            )}
          </Box>
        </Box>

        <Box
          sx={{
            width: `${isMobile ? "100%" : "60%"}`,
            height: `${isMobile ? "40%" : "100%"}`,
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            borderRadius: "10px",
            boxShadow: "0px 0px 5px 0.5px rgba(0,0,0,0.08)",
            overflowY: "auto",
            backgroundColor: colors.component,
          }}
        >
          {errors?.endDate ? (
            <p
              style={{
                fontSize: `${isMobile ? "10px" : "auto"}`,
                color: "red",
                background: colors.redAccent[900],
                padding: "1% 2%",
              }}
            >
              {errors?.endDate}
            </p>
          ) : (
            <p
              style={{
                fontSize: `${isMobile ? "10px" : "auto"}`,
              }}
            >
              {t("USERS_ADMIN.PROJECTS.PROJECT_INSIGHTS.LIST_OF_USERS_TEXT")}
            </p>
          )}

          {hoursEmployeeWorkedInProject?.length !== 0 &&
          hoursEmployeeWorkedInProject?.length !== undefined ? (
            hoursEmployeeWorkedInProject?.map((emp: any, index: string) => (
              <li
                key={index + 1}
                style={{
                  listStyle: "none",
                  width: "90%",
                  height: "fit-content",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  borderRadius: "5px",
                  background: colors.cardHover,
                  marginTop: "2vh",
                  color: colors.black,
                }}
              >
                <b>{emp?.employee}</b>
                <b>
                  {(emp?.hoursWorked?.totalTimeWorked?.hours || 0) +
                    " h :" +
                    " " +
                    ((emp?.hoursWorked?.totalTimeWorked?.minutes || 0) +
                      " min")}
                </b>
              </li>
            ))
          ) : (
            <img
              src={projectImg}
              width={isMobile ? 100 : 300}
              style={{
                opacity: 0.1,
                marginTop: `${isMobile ? "3vh" : "10vh"}`,
              }}
              alt="img-project"
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default EmployeesOnProjects;
