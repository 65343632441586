import { memo } from "react";
import { TableCell, TableRow } from "@mui/material";

interface ContractTableRowProps {
    row: any;
    index: number
}

const ContractTableRow = ({ row, index }: ContractTableRowProps) => {
    return (
        <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell component="th" scope="row">
                {index}
            </TableCell>
            <TableCell component="th" scope="row">
                {row?.startDate}
            </TableCell>
            <TableCell component="th" scope="row">
                {row?.endDate ? row?.endDate : "Ongoing"}
            </TableCell>

            <TableCell component="th" scope="row">
                {row?.percentage}%
            </TableCell>
            <TableCell component="th" scope="row">
                {row?.percentage * row?.workingHours / 100}
            </TableCell>
        </TableRow>
    );
};

export default memo(ContractTableRow);
