import { Box, useTheme } from "@mui/material";
import moment from "moment";
import { tokens } from "../../../core/AppStyles";

const ReportHeader = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box
      sx={{
        width: "100%",
        height: "80px",
        display: "grid",
        overflow: "hidden",
        gridTemplateColumns: "70% 30%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          fontSize: "12px",
        }}
      >
        <small>JV Elektro GmbH</small>
        <small>Markgasse 2</small>
        <small>8600 Dubendorf</small>
        <small
          style={{
            marginTop: "10px",
          }}
        >
          {moment().format("DD.MM.yyyy")}
        </small>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-end",
          width: "100%",
          overflow: "hidden",
        }}
      >
        <img
          src={colors.kinetonImage["logo"]}
          alt="logo"
          style={{ width: "170px", padding: "1%" }}
        />
      </Box>
    </Box>
  );
};

export default ReportHeader;
