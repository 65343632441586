import CustomHeader from "../../shared/custom/CustomHeader";
import CustomNavigationButton from "../../shared/custom/CustomNavigationButton";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { RED_COLOR_ACCENT } from "../../utils/constants/colors";
import useIsMobile from "../../shared/hooks/useIsMobile";
import { useTranslation } from "react-i18next";

const ViewProject = () => {
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  return (
    <CustomHeader title="View Project">
      <CustomNavigationButton
        customStyle={{
          backgroundColor: RED_COLOR_ACCENT,
          width: "10%",
          display: "flex",
          gap: 1,
          alignSelf: "center",
          fontSize: "12px",
        }}
        navigateTo={-1}
      >
        <KeyboardArrowLeftIcon /> {isMobile ? null : t("GO_BACK")}
      </CustomNavigationButton>
    </CustomHeader>
  );
};

export default ViewProject;
