/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useMemo, useEffect } from "react";
import CustomTable from "../../shared/custom/CustomTable";
import Box from "@mui/material/Box";
import { SelectChangeEvent } from "@mui/material";
import CustomHeader from "../../shared/custom/CustomHeader";
import SearchBar from "../../shared/custom/SearchBar";
import { useLazyGetPaginatedVehiclesQuery } from "../../data/endpoints/app.endpoints";
import BoxContainer from "../../shared/BoxContainer";
import CustomButton from "../../shared/custom/CustomButton";
import {
  BLUE_COLOR_ACCENT,
  BLUE_HOVER_COLOR_ACCENT,
} from "../../utils/constants/colors";
import { useNavigate } from "react-router";
import useIsMobile from "../../shared/hooks/useIsMobile";
import CustomHeaderMobile from "../../shared/custom/CustomHeaderMobile";
import useIsTablet from "../../shared/hooks/useIsTablet";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import VehicleTableRow from "./VehicleTableRow";
import VehiclesCustomTableMobile from "./VehiclesCustomTableMobile";
import { Vehicle } from "../../data/models/vehicle.interface";
import moment from "moment";
import NoDataComponent from "../../shared/NoDataComponent";

const Vehicles = () => {
  const [getPaginatedUsers, result] = useLazyGetPaginatedVehiclesQuery();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();

  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [q, setQ] = useState("");
  const [limit, setLimit] = useState<any>(10);

  const { t } = useTranslation();

  const handleRowPerPageChange = (event: SelectChangeEvent) => {
    setLimit(event.target.value);

    setPage(1);
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    getPaginatedUsers({
      limitTo: limit,
      currentPage: page,
      q,
    });
  }, [q, limit, page]);

  const vehicleTitles = [
    "No",
    t("VEHICLE.TABLE_TITLES.MODEL"),
    t("VEHICLE.TABLE_TITLES.CAR_PLATE"),
    t("VEHICLE.TABLE_TITLES.KILOMETERS"),
    t("VEHICLE.TABLE_TITLES.START_DATE_OF_USE"),
    t("VEHICLE.TABLE_TITLES.OUT_OF_SERVICE_DATE"),
    t("VEHICLE.TABLE_TITLES.STATUS"),
    t("VEHICLE.TABLE_TITLES.EDIT"),
  ];

  const vehiclesBodyRenderer = useMemo(() => {
    const dataRenderer: JSX.Element[] = [];
    result?.data?.data?.map((row: any, index: number) =>
      dataRenderer.push(<VehicleTableRow key={index} row={row} i={index} />)
    );
    return dataRenderer;
  }, [result?.data]);

  const dataValues = useMemo(() => {
    let data: Object[] = [];
    if (result?.data) {
      result?.data?.data?.map((car: Vehicle, index: number) => {
        return data.push({
          model: car?.model,
          car_plate: car?.plate,
          kilometers: car?.kilometers,
          start_date_of_use: moment(car?.createdAt)?.format("YYYY/DD/MM"),
          out_of_service_date: car?.outOfService,
        });
      });
    }
    return data;
  }, [result?.data]);

  const headers = [
    { label: t("VEHICLE.TABLE_TITLES.MODEL"), key: "model" },
    { label: t("VEHICLE.TABLE_TITLES.CAR_PLATE"), key: "car_plate" },
    { label: t("VEHICLE.TABLE_TITLES.KILOMETERS"), key: "kilometers" },
    {
      label: t("VEHICLE.TABLE_TITLES.START_DATE_OF_USE"),
      key: "start_date_of_use",
    },
    {
      label: t("VEHICLE.TABLE_TITLES.OUT_OF_SERVICE_DATE"),
      key: "out_of_service_date",
    },
  ];

  return (
    <BoxContainer>
      {isMobile || isTablet ? (
        <CustomHeaderMobile
          title={t("VEHICLE.TITLE")}
          subtitle={t("VEHICLE.SUBTITLE")}
          btnTitle="+"
          btnNavLink="/add-vehicle"
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "100%",
              marginTop: "1vh",
            }}
          >
            <SearchBar
              setSearchEvent={setQ}
              border="1px solid"
              borderColor="transparent"
              setPage={setPage}
            />
            <Box
              sx={{
                width: "100%",
                marginTop: "2vh",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                gap: 2,
              }}
            ></Box>
          </Box>
        </CustomHeaderMobile>
      ) : (
        <CustomHeader
          title={t("VEHICLE.TITLE")}
          subtitle={t("VEHICLE.SUBTITLE")}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              width: "70%",
              alignItems: "center",
              gap: 1,
            }}
          >
            <SearchBar
              setSearchEvent={setQ}
              setPage={setPage}
              border="1px solid"
              borderColor="transparent"
            />
            <CustomButton
              title={t("VEHICLE.ADD")}
              width="35%"
              color={BLUE_COLOR_ACCENT}
              onClick={() => navigate("/add-vehicle")}
              colorHover={BLUE_HOVER_COLOR_ACCENT}
            />
          </Box>
        </CustomHeader>
      )}
      <>
        {!result?.data?.data?.length ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "75vh",
            }}
          >
            <NoDataComponent title={t("VEHICLE.NO_VEHICLE_FOUND")} />
          </Box>
        ) : isMobile || isTablet ? (
          <>
            <CSVLink
              data={dataValues}
              headers={headers}
              filename={`${t("SIDENAV.VEHICLES")} ${moment().format(
                "DD-MM-YYYY"
              )}`}
              style={{
                background: "#43b685",
                color: "white",
                fontWeight: "500",
                width: "80px",
                display: "flex",
                justifyContent: "center",
                alignSelf: "end",
                alignItems: "center",
                textDecoration: "none",
                padding: "0.4% 0.2%",
                borderRadius: "5px",
                float: "right",
                marginBottom: "1vh",
              }}
            >
              Excel
            </CSVLink>
            <VehiclesCustomTableMobile
              mt={2}
              column1Name={t("DETAILS")}
              column2Name={t("VEHICLE.TABLE_TITLES.VEHICLES_MOBILE")}
              isPaginated={result?.data?.totalRecords > 10}
              page={page}
              data={result?.data?.data}
              handleChangePage={handleChangePage}
              rowsPerPage={limit}
              handleChangeRowsPerPage={handleRowPerPageChange}
              count={result?.data?.totalRecords}
            />
          </>
        ) : (
          <>
            <CSVLink
              data={dataValues}
              headers={headers}
              filename={`${t("SIDENAV.VEHICLES")} ${moment().format(
                "DD-MM-YYYY"
              )}`}
              style={{
                background: "#43b685",
                color: "white",
                fontWeight: "500",
                width: "130px",
                display: "flex",
                justifyContent: "center",
                alignSelf: "end",
                alignItems: "center",
                textDecoration: "none",
                padding: "0.4% 0.2%",
                borderRadius: "5px",
                float: "right",
              }}
            >
              {t("EXPORT_TO_EXCEL")}
            </CSVLink>

            <Box mt={3}>
              <CustomTable
                page={page}
                isPaginated={result?.data?.totalRecords > 10}
                handleChangePage={handleChangePage}
                rowsPerPage={limit}
                handleChangeRowsPerPage={handleRowPerPageChange}
                tableTitles={vehicleTitles}
                tableBodyRenderer={vehiclesBodyRenderer}
                count={result?.data?.totalRecords}
              />
            </Box>
          </>
        )}
      </>
    </BoxContainer>
  );
};

export default Vehicles;
