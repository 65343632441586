import { useMemo } from "react";
import CustomHeader from "../../shared/custom/CustomHeader";
import { selectDepartments } from "../../data/store/departmentSlice";
import { useSelector } from "react-redux";
import { Department } from "../../data/models/department.interface";
import AddDepartment from "./AddDepartment";
import BoxContainer from "../../shared/BoxContainer";
import DepartmentTableRow from "./DepartmentTableRow";
import CustomTable from "../../shared/custom/CustomTable";
import useIsMobile from "../../shared/hooks/useIsMobile";
import useIsTablet from "../../shared/hooks/useIsTablet";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../core/AppStyles";
import { useTranslation } from "react-i18next";

const Departments = () => {
  const departments = useSelector(selectDepartments);
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  const departmentsTitles = ["No.", `${t("NAME")}`, ""];

  const departmentsBodyRenderer = useMemo(() => {
    const dataRenderer: JSX.Element[] = [];
    departments?.map((row: Department, index: number) =>
      dataRenderer.push(
        <DepartmentTableRow key={index} index={index + 1} row={row} />
      )
    );
    return dataRenderer;
  }, [departments]);

  return (
    <BoxContainer>
      <CustomHeader
        title={t("USERS_ADMIN.DEPARTMENTS.TITLE")}
        subtitle={t("USERS_ADMIN.DEPARTMENTS.SUBTITLE")}
      />
      <Box
        style={{
          display: "flex",
          flexDirection: `${isMobile || isTablet ? "column" : "row"}`,
          gap: `${isMobile || isTablet ? "0px" : "25px"}`,
          justifyContent: "center",
          borderRadius: "5px",
          backgroundColor: "transparent",
        }}
        mt={2}
      >
        <div
          style={{
            width: `${isMobile || isTablet ? "100%" : "30%"}`,
            marginBottom: "10px",
            background: colors.component,
            padding: isMobile ? "5%" : "3% 2%",
            borderRadius: "8px",
            boxShadow: " 0 0 1px 0.5px rgba(0,0,0,0.1)",
          }}
        >
          <AddDepartment />
        </div>
        <div style={{ width: `${isMobile || isTablet ? "100%" : "70%"}` }}>
          <CustomTable
            isPaginated={false}
            tableTitles={departmentsTitles}
            tableBodyRenderer={departmentsBodyRenderer}
          />
        </div>
      </Box>
    </BoxContainer>
  );
};

export default Departments;
